import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { underlineIcon } from '@progress/kendo-svg-icons';
import { Nutrient, OcrNutritionFacts } from 'src/app/Models/OcrNutritionFacts';
import { ScanBarcodeResult } from 'src/app/scan-barcode/models/scanBarCodeResult';
import { RestApiService } from 'src/app/services/RestApiService.service';

@Component({
  selector: 'app-image-ocrdata',
  templateUrl: './ocrdata.component.html',
  styleUrls: ['./ocrdata.component.css']
})
export class ImageOcrDataComponent implements OnInit {
  showAddPnsButton: boolean = false;

  isIpadVertical = window.innerWidth < window.innerHeight;
  @Input() ocrRawResponse!: string;
  @Input()
  ocrNutritionFacts!: OcrNutritionFacts;
  @Input() nutrientTypes!: Nutrient;

  @Input()
  gtinCode: number = -1;

  @Output()
  onSaveDraftOcrNutritionFacts = new EventEmitter<OcrNutritionFacts>();

  @Output()
  onAddToPnms = new EventEmitter();

  @Output()
  onScanNewItem = new EventEmitter();
  @Output() nutritionFactChanged = new EventEmitter();

  @Output() changedDevicePostion = new EventEmitter();

  @Output() compareItems = new EventEmitter();
  disableAddToPnms: boolean = false;


  constructor(private restApiService: RestApiService, private router: Router) {

  }

  ngOnInit(): void {
    this.showAddPnsButton = this.restApiService.showAddPnsButton;
  }

  onChangedDevicePostion(event: boolean): void {
    this.isIpadVertical == event;
    this.changedDevicePostion.emit(event);
  }
  showErrorMessage: boolean = false;
  errorMessage: string = ''
  saveDraftOcrData() {
    console.log(JSON.stringify(this.ocrNutritionFacts));
    if (this.ocrNutritionFacts.servingData == undefined) {
      this.errorMessage = "Please set serving values";
      this.showErrorMessage = true;
      return;
    }
    //if (this.ocrNutritionFacts.servingData.householdServingUom == undefined || this.ocrNutritionFacts.servingData.householdServingUom.trim() == '') {
    //  this.ocrNutritionFacts.servingData.householdServingUom = '0';
    //}
    //if (this.ocrNutritionFacts.servingData.servingPerPackage == undefined || this.ocrNutritionFacts.servingData.servingPerPackage.trim() == '') {
    //  this.ocrNutritionFacts.servingData.servingPerPackage = '0';
    //}
    //if (this.ocrNutritionFacts.servingData.visualServingUom == undefined || this.ocrNutritionFacts.servingData.visualServingUom.trim() == '') {
    //  this.ocrNutritionFacts.servingData.visualServingUom = '0';
    //}
    //if (this.ocrNutritionFacts.servingData.servingSizeVal == undefined || this.ocrNutritionFacts.servingData.servingSizeVal == null) {
    //  this.ocrNutritionFacts.servingData.servingSizeVal = 0;
    //}
    if (this.ocrNutritionFacts.servingData.servingSizeUom == undefined || this.ocrNutritionFacts.servingData.servingSizeUom.trim() == '') {
      this.errorMessage = "Please provide serving per size UOM";
      this.showErrorMessage = true;
      return;
    }
    this.onSaveDraftOcrNutritionFacts.emit(this.ocrNutritionFacts);
  }
  onClose() {
    this.showErrorMessage = false;
  }
  onConfirm() {
    this.showErrorMessage = false;
  }
  compare() {
    this.compareItems.emit();
  }

  addDataToPnms() {
    this.onAddToPnms.emit();
    this.disableAddToPnms = true;
  }

  scanNewItem() {
    this.onScanNewItem.emit();
  }

  onNutritionFactChanged() {
    //  this.hasNutritionFactChanges = true;
    this.nutritionFactChanged.emit();
  }

}


