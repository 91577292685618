<div class="px-4 py-4 pb-0 mt-4 bg-padding">
  <div style="border: 1px solid rgba(0,0,0,.125); border-radius: 0.25rem;">
    <div class=" back-btn">
      <a (click)="backClicked()" id="backtosearch">
        <i class="bi bi-arrow-left-circle-fill icon-green" style="margin-left: -20px;"></i>
      </a>
    </div>

    <div>
      <div *ngIf="getGtinNutritionCount()>1" class="left col-lg-4 mx-auto" style="width: 100%; margin-top: 5px;">
        <ng-container *ngFor="let i of searchGtinResults.components;index as t">
          <div [id]="t+1" *ngIf="t+1==id">
            <div style="display: table;">
              <div class="form-check-label font-head" for="Item" style="display: table-row;">
                <div style="float:left;margin-left: 100px;width:170px; display: table-column;text-align: left;">Item Code: {{searchGtinResults.itemCode}} </div>
                <div style="float:left;margin-left: 100px;width:370px;display: table-column;">
                  GTIN: {{i.gtin}}
                </div>
                <div style="display: table-column; float: right; width:400px;text-align: left;">
                  Variety Pack
                  <span *ngFor="let j of searchGtinResults.components;index as k">
                    <a (click)="ShowAndHide(k+1)" [id]="k+1+'x'" [ngClass]="k+1+'x'===id+'x'?'inactiveLink':'activeLink'" style="text-decoration: underline;padding-right: 3px; ">{{k+1}}</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-check-label font-head" style="text-align:left; padding-left: 100px;">
              Description:{{i.description}}
            </div>
          </div>

        </ng-container>
      </div>
      <div *ngIf="getGtinNutritionCount()==1" class="col-lg-4 mx-auto " style="width: 100%; margin-top: 5px;">
        <div>
          <div class="form-check-label font-head" for="Item">

            <div *ngIf="isLandscape">
              <span style="float:left ;margin-left: 100px;">Item Code: {{searchGtinResults.itemCode}} </span>
              <span>
                GTIN: {{searchGtinResults.components[0].gtin}}
              </span>
              <span style="float:right; margin-right: 10px;"> Description: {{searchGtinResults.itemDescription}}</span>
            </div>

            <div *ngIf="!isLandscape">
              <div class="item-info">
                <span>Item Code: {{ searchGtinResults.itemCode }}</span>
                <span>GTIN: {{ searchGtinResults.components[0].gtin }}</span>
                <span>Description: {{ searchGtinResults.itemDescription }}</span>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <hr>
    <main>
      <section class="mb-5 main-content">
        <div class="container-fluid">
          <div class="row">
            <ng-container *ngFor="let i of searchGtinResults.components;index as k">
              <div class="container">
                <div class="nutrition_facts div1" align="center"
                     [ngClass]="i.panelType==='Single Panel'?'col-xl-4 col-lg-4 col-md-4 col-sm-4':'col-xl-5 col-lg-5 col-md-5 col-sm-5'">
                  <div [id]="k+1" *ngIf="k+1==id">
                    <nutrition-fact [component]="i"></nutrition-fact>
                    <br>
                  </div>
                </div>
                <div align="center" class="div2"
                     [ngClass]="i.panelType==='Single Panel'?'col-xl-8 col-lg-8 col-md-8 col-sm-8':'col-xl-7 col-lg-7 col-md-7 col-sm-7'">
                  <div [id]="k+1" *ngIf="k+1==id" style="flex:1">
                    <div *ngIf="i.unwantedIngridients != null && i.unwantedIngridients" style="text-align: left; font-size: 12px; padding-left: 1rem; padding-bottom: 1rem;">
                      <b>Made Without : No</b>
                    </div>
                    <div *ngIf="i.unwantedIngridients == null || !i.unwantedIngridients" style="text-align: left; font-size: 12px;  padding-left: 1rem; padding-bottom: 1rem;">
                      <b>Made Without : Yes</b>
                    </div>
                    <div class="card mb-3">
                      <div class="card-body code-details">
                        <div class="detail-box">
                          <b>INGREDIENTS :</b> {{i.ingredientList}}
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3">
                      <div class="card-body code-details">
                        <div class="detail-box" style="min-height: 50px;">
                          <b>ALLERGEN :</b> {{i.allergenList}}
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3">
                      <div class="card-body code-details">
                        <div style="min-height: 100px;

                                            text-align: left;
                                            font-size: 12px;">
                          <table>
                            <tr style="border-bottom: 1px solid black;">
                              <th style="background-color:#2f880f; color:white">
                                PIMS
                                ATTRIBUTES
                              </th>
                              <th style="background-color:#2f880f;"></th>
                            </tr>
                            <tr style="border-bottom: 1px solid black;">
                              <th style="background-color:rgb(225 222 222 / 54%);">Name</th>
                              <th style="background-color:rgb(225 222 222 / 54%);">Value</th>
                            </tr>

                            <tr>
                              <td>
                                Natural
                              </td>
                              <td>
                                {{searchGtinResults.pimsAttribues.natural}}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                Excluded
                              </td>

                              <td>
                                {{searchGtinResults.pimsAttribues.excluded}}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Glutenfree
                              </td>
                              <td>
                                {{searchGtinResults.pimsAttribues.glutenfree}}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Organic1
                              </td>
                              <td>
                                {{searchGtinResults.pimsAttribues.organic1}}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Organic2
                              </td>
                              <td>
                                {{searchGtinResults.pimsAttribues.organic2}}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Organic3
                              </td>
                              <td>
                                {{searchGtinResults.pimsAttribues.organic3}}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3">
                      <div class="card-body code-details">
                        <div style="min-height: 100px;

                                                text-align: left;
                                                font-size: 12px;">
                          <table border="0">
                            <tr style="border-bottom: 1px solid black;">
                              <th style="background-color:#2f880f; color:white">
                                PNMS ATTRIBUTES
                              </th>
                              <th style="background-color:#2f880f;"></th>
                              <th style="background-color:#2f880f;"></th>
                            </tr>
                            <tr style="border-bottom: 1px solid black;">
                              <th style="background-color:rgb(225 222 222 / 54%);">
                                Attribute Name(UOM)
                              </th>
                              <th style="background-color:rgb(225 222 222 / 54%);">Amount Per Serving</th>
                              <th style="background-color:rgb(225 222 222 / 54%);">DV%</th>
                            </tr>
                            <tr *ngFor="let j of GetNutrientsToShow(id)">
                              <td style="width: 200px;">{{j.nutrientName}}%</td>
                              <td>
                                {{getDualDisplayValue(j.nutrValue)}}
                              </td>
                              <td>
                                {{getDualDisplayValue(j.dvp)}}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3" *ngIf="searchGtinResults.isPartOfAudit==true && showGotoPanel && isFromAudit==false && searchGtinResults.auditStatusName !== 'Completed'">
                      <a (click)="gotoAudit()" style=" color: blue;">Go To Audit Notes</a>
                    </div>
                    <div class="card mb-3" *ngIf="searchGtinResults.isPartOfAudit && isFromAudit==true">
                      <div class="card-body code-details">
                        <div style="min-height: 100px; text-align: left; font-size: 12px; position: relative;">
                          <table border="0" *ngIf="searchGtinResults.isPartOfAudit">
                            <tr style="border-bottom: 1px solid black;">
                              <th style="background-color:#2f880f; color:white">
                                Audit Notes
                              </th>
                            </tr>
                            <tr style="border-bottom: 1px solid black;">
                              <td>
                                <input type="text" [(ngModel)]="searchGtinResults.auditNotes"
                                       style="width: 100%; height: 45px; overflow: scroll!important;"
                                       [disabled]="searchGtinResults.auditStatusName === 'Completed'"
                                       (input)="onInputChange($event)" maxlength="2000" />

                              </td>
                            </tr>
                          </table>
                          <br />
                          <br />
                          <br />
                          <div style="position: absolute; bottom: 0px; right: 10px;" *ngIf="isFromAudit">
                            <button class="btn btn-secondary buttonsuccess"
                                    [disabled]="searchGtinResults.auditStatusName=='Completed'"
                                    (click)="AddAuditNotes()">
                              Add Audit Notes
                            </button>
                            <button class="btn btn-secondary buttonsuccess"
                                    [disabled]="searchGtinResults.auditStatusName=='Completed'"
                                    (click)="AddAuditNotesAndUpdateStatus()"
                                    style="margin-left: 10px;">
                              Audit Complete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3">
                      <button class="btn btn-secondary buttonsuccess"
                              (click)="GoToCaptureImagePage()"
                              style="margin-left: 50%; width:200px">
                        Go To Capture Image
                      </button>
                    </div>

                    <!--<div class="card mb-3">
                      <div class="card-body code-details">
                        <div style="min-height: 100px;

                                                  text-align: left;
                                                  font-size: 12px;">

                          <table border="0" *ngIf="showUpdateButton">
                            <tr style="border-bottom: 1px solid black;">
                              <th style="background-color:#2f880f; color:white">
                                Audit Notes
                              </th>
                            </tr>
                            <tr style="border-bottom: 1px solid black;">
                              <td>
                                <input type="text" [(ngModel)]="auditNotes" style="width: 100%;height:30%" [disabled]="gtinAuditStatus=='COMPLETED'" />
                              </td>
                            </tr>
                          </table>
                          <div style="padding-left:54%;padding-top:5%">
                            <table style="height:10px" border="0">

                              <tr>
                                <td>
                                  <button class="btn btn-secondary buttonsuccess"
                                          [disabled]="gtinAuditStatus=='COMPLETED'"
                                          (click)="AddAuditNotes()">
                                    Add Audit Notes
                                  </button>
                                </td>
                                <td>
                                <button class="btn btn-secondary buttonsuccess"
                                        [disabled]="gtinAuditStatus=='COMPLETED'"
                                        (click)="UpdateStatus()">
                                  Audit Complete
                                </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </section>
    </main>
  </div>
</div>
<!-- alert dialog-->
<app-modal #modalComponent>
  <h1 class="modal-title fs-5 app-modal-header">{{modalData.header}}</h1>
  <div class="app-modal-body">{{modalData.message}}</div>
  <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
          (click)="closeModal()">
    Close
  </button>
</app-modal>
