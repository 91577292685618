import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft-list',
  templateUrl: './draft-list.component.html'
})
export class DraftListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
