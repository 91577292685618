import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IcomponentDescription } from "../models/componentinfo";
import { ComponentSet, MutliColumnSet } from "../../Models/Gtin";
@Component({
  selector: 'create-components',
  templateUrl: './selectAndCreateComponent.html',
  styleUrls: ['./selectAndCreateComponent.css']
})
export class selectAndCreateComponent {


  @Input() public multiPanelopened: boolean = false;
  @Input() public selectedPanelType: string = '';
  @Output() closeCreateComponent = new EventEmitter();
  @Input() public panelType: string = '';
  @Input() public isMultiPanel: boolean = false;
  column = 1;
  isDualColumn = false;
  mutliColumnSet: MutliColumnSet[] = [];
  @Input() public compnonentPanelStatus: Array<IcomponentDescription> = [];
  description = '';
  selectedComponentId = '';
  componentID = -1;

  onTmpComonentSelected(value: string): void {
    if (value === '') {
      this.componentID = -1;
      this.isDualColumn = false;
      this.description = '';
    }
    if (value && value != "") {
      this.selectedComponentId = value;
      const multicolItem = this.mutliColumnSet.filter(x => x.componentID === + this.selectedComponentId);
      if (multicolItem.length > 0) {
        if (multicolItem[0].isDualColumn) {
          this.isDualColumn = true;
        }
        else {
          this.isDualColumn = false;
        }
      }
      else {
        const item = this.compnonentPanelStatus.filter(x => x.componentId === this.selectedComponentId);
        if (item.length > 0) {
          if (item[0].description === 'D') {
            this.isDualColumn = true;
          }
          else {
            this.isDualColumn = false;
          }
        }
      }

      const selectedComponent = this.tmpCompnonentDescriptions.find(comp => comp.componentId.toString() === value);

      if (selectedComponent != null) {
        this.description = selectedComponent?.description;
        this.componentID = +(selectedComponent.componentId);
      }
    }
  }
  componentSet: ComponentSet = new ComponentSet(); // Initialize componentSet here
  @Output() componentSetEvent = new EventEmitter<ComponentSet>();
  close() {
    this.componentSet.componentSet = this.compnonentDescriptions;
    this.componentSet.multiColSet = this.mutliColumnSet
    this.componentSetEvent.emit(this.componentSet);
  }

  isNotInCompDescriptions(componentId: any): boolean {
    return !this.compnonentDescriptions.some(comp => comp.componentId === componentId);
  }

  getdescrption(value: MutliColumnSet) {
    if (this.tmpCompnonentDescriptions.filter(x => +x.componentId == value.componentID)[0].description.length > 3) {
      return this.tmpCompnonentDescriptions.filter(x => +x.componentId == value.componentID)[0].description.substring(0, 3) + '...';
    }
    return this.tmpCompnonentDescriptions.filter(x => +x.componentId == value.componentID)[0].description;
  }
  generateCells(count: number): any[] {
    return Array(count).fill('').map((_, index) => index + 1);
  }
  closeDialogNonMultiPanel() {

  }
  @Output() mutliColumn = new EventEmitter<MutliColumnSet[]>();
  @Input() public compnonentDescriptions: Array<IcomponentDescription> = [];
  @Input() public tmpCompnonentDescriptions: Array<IcomponentDescription> = [];
  index: number = 0;
  tmpdescription = ''

  addNextMultiPanel() {
    if (this.description != '') {

      this.tmpdescription = this.description;
      this.description = '';
      const item = this.mutliColumnSet.filter(x => x.componentID === this.componentID);
      if (item.length > 0) {
        if (this.isDualColumn) {
          this.mutliColumnSet.filter(x => x.componentID === this.componentID)[0].isDualColumn = true;
        }
        else {
          this.mutliColumnSet.filter(x => x.componentID === this.componentID)[0].isDualColumn = false;
        }
        this.column = 1;

        for (var x = 0; x < this.mutliColumnSet.length; x++) {
          var updateItem = this.mutliColumnSet.filter(k => k.index === x);
          this.mutliColumnSet.filter(k => k.index === x)[0].column = this.column;
          if (updateItem[0].isDualColumn) {
            this.column = this.column + 2;
          }
          else {
            this.column = this.column + 1;
          }
        }
        this.mutliColumnSet.filter(x => x.componentID === this.componentID)[0].description = this.tmpdescription;
        this.tmpCompnonentDescriptions.filter(x => +x.componentId === this.componentID)[0].description = this.tmpdescription;
        this.compnonentDescriptions.filter(x => +x.componentId === this.componentID)[0].description = this.tmpdescription;
      }

      else {

        if (this.isDualColumn) {
          const obj = new MutliColumnSet(this.componentID !== -1 ? this.componentID : this.compnonentDescriptions.length + 1, this.column, true, this.description, this.componentID === -1 ? true : false, this.index);
          this.mutliColumnSet.push(obj);
          this.column += 2;
          this.index += 1;
        }

        else {
          const obj = new MutliColumnSet(this.componentID !== -1 ? this.componentID : this.compnonentDescriptions.length + 1, this.column, false, this.description, this.componentID === -1 ? true : false, this.index);
          this.mutliColumnSet.push(obj);
          this.column += 1;
          this.index += 1;
        }

        const newComponent = {
          componentId: (this.componentID !== -1 ? this.componentID : this.compnonentDescriptions.length + 1).toString(), // Assign a unique ID
          description: this.tmpdescription
        };
        this.compnonentDescriptions.push(newComponent);


        if (this.componentID === -1) {
          this.tmpCompnonentDescriptions.push(newComponent);
        }
        setTimeout(() => {
          if (this.componentID !== -1) {
            this.tmpCompnonentDescriptions.filter(x => x.componentId == this.componentID.toString())[0].description = this.tmpdescription;
          }
          this.selectedComponentId = this.compnonentDescriptions.length.toString();
        }, 500);
      }
    }
  }
}

