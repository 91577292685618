import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[NutrientNumberOnly]'
})
export class NutrientNumberOnlyDirective {

  //private regex: RegExp = new RegExp('^[0-9]*$');
  private regex: RegExp = new RegExp(/^[0-9]+[a-zA-Z]*(\.[0-9a-zA-Z]*){0,1}$/g);  //Decimal Number
  private word:RegExp = new  RegExp(/^([A-Za-z]+){0,1}$/);
  private specialKeys: Array<string> = ['Backspace','ArrowLeft','ArrowRight', 'Tab', 'Delete', 'Shift', 'Enter', 'Home', 'End'];
  constructor(private elementRef: ElementRef) { }


  /**
   * Key board action
   * @param event 
   */
  @HostListener('keydown', ['$event'])onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const inputValue: string = this.elementRef.nativeElement.value.concat(event.key);
    console.log(event.key);
    //var lessThanNumbersRegex = new RegExp("^<\\d+\\w+$");
    if (inputValue && (!String(inputValue).match(this.regex) && !inputValue.startsWith('<'))
    //|| (inputValue.length > 3 && !lessThanNumbersRegex.test(inputValue) && inputValue.indexOf('.') > -1)
    || (inputValue.indexOf('<') >= 1 || inputValue.indexOf('>') >= 1) ) { 
      event.preventDefault();
    }
    else {
      if(String(inputValue).indexOf('.') > -1){
        var numbers = inputValue.split('.');
        if(numbers[0].match('[a-zA-Z]') || (numbers[1].match('[a-zA-Z]')?.index == 0 ) ) event.preventDefault();
      } 
    }
    return;
  }

  /**
   * Copy Paste action 
   * @param event 
   */
  @HostListener('paste', ['$event']) onPaste(event : any) {
      const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
      if (clipboardData) {
        /*
          const regEx = new RegExp('^[0-9]*$');
          if(!regEx.test(clipboardData)) {
              event.preventDefault();
          }*/

          if (clipboardData && (!String(clipboardData).match(this.regex) && !clipboardData.startsWith('<'))|| (clipboardData.indexOf('<') >= 1 || clipboardData.indexOf('>') >= 1) ) { 
            event.preventDefault();
          }
          else {
            if(String(clipboardData).indexOf('.') > -1){
              var numbers = clipboardData.split('.');
              if(numbers[0].match('[a-zA-Z]') || (numbers[1].match('[a-zA-Z]')?.index == 0 ) ) event.preventDefault();
            } 
          }
          
      }
      return;
  }
}