import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component as GtinComponent, NutrientsDto, NutrientValuesDetail } from 'src/app/Models/Gtin';
import { OcrNutrient, OcrNutritionFacts } from 'src/app/Models/OcrNutritionFacts';
///

const highlightedNutrients: string[] = ['Cholesterol', 'Sodium', 'Total Carbohydrate', 'Protein'];
const ignoreNutrients: string[] = ['Calories'];
const LESSTHANTEXT = 'Less than';

@Component({
  selector: 'app-image-ocr-serving-editor',
  templateUrl: './serving-editor.component.html',
  styleUrls: ['./serving-editor.component.scss']
})
export class ServingEditorComponent implements OnInit {
  @Input() ocrNutritionFacts!: OcrNutritionFacts;

  get servingData() {
    return this.ocrNutritionFacts.servingData;
  }
  // Bug fix saving negative values
  //get householdServingSize() {
  //  return this.servingData.householdServingSize;
  //}

  //set householdServingSize(value: number) {

  //  if (value < 0) {
  //    return;
  //  }

  //  console.log(value);
  //}

  constructor() {
  }

  ngOnInit(): void {
  }
}


/*

@Directive({
  selector: 'appTwoDigitDecimaNumber'
})

export class TwoDigitDecimaNumberDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

  @Input('appTwoDigitDecimaNumber') decimal:any;

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])

  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if(this.decimal){  
      if (this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      let current: string = this.el.nativeElement.value;
      let next: string = current.concat(event.key);
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }else{
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 105)) {
        event.preventDefault();
      }
      return;
    }
  }
}
*/
