<kendo-scrollview [data]="items" [height]="height" [pageable]="shwoImage==true?true:false">
  <ng-template let-item="item">
    <div class="popup">
      <img *ngIf="shwoImage===true" src="{{ item }}" draggable="false"
           style=" height: 500px;
        width: 400px;" />
    </div>
    <div  *ngIf="shwoImage===false" style="overflow-y: scroll; height: 500px;">
      <div class="nutrition_fact-container" *ngIf="component.isComponentDataExist">

        <div class="header" style="border-bottom: 1px solid black;">Nutrition Facts</div>
        <!-- <div class="separator-thin"></div> -->
        <div class="text-align-left">{{component.servingPerPackage}} servings per container</div>
        <div class="serving-size" style="border-bottom: 3px solid black;">
          Serving size {{
 component.householdServingSize |
                    number
          }}&nbsp;{{component.householdServingUom}}&nbsp;({{component.servingSizeVal}}{{component.servingSizeUom}}/{{component.visualServingUom}})
        </div>
        <!-- <div class="separator-dark"></div> -->
        <!--single panel results-->
        <table style="height: 100%" *ngIf="component.panelType==='Single Panel'">
          <thead>
            <tr class="separator-calories">
              <th class="calories-header">
                Calories
                <!-- <div class="separator-calories"></div> -->
              </th>
              <th>
                <div>
                  {{getMiscellaneousData().itemHeader}}
                  <div class="calories-number">{{getMiscellaneousData().calorieValue}}</div>
                  <!-- <div class="separator-calories"></div> -->
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 50px;">
              <td></td>
              <td class="dv-bold-right">%DV*</td>
            </tr>
            <tr *ngFor="let nutrient of GetNutrientsToShow() index as i"
                [ngStyle]="{'border-bottom':GetNutrientsToShow().length==i+1?'0pt solid black':'0.6pt solid black'}">

              <td class="separator-thin-right-line"
                  [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}"
                  [attr.id]="removeSpaces(nutrient.nutrientName)">
                {{ nutrient.nutrientName }}
              </td>

              <td>
                <div class="serving-container">

                  <span style="margin-left:5px;">{{getDualDisplayValue(nutrient.nutrValue)}}{{getUom(nutrient.uom)}}</span>
                  <span class="text-bold"> {{getDualDisplayValue(nutrient.dvp)}}% </span>
                </div>
                <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}"></div> -->
              </td>
            </tr>
          </tbody>
        </table>

        <!--dual panel results-->
        <table style="height: 100%" *ngIf="component.panelType==='Dual Column'">
          <thead>
            <tr style="height:80px" class="separator-calories">
              <th class="calories-header" style="border-right: 0.5pt solid black;">
                Calories
                <!-- <div class="separator-calories"></div> -->
              </th>
              <th style="border-right: 0.5pt solid black;">
                <div class="d-flex h-100 flex-column">
                  <!-- <span *ngIf="getMiscellaneousData().dualitemHeader === null">&nbsp;</span> -->
                  {{getMiscellaneousData().itemHeader}}
                  <div class="calories-number mt-auto">{{getMiscellaneousData().calorieValue}}</div>
                  <!-- <div class="separator-calories"></div> -->
                </div>
              </th>
              <th colspan="2">
                <div class="d-flex h-100 flex-column">
                  <!-- <span *ngIf="getMiscellaneousData().dualitemHeader === null">&nbsp;</span> -->
                  {{getMiscellaneousData().dualitemHeader}}
                  <div class="calories-number mt-auto"
                       *ngIf="getMiscellaneousData().dualcalorieValue !== undefined">
                    {{getDualDisplayValue(getMiscellaneousData().dualcalorieValue)}}
                  </div>
                  <!-- <div class="separator-calories"></div> -->
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td class="dv-bold-right">%DV*</td>
              <td class="dv-bold-right">%DV*</td>
            </tr>
            <tr *ngFor="let nutrient of GetNutrientsToShow(); index as i"
                [ngStyle]="{'border-bottom':GetNutrientsToShow().length==i+1?'0pt solid black':'0.6pt solid black'}">
              <td class="separator-thin-right-line"
                  [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
                {{nutrient.nutrientName}}
                <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
              </td>
              <td class="separator-thin-right-line">
                <div class="serving-container">
                  <span style="margin-left:5px;">{{getDualDisplayValue(nutrient.nutrValue)}}{{getUom(nutrient.uom)}}</span>
                  <span class="text-bold"> {{getDualDisplayValue(nutrient.dvp)}}% </span>
                </div>
                <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
              </td>

              <td>
                <div class="serving-container">
                  <span style="margin-left:5px;">{{getDualDisplayValue(nutrient.dualNutrValue)}}{{getUom(nutrient.uom)}}</span>
                  <span style="margin-left:20px;" class="text-bold">
                    {{getDualDisplayValue(nutrient.dualDVP)}}%
                  </span>
                </div>
                <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
</kendo-scrollview>
