import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';

import {
  OnDestroy,
  ElementRef,
  HostListener,
  ViewChild,
  Input
} from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';

import { LoginProfile } from '../services/login-profile-types';
import { componentDestroyed } from '../shared/generics/componentdestroyed';
import { DataService } from '../services/DataService';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  //styleUrls: ['./header.component.css']
  styleUrls: ['./../../styles.css']
})
export class HeaderComponent implements OnInit {
  elem: any;
  isPadVertical = false;
  fullscreen = false;
  isVisible = false;
  isFullScreen = false;
  @Input()
  loginProfile!: Observable<LoginProfile>;
  private eventsSubscription!: Subscription;
  @ViewChild('anchor')
  public anchor!: ElementRef;
  @ViewChild('popup', { read: ElementRef })
  public popup!: ElementRef;
  itemCount = 0;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public loginProfileInfo: LoginProfile = {
    profileName: '',
    icon: 'logout',
    email: '',
    profileLogoText: '',
    loginDisplay: false,
    userId: null
  };
  public show = false;
  sublinksDisplay: string = 'none';

  constructor(@Inject(DOCUMENT) private document: any, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, private dataService :DataService,
    private msalBroadcastService: MsalBroadcastService) {

  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  isLandscape = window.innerWidth > window.innerHeight;

  toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    if (!this.isFullScreen) {
      docEl.requestFullscreen();
    } else {
      doc.exitFullscreen();
    }

    this.isFullScreen = !this.isFullScreen;
  }

  // Listen for the fullscreenchange event to update the fullscreen state
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  handleFullscreenChange(event: Event) {
    this.isFullScreen = !!document.fullscreenElement
  }

  showProfile() {
    this.isVisible = !this.isVisible;
  }

  showSublinks() {
    this.sublinksDisplay = 'block';
  }

  loaddashboard() {
    this.dataService.changecurrentloadDashBoard('Y');
  }

  hideSublinks() {
    this.sublinksDisplay = 'none';
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLandscape = window.innerWidth > window.innerHeight;
    if (!this.isLandscape) {
      this.isPadVertical = true;
      this.scrollToTop();
    }
    if (this.isPadVertical && this.isLandscape) {
      this.scrollToTop()

      //this.runBothTimeouts().then(() => {
      //  alert('Both timeouts have completed.');
      //});

      this.isPadVertical = false;
    }
  }

  async runBothTimeouts() {
    await this.delay(1000);
    this.toggleFullScreen();
    await this.delay(1000);
    this.toggleFullScreen();
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnInit(): void {
    this.elem = document.documentElement;
    this.eventsSubscription = this.loginProfile && this.loginProfile
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => this.setLoginInfo(data));

    setInterval(() => {
      this.isLandscape = window.innerWidth > window.innerHeight;
    }, 1000);

  }
  setLoginInfo(data: LoginProfile): void {
    if (data) {
      this.loginProfileInfo.loginDisplay = data.loginDisplay;
      this.loginProfileInfo.email = data.email;
      this.loginProfileInfo.profileName = data.profileName;
      this.loginProfileInfo.profileLogoText = data.profileName.substring(0, 1);
      this.loginProfileInfo.userId = data.userId;
    }
  }
  onItemClick(): void {
    this.logout();
  }
  private contains(target: unknown): boolean {
    return (
      (this.anchor && this.anchor.nativeElement.contains(target)) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.toggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  logout(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  }
  login(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  maxminscreen() {
    if (this.fullscreen == false) {
      this.fullscreen = true;
      this.openFullscreen();
      return;
    } else {
      this.fullscreen = false;
      this.closeFullscreen();
    }
  }


  openFullscreen() {

    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen({ navigationUI: "hide" });
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

}

