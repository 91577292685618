<!--<kendo-scrollview [data]="items"  [arrows]="true">
  <ng-template let-item="item">
    <img *ngIf="item!=='noimage'" src="{{ item }}" draggable="false"
         style=" height: 500px;
        width: 400px;" />
    </ng-template>
</kendo-scrollview>-->
Testing page.
Testing page.
Testing page.
Testing page.
Testing page.
Testing page.
Testing page.
Testing page.
