import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { EisnmGtinData } from '../Models/EisnmNutritionData';
import { RestApiService } from '../services/RestApiService.service';


@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.css']
})
export class ItemsListComponent implements OnInit {

  completedAndDraftGtinList!: EisnmGtinData[];
  public gridView !: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  displayLoadingIcon: boolean= false;
  noResultsFound : boolean = false;
  
  public state: State = {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'gtin', operator: 'contains' }]
    }
};

  constructor(private restApiService: RestApiService, private router: Router) {}

  ngOnInit(): void {
    this.getGtinList();
    this.restApiService.quaggaStop();
  }
  
  navigateToCompareItems(gtinCode:any) {
    const url = `compareItems?gtin=${gtinCode}`
    this.router.navigateByUrl(url);
  }
getGtinList(){
  this.displayLoadingIcon = true;
  this.restApiService.getGtinDetails().subscribe((data: any) => {   
    if(data == "No Results Found") {
      this.noResultsFound = true;
    }
    else 
    {
      this.noResultsFound = false;
      this.completedAndDraftGtinList =  JSON.parse(JSON.stringify(data));
      this.loadItems();
    }
    this.displayLoadingIcon = false;
  });
}

public pageChange(event: PageChangeEvent): void {
  this.skip = event.skip;
  this.loadItems();
}

private loadItems(): void {
  this.gridView = {
    data: this.completedAndDraftGtinList.slice(this.skip, this.skip + this.pageSize),
    total: this.completedAndDraftGtinList.length,
  };
}

}
