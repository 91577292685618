<div class="app">
    <app-layout></app-layout>
</div>
<div class="footer">

    
    <!--<a routerLink="search" routerLinkActive="active">Search</a>-->
    <!-- <a routerLink="readText" routerLinkActive="active">Get Gtin Details </a> -->
    <!-- <a routerLink ="scanBarCode" routerLinkActive="active">Scan Barcode</a> -->
    <!--<a routerLink="captureImage" routerLinkActive="active">Capture Image</a>-->
    <!--<a routerLink="compareItems" routerLinkActive="active">Compare Items</a>-->
    <!--<a routerLink="draftList" routerLinkActive="active">Draft List</a>-->
    <!--<a routerLink="compltedList" routerLinkActive="active">Completed List</a>-->
    <!--<a routerLink="report" routerLinkActive="active">Report</a>-->
</div>
<div class="pane-content">
    <app-header [loginProfile]="loginProfileEvent.asObservable()"></app-header>
</div>
<div class="pane-content">
    <router-outlet></router-outlet>
</div>