import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from,throwError } from 'rxjs';
import { retry, catchError, tap, switchMap } from 'rxjs/operators';
import { Item } from '../Models/item';
import { Gtin, UpdatedRecordCollection, RecordsToUpdateDValue, AllergenIngredinatsUpdatedData, AuditNotes, GenerateReportSearch, DashboardReport } from '../Models/Gtin';
import { AudioToText, Nutrient, OcrNutritionFacts } from '../Models/OcrNutritionFacts';
import { OcrData, ScanCodeData } from '../Models/OcrData';
import { ProcessOcrData, ReadImageFromBlobByGtinResponse } from '../Models/ProcessOcrData';
import { EisnmGtinData } from '../Models/EisnmNutritionData';
import { environment } from 'src/environments/environment';
import { SaveImageToBlobResponse } from '../Models/OcrBlob';
import Quagga from '@ericblade/quagga2';
import { AuditReport } from '../Models/AuditReport';
import { jsIcon } from '@progress/kendo-svg-icons';
import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { DatePipe } from '@angular/common';

//item numbers are greater 6 then it is GTIN
//<=6 it is itemcode
@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  // Define API
  isScanerRunning: boolean = false;
  showAddPnsButton: boolean = false;
  gatewayHost = environment.gatewayHost;
  //gatewayHost = 'http://localhost:5043/api/v1';
  constructor(private http: HttpClient, private authService: MsalService, private datePipe: DatePipe) {
    console.log("Constructor : " + this.gatewayHost);
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  getImage(imageName: string): Observable<any> {
    return this.http
      .get<any>(this.gatewayHost + '/HelpContent/GetBase64StringForRequestedImage?_imageName=' + imageName)
      .pipe(retry(1), catchError(this.handleError));
  }

  //postupdatedNutrientsDisplayValueToPNMS
  // HttpClient API get() method => Fetch item list
  getItems(): Observable<Item> {
    return this.http
      .get<Item>(this.gatewayHost + 'item/items')
      .pipe(retry(1), catchError(this.handleError));
  }
  postupdatedNutrientsDisplayValueToPNMS(gtin: any, userName: string) {
    return this.http
      .get<any>(this.gatewayHost + '/PNMSUpdate/UpdatePNMSUpdateWithOCRData?Gtin=' + gtin + "&userName=" + userName)
      .pipe(retry(1), catchError(this.handleError));
    //return this.http.get<any>(this.gatewayHost + '/PNMSUpdate/UpdatePNMSUpdateWithOCRData?gtin=' + gtin).pipe(retry(1), catchError(this.handleError));

  }


  getLastAuditRunDate(): Observable<any> {
    return this.http
      .get<any>(this.gatewayHost + '/AuditReport/GetLastAuditRunDate')
      .pipe(retry(1), catchError(this.handleError));
  }

  postAuditNotes(auditNotes: AuditNotes, loggedInUser: any): Observable<any> {
    return this.http.post<any>(`${this.gatewayHost}/AuditReport/postAuditNotes?PNumber=${loggedInUser}`, auditNotes);
  }

  getOCRDetailsByGtin(id: any) {
    return this.http
      .get<Gtin>(this.gatewayHost + '/ItemSearch/getOCRDetailsByGtin?Gtin=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }
  getGtinByItemCode(id: any): Observable<Item> {

    console.log("getGtinByItemCode : " + this.gatewayHost);
    console.log(environment.gatewayHost + 'ItemSearch/SearchByItemCode?itemCode=' + id);

    return this.http
      .get<Item>(this.gatewayHost + '/ItemSearch/SearchByItemCode?itemCode=' + id)
      .pipe(retry(0), catchError(this.handleError));
  }
  postupdatedNutrientsDisplayValueToOCRDB(recordsToUpdate: UpdatedRecordCollection): Observable<any> {
    return this.http.post<any>(`${this.gatewayHost}/ItemSearch/updateOCRDetailsByGtin`, recordsToUpdate);
  }



  // postupdatedAllergenIngredinatsToOCRDB(allergenIngredinatsUpdatedData: AllergenIngredinatsUpdatedData[]): Observable<any> {
  //   return this.http.post<any>(`${this.apiURL}/updateAllergenIngredinatsByGtin`, allergenIngredinatsUpdatedData);
  // }

  // postupdatedNutrientsDvpToOCRDB(recordsToUpdate: RecordsToUpdateDVP[]): Observable<any> {
  //   console.log("reached service dvp");
  //   return this.http.post<any>(`${this.apiURL}/updateOCRDetailsByGtin?type=dvp`, recordsToUpdate);
  // }


  // HttpClient API get() method => Fetch item SearchByItemCode
  getItembyGtin(id: any): Observable<Gtin> {
    //const urlRequest = `${environment.gatewayHost}/ecps/api/${environment.apiVersion}/MasterAllowancesCharges/GetMasterAllowancesCharges`;

    return this.http
      .get<Gtin>(this.gatewayHost + '/ItemSearch/getDetailsByGtin?Gtin=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  getGtinBasicInformtaion(id: any): Observable<OcrData> {
    return this.http
      .get<OcrData>(this.gatewayHost + '/ProcessOcr/GetGtinComponentInformation?gtinCode=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  checkGtinExistsInScanCode(id: any): Observable<ScanCodeData> {
    return this.http
      .get<ScanCodeData>(this.gatewayHost + '/ProcessOcr/GetGtinExistsInScanCode?gtinCode=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }
  //Get Images from blob
  readImageFromBlobByGtin(gtin: any, status: any): Observable<ReadImageFromBlobByGtinResponse> {
    return this.http
      .get<ReadImageFromBlobByGtinResponse>(this.gatewayHost + '/ProcessOcr/ReadImageFromBlobByGtin?gtin=' + gtin + '&status=' + status)
      .pipe(retry(1), catchError(this.handleError));
  }


  //getTextFromAudio(body: any): Observable<AudioToText> {

  //  //const base64Audio = body.get('Base64String');
  //  //console.log('Base64 audio:', base64Audio);

  // // console.log(JSON.stringify(body));

  //  return this.http
  //    .post<any>(
  //      this.gatewayHost + '/Ocr/ProcessAudio/',
  //     // 'http://localhost:5043/api/v1' + '/Ocr/ProcessAudio/',
  //      JSON.stringify(body),
  //      this.httpOptions
  //    )
  //    .pipe(retry(1), catchError(this.handleError));
  //}


  // OCR data
  getOcrNutritionFacts(body: any): Observable<OcrNutritionFacts> {
    return this.http
      .post<OcrNutritionFacts>(
        this.gatewayHost + '/Ocr/ProcessImage?UIDateTime=' + this.getFormattedTimestamp(),
        JSON.stringify(body),
        this.httpOptions
      )
      .pipe(retry(0), catchError(this.handleError));
  }
  getFormattedTimestamp(): any {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
  }
  //get nutrients

  GetImageUploadImageStatus(): Observable<boolean> {
    return this.http
      .get<boolean>(this.gatewayHost + '/Ocr/GetImageUploadFlag')
      .pipe(retry(1), catchError(this.handleError));
  }

  getNutritions(): Observable<Nutrient> {
    return this.http
      .get<Nutrient>(this.gatewayHost + '/ProcessOcr/GetNutrients')
      .pipe(retry(1), catchError(this.handleError));
  }

  saveImageToBlob(item: any): Observable<SaveImageToBlobResponse> {
    return this.http
      .post<SaveImageToBlobResponse>(
        this.gatewayHost + '/ProcessOcr/SaveImageToBlob',
        JSON.stringify(item),
        this.httpOptions
      )
      .pipe(retry(0), catchError(this.handleError));
  }


  // HttpClient API put() method => Update item
  updateItem(id: any, item: any): Observable<Item> {
    return this.http
      .put<Item>(
        this.gatewayHost + '/item/' + id,
        JSON.stringify(item),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  saveDraftOcrData(item: any): Observable<ProcessOcrData> {
    return this.http
      .post<ProcessOcrData>(
        this.gatewayHost + '/ProcessOcr/SaveOcrData',
        JSON.stringify(item),
        this.httpOptions
      )
      .pipe(retry(0), catchError(this.handleError));
  }


  savePnmsData(item: any): Observable<ProcessOcrData> {
    return this.http
      .post<ProcessOcrData>(
        this.gatewayHost + '/ProcessOcr/SavePNMSData',
        JSON.stringify(item),
        this.httpOptions
      )
      .pipe(retry(0), catchError(this.handleError));
  }
  /*
    updateOcrData(item: any): Observable<any> {
      return this.http
        .post<any>(
          this.gatewayHost+'/ProcessOcr/UpdateOcrData',
          JSON.stringify(item),
          this.httpOptions
        )
        .pipe(retry(0), catchError(this.handleError));
    }
  */


  checkGtinExists(gtin: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.gatewayHost + '/ProcessOcr/IsGtinExists?gtinCode=' + gtin)
      .pipe(retry(1), catchError(this.handleError));
  }

  getGtinDetails(): Observable<any> {
    return this.http
      .get<any>(this.gatewayHost + '/ProcessOcr/GetDraftStatusGtinList')
      .pipe(retry(0), catchError(this.handleError));
  }

  deleteOcrData(gtin: string): Observable<boolean> {
    return this.http
      .delete<boolean>(this.gatewayHost + '/ProcessOcr/DeleteOcrData?gtin=' + gtin)
      .pipe(retry(0), catchError(this.handleError));
  }

  quaggaStop() {
    if (this.isScanerRunning) Quagga.stop();
  }

  showAddPNSButton(result: boolean) {
    this.showAddPnsButton = result;
  }

  getGtinItemDescription(id: any): Observable<string> {
    return this.http
      .get<string>(this.gatewayHost + '/ProcessOcr/GetGtinItemDescription?gtinCode=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  getReports(loggedInUser: any) {
    return this.http
      .get<any>(this.gatewayHost + '/AuditReport/getReports?loggedInUser=' + loggedInUser)
      .pipe(retry(1), catchError(this.handleError));
  }

  DeleteAllUserReports(loggedInUser: any) {
    return this.http
      .get<any>(this.gatewayHost + '/AuditReport/DeleteAllUserReports?loggedInUser=' + loggedInUser)
      .pipe(retry(1), catchError(this.handleError));
  }

  DeleteUserSelectedReport(reportName: any, loggedInUser: any) {
    return this.http.get<any>(`${this.gatewayHost}/AuditReport/DeleteUserSelectedReport?reportName=${reportName}&loggedInUser=${loggedInUser}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  //getUserReports(getUserReports: any,loggedInUser:any) {
  //  return this.http.get<any>(`${this.gatewayHost}/AuditReport/getUserReports?getUserReports='=${getUserReports}&loggedInUser=${loggedInUser}`)
  //    .pipe(retry(1), catchError(this.handleError));
  //}

  getUserReports(loggedInUser: any) {
    return this.http.get<any>(`${this.gatewayHost}/AuditReport/getUserReports?loggedInUser=${loggedInUser}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateGtinAuditStatus(gtin: any, loggedInUser: any) {
    return this.http
      .get<any>(this.gatewayHost + '/AuditReport/UpdateGtinAuditStatus?gtin=' + gtin + '&userPNumber=' + loggedInUser)
      .pipe(retry(1), catchError(this.handleError));
  }

  getDashBoardReport() {
    return this.http
      .get<any>(this.gatewayHost + '/AuditReport/GetDashBoardReport')
      .pipe(retry(1), catchError(this.handleError));
  }


  getAuditReportForSelectedReportId(reportId: number) {
    return this.http
      .get<DashboardReport>(this.gatewayHost + '/AuditReport/GetAuditReportForSelectedReportId?reportId=' + reportId)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAuditReport(fromDate: Date, todate: Date, storeNumber: string) {

    const fromMonth = (fromDate.getMonth() + 1).toString().padStart(2, '0');
    const fromDay = fromDate.getDate().toString().padStart(2, '0');
    const fromYear = fromDate.getFullYear().toString();
    const fromDateInDesiredFormat = `${fromMonth}/${fromDay}/${fromYear}`;

    const toMonth = (todate.getMonth() + 1).toString().padStart(2, '0');
    const toDay = todate.getDate().toString().padStart(2, '0');
    const toYear = todate.getFullYear().toString();
    const toDateInDesiredFormat = `${toMonth}/${toDay}/${toYear}`;

    var result = this.http
      .get<any>(this.gatewayHost + '/AuditReport/GetAuditReport?fromDate=' + fromDateInDesiredFormat.toString()
        + '&toDate=' + toDateInDesiredFormat.toString() + '&storeNumber=' + storeNumber)
      .pipe(retry(1), catchError(this.handleError));

    return result;
  }

  generateAuditReport(generateReportSearch: GenerateReportSearch): Observable<any> {

    //const fromMonth = (fromDate.getMonth() + 1).toString().padStart(2, '0');
    //const fromDay = fromDate.getDate().toString().padStart(2, '0');
    //const fromYear = fromDate.getFullYear().toString();
    //const fromDateInDesiredFormat = `${fromMonth}/${fromDay}/${fromYear}`;

    //const toMonth = (todate.getMonth() + 1).toString().padStart(2, '0');
    //const toDay = todate.getDate().toString().padStart(2, '0');
    //const toYear = todate.getFullYear().toString();
    //const toDateInDesiredFormat = `${toMonth}/${toDay}/${toYear}`;

    return this.http.post<any>(`${this.gatewayHost}/AuditReport/GenerateAuditReport`, generateReportSearch);


    //var result = this.http
    //  .get<any>(this.gatewayHost + '/ItemSearch/GenerateAuditReport?fromDate=' + fromDateInDesiredFormat.toString() + '&toDate=' + toDateInDesiredFormat.toString() + '&reportName=' + reportName)
    //  .pipe(retry(1), catchError(this.handleError));

    //return result;
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      console.log(errorMessage);
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.log(errorMessage);
    }
    console.log(errorMessage);
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
