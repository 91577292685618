import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuditNotes, Gtin, NutrientValuesDetail } from 'src/app/Models/Gtin';
import { RestApiService } from '../../services/RestApiService.service';
import { MsalService } from '@azure/msal-angular';
import { DataService } from '../../services/DataService';
import { Router } from '@angular/router';

@Component({
  selector: 'search-gtin-results',
  templateUrl: './search-gtin-results.component.html',
  styleUrls: ['./search-gtin-results.component.css']
})
//updateGtinAuditStatus
export class SearchGtinResults implements OnInit, OnChanges {
  @Input() searchGtinResults!: Gtin;
  @Input() showUpdateButton: boolean = false;
  @Input() gtinAuditStatus: string = "";
  @Input() isFromAudit: boolean = false;
  numofTables!: number;
  @Input() auditNotes!: string;
  nutrtionDetails!: string;
  allergianDetails!: string;
  ingrediantDetails!: string;
  nutrionDetailsArray: string[] = [];
  allergionDetailsArray: string[] = [];
  ingrediantDetailsArray: string[] = [];
  id: number = 1;
  includeNutrientIds = [200,201];
  @ViewChild('tableid') inputitemgtincode!: ElementRef;
  @ViewChild('modalComponent') modalComponent: any;
  @Output() calbackgtinresults = new EventEmitter<boolean>();
  userId!: any;
  isPartOfAudit: boolean = false;
  constructor(private restApiService: RestApiService, private authService: MsalService, private dataService: DataService, private router: Router) {
    this.restApiService.quaggaStop();
    var accountInfo = this.authService.instance.getAllAccounts();
    if (accountInfo != null && accountInfo.length > 0 && accountInfo[0].idTokenClaims) {
      this.userId = accountInfo[0].idTokenClaims['sAMAccountName'];
    }
  }
  modalData = {
    isSuccess: false,
    message: '',
    header: ''
  }
  closeModal() {
    this.modalComponent.hide();
  }
  showGotoPanel: boolean = true




  gotoAudit() {
    this.showGotoPanel = false;
    this.isFromAudit = true;
    this.showUpdateButton = true;
    //this.dataService.changeMessage(+this.searchGtinResults.components[0].gtin);
    //const url = `search`;
    //this.router.navigateByUrl(url);
    if (this.searchGtinResults.isPartOfAudit) {
      this.showUpdateButton = true;
    }
    else {
      this.showUpdateButton = false;
    }
    //this.showUpdateButton = true;
  }
  backClicked() {
    //window.location.reload();
    this.calbackgtinresults.emit(true);

  }

  async  AddAuditNotes() {
  return new Promise((resolve, reject) => {
    this.restApiService
      .postAuditNotes(new AuditNotes(this.searchGtinResults.auditNotes, this.searchGtinResults.components[0].gtin), this.userId)
      .subscribe(
        (data: any) => {
          this.modalData.header = 'Add Audit Notes';
          this.modalData.message = 'Audit Notes have been added.';
          this.modalData.isSuccess = true;
          this.modalComponent.show();
          resolve(data);
        },
        (error: any) => {
          reject(error);
        }
      );
  });
}

  //async AddAuditNotes() {
  //  this.restApiService.postAuditNotes(new AuditNotes(this.auditNotes, this.searchGtinResults.components[0].gtin), this.userId).subscribe((data: any) => {
  //    this.modalData.header = 'Add Audit Notes';
  //    this.modalData.message = 'Audit Notes have been added.';
  //    this.modalData.isSuccess = true;
  //    this.modalComponent.show();
  //  });
  //}


  async UpdateStatus() {
  return new Promise((resolve, reject) => {
    this.restApiService.updateGtinAuditStatus(this.searchGtinResults.components[0].gtin, this.userId).subscribe(
      (data: any) => {
        this.modalData.header = 'Audit Status Update';
        this.modalData.message = 'Audit Status has been updated.';
        //this.gtinAuditStatus = 'complete';
        this.searchGtinResults.auditStatusName = 'Completed'
        this.modalData.isSuccess = true;
        this.modalComponent.show();
        resolve(data);
      },
      (error: any) => {
        reject(error);
      }
    );
  });
}

  //async UpdateStatus() {
  //  this.restApiService.updateGtinAuditStatus(this.searchGtinResults.components[0].gtin, this.userId).subscribe((data: any) => {
  //    this.modalData.header = 'Audit Status Update';
  //    this.modalData.message = 'Audit Status has been updated.';
  //    this.gtinAuditStatus = "complete";
  //    this.modalData.isSuccess = true;
  //    this.modalComponent.show();
  //  });
  //}

  onInputChange(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length > 2000) {
      // Truncate the input to 2000 characters
      this.auditNotes = inputValue.slice(0, 2000);
    }
  }

  async AddAuditNotesAndUpdateStatus() {
    
    if (this.searchGtinResults.auditStatusName.trim() !== '') {
    await this.AddAuditNotes();
  }
  await this.UpdateStatus();
  }

  async GoToCaptureImagePage() {
    this.router.navigate(['/captureImage'], { queryParams: { gtin: this.searchGtinResults.components[0].gtin } });
  }


  getUom(value: string){
    var uomVlaue : string = "";
    if(value != null) {
        uomVlaue = value;
        if(value === "IU")  uomVlaue = "mcg";
    }
    return uomVlaue;
}
  filternutrition(nutrientValuesDetail: NutrientValuesDetail): boolean {
    //this.searchGtinResults.components[0].nutrientValuesDetails[0].dualNutrValue
    if (nutrientValuesDetail.nutrientID == 200 || nutrientValuesDetail.nutrientID == 201) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  isLandscape = window.innerWidth > window.innerHeight;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }

  ngOnInit(): void {
    setInterval(() => {
      this.isLandscape = window.innerWidth > window.innerHeight;
    }, 1000);
  }

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  ShowAndHide(id: number) {
    this.id = id;
  }
  getGtinNutritionCount(): number {
    return this.searchGtinResults.components.length;
  }
  getDualDisplayValue(value : string) {
    if(value==undefined){
      return "";
    }
    if (value.trim()==""){
      return "";
    }
    if(value != null) {
   var returnValue:string = value;
    
    return Number(returnValue);
  }
  return "";
}
  addPNMSAttributesIfNotAvailable() {

    for (let i = 0; i < this.searchGtinResults.components.length; i++) {
      const component = this.searchGtinResults.components[i];
      let hasNutrient200 = false;
      let hasNutrient201 = false;
      for (let j = 0; j < component.nutrientValuesDetails.length; j++) {
        if (component.nutrientValuesDetails[j].nutrientID === 200) {
          hasNutrient200 = true;
          break;
        }
      }
      for (let k = 0; k < component.nutrientValuesDetails.length; k++) {
        if (component.nutrientValuesDetails[k].nutrientID === 201) {
          hasNutrient201 = true;
          break;
        }
      }
      
      if (!hasNutrient200) {
        const newNutrientValueDetail: NutrientValuesDetail = {
          nutrientID: 200,
          nutrientName: "Whole Grain",
          effectiveDt: "",
          terminationDt: "",
          nutrValue: "",
          dvp: "",
          displayValue: "",
          dualNutrValue: "",
          dualDVP: "",
          duaL_DisplayValue: "",
          uom: "",
          dualuom: "",
        };
        component.nutrientValuesDetails.push(newNutrientValueDetail);
      }
      if (!hasNutrient201) {
        const newNutrientValueDetail: NutrientValuesDetail = {
          nutrientID: 201,
          nutrientName: "Juice",
          effectiveDt: "",
          terminationDt: "",
          nutrValue: "",
          dvp: "",
          displayValue: "",
          dualNutrValue: "",
          dualDVP: "",
          duaL_DisplayValue: "",
          uom: "",
          dualuom: "",
        };
        component.nutrientValuesDetails.push(newNutrientValueDetail);
      }
    }
  }

  GetNutrientsToShow(id:number) {
    this.addPNMSAttributesIfNotAvailable();
    return this.searchGtinResults.components[id-1].nutrientValuesDetails.filter(n => this.includeNutrientIds.includes(n.nutrientID));
  }
}
