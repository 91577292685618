import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReStrictNumberOnlyDirective } from 'src/utils/ReStrictNumberOnlyDirective';
import { ReStrictPercentageOnlyDirective } from 'src/utils/PercentageOnlyDirective';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { AppComponent } from './app.component';
import { SearchItemComponent } from './search-results/search-item.component';
import { HomeComponent } from './home/home.component';
import { ScanBarcodeComponent } from './scan-barcode/scan-barcode.component';
import { CompareItemComponent } from './compare-item/compare-item.component';
import { LoginComponent } from './login/login.component';
import { ActivityComponent } from './activity/activity.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RestApiService } from './services/RestApiService.service';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WebcamModule } from 'ngx-webcam';

import { WebcamSnapshotComponent } from './scan-barcode/webcam-snapshot/webcam-snapshot.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchItemResults } from './search-results/search-item-results/search-item-results-component';
import { SearchGtinResults } from './search-results/search-gtin-results/search-gtin-results.component';
import { CapturePictureComponent, ImageOcrThumbnailsComponent, ImageOcrComponent, CaptureScanBarcodeComponent, ImageOcrDataComponent, ImageOcrNutritionFactComponent } from './capture-image';
import { DraftListComponent } from './draft-list/draft-list.component';
import { CompletedListComponent } from './completed-list/completed-list.component';
import { ReadTextFromImage } from './read.text.from.image/read.text.from.image';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { HeaderComponent } from './header/header.component';
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { SidebarComponent } from './sidebar/sidebar.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ContentComponent } from './content/content.component';
import { LayoutComponent } from './layout/layout.component';
import { NutritionFactComponent } from './search-results/nutrition-facts/nutrition-fact.component';
import { ReportComponent } from './report/report.component';
import { Barcodeitem } from './scan-barcode/scanbarcodeItem/barcode-item';
import { NutritionOCRFactComponent } from './compare-item/search-results-OCR/nutrition-fact-ocr/nutrition-fact-ocr';
import { SearchOCRGtinResults } from './compare-item/search-results-OCR/search-ocr-results';
import { NutritionPNMSFactComponent } from './compare-item/search-results-PNMS/nutrition-fact-pnms/nutrition-fact-pnms';
import { SearchPNMSGtinResults } from './compare-item/search-results-PNMS/search-pnms-results';
import { ServingEditorComponent } from './capture-image';
import { ModalComponent } from './shared/modal.component';
import { ItemsListComponent } from './items-list/items-list.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { CommonModule, DatePipe } from '@angular/common';
// import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';

import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { method } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AuditListComponent } from './items-list/audit/audit-list.component';
import { CustomPaginationComponent } from './shared/Pagination/pagination.component';
import { PositiveNumberDirective } from 'src/utils/PositiveNumberDirective';
import { NutrientNumberOnlyDirective } from 'src/utils/NutrientNumberOnlyDirective';
import { ValidateWithPercentageDirective } from 'src/utils/ValidateWithPercentageDirective';
import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { ConfirmationDialogComponent } from './shared/confirmation/confirmation.dialog.component';
import { GTINConverter } from './Models/Gtin';
import { TestComponent } from './home/Test/test.component';
import { TestImage } from './Test/test.image.component';
import { confirmpanelcomponent } from './capture-image/confirmPanelType/confirm-panel-component';
import { selectAndCreateComponent } from './capture-image/selectAndCreateComponent/selectAndCreateComponent';
import { HelpComponent } from './help/help.component';
export function loggerCallback(logLevel: LogLevel, message: string): void {
}

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

  // Creates custom client application to implement login
// functionality
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(
      
      {
      auth: {
        clientId: environment.ClienId,
        authority: `https://login.microsoftonline.com/${environment.tenantId}/`,
        //redirectUri: environment.redirectUrl,
        redirectUri: window.location.origin,
      },
      
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }

// provides authRequest configuration
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);
  protectedResourceMap.set(`${environment.gatewayHost}/*`, [
    environment.scopeUri
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}
 


const appRoutes: Routes = [

  { path: 'Test', canActivate: [MsalGuard], component: TestImage },
  { path: '', canActivate: [MsalGuard],component: HomeComponent },
  { path: 'home', canActivate: [MsalGuard],component: HomeComponent },
  { path: 'search', canActivate: [MsalGuard],component: SearchItemComponent },


  {
    path: 'scanBarCode',canActivate: [MsalGuard], component: ScanBarcodeComponent,

    children: [
      { path: 'scanBarCode/webcam-snapshot', component: WebcamSnapshotComponent }
    ],

  },
  //AuditList
  { path: 'compareItems',canActivate: [MsalGuard], component: CompareItemComponent },
  { path: 'auditList', canActivate: [MsalGuard], component: AuditListComponent },
  { path: 'helpContent', canActivate: [MsalGuard], component: HelpComponent },
  {
    path: 'captureImage',canActivate: [MsalGuard], component: ImageOcrComponent
  },
  { path: 'draftList',canActivate: [MsalGuard], component: DraftListComponent },
  { path: 'list', canActivate: [MsalGuard],component: ItemsListComponent },
  { path: 'report', canActivate: [MsalGuard],component: ReportComponent },
  { 
    path: '**', 
    redirectTo: ''
  }

]

@NgModule({
  declarations: [
    HelpComponent,
    selectAndCreateComponent,
    confirmpanelcomponent,
    TestImage,
    ConfirmationDialogComponent,
    CustomPaginationComponent,
    AuditListComponent,
    SearchItemComponent,
    HomeComponent,
    ScanBarcodeComponent,
    CompareItemComponent,
    LoginComponent,
    ActivityComponent,
    DraftListComponent,
    CompletedListComponent,
    AppComponent,
    ScanBarcodeComponent,
    WebcamSnapshotComponent,
    SearchItemResults,
    SearchGtinResults,
    ReadTextFromImage,
    HeaderComponent,
    SidebarComponent,
    ContentComponent,
    LayoutComponent,
    NutritionFactComponent,
    ReportComponent,
    Barcodeitem,
    ImageOcrComponent,
    CaptureScanBarcodeComponent,
    CapturePictureComponent,
    ImageOcrThumbnailsComponent,
    ImageOcrDataComponent,
    ImageOcrNutritionFactComponent,
    NutritionOCRFactComponent,
    SearchOCRGtinResults,
    SearchPNMSGtinResults,
    NutritionPNMSFactComponent,
    ServingEditorComponent,
    ModalComponent,
    ItemsListComponent,
    ReStrictNumberOnlyDirective,
    ReStrictPercentageOnlyDirective,
    PositiveNumberDirective,
    NutrientNumberOnlyDirective,
    ValidateWithPercentageDirective,
    TestComponent
    // TooltipsModule,
  ],
  imports: [
    ChartsModule,
    TreeViewModule,
    DropDownListModule,
    DialogsModule,
    DropDownsModule,
    InfiniteScrollModule,
    CommonModule,
    // ListViewModule,
    // NavigationModule,
    // CostThresholdMaintenanceModule,
    // CostItemMaintenanceModule,
    // AllowancesAndChargesModule,
    // NgbModule,
    // FontAwesomeModule,

    ImageCropperModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    WebcamModule,
    PopupModule,
    FormsModule,
    ReactiveFormsModule,    
    LabelModule,
    InputsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    RouterModule.forRoot(appRoutes, { useHash: true }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: !isDevMode(),
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    GridModule,
    // CustomMessagesComponent, FloatingLabelComponent, FloatingLabelModule, LabelComponent, LabelDirective, LabelModule, LocalizedMessagesDirective, SharedDirectivesModule,
    BrowserAnimationsModule,
    LayoutModule,
    ScrollViewModule,
    TooltipsModule,
    ButtonsModule
  ],
  providers: [GTINConverter,RestApiService,
    HttpClient, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
    // MsalBroadcastService,
    // {
    //   provide: RestApiService,
    //   useFactory: (http: HttpClient) => new RestApiService(http, environment.scopeUri),
    //   deps: [HttpClient],
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
