import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BeepService } from './beep.service';
import Quagga from '@ericblade/quagga2';
import { Article } from './article';
import { ShoppingCart } from './shopping-cart';
//import { UpdateService } from './update.service';
import { environment } from '../../environments/environment';
import { getMainBarcodeScanningCamera } from './camera-access';
import { RestApiService } from '../services/RestApiService.service';
import { Gtin } from '../Models/Gtin';
import { ScanBarcodeResult } from './models/scanBarCodeResult';
import { isGTIN,isValid } from 'gtin'
import { EventEmitterService } from '../event-emitter.service';


@Component({
  selector: 'app-scan-barcode',
  templateUrl: './scan-barcode.component.html',
  styleUrls: ['./scan-barcode.component.css']
})
export class ScanBarcodeComponent implements AfterViewInit {

  started: boolean | undefined;
  errorMessage: string | undefined;
  acceptAnyCode = true;
  items: [Article, number][] = [];
  totalPrice: number = 0;
  arr: number[] = [];
  gtin!: Gtin;
  @Output() scanBarcodeChanged = new EventEmitter<ScanBarcodeResult>();

  private shoppingCart: ShoppingCart;
  private lastScannedCode: string | undefined;
  private lastScannedCodeDate: number | undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private beepService: BeepService,
    private restApiService: RestApiService,
    //private updateService: UpdateService, 
    private eventEmitterService: EventEmitterService ) {
    this.shoppingCart = new ShoppingCart();
  }
  
  ngOnInit() {    
    if (this.eventEmitterService.subsVar==undefined) {    
      this.eventEmitterService.subsVar = this.eventEmitterService.    
      invokeScanbarCodeComponentFunction.subscribe((name:string) => {    
        this.initializeScanner();    
      });    
    }    
  }   

  ngAfterViewInit(): void {


    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
      this.errorMessage = 'getUserMedia is not supported';
      return;
    }

    this.initializeScanner();
    // testing
   // this.onBarcodeScanned("8");

    //if (environment.production) {
    //  setTimeout(() => {
    //    this.updateService.checkForUpdates();
    //  }, 10000);
    //}
  }

  private initializeScanner(): Promise<void> {
    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
      this.errorMessage = 'getUserMedia is not supported. Please use Chrome on Android or Safari on iOS';
      this.started = false;
      return Promise.reject(this.errorMessage);
    }

    // enumerate devices and do some heuristics to find a suitable first camera
    return Quagga.CameraAccess.enumerateVideoDevices()
      .then((mediaDeviceInfos) => {
        const mainCamera = getMainBarcodeScanningCamera(mediaDeviceInfos);
        if (mainCamera) {
          console.log(`Using ${mainCamera.label} (${mainCamera.deviceId}) as initial camera`);
          return this.initializeScannerWithDevice(mainCamera.deviceId);
        } else {
          console.error(`Unable to determine suitable camera, will fall back to default handling`);
          return this.initializeScannerWithDevice(undefined);
        }
      })
      .catch(error => {
        this.errorMessage = `Failed to enumerate devices: ${error}`;
        this.started = false;
      });
  }

  private initializeScannerWithDevice(preferredDeviceId: string | undefined): Promise<void> {
    console.log(`Initializing Quagga scanner...`);

    const constraints: MediaTrackConstraints = {};
    if (preferredDeviceId) {
      // if we have a specific device, we select that
      constraints.deviceId = preferredDeviceId;
    } else {
      // otherwise we tell the browser we want a camera facing backwards (note that browser does not always care about this)
      constraints.facingMode = 'environment';
    }

    return Quagga.init({
      inputStream: {
        type: 'LiveStream',
        constraints,
        area: { // defines rectangle of the detection/localization area
          top: '25%',    // top offset
          right: '10%',  // right offset
          left: '10%',   // left offset
          bottom: '25%'  // bottom offset
        },
        target: document.querySelector('#scanner-container') ?? undefined
      },
      decoder: {
        readers: ['ean_reader'],
        multiple: false
      },
      // See: https://github.com/ericblade/quagga2/blob/master/README.md#locate
      locate: false
    },
      (err) => {
        if (err) {
          console.error(`Quagga initialization failed: ${err}`);
          this.errorMessage = `Initialization error: ${err}`;
          this.started = false;
          this.restApiService.isScanerRunning = false;
        } else {
          console.log(`Quagga initialization succeeded`);
          Quagga.start();
          this.started = true;
          this.restApiService.isScanerRunning = true;
          this.changeDetectorRef.detectChanges();
          Quagga.onDetected((res) => {
            if (res.codeResult.code) {
              this.onBarcodeScanned(res.codeResult.code);
            }
          });
        }
      });
  }

  onBarcodeScanned(code: string) {
    console.log("onBarcodeScanned called");
    // remove this - Comment test code
    //this.scanBarcodeChanged.emit({ code: 8, isSuccess: true });
    if (code.length<13){
      code = "0".repeat( 13 - code.length) + code;
     }
    code = code.slice(1, code.length).toString();

    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();
    if (code === this.lastScannedCode
      && ((this.lastScannedCodeDate !== undefined) && (now < this.lastScannedCodeDate + 1500))) {
      return;
    }

    this.arr.push(parseInt(code));

    var biggest = -1, number;
    for (var key in this.arr) {
      if (this.arr[key] > biggest) {
        biggest = this.arr[key];
        number = key;
      }
    }

    if (code === number) {
      return;
    }


    // this.clearCart()
    // // only accept articles from catalogue
    // //let article = this.catalogue.find(a => a.ean === code);
    // //if (!article) {
    //   if (this.acceptAnyCode) {
    //     article = this.createUnknownArticle(code);
    //   } else {
    //     return;
    //   //}
    // }
    if (isGTIN(code.toString())) {
      //this.restApiService.getItembyGtin(code.slice(0, -1)).subscribe((data: {}) =>
      //this.gtin = JSON.parse(JSON.stringify(data)));
      const numericalCode = Number(code.slice(0, -1));
      // remove
      //const numericalCode = '2100065883';
      // add this - Uncomment
      this.scanBarcodeChanged.emit({ code: numericalCode, isSuccess: true, errorMessage : '', showAddPnsButton:false });
      this.lastScannedCode = code;
      this.lastScannedCodeDate = now;
      //this.beepService.beep();
      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.detach();
    }
    else {

      //the below code shows logs on the screen
      //this.shoppingCart.addArticle(this.createUnknownArticle(code));
      //this.items = this.shoppingCart.contents;
      // this.totalPrice = this.shoppingCart.totalPrice;

      // this.lastScannedCode = code;
      // this.lastScannedCodeDate = now;
      // this.beepService.beep();
      // this.changeDetectorRef.detectChanges();

      // add this - Uncomment 
      this.scanBarcodeChanged.emit({ code: -1, isSuccess: false, errorMessage : 'Invalid gtin code', showAddPnsButton:false });
    }
  }

  // clearCart() {
  //   this.arr=[];
  //   this.shoppingCart.clear();
  //   this.items = this.shoppingCart.contents;
  // }

  private createUnknownArticle(code: string): Article {
    return {
      ean: code,
      name: `Code ${code}`,
      image: 'assets/classy_crab_unknown.png',
      price: 42
    }
  }
  // private validateCheckDigit(barcode: string): boolean {
  //   // Here you can implement the algorithm of your choice to validate the check digit.
  //   // for example: 
  //   const checkDigit = Number(barcode[barcode.length - 1]);
  //   const barcodeWithoutCheckDigit = barcode.slice(0, -1);
  //   const sum = barcodeWithoutCheckDigit
  //     .split('')
  //     .map((char, index) => Number(char) * (index % 2 === 0 ? 3 : 1))
  //     .reduce((a, b) => a + b);
  //   const remainder = sum % 10;
  //   const calculatedCheckDigit = remainder === 0 ? 0 : 10 - remainder;
  //   return checkDigit === calculatedCheckDigit;
  // }

  getGtinCount(): number {
    if (this.gtin != null) {
      return this.gtin.components == undefined ? 0 : this.gtin.components.length;
    }
    else {
      return 0;
    }
  }
}
