<div class="container">
  <div class="left-div">
    <!--<div class="example-config">
      Selected keys: {{ selectedKeys.join(",") }}
    </div>-->
    <kendo-treeview [nodes]="data"
                    textField="text"
                    [hasChildren]="hasChildren"
                    (selectionChange)="onSelectionChange($event)"
                    [children]="fetchChildren"
                    kendoTreeViewExpandable
                    [expandedKeys]="expandedKeys"
                    kendoTreeViewSelectable
                    [(selectedKeys)]="selectedKeys">
    </kendo-treeview>
  </div>
  <div class="right-div" *ngIf="isuserLoggedin">

    <!--Search Page-->
    <div id="SearchLandingPage" *ngIf="SearchLandingPage">
      <!--<img src="../../../../assets/images/HelpImages/Search/Search_Landing_Page.png" width="935px" height="582" />-->
      <img [src]="src" />
    </div>

    <div id="searchitemresults" *ngIf="SearchItemResults">
      <!--<img src="../../../../assets/images/HelpImages/Search/Search_Item_Results.png" width="935px" height="582" /><br />-->
      <img [src]="src"  height="582"/>
      <span>
        <b>
          If only one record is found when searching by Item Code, the system can fetch and display nutritional results.
          If multiple records are found, the user will be prompted to select a record so that the system
          can fetch the corresponding nutritional results accordingly as shown here.
        </b>
      </span>
    </div>

    <div id="SearchNutririonsFacts" *ngIf="SearchNutririonsFacts">
      <!--<img src="../../../../assets/images/HelpImages/Search/Search_Nutririons_Facts.png" width="935px" height="582" />-->
      <img [src]="src" width="935px" height="582"/>
      <br />
      <!--<img src="../../../../assets/images/HelpImages/Search/Search_Nutririons_Facts_Continued_1.png" width="935px" height="582" />-->
      <img [src]="src1" width="935px" height="582"/>
    </div>

    <!--Audit Report-->
    <div id="AuditReportLandingPage" *ngIf="AuditReportLandingPage">
      <!--<img src="../../../../assets/images/HelpImages/AuditReport/AuditReport.png" width="935px" height="582" />-->
      <img [src]="src" height="582" />
    </div>

    <!--Activity-->
    <div id="ActivityPage" *ngIf="ActivityPage">
      <!--<img src="../../../../assets/images/HelpImages/Activity/ActivityPage.png"/>-->
      <img [src]="src" />
    </div>

    <!--Capture Image-->
    <div id="CaptureImageSearchpage" *ngIf="CaptureImageSearchpage">
      <!--<img src="../../../../assets/images/HelpImages/CaptureImage/Capture_Search_Page.png" width="935px" height="582" />-->
      <img [src]="src" width="935px" height="582"/>
    </div>
    <div id="CaptureAddComponentspage" *ngIf="CaptureAddComponentspage">
      <!--<img src="../../../../assets/images/HelpImages/CaptureImage/Capture_add_components.png" width="935px" height="582" />-->
      <img [src]="src" width="935px" height="582"/>
    </div>
    <div id="CaptureUpdatePanelpage" *ngIf="CaptureUpdatePanelpage">
      <!--<img src="../../../../assets/images/HelpImages/CaptureImage/Capture_updated_panel.png" width="935px" height="582" />-->
      <img [src]="src" width="935px" height="582"/>
    </div>
    <div id="CaptureImage" *ngIf="CaptureImage">
      <!--<img src="../../../../assets/images/HelpImages/CaptureImage/Capture_capture_image.png" />-->
      <img [src]="src"/>
    </div>

    <!--Audit DashBoard-->
    <div id="AuditDashBoardLandingPage" *ngIf="AuditDashBoardLandingPage">
      <!--<img src="../../../../assets/images/HelpImages/AuditDashBoard/AuditDashBoard_Landing_Page.png" width="935px" height="582" />-->
      <img [src]="src" width="935px" height="582" />
    </div>

    <div id="AuditDashBoardReportPage" *ngIf="AuditDashBoardReportPage">
      <!--<img src="../../../../assets/images/HelpImages/AuditDashBoard/AuditDashBoard_Report_Page.png" width="935px" height="582" />-->
      <img [src]="src"  width="935" height="582"/>
    </div>

  </div>
</div>

