<div *ngIf="component.isComponentDataExist" style="height: 500px; overflow-y: scroll;">
  <div class="nutrition_fact-container">
    <div class="header" style="border-bottom: 1px solid black;">Nutrition Facts</div>
    <!-- <div class="separator-thin"></div> -->
    <div class="text-align-left">{{component.servingPerPackage}} servings per container</div>
    <div class="serving-size" style="border-bottom: 3px solid black;">
      Serving size {{
 component.householdServingSize |
        number
      }}&nbsp;{{component.householdServingUom}}&nbsp;({{component.servingSizeVal}}{{component.servingSizeUom}}/{{component.visualServingUom}})
    </div>
    <!-- <div class="separator-dark"></div> -->
    <!--single panel results-->
    <table style="height: 100%" *ngIf="component.panelType==='Single Panel'">
      <thead>
        <tr class="separator-calories">
          <th class="calories-header">
            Calories
            <!-- <div class="separator-calories"></div> -->
          </th>
          <th>
            <div>
              {{getMiscellaneousData().itemHeader}}

              <ng-container *ngFor="let nutrient of GetNutrientCaloriyToShow()">
                <input type="text" [(ngModel)]="nutrient.nutrValue"
                       (ngModelChange)="onNutrientDispalyvalueChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.nutrValue)"
                       [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ? 'black' : '#ff6600'
                                ,'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ? 'hidden': 'groove'}"
                       class="my-input" style="width: 55px; height: 32px;"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" />
              </ng-container>

              <!-- <div class="calories-number">{{getMiscellaneousData().calorieValue}}</div> -->
              <br>
              <!-- <div class="separator-calories"></div> -->
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style="height: 50px;">
          <td align="right"></td>
          <td class="dv-bold-right">%DV*</td>
        </tr>
        <tr *ngFor="let nutrient of GetNutrientsToShow() index as i" [ngStyle]="{'border-bottom':GetNutrientsToShow().length==i+1?'0pt solid black':'0.6pt solid black'}">
          <td class="separator-thin-right-line"
              [ngClass]="{'nutrient-highlighted': isHighlightedNutrient(nutrient)}"
              [attr.id]="removeSpaces(nutrient.nutrientName)">
            {{ nutrient.nutrientName }}
          </td>
          <td style="width :45%">

            <div class="serving-container">
              <div>
                <span *ngIf="!getVisibilityValue1(nutrient.nutrientID)" (click)="showTextBoxValue1(nutrient.nutrientID)"
                      style="display:block;width:55px;min-height: 21px;"
                      [ngStyle]="{'border-bottom': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ? 'white' :
                                  '2pt solid #ff6600'}">{{nutrient.nutrValue}}{{getUom(nutrient.uom)}}</span>
                <input autofocus name="{{nutrient.nutrientID}}" type="text" [(ngModel)]="nutrient.nutrValue"
                       *ngIf="getVisibilityValue1(nutrient.nutrientID)" (focusout)="showSpanValue1(nutrient.nutrientID)"
                       (ngModelChange)="onNutrientDispalyvalueChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.nutrValue)"
                       [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue,
                                   'displayValue') ? 'black' : '#ff6600'
                                }"
                       class="my-input"
                       style="width: 55px; height: 32px; display: inline-block; margin-right: 2px;"
                       oninput="this.value = this.value.replace(/[^<0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/(.*<.*)<+/g, '$1');" />
                <!-- <span style="display: inline-block;">{{getUom(nutrient.uom)}}</span> -->

              </div>

              <span *ngIf="!getVisibilityDv1(nutrient.nutrientID)" (click)="showTextBoxDv1(nutrient.nutrientID)" style="display:block;width:55px;min-height: 21px;"
                    [ngStyle]="{'border-bottom': getdifference(component.componentID, nutrient.nutrientID, nutrient.dvp, 'dvp') ? 'white' : '2pt solid #ff6600'}">{{nutrient.dvp}}</span>
              <input autofocus="(1===1)" type="text" [(ngModel)]="nutrient.dvp" *ngIf="getVisibilityDv1(nutrient.nutrientID)" (focusout)="showSpanDv1(nutrient.nutrientID)"
                     (ngModelChange)="onNutrientDvpChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.dvp)"
                     [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.dvp, 'dvp') ?'black' : '#ff6600'}"
                     class="my-input" style="width: 55px; height: 32px;"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" />
            </div>
            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}"></div> -->
          </td>
        </tr>
      </tbody>
    </table>
    <!--dual panel results-->
    <table style="height: 100%" *ngIf="component.panelType==='Dual Column'">
      <thead>
        <tr class="separator-calories" style="height:80px">
          <th class="calories-header " style="border-right: 0.5pt solid black;">
            Calories
            <!-- <div class="separator-calories"></div> -->
          </th>
          <th style="border-right: 0.5pt solid black;">
            <div class="d-flex h-100 flex-column">
              <!-- <span *ngIf="getMiscellaneousData().dualitemHeader === null">&nbsp;</span> -->
              {{getMiscellaneousData().itemHeader}}
              <div *ngFor="let nutrient of GetNutrientCaloriyToShow()" style="margin-top: auto;">
                <input type="text" [(ngModel)]="nutrient.nutrValue"
                       (ngModelChange)="onNutrientDispalyvalueChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.nutrValue)"
                       [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ? 'black' : '#ff6600'
                                ,'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ? 'hidden': 'groove'}"
                       class="my-input" style="width: 55px; height: 32px;"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/(.*<.*)<+/g, '$1');" />
              </div>
              <!-- <div class="calories-number mt-auto">{{getMiscellaneousData().calorieValue}}</div> -->
              <!-- <div class="separator-calories"></div> -->
            </div>
          </th>
          <th colspan="2">
            <div class="d-flex h-100 flex-column">
              <span *ngIf="getMiscellaneousData().dualitemHeader === undefined">&nbsp;</span>
              {{getMiscellaneousData().dualitemHeader}}
              <div *ngFor="let nutrient of GetNutrientCaloriyToShow()" style="margin-top: auto;">
                <input type="text" [(ngModel)]="nutrient.dualNutrValue"
                       (ngModelChange)="onNutrientDualDispalyvalueChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.dualNutrValue)"
                       [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualNutrValue, 'duaL_DisplayValue') ? 'black' : '#ff6600'
                                ,'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualNutrValue, 'duaL_DisplayValue') ? 'hidden': 'groove'}"
                       class="my-input" style="width: 55px; height: 32px;"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/(.*<.*)<+/g, '$1');" />
              </div>
              <!-- <div class="calories-number mt-auto">{{getMiscellaneousData().dualcalorieValue | number:
                  '1.0-0'}}</div> -->
              <!-- <div class="separator-calories"></div> -->
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="right"></td>
          <td class="dv-bold-right">%DV*</td>
          <td class="dv-bold-right">%DV*</td>
        </tr>
        <tr *ngFor="let nutrient of GetNutrientsToShow(); index as i" [ngStyle]="{'border-bottom':GetNutrientsToShow().length==i+1?'0pt solid black':'0.6pt solid black'}">
          <td class="separator-thin-right-line"
              [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
            {{nutrient.nutrientName}}
            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
          </td>
          <td class="separator-thin-right-line">
            <!-- <div class="serving-container">
                <span *ngIf="nutrient.displayValue != null && nutrient.displayValue.startsWith('Less than');
                     else uomDVDisplay;"
                    style="margin-left:5px;">{{getDualDisplayValue(nutrient.displayValue)}}</span>
                <ng-template #uomDVDisplay>
                    <span
                        style="margin-left:5px;">{{getDualDisplayValue(nutrient.nutrValue)}}{{getUom(nutrient.uom)}}</span>
                </ng-template>
                <span class="text-bold"> {{nutrient.dvp | number: '1.0-0'}}% </span>
            </div> -->
            <div class="serving-container">
              <div>
                <span *ngIf="!getVisibilityValue1(nutrient.nutrientID)" (click)="showTextBoxValue1(nutrient.nutrientID)" style="display:block;width:40px;min-height: 21px;"
                      [ngStyle]="{'border-bottom': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ?'white' : '2pt solid #ff6600'}">{{nutrient.nutrValue}}{{getUom(nutrient.uom)}}</span>
                <input id="fv{{nutrient.nutrientID}}" autofocus="(1===1)" name="{{nutrient.nutrientID}}" type="text" [(ngModel)]="nutrient.nutrValue" *ngIf="getVisibilityValue1(nutrient.nutrientID)" (focusout)="showSpanValue1(nutrient.nutrientID)"
                       (ngModelChange)="onNutrientDispalyvalueChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.nutrValue)"
                       [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ? 'black' : '#ff6600',
                                'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.nutrValue, 'displayValue') ?'hidden': 'groove'}"
                       class="my-input" style="width: 40px; height: 32px;"
                       oninput="this.value = this.value.replace(/[^<0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/(.*<.*)<+/g, '$1');" />
                <!-- {{getUom(nutrient.uom)}} -->
              </div>
              <span *ngIf="!getVisibilityDv1(nutrient.nutrientID)" (click)="showTextBoxDv1(nutrient.nutrientID)" style="display:block;width:40px;min-height:21px;"
                    [ngStyle]="{'border-bottom': getdifference(component.componentID, nutrient.nutrientID, nutrient.dvp, 'dvp') ? 'white' : '2pt solid #ff6600'}">{{nutrient.dvp}}</span>
              <input type="text" [(ngModel)]="nutrient.dvp" (focusout)="showSpanDv1(nutrient.nutrientID)" *ngIf="getVisibilityDv1(nutrient.nutrientID)"
                     (ngModelChange)="onNutrientDvpChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.dvp)"
                     [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.dvp, 'dvp') ? 'black' : '#ff6600',
                            'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.dvp, 'dvp') ? 'hidden': 'groove'}"
                     class="my-input" style="width: 40px; height: 32px;"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" />
            </div>
            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
          </td>
          <td>
            <div class="serving-container">
              <div>
                <span *ngIf="!getVisibilityValue2(nutrient.nutrientID)" (click)="showTextBoxValue2(nutrient.nutrientID)" style="display:block;width:40px;min-height:21px;"
                      [ngStyle]="{'border-bottom': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualNutrValue, 'duaL_DisplayValue') ?'white' : '2pt solid #ff6600'}">{{nutrient.dualNutrValue}}{{getUom(nutrient.uom)}}</span>
                <input id="sv{{nutrient.nutrientID}}" autofocus name="{{nutrient.nutrientID}}" type="text" [(ngModel)]="nutrient.dualNutrValue" *ngIf="getVisibilityValue2(nutrient.nutrientID)" (focusout)="showSpanValue2(nutrient.nutrientID)"
                       (ngModelChange)="onNutrientDualDispalyvalueChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.dualNutrValue)"
                       [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualNutrValue, 'duaL_DisplayValue') ?'black' : '#ff6600',
                            'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualNutrValue, 'duaL_DisplayValue') ?'hidden': 'groove'}"
                       class="my-input" style="width: 40px; height: 32px;"
                       oninput="this.value = this.value.replace(/[^<0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/(.*<.*)<+/g, '$1');" />
                <!-- {{getUom(nutrient.uom)}}</div> -->
              </div>
              <span *ngIf="!getVisibilityDv2(nutrient.nutrientID)" (click)="showTextBoxDv2(nutrient.nutrientID)" style="display:block;width:40px;min-height:21px;"
                    [ngStyle]="{'border-bottom': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualDVP, 'dualDVP') ? 'white' : '2pt solid #ff6600'}">{{nutrient.dualDVP}}</span>
              <input type="text" [(ngModel)]="nutrient.dualDVP" (focusout)="showSpanDv2(nutrient.nutrientID)" *ngIf="getVisibilityDv2(nutrient.nutrientID)"
                     (ngModelChange)="onNutrientDualDvpChange(component.gtin, component.componentID, nutrient.nutrientID, nutrient.dualDVP)"
                     [ngStyle]="{'border-color': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualDVP, 'dualDVP') ? 'black' : '#ff6600',
                            'border-bottom-style': getdifference(component.componentID, nutrient.nutrientID, nutrient.dualDVP, 'dualDVP') ? 'hidden': 'groove'}"
                     class="my-input" style="width: 40px; height: 32px;"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" />
            </div>
            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="!component.isComponentDataExist"><h4>No OCR component exist to compare.</h4></div>
