<div class="pagination-container">
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <li class="page-item" *ngIf="!isFirstPage()">
        <a class="page-link" (click)="goToPage(1)" aria-label="First">
          First
        </a>
      </li>
      <li class="page-item" *ngIf="!isFirstPage()">
        <a class="page-link" (click)="goToPage(currentPage - 1)" aria-label="Previous">
          Previous
        </a>
      </li>
      <li class="page-item" *ngFor="let page of pagesArray">
        <a class="page-link" [class.active]="currentPage === page" (click)="goToPage(page)">
          {{ page }}
        </a>
      </li>
      <li class="page-item" *ngIf="!isLastPage()">
        <a class="page-link" (click)="goToPage(currentPage + 1)" aria-label="Next">
          Next
        </a>
      </li>
      <li class="page-item" *ngIf="!isLastPage()">
        <a class="page-link" (click)="getLastFiveAscending()" aria-label="Last">
          Last
        </a>
      </li>
    </ul>
  </nav>
</div>
