import { ChangeDetectorRef, Component, EventEmitter, HostListener, Output, ViewChild } from "@angular/core";
import { AuditNotes, ComponentSet, Gtin, MutliColumnSet } from "../Models/Gtin";
import { ScanBarcodeResult } from "../scan-barcode/models/scanBarCodeResult";
import { Nutrient, nutrientValue, OcrNutrient, OcrNutritionFacts, ServingData } from 'src/app/Models/OcrNutritionFacts';
import { RestApiService } from '../services/RestApiService.service';
import { OcrData } from "../Models/OcrData";
import { EismnNutrientValueData } from "../Models/EisnmNutritionData";
import { CapturedImage } from "./models/capturedImage";
import { getOcrNutritionFactsObject } from "../Models/model-parser";
import { ActivatedRoute, Router } from "@angular/router";
import { GetBinaryDataFromCapturedImage } from "src/utils/Image";
import { PNMSData } from "../Models/PNMSDataModels";
import { isNumber } from "lodash";
import { MsalService } from "@azure/msal-angular";
import { IcomponentDescription } from "./models/componentinfo";
import { environment } from 'src/environments/environment';
import { Subscription } from "rxjs";
import { CapturePictureComponent } from "./capture-picture/capture-picture.component";
import { MultiColumnComboBoxComponent } from "@progress/kendo-angular-dropdowns";

const filesFolder = "C:\\testimages\\";

const uoms = ["mcg", "mg", "g"];

@Component({
  selector: 'app-image-ocr',
  templateUrl: './image-ocr.component.html',
  styleUrls: ['./image-ocr.component.css']
})
export class ImageOcrComponent {
  @ViewChild(CapturePictureComponent, { static: false }) CapturePictureComponent: CapturePictureComponent | undefined;

  rawResponse = '';
  isIpadVertical = false;
  selectedPanelType: string = '';
  skipScanStep = false;
  private routeSub!: Subscription;
  step: 'capture-scanbarcode' | 'capture-picture' = 'capture-scanbarcode';
  showInvalidScanBarcodeMessage = false;
  gtinCode: number = -1;
  itemDescription: string = '';
  ocrDataResult: { data: OcrData[] } | undefined;
  errorMessage: string = '';
  disableCamera: boolean = true;
  enhancedOcrNutritionFacts: OcrNutritionFacts | null = null;
  ocrNutritionFacts: OcrNutritionFacts | null = null;
  nutrientTypes: any;
  isLoadingNutritionFacts = false;
  mutliColumnSet: MutliColumnSet[] = [];
  capturedImages: CapturedImage[] = [];
  selectedThumbnail: CapturedImage | undefined;
  servingData!: ServingData;
  hasNutritionFactChanges = false;
  isCompareFlow = false;
  gtinKey!: string;
  private nutritionFactText: string = '';
  displayOcrView: boolean = true;
  public compnonentDescriptions: Array<IcomponentDescription> = [];
  public compnonentPanelStatus: Array<IcomponentDescription> = [];
  public tmpCompnonentDescriptions: Array<IcomponentDescription> = [];

  @Output() scanBarcodeChanged = new EventEmitter<ScanBarcodeResult>();

  selectedComponentId = '';
  selectedDescription = '';
  centerContent: boolean = false;
  @ViewChild('modalComponent') modalComponent: any;
  @ViewChild('scanNewItemModalComponent') scanNewItemModalComponent: any;

  @ViewChild('modalVarietyComponent') modalVarietyComponent: any;

  modalData = {
    isSuccess: false,
    message: '',
    header: ''
  }

  modalVarietyData = {
    isSuccess: false,
    message: 'Please select component description',
    header: 'Component Description'
  }
  userId: any;
  draftSaved = false;

  constructor(private restApiService: RestApiService, private route: ActivatedRoute,
    private authService: MsalService,
    private router: Router, private cdr: ChangeDetectorRef) {
    //this.compnonentDescriptions.push({ componentId: "123", description: "Test1" });
    this.restApiService.quaggaStop();
    var accountInfo = this.authService.instance.getAllAccounts();
    if (accountInfo != null && accountInfo.length > 0 && accountInfo[0].idTokenClaims) {
      this.userId = accountInfo[0].idTokenClaims['sAMAccountName'];
    }
  }
  onChangedDevicePostion(event: boolean) {
    this.isIpadVertical = event;
    this.cdr.detectChanges();
  }
  generateCells(count: number): any[] {
    return Array(count).fill('').map((_, index) => index + 1);
  }

  isMultiPanelImage(event: boolean) {
    this.isMultiPanel = event;
  }

  getDescriptionByComponentId(componentId: any) {
    const component = this.compnonentDescriptions.find(item => item.componentId === componentId);
    if (component) {
      return component.description;
    } else {
      return ''; // Handle the case when the componentId is not found
    }
  }
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: Event): void {
    window.scrollTo(0, 0);
  }

  addNewComponent() {
    this.description = '';
    if (this.isMultiPanel) {
      this.multiPanelopened = true;
    }
    else {
      const newDescription = prompt('Enter a new component description:');
      if (newDescription) {
        // Create a new item and add it to the array
        const newComponent = {
          componentId: (this.compnonentDescriptions.length + 1).toString(), // Assign a unique ID
          description: newDescription
        };
        this.compnonentDescriptions.push(newComponent);

        setTimeout(() => {
          this.ocrNutritionFacts = null;
          this.enhancedOcrNutritionFacts = null;
          this.selectedDescription = newDescription;
          this.mySelect.nativeElement.value = this.compnonentDescriptions.length;
          this.selectedComponentId = this.compnonentDescriptions.length.toString();
          this.CapturePictureComponent?.refreshCamera();
        }, 500);

      }
    }
  }
  panelType: string = '';
  isProd = environment.production;
  ngOnInit(): void {

    this.getNutrients();
    this.routeSub = this.route.queryParams.subscribe(params => {
      this.gtinCode = params['gtin'];
    });
    if (this.gtinCode > 0) {
      this.skipScanStep = true;
    }
  }
  nutrientTypesWithOutFilter: any;
  getNutrients(): void {
    this.restApiService.getNutritions().subscribe({
      next: (data) => {
        this.nutrientTypesWithOutFilter = data;
        this.nutrientTypes = this.nutrientTypesWithOutFilter.filter((item: { nutrientName: string; }) => {
          return item.nutrientName !== "Juice" && item.nutrientName !== "Whole Grain";
        });
      },
      error: () => {
      }
    });

  }

  onScanBarcodeChanged(result: ScanBarcodeResult) {

    this.ocrNutritionFacts = null;
    this.capturedImages = [];
    console.log("onScanBarcodeChanged", result);
    this.showInvalidScanBarcodeMessage = false;

    if (result.isSuccess) {
      console.log('this.showPNSButton.emit :' + result.showAddPnsButton);
      this.restApiService.showAddPNSButton(result.showAddPnsButton);

      this.step = 'capture-picture';
      this.gtinCode = result.code;
      this.ocrDataResult = result.ocrDataResult;

      if ((this.ocrDataResult?.data && this.ocrDataResult.data.length > 1) || (this.ocrDataResult?.data[0].panelType.toLocaleLowerCase().includes('variety'))) {
        this.panelType = 'Variety Pack';
        this.selectedPanelType = 'Variety Pack';
      }
      else if (this.ocrDataResult?.data[0].panelType.toLocaleLowerCase().includes('single')) {
        this.panelType = 'Single Panel';
        this.selectedPanelType = 'Single Panel';
      }
      else if (this.ocrDataResult?.data[0].panelType.toLocaleLowerCase().includes('dual')) {
        this.panelType = 'Dual Column';
        this.selectedPanelType = 'Dual Column';
      }



      //if (this.ocrDataResult?.data[0].panelType == undefined ? '' : this.ocrDataResult?.data[0].panelType.toLowerCase().includes('single')) {
      //  this.panelType = 'Single Panel';
      //}
      //else if (this.ocrDataResult?.data[0].panelType == undefined ? '' : this.ocrDataResult?.data[0].panelType.toLowerCase().includes('dual')) {
      //  this.panelType = 'Dual Column';
      //}
      //else {
      //  this.panelType = 'Variety Pack';
      //}

      //this.selectedPanelType = this.panelType;
      this.opened = true;


      const servingData: ServingData = {
        servingSizeVal: 0,
        servingSizeUom: "g",
        householdServingSize: 0,
        householdServingUom: "g",
        visualServingUom: "",
        servingPerPackage: ""
      };

      if (this.ocrDataResult != null && this.ocrDataResult.data != null && this.ocrDataResult.data.length > 0) {

        this.compnonentDescriptions = [];

        this.ocrDataResult.data.forEach(item => {
          if (item.compDescription != "") {
            this.compnonentDescriptions.push({ componentId: item.componentID, description: item.compDescription });
            this.compnonentPanelStatus.push({ componentId: item.componentID, description: item.panelType.toLowerCase().indexOf('dual') > -1 ? 'D' : 'S' });
          }
        });


        servingData.servingSizeVal = Number(this.ocrDataResult?.data[0].servingSizeVal);
        servingData.servingSizeUom = this.ocrDataResult?.data[0].servingSizeUom;
        servingData.householdServingSize = Number(this.ocrDataResult?.data[0].householdServingSize);
        servingData.householdServingUom = this.ocrDataResult?.data[0].householdServingUom;
        servingData.visualServingUom = this.ocrDataResult?.data[0].visualServingUom;
        servingData.servingPerPackage = this.ocrDataResult?.data[0].servingPerPackage;
        this.itemDescription = this.ocrDataResult?.data[0].itemDescription;
      }
      else {
        this.restApiService.getGtinItemDescription(this.gtinCode).subscribe({
          next: (data: any) => {
            this.itemDescription = data.itemDescription;

          },
          error: () => {

          }
        });
      }

      this.servingData = servingData;

    }
    else {
      this.showInvalidScanBarcodeMessage = true;
      this.errorMessage = result.errorMessage;
    }

    // this.showScanErrorMessage = false;
    // this.showResultsNotFoundMessage=false;
    // if (result.isSuccess) {
    //   this.itemgtincode = result.code;
    //   this.disableCamera = true;
    //   this.type = 'gtin';
    //   this.searchByItemOrGtinCode()
    // }
    // else {
    //   this.showScanErrorMessage = true;

    //   console.log(this.type);
    // }
    // this.type = 'scanBarCode';
  }


  populateServingData() {
    const servingData: any = {};
    var servingOcrData = this.ocrDataResult?.data.filter(x => {
      return x.componentID == this.selectedComponentId;
    });

    if (servingOcrData && servingOcrData.length > 0) {

      servingData.servingSizeVal = Number(servingOcrData[0].servingSizeVal);
      servingData.servingSizeUom = servingOcrData[0].servingSizeUom;
      servingData.householdServingSize = Number(servingOcrData[0].householdServingSize);
      servingData.householdServingUom = servingOcrData[0].householdServingUom;
      servingData.visualServingUom = servingOcrData[0].visualServingUom;
      servingData.servingPerPackage = servingOcrData[0].servingPerPackage;
      this.servingData = servingData;
      //console.log('ln153:' + JSON.stringify(this.servingData));

      if (this.ocrNutritionFacts) {
        this.ocrNutritionFacts.servingData = this.servingData;
      }
    }

  }
  @ViewChild('compDescriptions') mySelect: any;


  onTmpComonentSelected(value: string): void {
    if (value === '') {
      this.componentID = -1;
    }
    if (value && value != "") {
      this.ocrNutritionFacts = null;
      this.enhancedOcrNutritionFacts = null;
      this.selectedComponentId = value;
      const selectedComponent = this.tmpCompnonentDescriptions.find(comp => comp.componentId.toString() === value);

      if (selectedComponent != null) {
        this.description = selectedComponent?.description;
        this.componentID = +(selectedComponent.componentId);
      }
    }
  }

  onComonentSelected(value: string): void {
    if (value && value != "") {
      this.ocrNutritionFacts = null;
      this.enhancedOcrNutritionFacts = null;
      this.selectedComponentId = value;
      const selectedComponent = this.compnonentDescriptions.find(comp => comp.componentId.toString() === value);
      // Update the selectedDescription property
      this.selectedDescription = selectedComponent ? selectedComponent.description : '';
      this.componentID = +(selectedComponent ? selectedComponent.componentId : -1);
      this.populateServingData();
    }
    else {
      this.selectedComponentId = '';
    }
  }

  description = '';
  componentID: number = -1;
  closeDialog() {

    if (this.compnonentDescriptions.length < 2) {
      alert("Please add atleast two compoenets as it is variety panel");
      return;
    }
    else {
      this.multiPanelopened = false;
    }
  }
  getdescrption(value: MutliColumnSet) {
    return this.tmpCompnonentDescriptions.filter(x => +x.componentId == value.componentID)[0].description;
  }
  checkComponentSelection() {
    var showModal = false;
    if (this.compnonentDescriptions.length > 1 && this.selectedPanelType == 'Variety Pack') {
      if (this.selectedComponentId == '') {
        showModal = true;
        this.modalVarietyComponent.show();
      }
    }
    return showModal;
  }

  addNextMultiPanel1(event: MutliColumnSet[]) {
  }



  addNextMultiPanel(event: MutliColumnSet[]) {
  }


  addNext() {
    // Create a new item and add it to the array
    const newComponent = {
      componentId: (this.compnonentDescriptions.length + 1).toString(), // Assign a unique ID
      description: this.description
    };
    this.compnonentDescriptions.push(newComponent);
  }


  closeDialogNonMultiPanel() {
    if (this.compnonentDescriptions.length == 0) {
      alert("Please add atleast two compoenents as it is variety panel");
      return;
    }
    else {
      this.nonMultiPanelopened = false;
      this.componentPopUp = false;
    }
  }


  public opened = false;
  multiPanelopened = false;
  nonMultiPanelopened = false;
  isMultiPanel = false;
  isDualColumn = false;
  column = 1;
  componentPopUp = false;
  public close(): void {
    this.tmpCompnonentDescriptions = this.compnonentDescriptions;
    this.compnonentDescriptions = [];
    if (this.isMultiPanel) {
      this.multiPanelopened = true;
      this.compnonentDescriptions = [];
    }
    else {
      this.nonMultiPanelopened = true;
    }
    this.opened = false;
    this.componentPopUp = true;
  }
  closeCreateComponent(event: ComponentSet) {
    this.compnonentDescriptions = event.componentSet;
    this.mutliColumnSet = event.multiColSet;
    this.componentPopUp = false;
    this.CapturePictureComponent?.refreshCamera();
  }

  closeselectPanelType(event: string) {

    this.tmpCompnonentDescriptions = this.compnonentDescriptions;
    this.compnonentDescriptions = [];
    if (this.isMultiPanel) {
      this.multiPanelopened = true;
    }
    else {
      this.nonMultiPanelopened = true;
    }
    this.opened = false;
    this.componentPopUp = true;


    this.selectedPanelType = event;
    if (this.selectedPanelType !== 'Variety Pack') {
      this.componentPopUp = false;
    }
    else {
      this.componentPopUp = true;
      this.disableCamera = false;
      this.CapturePictureComponent?.refreshCamera();

    }
    //this.opened = false;
    //this.componentPopUp = true;
  }


  closeVarietyComponenttModal() {
    this.modalVarietyComponent.hide();
  }
  isNotInCompDescriptions(componentId: any): boolean {
    return !this.compnonentDescriptions.some(comp => comp.componentId === componentId);
  }
  // add thumbnail parameter when its ready to be integrated
  processOcr() {
    this.displayOcrView = true;
    this.centerContent = true;
    //show alert message if nothing is selectedComponent dropdown for varity pack
    var status = this.checkComponentSelection();
    //this.getOcrNutritionFacts("C:\\POC\\PNMS\\OcrApiTest\\bin\\Debug\\net6.0-windows\\Images\\test1.png");
    // this.getOcrNutritionFacts("C:\\POC\\PNMS\\OcrApiTest\\bin\\Debug\\net6.0-windows\\Images\\test2.png");
    //if (!status) {
    //this.getOcrNutritionFacts('', "C:\\testimages\\test1.jpg");
    //}

    //const x = prompt("Enter Image number");
    //if (x !=null && +x === 1) {
    //  if (!status) {
    //    const selectedset = this.mutliColumnSet.find(x => x.componentID === +this.selectedComponentId);
    //    this.getOcrNutritionFacts('', 'C:\\testimages\\img1.jpg', selectedset?.column, !selectedset?.isDualColumn, this.isMultiPanel);
    //  }
    //}

    //if (x != null && +x === 2) {
    //  if (!status) {
    //    const selectedset = this.mutliColumnSet.find(x => x.componentID === +this.selectedComponentId);
    //    this.getOcrNutritionFacts('', 'C:\\testimages\\img2.jpg', selectedset?.column, !selectedset?.isDualColumn, this.isMultiPanel);
    //  }
    //}

    //if (!status) {
    //  const selectedset = this.mutliColumnSet.find(x => x.componentID === +this.selectedComponentId);
    //  this.getOcrNutritionFacts('', 'C:\\testimages\\varpack6.jpg', selectedset?.column, !selectedset?.isDualColumn, this.isMultiPanel);
    //}


    if (!status) {
      if (this.selectedThumbnail) {
        var filename = this.selectedThumbnail.fileName;
        var imageBase64 = this.selectedThumbnail;
        //let currentDateTime = this.datepipe.transform((new Date), 'MMddyyyy_hmmss');
        // let gtin = this.gtinCode.toString();
        this.capturedImages.map(i => {
          if (i.fileName === filename) i.isProcessed = true;
        });
        //this.getOcrNutritionFacts("C:\\Users\\P1793934\\Downloads\\" + this.selectedThumbnail.fileName);
        // When deploy to Dev environment enable this code and send empty path if it is required.
        if (this.isMultiPanel) {
          const selectedset = this.mutliColumnSet.find(x => x.componentID === +this.selectedComponentId);
          this.getOcrNutritionFacts(GetBinaryDataFromCapturedImage(imageBase64.data), '', selectedset?.column, !selectedset?.isDualColumn, true);
        }
        else {
          this.getOcrNutritionFacts(GetBinaryDataFromCapturedImage(imageBase64.data), '');
        }
        // this.getOcrNutritionFacts(imageBase64.data.replace('data:image/png;base64,', ''),'');
      }
    }
  }

  scanNewItem() {
    if (!this.draftSaved || this.checkNutritionFactHasChanges()) {
      this.capturedImages = [];
      this.scanNewItemModalComponent.show();
    }
    else {
      this.step = 'capture-scanbarcode';
    }
  }

  onScanNewItemNo() {
    this.scanNewItemModalComponent.hide();
    this.step = 'capture-scanbarcode';
  }

  onScanNewItemYes() {
    this.scanNewItemModalComponent.hide();
    const apiDataObject = this.getOcrSaveApiObject(null);
    this.restApiService.saveDraftOcrData(apiDataObject).subscribe({
      complete: () => {
        this.draftSaved = true;
        this.backupNutritionFact();
        this.step = 'capture-scanbarcode';
      },
      error: () => {
        this.modalData.isSuccess = false;
        this.modalData.message = "Error occured while saving data"
        this.modalComponent.show();
      }
    });
    this.ocrNutritionFacts = null;
  }

  private getOcrNutritionFacts(imagefile: any, imagePath: string, column: number = 0, isSinglePanel = true, isMultiColumn = false) {
    this.isLoadingNutritionFacts = true;

    this.restApiService.getOcrNutritionFacts({ imagefile: imagefile, imagepath: imagePath, column: column, isSinglePanel: isSinglePanel, isMultiColumn: isMultiColumn }).subscribe(
      {
        next: (data: {}) => {
          this.ocrNutritionFacts = getOcrNutritionFactsObject(data);
          this.rawResponse = this.ocrNutritionFacts.rawResponse;
          this.populateServingData();
          this.backupNutritionFact();
          this.isLoadingNutritionFacts = false;
          this.getGTINDetails(this.gtinCode);
          this.gtinKey = this.gtinCode.toString();
          this.enhancedOcrNutritionFacts = this.GetUpdatedEnhancedOcrData(this.enhancedOcrNutritionFacts, this.ocrNutritionFacts);
          this.ocrNutritionFacts = JSON.parse(JSON.stringify(this.enhancedOcrNutritionFacts)) as OcrNutritionFacts;
          // console.log("ocr nutri fact ln:268", this.ocrNutritionFacts);
          this.removeGtinNutritionTypes();

          let indexOfPeriod: number = this.ocrNutritionFacts.ingredients.indexOf(".");
          if (indexOfPeriod !== -1) {
            // Extract substring until the first period
            this.ocrNutritionFacts.ingredients = this.ocrNutritionFacts.ingredients.substring(0, indexOfPeriod);
          }

        },
        error: (e) => {
          this.modalData.header = "Process OCR";
          this.modalData.isSuccess = false;
          this.modalData.message = "Error occured while processing OCR image";
          this.modalComponent.show();
        }
      }).add(() => this.isLoadingNutritionFacts = false);
  }

  private removeGtinNutritionTypes() {
    this.ocrNutritionFacts?.arrayoffacts.forEach(af => {
      this.RemoveElementFromArray(af.fact.trim());

    });
  }

  RemoveElementFromArray(element: string) {

    this.nutrientTypes.forEach((value: any, index: number) => {
      if (value.nutrientName.trim() == element) this.nutrientTypes.splice(index, 1);
    });
  }

  GetUpdatedEnhancedOcrData(enhancedOcrNutritionFacts: OcrNutritionFacts | null, ocrNutritionFacts: OcrNutritionFacts) {


    const newEnhancedOcrNutritionalFacts = JSON.parse(JSON.stringify(ocrNutritionFacts)) as OcrNutritionFacts;

    if (enhancedOcrNutritionFacts == null) {
      return newEnhancedOcrNutritionalFacts;
    }

    //console.log("normal: " + JSON.stringify(ocrNutritionFacts.arrayoffacts));

    ocrNutritionFacts.arrayoffacts.forEach(x => {
      if (x.fact.trim().toLowerCase().indexOf('total carb') > -1) {
        enhancedOcrNutritionFacts.arrayoffacts =
          enhancedOcrNutritionFacts.arrayoffacts.filter(y => y.fact.toLowerCase().indexOf('total carb') < 0);
      }
    })
    //console.log("enahnced: " + JSON.stringify(enhancedOcrNutritionFacts.arrayoffacts));

    const enhancedArrayOfFacts = this.getEnhancedArrayOfFacts(enhancedOcrNutritionFacts.arrayoffacts, ocrNutritionFacts.arrayoffacts);


    newEnhancedOcrNutritionalFacts.arrayoffacts = enhancedArrayOfFacts;

    if (ocrNutritionFacts.ingredients == null || ocrNutritionFacts.ingredients == "") {
      newEnhancedOcrNutritionalFacts.ingredients = enhancedOcrNutritionFacts.ingredients;
    }
    if (ocrNutritionFacts.allergyInformation == null || ocrNutritionFacts.allergyInformation == "") {
      newEnhancedOcrNutritionalFacts.allergyInformation = enhancedOcrNutritionFacts.allergyInformation;
    }
    newEnhancedOcrNutritionalFacts.servingBlockText = this.getEnhanceServingBlockText(enhancedOcrNutritionFacts.servingBlockText, ocrNutritionFacts.servingBlockText);

    //set panel size
    if (ocrNutritionFacts.panelSize > 0) newEnhancedOcrNutritionalFacts.panelSize = ocrNutritionFacts.panelSize;
    else newEnhancedOcrNutritionalFacts.panelSize = enhancedOcrNutritionFacts.panelSize;

    newEnhancedOcrNutritionalFacts.servingData = ocrNutritionFacts.servingData;
    //fact headers
    newEnhancedOcrNutritionalFacts.factHeaders = this.getFactHeaders(enhancedOcrNutritionFacts.factHeaders, ocrNutritionFacts.factHeaders)



    //alert(JSON.stringify(newEnhancedOcrNutritionalFacts));
    return newEnhancedOcrNutritionalFacts;

  }

  getEnhancedArrayOfFacts(previousEnhancedNutrients: OcrNutrient[], currentNutrients: OcrNutrient[]) {
    //loop through the items 
    var enhancedArrayOfFacts = currentNutrients.length > 0 ? JSON.parse(JSON.stringify(currentNutrients)) as OcrNutrient[] : previousEnhancedNutrients;
    var newItems = [];
    if (currentNutrients.length > 0) {
      for (let r = 0; r < enhancedArrayOfFacts.length; r++) {
        const prevEnhancedItem = previousEnhancedNutrients.find(i => i.fact == enhancedArrayOfFacts[r].fact);
        console.log(prevEnhancedItem)

        if (prevEnhancedItem != null) {
          enhancedArrayOfFacts[r].dv = this.getEnhancedValue(enhancedArrayOfFacts[r].dv, prevEnhancedItem.dv);
          enhancedArrayOfFacts[r].dv2 = this.getEnhancedValue(enhancedArrayOfFacts[r].dv2, prevEnhancedItem.dv2);
          enhancedArrayOfFacts[r].value = this.getEnhancedValue(enhancedArrayOfFacts[r].value, prevEnhancedItem.value);
          enhancedArrayOfFacts[r].value2 = this.getEnhancedValue(enhancedArrayOfFacts[r].value2, prevEnhancedItem.value2);
        }
        else {
          enhancedArrayOfFacts[r].dv = this.getEnhancedValue(enhancedArrayOfFacts[r].dv, null);
          enhancedArrayOfFacts[r].dv2 = this.getEnhancedValue(enhancedArrayOfFacts[r].dv2, null);
          enhancedArrayOfFacts[r].value = this.getEnhancedValue(enhancedArrayOfFacts[r].value, null);
          enhancedArrayOfFacts[r].value2 = this.getEnhancedValue(enhancedArrayOfFacts[r].value2, null);

          previousEnhancedNutrients.push(enhancedArrayOfFacts[r]);

        }
      }
      if (previousEnhancedNutrients) enhancedArrayOfFacts = previousEnhancedNutrients;

    }
    return enhancedArrayOfFacts;

  }

  getEnhancedValue(currentValue: any, prevEnhanceValue: any) {
    if (currentValue == null || currentValue == '') {
      return prevEnhanceValue;
    }

    return currentValue;
  }

  compareIngridients(item1: string, item2: string) {
    var modifiedIngrident = item1;
    if (item2 !== '' && modifiedIngrident !== item2) {
      modifiedIngrident = item2;
    }
    return modifiedIngrident;
  }

  compareAllergen(item1: string, item2: string) {
    var modifiedAlergen = item1;
    if (item2 !== '' && modifiedAlergen !== item2) {
      modifiedAlergen = item2;
    }
    return modifiedAlergen;
  }

  getEnhanceServingBlockText(previousServingText: string, currentSeringText: string) {
    var modifiedAlergen = previousServingText;
    if (currentSeringText != null && currentSeringText !== '' && modifiedAlergen !== currentSeringText) {
      modifiedAlergen = currentSeringText;
    }
    return modifiedAlergen;
  }

  getFactHeaders(previousFactHeaders: string[], currentFactHeaders: string[]) {
    var modifiedFactHeaders = previousFactHeaders;
    if (currentFactHeaders && currentFactHeaders != null && currentFactHeaders.length > 0) {
      modifiedFactHeaders = currentFactHeaders;
    }
    return modifiedFactHeaders;
  }


  resetOcr() {
    this.displayOcrView = false;
    this.enhancedOcrNutritionFacts = null;
    this.isLoadingNutritionFacts = false;

  }

  private getOcrSaveApiObject(result: any) {
    var ocrData: any = {};
    if (this.ocrDataResult != null) {
      if (this.ocrDataResult.data != null)
        if (this.ocrDataResult.data.length == 1 || this.selectedPanelType.toLowerCase() == 'single panel' || this.selectedPanelType.toLowerCase() == 'dual column') {
          if (this.panelType.toLowerCase() == 'variety pack' && (this.selectedPanelType.toLowerCase() == 'dual column' || this.selectedPanelType.toLowerCase() == 'single panel')) {

            ocrData = this.ocrDataResult.data[0];
            this.selectedComponentId = '-1';
            this.selectedDescription = '';
            ocrData.componentID = '';
            ocrData.compDescription = '';
            ocrData.servingSizeVal = '';
            ocrData.servingSizeUom = '';
            ocrData.householdServingSize = '';
            ocrData.householdServingUom = '';
            ocrData.visualServingUom = '';
            ocrData.servingPerPackage = '';
            // ocrData.panelType = this.selectedPanelType;
            ocrData.itemHeader = '';
            ocrData.dualItemHeader = '';
            ocrData.itemDescription = '';
            //alert(JSON.stringify(ocrData));
            // console.log(JSON.stringify(ocrData));
          }
          else {
            ocrData = this.ocrDataResult.data[0];
          }
        }
        else {
          if (this.panelType.toLowerCase() == 'variety pack'
            && this.selectedPanelType.toLowerCase() == 'variety pack' && +this.selectedComponentId < 20) {

            const ocrData1 = {
              "gtin": this.ocrDataResult.data[0].gtin,//we know gtin
              "nutrInfoID": this.ocrDataResult.data[0].nutrInfoID,//we know
              "nutrSourceCd": "",
              "nutrStatusCd": "",
              "segment": "",
              "componentID": "",//we will take care
              "compDescription": this.selectedDescription,////we will take care
              "servingSizeVal": "",//UI supports
              "servingSizeUom": "",//UI supports
              "householdServingSize": "",//UI supports
              "householdServingUom": "", //UI supports
              "visualServingUom": "",//UI supports
              "servingPerPackage": "",//UI supports
              "panelType": "",//we will take care
              "itemHeader": "",
              "dualItemHeader": "",
              "itemDescription": ""
            }
            ocrData = ocrData1;// this.ocrDataResult.data[0];
          }
          else {
            ocrData = this.ocrDataResult.data.filter(i => i.componentID == this.selectedComponentId)[0];
          }
        }
    }
    else {
      //if new gitn then insert into SQL
      //set the defualt values for componentId, other fields
      ocrData.gtin = this.gtinCode;
      if (result.pnmsResult != null) {
        ocrData.nutrInfoID = result.pnmsResult.nutritionInfoId.toString();
        ocrData.nutrSourceCd = "W";
        ocrData.nutrStatusCd = "A";
        ocrData.componentID = result.pnmsResult.componentIds != null && result.pnmsResult.componentIds.length > 0 ? result.pnmsResult.componentIds[0].toString() : null;
        ocrData.compDescription = null;
      }
      else {
        ocrData.nutrInfoID = null;
        ocrData.nutrSourceCd = null;
        ocrData.nutrStatusCd = null;
        ocrData.componentID = null;
        ocrData.compDescription = null;
      }
    }
    const nutrientsList: Array<EismnNutrientValueData> = [];
    const ocrNutritionFacts = this.ocrNutritionFacts;
    var servingData: any;
    if (ocrNutritionFacts != null) servingData = ocrNutritionFacts.servingData;

    if (ocrNutritionFacts != null) {
      ocrNutritionFacts.arrayoffacts.forEach(x => {
        const item: EismnNutrientValueData = new EismnNutrientValueData();
        item.gtin = ocrData.gtin;

        item.nutrientName = x.fact;

        //uom for single and dual 
        var sUoms = this.getValues(x.value);
        item.displayValue = this.getDisplayValue(x.value);
        item.displayValueDual = this.getDisplayValue(x.value2);
        console.log(sUoms, item.displayValue);
        if (sUoms != null && sUoms.length === 3) {
          item.nutrValUom = sUoms[2];
          item.nutrValue = Number(sUoms[1]);
        }
        //daul - uom and value
        var dUoms = this.getValues(x.value2);
        if (dUoms != null && dUoms.length === 3) {
          item.nutrValueDual = Number(dUoms[1]);
          item.nutrValDualUom = dUoms[2];
        }

        var sDvs = this.getValues(x.dv);
        if (sDvs != null && sDvs.length === 3) {
          item.dvp = Number(sDvs[1]);
        }

        var dDvs = this.getValues(x.dv2);
        if (dDvs != null && dDvs.length === 3) {
          item.dvpDual = Number(dDvs[1]);
        }

        item.componentId = ocrData.componentID;
        nutrientsList.push(item);
      });
    }

    if (ocrNutritionFacts != null) {
      ocrNutritionFacts.pnmsAttributes.forEach(p => {
        if (p.amountPerServing || p.dv) {
          //const uoms = this.getValues(p.amountPerServing);
          const item: EismnNutrientValueData = new EismnNutrientValueData();
          item.nutrientName = p.name;
          item.nutrValue = p.amountPerServing;
          item.displayValue = p.amountPerServing && Number(p.amountPerServing) > 0 ? p.amountPerServing.toString() : '';
          item.nutrValUom = p.amountPerServing && Number(p.amountPerServing) > 0 ? '%' : '';
          item.dvp = p.dv;
          //item.displayValueDual = p.dv && Number(p.dv) > 0 ? p.dv.toString() : '';
          nutrientsList.push(item);
        }
      });

    }
    var panelType = null;

    var panelType = null;
    if (ocrNutritionFacts != null && ocrNutritionFacts.panelSize != null && ocrNutritionFacts.panelSize > 0) {
      if (ocrNutritionFacts.panelSize == 2) {
        if (this.selectedPanelType == 'Variety Pack') {

          panelType = 'VARIETY DUAL-COLUMN';
        }
        else {
          panelType = 'DUAL-COLUMN';
        }
      }

      if (ocrNutritionFacts.panelSize == 1) {
        if (this.selectedPanelType == 'Variety Pack') {
          panelType = 'VARIETY SINGLE';
        }
        else {
          panelType = 'SINGLE';
        }
      }
    }
    const apiDataObject = {
      selectedComponentId: this.selectedComponentId,
      selectedComponentDescription: this.getDescriptionByComponentId(this.selectedComponentId),
      createdBy: this.userId,
      lastModifiedBy: this.userId,
      eisnmGtinDto: {
        gsno: 0,
        gtin: this.gtinCode.toString(),
        nutrInfoID: ocrData.nutrInfoID,
        nutrSourceCd: ocrData.nutrSourceCd,
        nutrStatusCd: ocrData.nutrStatusCd,
        ocrStatusCd: result != null && result.pnmsResult != null ? "COMPLETED" : "DRAFT"
      },
      eisnmComponentDto: {
        gsno: 0,
        gtin: this.gtinCode.toString(),
        componentID: ocrData.componentID,
        compDescription: ocrData.compDescription == null ? "" : ocrData.compDescription,
        servingSizeVal: servingData.servingSizeVal,
        servingSizeUom: servingData.servingSizeUom,
        householdServingSize: servingData.householdServingSize,
        householdServingUom: servingData.householdServingUom,
        visualServingUom: servingData.visualServingUom,
        servingPerPackage: servingData.servingPerPackage,
        panelType: panelType,
        itemHeader: this.ocrNutritionFacts != null ? this.ocrNutritionFacts.factHeaders == null ? "" : (this.ocrNutritionFacts.factHeaders.length > 0 ? this.ocrNutritionFacts.factHeaders[0] : "") : '',
        dualItemHeader: this.ocrNutritionFacts != null ? this.ocrNutritionFacts.factHeaders == null ? "" : (this.ocrNutritionFacts.factHeaders.length > 1 ? this.ocrNutritionFacts.factHeaders[1] : "") : ''
      },
      "eismnAllergenDto": {
        gsno: 0,
        gtin: this.gtinCode.toString(),
        componentID: ocrData.componentID,
        allergen: this.ocrNutritionFacts != null ? this.ocrNutritionFacts.allergyInformation : ''
      },
      "eismnIngredientsDto": {
        gtin: this.gtinCode.toString(),
        componentID: ocrData.componentID,
        ingredients: this.ocrNutritionFacts != null ? this.ocrNutritionFacts.ingredients : ''
      },
      "eismnNutrientValueDto": nutrientsList,
      "eismnOcrImagesDto": null,
      "eisnmNutrientDto": null
    }

    return apiDataObject;
  }

  saveDraftOcrNutritionFacts(pnmsResult: any) {

    const apiDataObject = this.getOcrSaveApiObject(pnmsResult);


    var status = this.checkComponentSelection();

    if (!status) {

      if (apiDataObject == null) {
        return;
      }

      this.modalData.header = "Save image data";

      this.restApiService.saveDraftOcrData(apiDataObject).subscribe({
        complete: () => {
          //this.hasNutritionFactChanges = false;
          this.draftSaved = true;
          this.backupNutritionFact();
          if (this.isCompareFlow) {
            this.isCompareFlow = false;
            this.navigateToCompareItems();
            return;
          }
          this.modalData.isSuccess = true;
          this.modalData.message = "Image data saved successfully"
          this.modalComponent.show();

          //remove the item from local cache
          // LocalStorage.removeItem(this.gtinKey);

        },
        error: () => {
          this.modalData.isSuccess = false;
          this.modalData.message = "Some error occured while saving data"
          this.modalComponent.show();
        }
      });

      var imagesToSave = this.capturedImages.filter(i => !i.isImageSaved && i.isProcessed);

      if (imagesToSave.length > 0) {

        var blobApiData = {
          gtin: '' + this.gtinCode.toString() + '', images: imagesToSave.map(i => this.getBlobObject(i)), lastModifiedBy: this.userId
        };

        this.restApiService.saveImageToBlob(blobApiData).subscribe({
          complete: () => {
            imagesToSave.forEach(i => {
              this.capturedImages.filter(ci => {
                if (i.fileName == ci.fileName) ci.isImageSaved = true;
              });
            });
          }
        });
      }
    }

    this.restApiService
      .postAuditNotes(new AuditNotes('This Gtin was set as Draft', this.gtinCode.toString()), this.userId)
      .subscribe(
        (data: any) => {
        },
        (error: any) => {
        }
      );
  }

  onImageCaptured(capturedImage: CapturedImage) {

    this.capturedImages.push(capturedImage);
    this.onThumbnailSelected(capturedImage);
  }

  onThumbnailSelected(captureImage: CapturedImage) {
    // console.log(name);
    this.selectedThumbnail = captureImage;
  }

  getValues(value: string) {
    var items = value != null && value.trim() !== "" ? value.match(/([\d\.]+)(.*)/) : "";
    return items;
  }

  removeUom(text: string) {
    uoms.forEach(u => {
      text = text.replace(u, "");
    });

    return text;
  }

  replaceLessThan(text: string) {
    return text.replace("<", "less than ");
  }

  getDisplayValue(text: string) {
    if (text != null && text.trim() != "") {
      text = this.removeUom(text);
      text = this.replaceLessThan(text);
      return text;
    }
    return "";
  }

  closeSaveDraftModal() {
    this.modalComponent.hide();
  }

  onNutritionFactChanged() {
    //console.log("nutrition fact changed called");
    this.hasNutritionFactChanges = true;
  }

  onCompareItemUnconditionally(gtinCode: number) {
    this.gtinCode = gtinCode;
    this.navigateToCompareItems();
  }

  onCompareItems() {
    console.log("Comparing items");
    this.isCompareFlow = true;
    this.restApiService.checkGtinExists(this.gtinCode.toString()).subscribe(
      {
        next: (data) => {
          if (!this.checkNutritionFactHasChanges() && data) {
            this.navigateToCompareItems();
          }
          else {
            this.saveDraftOcrNutritionFacts(null);
          }
        },
        error: (e) => {

        }
      });
  }


  navigateToCompareItems() {
    const url = `compareItems?gtin=${this.gtinCode}`
    this.router.navigateByUrl(url);
  }

  private getGTINDetails(gtinCode: number) {
    this.restApiService.getGtinBasicInformtaion(gtinCode).subscribe((data: {}) => {
      var responseData = JSON.stringify(data);

      if (!responseData || responseData == JSON.stringify('No Results Found')) {
        this.disableCamera = true;

        this.scanBarcodeChanged.emit({ code: 0, isSuccess: false, errorMessage: 'No Results Found', showAddPnsButton: false });
      }
      else {
        const ocrDataResult = { data: JSON.parse(responseData) };
        if (ocrDataResult.data.length > 0) {


          var servingOcrData = this.ocrDataResult?.data.filter(x => {
            return x.componentID == this.selectedComponentId;
          });

          if (servingOcrData != null && servingOcrData.length > 0) {
            this.servingData.householdServingSize = Number(servingOcrData[0].householdServingSize);
            this.servingData.householdServingUom = servingOcrData[0].householdServingUom;
            this.servingData.servingPerPackage = servingOcrData[0].servingPerPackage;
            this.servingData.servingSizeUom = servingOcrData[0].servingSizeUom;
            this.servingData.servingSizeVal = Number(servingOcrData[0].servingSizeVal);
            this.servingData.visualServingUom = servingOcrData[0].visualServingUom;
          }
        }
        else {
          this.servingData.householdServingSize = 0;
          this.servingData.householdServingUom = '';
          this.servingData.servingPerPackage = '';
          this.servingData.servingSizeUom = '';
          this.servingData.servingSizeVal = 0;
          this.servingData.visualServingUom = '';

        }
        if (this.ocrNutritionFacts != null) this.ocrNutritionFacts.servingData = this.servingData;
        // console.log("ocrData", ocrDataResult);
        this.scanBarcodeChanged.emit({ code: gtinCode, isSuccess: true, errorMessage: '', ocrDataResult, showAddPnsButton: false });
      }
    });
  }

  private backupNutritionFact() {
    this.nutritionFactText = JSON.stringify(this.ocrNutritionFacts);
  }

  private checkNutritionFactHasChanges() {
    return this.nutritionFactText != JSON.stringify(this.ocrNutritionFacts);
  }
  addToPnms() {
    this.isLoadingNutritionFacts = true;
    let apiObject = this.getPnmsDataObject();
    this.restApiService.savePnmsData(apiObject).subscribe(result => {
      this.saveOrUpdateOcrData(result);
      this.isLoadingNutritionFacts = false;
    });
    this.isLoadingNutritionFacts = false;
  }



  private saveOrUpdateOcrData(result: any) {
    this.saveDraftOcrNutritionFacts(result);
  }

  private getBlobObject(capturedImage: CapturedImage) {
    return ({ imageData: GetBinaryDataFromCapturedImage(capturedImage.data), fileName: capturedImage.fileName, imageType: 'png' });
  }

  private getPnmsDataObject(): PNMSData {

    let nutrientValues: Array<nutrientValue> = [];
    if (this.ocrNutritionFacts != null) {
      this.ocrNutritionFacts.arrayoffacts.forEach(a => {
        let nutrValue: any = {};
        nutrValue.nutrientName = a.fact;
        if (a.dv) nutrValue.dvp = Number(a.dv.replace('%', ''));
        if (a.dv2) nutrValue.dvpDual = Number(a.dv2.replace('%', ''));
        if (a.value) {
          var valuesOne = this.getValues(a.value);
          if (valuesOne != null && valuesOne.length == 3) {
            nutrValue.nutrValue = valuesOne[1];
            nutrValue.displayValue = valuesOne[1];
          }
        }
        if (a.value2) {
          var values = this.getValues(a.value2);
          if (values != null && values.length == 3) {
            nutrValue.nutrValueDual = Number(values[1]);
            nutrValue.displayValueDual = values[1];
          }
        }
        nutrientValues.push(nutrValue);
      });

      if (this.ocrNutritionFacts.pnmsAttributes != null) {
        this.ocrNutritionFacts.pnmsAttributes.forEach(p => {
          if (p.amountPerServing || p.dv) {
            //const uoms = this.getValues(p.amountPerServing);
            let item: any = {};
            item.nutrientName = p.name;
            item.nutrValue = p.amountPerServing;
            item.displayValue = p.amountPerServing && Number(p.amountPerServing) > 0 ? p.amountPerServing.toString() : '';
            item.nutrValUom = p.amountPerServing && Number(p.amountPerServing) > 0 ? '%' : '';
            item.dvp = p.dv;
            //item.displayValueDual = p.dv && Number(p.dv) > 0 ? p.dv.toString() : '';
            nutrientValues.push(item);
          }
        });
      }


    }
    var itemheader: any;
    if (this.ocrNutritionFacts != null && this.ocrNutritionFacts.factHeaders &&
      this.ocrNutritionFacts.factHeaders != null && this.ocrNutritionFacts.factHeaders.length > 0
      && this.ocrNutritionFacts.factHeaders[0] != null) {
      itemheader = this.ocrNutritionFacts.factHeaders[0];
    }
    else {
      itemheader = '';
    }

    return {
      nutritionalInfo: {
        "nutrInfoId": 0,
        description: this.itemDescription,
        "samllestUnits": 0,
        "allergenStatement": "",
        "providerId": 0,
        gtin: this.gtinCode,
        "recipeId": 0,
        "nutrSourceCd": "L",
        "createdBy": this.userId,
        "lastModifiedBy": this.userId,
        "nutrStatusCd": "A",
        "additive": "N",
        "isfdA2018": "N",
        segment: "FOOD"
      },
      nutritionalFacts: this.ocrNutritionFacts != null ? this.ocrNutritionFacts.arrayoffacts.map(i => ({
        priority: 1,
        nutrientName: i.fact
      })) : [],
      nutritionalComponents: [
        {
          description: "",
          "createD_BY": this.userId,
          "lasT_MODIFIED_BY": this.userId,
          "servinG_SIZE_UOM": this.ocrNutritionFacts != null && this.ocrNutritionFacts.servingData ? this.ocrNutritionFacts.servingData.servingSizeUom : '',
          "servinG_SIZE_VAL": this.ocrNutritionFacts != null && this.ocrNutritionFacts.servingData ? this.ocrNutritionFacts.servingData.servingSizeVal : 0,
          "dlY_VAL_INTK_REF": "test",
          "hshlD_SERVING_UOM": this.ocrNutritionFacts != null && this.ocrNutritionFacts.servingData ? this.ocrNutritionFacts.servingData.householdServingUom : '',
          "hshlD_SERVING_SIZE": this.ocrNutritionFacts != null && this.ocrNutritionFacts.servingData ? this.ocrNutritionFacts.servingData.householdServingSize : 0,
          "visuaL_SERVING_UOM": this.ocrNutritionFacts != null && this.ocrNutritionFacts.servingData ? this.ocrNutritionFacts.servingData.visualServingUom : '',
          "nutR_STATUS": "A",
          "nutR_INFO_ID": 0,
          "servingS_PER_PKG": this.ocrNutritionFacts != null && this.ocrNutritionFacts.servingData ? this.ocrNutritionFacts.servingData.servingPerPackage : '',
          "iS_ADMINOVERRIDEN": "N",
          "admiN_COMMENTS": "test",
          "comments": "test",
          "caloriE_VALUE": 1,
          "caloriE_UOM": "g",
          "caloriE_MIN": 1,
          "caloriE_MAX": 2,
          "packagE_SIZE_VAL": 2,
          "packagE_SIZE_UOM": "g",
          "caloriE_DESC_FORMAT_ID": 0,
          "nutritionavailable": "Y",
          "duaL_PANEL": "",
          "paneltype": this.getPanelType(this.ocrDataResult),
          "itemheader": itemheader
        }
      ],
      "nutrientValues": nutrientValues,
      "allergens": this.ocrNutritionFacts != null ? this.ocrNutritionFacts.allergyInformation : '',
      "ingridents": this.ocrNutritionFacts != null ? this.ocrNutritionFacts.ingredients : '',
      "createdBy": this.userId,
    }
  }

  private getPanelType(data: any): string {
    var paramPanelType = 'SINGLE';
    if (data && data.length > 1) paramPanelType: 'VARIETY';
    else if (this.ocrNutritionFacts != null && this.ocrNutritionFacts.panelSize == 2) paramPanelType = "DUAL-COLUMN";
    return paramPanelType;
  }

}
