<div style="padding-top: 200px; display: flex; justify-content: center; flex-direction: column;padding-left:25%;padding-right:25% ">


  <div style="padding-top: 20px;width:100%">
    <table border="1" style="padding-top: 20px; margin: 0 auto;width:500px">
      <tr>
        <td style="height: 10px; background-color: green; text-align: center;">
          <span style="color:white"><b>Update Panel</b></span>
        </td>
      </tr>
      <tr>
        <td style="height: 30px;"></td>
      </tr>
      <tr>
        <td style="padding-left:10%">


          <table border="1" style="width: 90%;">
            <tr style="border:inherit">
              <td style="height: 10px; background-color: green; text-align: center;">
                <span style="color:white"><b>Current Panel</b></span>
              </td>
              <td style="height: 10px; background-color: green; text-align: center;">
              <td style="height: 10px; background-color: green; text-align: center;"> <span style="color:white"><b>New Panel</b></span></td>
            </tr>
            <tr>
              <td style="padding-left:5%"><b>{{ panelType }}</b></td>
              <td style="text-align: right; font-size: 48px;"><i class="bi bi-arrow-right-circle-fill green-icon"></i></td>
              <td>
                <table style="margin: 0 auto;">
                  <tr>
                    <td style="padding-top: 10px; padding-left: 15px">
                      <label>
                        <input type="radio" [(ngModel)]="selectedPanelType" value="Dual Column">&nbsp;
                        <b>Dual Column</b>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-top: 10px;padding-left:15px">
                      <label>
                        <input type="radio" [(ngModel)]="selectedPanelType" value="Single Panel">&nbsp;
                        <b>Single Panel</b>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-top: 10px;padding-left:15px">
                      <label>
                        <input type="radio" [(ngModel)]="selectedPanelType" value="Variety Pack">&nbsp;
                        <b>Variety Pack</b>
                        <div *ngIf="selectedPanelType=='Variety Pack'" style="padding-left:25px">
                          <input type="checkbox" (change)="handleChange($event)" style="padding-left:5px" [(ngModel)]="isMultiPanel" />
                          <b style="padding-left:7px">Is Multi Column</b>

                        </div>
                      </label>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

          </table>
        </td>
        </tr>
      <tr>
        <td style="height:30px"></td>
      </tr>
      <tr>
        <td style="padding-left:70%">
          <button (click)="close()" style="width:50px" class="btn btn-secondary buttonsuccess">OK</button>
        </td>
      </tr>
      </table>
       

        <!-- Separate container for the button -->
        <!--<div style="text-align: right; padding-top: 30px;">
          <button (click)="close()" style="width:50px" class="btn btn-secondary buttonsuccess">OK</button>
        </div>-->
  </div>
  </div>
