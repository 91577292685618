import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerItem, DrawerSelectEvent } from "@progress/kendo-angular-layout";

interface Item {
  text: string;
  icon: string;
  path?: string;
  selected?: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private router: Router) { }

  public selected = "Search";

  public items: Array<DrawerItem> = [
    { text: "Search", icon: "k-i-search", id: "search", selected: true },
    //  { separator: true },
    { text: "Scan Barcode", icon: "k-i-barcode", id: "readText" },
    { text: "Capture Image", icon: "k-i-image", id: "captureImgae" },
    { text: "Compare", icon: "k-i-eye", id: "compareItems" },
    { text: "Draft List", icon: "k-i-track-changes-enable", id: "draftList" },
    { text: "Completed List", icon: "k-i-success", id: "compltedList" },
    // { separator: true },
    { text: "Other", icon: "k-i-star-outline" },
  ];

  public onSelect(ev: DrawerSelectEvent): void {
    console.log(ev);
    // this.selected = ev.item.id;
    this.router.navigate([ev.item.id]);
    // console.log(ev.item.id);
  }

  ngOnInit(): void {
  }

}
