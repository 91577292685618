import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AllergenIngredinatsUpdatedData, Gtin, Component as GtinComponent, RecordsToUpdateDValue, UpdatedRecordCollection } from '../Models/Gtin';
import { RestApiService } from '../services/RestApiService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { isBoolean, trim } from 'lodash';
import { bookIcon } from '@progress/kendo-svg-icons';
import { finalize } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ConfirmationDialogComponent } from '../shared/confirmation/confirmation.dialog.component';
import { DataService } from '../services/DataService';

@Component({
  selector: 'app-compare-item',
  templateUrl: './compare-item.component.html',
  styleUrls: ['./compare-item.component.css']
})
export class CompareItemComponent implements OnInit, AfterViewInit {
  shwoImage: boolean = false;
  ocrGtinResults!: Gtin;
  ocrGtinResultsFromService!: Gtin;
  pnmsGtinResults!: Gtin;
  showChild: boolean = true;
  id = 1;
  gtin!: string;
  flashMessage = '';
  pnmsserviceCallResults: string = '';
  ocrserviceCallResults: string = '';
  pnmsSource: string = "PNMS";
  ocrSource: string = "OCR";
  activeLinkClass = 'activeLink';
  inactiveLinkClass = 'inactiveLink';
  recordsToUpdateDisplayValue: RecordsToUpdateDValue[] = [];
  recordsToUpdateDvp: RecordsToUpdateDValue[] = [];
  recordsToUpdateDualDisplayValue: RecordsToUpdateDValue[] = [];
  recordsToUpdateDualDvp: RecordsToUpdateDValue[] = [];
  ingredinatsUpdatedData: AllergenIngredinatsUpdatedData[] = [];
  allergenUpdatedData: AllergenIngredinatsUpdatedData[] = [];
  private routeSub!: Subscription;
  isLoadingCompareItems: boolean = false;
  showFlashMessageOnPNMSUpdate: boolean = false;
  showFlashMessageOnOCRUpdate: boolean = false;
  isPNMSupdate: boolean = false;
  userId: any;
  ingredinatsUpdatedDataSuccess: boolean = true;
  allergenUpdatedDataSuccess: boolean = true;
  recordsToUpdateDisplayValueSuccess: boolean = true;
  recordsToUpdateDvpSuccess: boolean = true;
  recordsToUpdateDualDisplayValueSuccess: boolean = true;
  recordsToUpdateDualDvpSuccess: boolean = true;
  @ViewChild('modalComponent') modalComponent: any;

  modalData = {
    isSuccess: false,
    message: '',
    header: ''
  }
  constructor(private authService: MsalService, private restApiService: RestApiService, @Inject(DOCUMENT) private document: Document, private route: ActivatedRoute, private router: Router, private dataService: DataService) {
    this.restApiService.quaggaStop();
    var accountInfo = this.authService.instance.getAllAccounts();
    if (accountInfo != null && accountInfo.length > 0 && accountInfo[0].idTokenClaims) {
      this.userId = accountInfo[0].idTokenClaims['sAMAccountName'];
    }
  }

  onExpand() {
    this.shwoImage = !this.shwoImage
  }

  onCollapse() {
    this.shwoImage = false;

  }
  @ViewChild('confirmationDialog', { static: false }) confirmationDialog!: ConfirmationDialogComponent;

  getocrDescription(component: GtinComponent) {
    const comp = this.ocrGtinResults.components.filter(x => x.componentID == component.componentID);
    if (comp !== null && comp !== undefined && comp.length > 0) {
      return comp[0].description;
    }
    return component.description;
  }





  gotosearchpage() {
    this.dataService.changeSource("compareItems?gtin=" + this.currentgtin);

    this.dataService.changeMessage(+this.currentgtin);
    this.router.navigateByUrl('search');
  }

  openConfirmationDialog(): void {
    this.confirmationDialog.open();
  }

  onConfirm(): void {
    // User clicked "OK" in the dialog
    // Perform your action here
    this.updatePNMSDBwithUpdatedRecotds();
  }

  onCancel(): void {
    // User clicked "Cancel" in the dialog
    // Handle the cancellation or alternative action
    console.log("User has cancelled Update PNMS")
  }



  updateOCRingrediants(event: string) {
    this.ocrGtinResults.components.forEach(element => {
      if (element.componentID === event) {
        element.ingredientList = this.pnmsGtinResults.components.find(x => x.componentID == event)?.ingredientList ?? '';
      }
    });

    var record = this.ingredinatsUpdatedData.find(x => x.componentID == event);
    if (record != undefined) {
      record.updateData = this.pnmsGtinResults.components.find(x => x.componentID == event)?.ingredientList ?? '';
    }
    else {
      this.ingredinatsUpdatedData.push(new AllergenIngredinatsUpdatedData("INGREDIENTS", event, this.pnmsGtinResults.components.find(x => x.componentID == event)?.ingredientList ?? ''));
    }
  }
  updateOCRAllergen(event: string) {
    this.ocrGtinResults.components.forEach(element => {
      if (element.componentID === event) {
        element.allergenList = this.pnmsGtinResults.components.find(x => x.componentID == event)?.allergenList ?? '';
      }
    });

    var record = this.allergenUpdatedData.find(x => x.componentID == event);
    if (record != undefined) {
      record.updateData = this.pnmsGtinResults.components.find(x => x.componentID == event)?.allergenList ?? '';
    }
    else {
      this.allergenUpdatedData.push(new AllergenIngredinatsUpdatedData("ALLERGEN", event, this.pnmsGtinResults.components.find(x => x.componentID == event)?.allergenList ?? ''));
    }
  }
  resetIngredintns(event: string) {
    this.ocrGtinResults.components.forEach(element => {
      if (element.componentID === event) {
        element.ingredientList = this.ocrGtinResultsFromService.components.find(x => x.componentID == event)?.ingredientList ?? '';
      }
    });

    this.ingredinatsUpdatedData = [];
  }
  resetAllergen(event: string) {
    this.ocrGtinResults.components.forEach(element => {
      if (element.componentID === event) {
        element.allergenList = this.ocrGtinResultsFromService.components.find(x => x.componentID == event)?.allergenList ?? '';
      }
    });

    this.allergenUpdatedData = [];
  }
  //showFlashMessageOnPNMSDBUpdate() {
  //  this.showFlashMessageOnPNMSUpdate = true;
  //  setTimeout(() => {
  //    this.showFlashMessageOnPNMSUpdate = false;
  //    this.document.location.reload();
  //  }, 5000);
  //}
  //showFlashMessageOnOCRDBUpdate() {
  //  this.showFlashMessageOnOCRUpdate = true;
  //  setTimeout(() => {
  //    this.showFlashMessageOnOCRUpdate = false;
  //    // this.document.location.reload();
  //  }, 5000);
  //}

  isLandscape = window.innerWidth > window.innerHeight;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }

  ngAfterViewInit(): void {
  }
  ShowAndHide(id: number) {
    this.id = id;
  }
  getUpdatedRecordDispalyValue(event: RecordsToUpdateDValue) {
    var record = this.recordsToUpdateDisplayValue.find(x => x.gtin == event.gtin && x.componentID == event.componentID && x.nutrientID == event.nutrientID);
    if (record != undefined) {
      record.value = event.value;
    }
    else {
      this.recordsToUpdateDisplayValue.push(event);
    }
  }
  getUpdatedRecordDvp(event: RecordsToUpdateDValue) {
    var record = this.recordsToUpdateDvp.find(x => x.gtin == event.gtin && x.componentID == event.componentID && x.nutrientID == event.nutrientID);
    if (record != undefined) {
      record.value = event.value;
    }
    else {
      this.recordsToUpdateDvp.push(event);
    }
  }
  getUpdatedRecordDualDispalyValue(event: RecordsToUpdateDValue) {
    var record = this.recordsToUpdateDualDisplayValue.find(x => x.gtin == event.gtin && x.componentID == event.componentID && x.nutrientID == event.nutrientID);
    if (record != undefined) {
      var x = this.ocrGtinResults.components.find(k => k.componentID == event.componentID)?.nutrientValuesDetails.find(p => p.nutrientID == event.nutrientID);
      if (x != undefined) {
        if (x.nutrValue == event.value) {
          this.recordsToUpdateDualDisplayValue = this.recordsToUpdateDualDisplayValue.filter(item => item.nutrientID !== event.nutrientID);
          return;
        }
      }
      record.value = event.value;
    }
    else {
      this.recordsToUpdateDualDisplayValue.push(event);
    }
  }
  getUpdatedRecordDualDvp(event: RecordsToUpdateDValue) {

    var record = this.recordsToUpdateDualDvp.find(x => x.gtin == event.gtin && x.componentID == event.componentID && x.nutrientID == event.nutrientID);
    if (record != undefined) {
      var x = this.ocrGtinResults.components.find(k => k.componentID == event.componentID)?.nutrientValuesDetails.find(p => p.nutrientID == event.nutrientID);
      if (x != undefined) {
        if (x.nutrValue == event.value) {
          this.recordsToUpdateDualDvp = this.recordsToUpdateDualDvp.filter(item => item.nutrientID !== event.nutrientID);
          return;
        }
      }
      record.value = event.value;
    }
    else {
      this.recordsToUpdateDualDvp.push(event);
    }
  }
  onUpdateallergenIngredinatsUpdatedData(event: AllergenIngredinatsUpdatedData) {
    if (event.type == "ALLERGEN") {
      var record = this.allergenUpdatedData.find(x => x.componentID == event.componentID && x.type == event.type);
      if (record != undefined) {
        record.updateData = event.updateData;
      }
      else {
        this.allergenUpdatedData.push(event);
      }
    }
    if (event.type == "INGREDIENTS") {
      var record = this.ingredinatsUpdatedData.find(x => x.componentID == event.componentID && x.type == event.type);
      if (record != undefined) {
        record.updateData = event.updateData;
      }
      else {
        this.ingredinatsUpdatedData.push(event);
      }
    }
  }
  isPNMSDBUpdate = false;
  async updatePNMSDBwithUpdatedRecotds() {
    try {
      this.isLoadingCompareItems = true;
      this.isPNMSDBUpdate = true;
      await this.updateOCRDBwithUpdatedRecotds();
      this.isLoadingCompareItems = true;
      setTimeout(() => {
        this.getGtinResultsforbothOCRandPNMS(this.pnmsGtinResults.components[0].gtin);
        this.isLoadingCompareItems = false;
        this.id = 1;
        this.isPNMSDBUpdate = false;

        setTimeout(() => {
          this.gotosearchpage();
        }, 2000)


      }, 15000);



      this.restApiService.updateGtinAuditStatus(this.pnmsGtinResults.components[0].gtin, this.userId).subscribe(
        (data: any) => {
        },
        (error: any) => {
        }
      );



      //await this.restApiService.postupdatedNutrientsDisplayValueToPNMS(this.pnmsGtinResults.components[0].gtin, this.userId)
      //  .pipe(finalize(() => {
      //    this.isLoadingCompareItems = false;
      //    this.modalData.header = 'Update PNMS';
      //    this.modalData.message = 'PNMS DB has been updated.';
      //    this.modalData.isSuccess = true;
      //    this.modalComponent.show();
      //    this.ocrGtinResults.status = "complete";
      //    this.isPNMSDBUpdate = false;
      //  }))
      //  .toPromise();

      //this.isPNMSupdate = true;
    } catch (error) {
      // Handle any errors that occurred during the update process
      console.error('Error occurred during PNMS DB update:', error);
      // You can choose to throw the error or handle it as per your requirement
    }
  }

  updateOCRDBwithUpdatedRecotds() {
    return new Promise<void>((resolve, reject) => {
      try {
        const promises = [];

        if (this.recordsToUpdateDualDvp.length > 0) {
          const updatedRecordCollectiondualdvp = new UpdatedRecordCollection('dualdvp', this.userId, this.recordsToUpdateDualDvp);
          promises.push(this.restApiService.postupdatedNutrientsDisplayValueToOCRDB(updatedRecordCollectiondualdvp).toPromise());
        }

        if (this.recordsToUpdateDualDisplayValue.length > 0) {
          const updatedRecordCollectionDualDisplayvalue = new UpdatedRecordCollection('dualDisplayvalue', this.userId, this.recordsToUpdateDualDisplayValue);
          promises.push(this.restApiService.postupdatedNutrientsDisplayValueToOCRDB(updatedRecordCollectionDualDisplayvalue).toPromise());
        }

        if (this.recordsToUpdateDvp.length > 0) {
          const updatedRecordCollectiondvp = new UpdatedRecordCollection('dvp', this.userId, this.recordsToUpdateDvp);
          promises.push(this.restApiService.postupdatedNutrientsDisplayValueToOCRDB(updatedRecordCollectiondvp).toPromise());
        }

        if (this.recordsToUpdateDisplayValue.length > 0) {
          const updatedRecordCollectiondisplayvalue = new UpdatedRecordCollection('displayvalue', this.userId, this.recordsToUpdateDisplayValue);
          promises.push(this.restApiService.postupdatedNutrientsDisplayValueToOCRDB(updatedRecordCollectiondisplayvalue).toPromise());
        }

        if (this.allergenUpdatedData.length > 0) {
          const recordsToUpdate = this.allergenUpdatedData.map(data => {
            return new RecordsToUpdateDValue(
              this.ocrGtinResults.components[0].gtin,
              data.componentID,
              0,
              data.updateData
            );
          });

          const UpdatedallergenIngredinatsUpdatedData = new UpdatedRecordCollection('allergen', this.userId, recordsToUpdate);
          promises.push(this.restApiService.postupdatedNutrientsDisplayValueToOCRDB(UpdatedallergenIngredinatsUpdatedData).toPromise());
        }

        if (this.ingredinatsUpdatedData.length > 0) {
          const recordsToUpdate = this.ingredinatsUpdatedData.map(data => {
            return new RecordsToUpdateDValue(
              this.ocrGtinResults.components[0].gtin,
              data.componentID,
              0,
              data.updateData
            );
          });

          const UpdatedallergenIngredinatsUpdatedData = new UpdatedRecordCollection('ingrediants', this.userId, recordsToUpdate);
          promises.push(this.restApiService.postupdatedNutrientsDisplayValueToOCRDB(UpdatedallergenIngredinatsUpdatedData).toPromise());
        }

        Promise.all(promises)
          .then(() => {
            this.recordsToUpdateDualDvp = [];
            this.recordsToUpdateDualDisplayValue = [];
            this.recordsToUpdateDvp = [];
            this.recordsToUpdateDisplayValue = [];
            this.allergenUpdatedData = [];
            this.ingredinatsUpdatedData = [];

            resolve();

            if (!this.isPNMSDBUpdate) {
              this.isLoadingCompareItems = false;
              this.modalData.header = 'Update OCR';
              this.modalData.message = 'OCR DB has been updated.';
              this.modalData.isSuccess = true;
              this.modalComponent.show();
              this.isPNMSDBUpdate = false;
            }
            else {
              this.restApiService.postupdatedNutrientsDisplayValueToPNMS(this.pnmsGtinResults.components[0].gtin, this.userId)
                .pipe(finalize(() => {
                  //this.isLoadingCompareItems = false;
                  this.modalData.header = 'Update PNMS';
                  this.modalData.message = 'PNMS DB has been updated.';
                  this.modalData.isSuccess = true;
                  this.modalComponent.show();
                  this.ocrGtinResults.status = "complete";
                  this.isPNMSDBUpdate = true;
                  // this.getGtinResultsforbothOCRandPNMS(this.pnmsGtinResults.components[0].gtin);
                }))
                .toPromise();
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
            reject(error);
          });

      } catch (error) {
        console.error('An error occurred:', error);
        reject(error);
      }
    });
  }

  getStyle() {
    if (this.isLandscape) {
      return {
        'overflow-y': 'scroll',
        'max- height': '682px'
      };
    }
    else {
      return {
        'overflow-y': 'scroll',
        'max- height': '1000px'
      };
    }
  }

  currentgtin = ''
  ngOnInit(): void {
    this.routeSub = this.route.queryParams.subscribe(params => {
      const gtin = params['gtin'];
      this.currentgtin = gtin;
      this.getGtinResultsforbothOCRandPNMS(gtin);
      this.gtin = gtin;
    });

    setInterval(() => {
      this.isLandscape = window.innerWidth > window.innerHeight;
    }, 1000);

  }

  getGtinNutritionCount() {
    return this.pnmsGtinResults == undefined ? 0 : this.pnmsGtinResults.components.length;
  }
  //getGtinResultsforbothOCRandPNMS(gtin: string) {
  //  this.isLoadingCompareItems = true;
  //  this.restApiService.getOCRDetailsByGtin(gtin).subscribe((data: {}) => {
  //    this.ocrserviceCallResults = JSON.stringify(data);
  //    this.ocrGtinResults = JSON.parse(this.ocrserviceCallResults);
  //    this.ocrGtinResultsFromService = JSON.parse(this.ocrserviceCallResults);
  //    if (this.ocrGtinResults) {
  //      this.restApiService.getItembyGtin(gtin).subscribe((data: {}) => {
  //        this.pnmsserviceCallResults = JSON.stringify(data);
  //        this.pnmsGtinResults = JSON.parse(this.pnmsserviceCallResults);
  //        this.pnmsGtinResults.status = this.ocrGtinResults.status;
  //        this.isLoadingCompareItems = false;
  //      });
  //    }
  //    else {
  //      this.isLoadingCompareItems = false;
  //    }
  //  });
  //}

  loadingError = false;
  getGtinResultsforbothOCRandPNMS(gtin: string) {
    this.isLoadingCompareItems = true;

    // Make the first API call to get OCR details by GTIN
    this.restApiService.getOCRDetailsByGtin(gtin).pipe(
      catchError(error => {
        // Handle error for the first API call
        this.loadingError = true;
        console.error('Error in getOCRDetailsByGtin:', error);
        this.isLoadingCompareItems = false;
        return throwError(error); // Rethrow the error to propagate it to the subscriber
      })
    ).subscribe((data: {}) => {
      this.ocrserviceCallResults = JSON.stringify(data);
      this.ocrGtinResults = JSON.parse(this.ocrserviceCallResults);
      this.ocrGtinResultsFromService = JSON.parse(this.ocrserviceCallResults);

      if (this.ocrGtinResults) {
        // Make the second API call to get item details by GTIN
        this.restApiService.getItembyGtin(gtin).pipe(
          catchError(error => {
            // Handle error for the second API call
            this.loadingError = true;
            console.error('Error in getItembyGtin:', error);
            this.isLoadingCompareItems = false;
            return throwError(error); // Rethrow the error to propagate it to the subscriber
          })
        ).subscribe((data: {}) => {
          this.pnmsserviceCallResults = JSON.stringify(data);
          this.pnmsGtinResults = JSON.parse(this.pnmsserviceCallResults);

          this.ocrGtinResults.components.forEach(ocrComp => ocrComp.isComponentDataExist = true)
          this.pnmsGtinResults.components.forEach(pnmsComp => pnmsComp.isComponentDataExist = true)

          const PNMScomponentIDs: string[] = this.pnmsGtinResults.components.map((component) => component.componentID);
          const OCRcomponentIDs: string[] = this.ocrGtinResults.components.map((component) => component.componentID);

          const combinedComponentIDs: string[] = [...PNMScomponentIDs, ...OCRcomponentIDs];
          const uniqueComponentIDsSet = new Set<string>();
          combinedComponentIDs.forEach((componentID) => {
            uniqueComponentIDsSet.add(componentID);
          });



          uniqueComponentIDsSet.forEach(x => {
            if (PNMScomponentIDs.indexOf(x) > -1 && (!(OCRcomponentIDs.indexOf(x) > -1))) {
              this.ocrGtinResults.components.push({
                isComponentDataExist: false,
                gtin: gtin,
                nutrInfoID: '',
                nutrSourceCd: '',
                segment: '',
                componentID: x.toString(),
                description: '',
                servingSizeVal: '',
                servingSizeUom: '',
                householdServingSize: '',
                householdServingUom: '',
                visualServingUom: '',
                servingPerPackage: '',
                panelType: '',
                itemHeader: '',
                allergenList: '',
                ingredientList: '',
                nutrientValuesDetails: [],
                unwantedIngridients: '',
              });
            }
          });

          uniqueComponentIDsSet.forEach(x => {
            if (OCRcomponentIDs.indexOf(x) > -1 && (!(PNMScomponentIDs.indexOf(x) > -1))) {
              this.pnmsGtinResults.components.push({
                isComponentDataExist: false,
                gtin: gtin,
                nutrInfoID: '',
                nutrSourceCd: '',
                segment: '',
                componentID: x.toString(),
                description: this.ocrGtinResults.components.find(a => a.componentID == x)?.description,
                servingSizeVal: '',
                servingSizeUom: '',
                householdServingSize: '',
                householdServingUom: '',
                visualServingUom: '',
                servingPerPackage: '',
                panelType: '',
                itemHeader: '',
                allergenList: '',
                ingredientList: '',
                nutrientValuesDetails: [],
                unwantedIngridients: '',
              });
            }
          });

          this.pnmsGtinResults.components.sort((a, b) => +a.componentID - +b.componentID);
          this.ocrGtinResults.components.sort((a, b) => +a.componentID - +b.componentID);
          this.pnmsGtinResults.status = this.ocrGtinResults.status;
          this.isLoadingCompareItems = false;

        });
      } else {
        this.isLoadingCompareItems = false;
      }
    });
  }


  getGtinOCRCount(): number {
    return this.ocrGtinResults == undefined ? 0 : this.ocrGtinResults.components.length;
  }
  getGtinPNMSCount(): number {
    return this.pnmsGtinResults == undefined ? 0 : this.pnmsGtinResults.components.length;
  }
  closeModal() {
    if (this.isPNMSupdate) {
      this.getGtinResultsforbothOCRandPNMS(this.ocrGtinResults.components[0].gtin);
      this.isPNMSupdate = false;
    }
    this.modalComponent.hide();
  }
}
