<div *ngIf="displayLoadingIcon" class="spinner-border" role="status"
     style="position:absolute;top:50%;left:50%; height: 50px; width: 50px;">
  <span class="sr-only">Loading...</span>
</div>
<div *ngIf="noResultsFound" style="top: 15%;position:relative;text-align: center; font-weight:bold;">
  <span>No results found!</span>
</div>
<div *ngIf="!displayLoadingIcon && !noResultsFound" class="container-fluid" style="top:60px; position:relative;">
  <div class="row">
    <div class="col-md-12" align="center">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <kendo-grid [kendoGridBinding]="completedAndDraftGtinList" [pageSize]="pageSize"
                          [filterable]="true" [skip]="skip" [pageable]="true" (pageChange)="pageChange($event)">

                <kendo-grid-column field="gtin" title="GTIN" [headerStyle]="{ 'font-weight': 'bold' }">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter"></kendo-grid-string-filter-cell>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                    <a (click)="navigateToCompareItems(dataItem.gtin)"
                       style="text-decoration: underline;cursor: pointer;">
                      <span style="color:blue">{{dataItem.gtin}}</span>
                    </a>
                  </ng-template>
                </kendo-grid-column>


                <kendo-grid-column field="ocrStatusCd" title="GTIN Status" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false"
                                                   [column]="column"
                                                   [filter]="filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="lastModifiedBy" title="Last modified by" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false"
                                                   [column]="column"
                                                   [filter]="filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="lastModifiedDateTime" title="Last modified date" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false"
                                                   [column]="column"
                                                   [filter]="filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
                </kendo-grid-column>



              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
