import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RestApiService } from '../services/RestApiService.service';
import { Gtin, GTINConverter, GtinResult, GTINHelper } from '../Models/Gtin';
import { Item } from '../Models/item';
import { NgForm } from '@angular/forms';
import { ScanBarcodeResult } from '../scan-barcode/models/scanBarCodeResult';
import Quagga from '@ericblade/quagga2';
import { EventEmitterService } from '../event-emitter.service';
import { DataService } from '../services/DataService';
import { ActivatedRoute, Router } from '@angular/router';
import { bookIcon } from '@progress/kendo-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.css']
})
export class SearchItemComponent implements OnInit {

  @Input() gtinNumber: string | undefined;
  @Input() isFromAudit: boolean = false;
  gtin!: Gtin;
  auditNotes: string = '';
  type: string = 'item';
  showUpdateButton: boolean = false;
  itemgtincode!: number;
  showsearchDiv: boolean = true;
  serviceCallResults: string = '';
  serviceResultsforItem: Item[] = [];
  showResultsNotFoundMessage: boolean = false;
  myForm: any;
  disableCamera: boolean = true;
  showScanErrorMessage = false;
  selectedType: string = "item";
  hasMultipleItemResult: boolean = false;
  gtinAuditStatus!: string;
  sourcepage!: string;
  displayLoadingIcon: boolean = false;
  backButtonClicked = false;

  gtinSelected: string = '';
  itemSelected: string = 'checked';
  scanCodeSelected: string = '';
  private routeSub!: Subscription;
  constructor(private restApiService: RestApiService, private router: Router, private dataService: DataService,
    private eventEmitterService: EventEmitterService, private GtinConverter: GTINConverter, private route: ActivatedRoute) { }
  ngOnInit(): void {

    this.routeSub = this.route.queryParams.subscribe(params => {
      //this.isFromAudit = params['fromAudit'];
      this.isFromAudit = !!params['fromAudit'];
    });

    this.disableCamera = true;
    this.restApiService.quaggaStop();
    this.dataService.currentMessage.subscribe(message => {
      if (message != 0) {
        this.showsearchDiv = false;
        this.itemgtincode = message;
        this.showUpdateButton = true;
        this.type = 'gtin';
        this.searchByItemOrGtinCode();
      }
    });
    //this.dataService.currentFromAuditPage.subscribe(status => {
    //  if (status != false) {
    //    this.isFromAudit = status;
    //    //this.dataService.changeFromAuditPage(false);
    //  }
    //});

    //this.dataService.currentFromAuditPage.subscribe(status => {
    //  this.dataService.changeFromAuditPage(false);
    //});
    

    this.dataService.currentStatus.subscribe(status => {
      if (status != "") {
        this.gtinAuditStatus = status.toLowerCase();
      }
    });

    this.dataService.currentauditNotes.subscribe(status => {
      if (status != "") {
        this.auditNotes = status;
      }
    });
    
    this.dataService.currentSource.subscribe(source => {
      if (source != "") {
        this.sourcepage = source;
      }
    });
    this.dataService.changeMessage(0);
    this.dataService.changeCurrentAuditNotes("");
  }

  @ViewChild('inputitemgtincode') inputitemgtincode!: ElementRef;
  @ViewChild('myForm') form!: NgForm;

  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['gtinNumber']) {
      this.itemgtincode = changes['gtinNumber'].currentValue;
      this.disableCamera = true;
      // this.type = 'gtin';
      this.searchByItemOrGtinCode()
    }
  }
  makeshowsearchDivtrue() {

    if (this.showUpdateButton) {
      this.router.navigateByUrl(this.sourcepage);
    }

    this.showsearchDiv = true;
    this.backButtonClicked = true;
    this.showResultsNotFoundMessage = false;
    if (this.selectedType === "item") {
      //so the item results 
      this.type = "item";

      if (this.hasMultipleItemResult) {
        this.itemgtincode = this.gtin.itemCode;
        this.searchByItemOrGtinCode();
        //this.showResultsNotFoundMessage = false;       
      }
    }
    else {
      this.serviceResultsforItem = [];
      this.gtin = <Gtin>{};
      this.type = "gtin";
    }


  }
  isLandscape = window.innerWidth > window.innerHeight;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }



  getItemsCount(): number {
    if (this.serviceResultsforItem != null) {
      return this.serviceResultsforItem == undefined ? 0 : this.serviceResultsforItem.length;
    }
    else {
      return 0;
    }
  }

  getGtinCount(): number {
    if (this.gtin != null) {
      //return this.gtin.nutrientValuseDetails == undefined ? 0 : this.gtin.nutrientValuseDetails.length;
      return this.gtin.components == undefined ? 0 : this.gtin.components.length;
    }
    else {
      return 0;
    }
  }
  placeholderText: string = 'Enter Item Code.';
  radioChangedHandler(value: any) {
    this.serviceResultsforItem = [];
    this.type = value;
    this.selectedType = this.type;

    this.gtinSelected = '';
    this.itemSelected = '';
    this.scanCodeSelected = '';

    if (this.selectedType == 'gtin') {
      this.gtinSelected = "checked";
      this.placeholderText = "Enter GTIN/Compressed GTIN without check digit..";
    }
    else if (this.selectedType == 'scanBarCode') this.scanCodeSelected = 'checked';
    else if (this.selectedType == 'item') {
      this.placeholderText = "Enter Item Code.";
      this.itemSelected = 'checked';
    }

    this.showResultsNotFoundMessage = false;
    this.showScanErrorMessage = true;

    Quagga.stop();
    if (this.type == "scanBarCode") {
      this.disableCamera = false;
      this.showScanErrorMessage = false;
      this.restApiService.isScanerRunning = true;
      Quagga.start();
    }
    else {
      this.disableCamera = true;
      if (this.inputitemgtincode) this.inputitemgtincode.nativeElement.value = "";
      this.restApiService.isScanerRunning = false;
    }
  }

  onScanBarcodeChanged(result: ScanBarcodeResult) {
    console.log("onScanBarcodeChanged", result);
    this.showScanErrorMessage = false;
    this.showResultsNotFoundMessage = false;
    if (result.isSuccess) {
      this.itemgtincode = result.code;
      this.disableCamera = true;
      this.type = 'gtin';
      this.searchByItemOrGtinCode()
    }
    else {
      this.showScanErrorMessage = true;

      console.log(this.type);
    }
    this.type = 'scanBarCode';
  }

  formSubmit() {
    this.showUpdateButton = false;
    this.showResultsNotFoundMessage = false;
    this.backButtonClicked = false;
    this.selectedType = this.type;
    this.showScanErrorMessage = false;
    //alert(this.inputitemgtincode.nativeElement.value.length);
    this.itemgtincode = this.inputitemgtincode.nativeElement.value;
    if (this.type == 'gtin' || this.type == 'scanBarCode') {
      if (this.inputitemgtincode.nativeElement != undefined && this.inputitemgtincode.nativeElement.value.trim() != "") {
        if (this.itemgtincode.toString().length < 6) {
          this.itemgtincode = this.inputitemgtincode.nativeElement.value.padStart(6, '0');
          //alert(this.itemgtincode)
        }
        if (this.itemgtincode.toString().length == 6) {
          const result = this.GtinConverter.convertUpcToGtin(this.itemgtincode.toString());
          this.itemgtincode = +result.slice(0, -1);
          //alert(this.itemgtincode)
        }
      }
    }
    if (this.inputitemgtincode.nativeElement != undefined && this.inputitemgtincode.nativeElement.value.trim() != "") {
      this.searchByItemOrGtinCode();
    }
  }
  getGtin(event: number) {
    this.type = 'gtin';
    this.selectedType = "item";
    this.backButtonClicked = false;
    this.itemgtincode = event;
    this.inputitemgtincode.nativeElement = undefined;
    this.searchByItemOrGtinCode()
    Quagga.stop();
  }

  searchByItemOrGtinCode() {
    //this.showsearchDiv = false;
    this.showResultsNotFoundMessage = false;
    this.displayLoadingIcon = true;
    if (this.itemgtincode != 0) {

      if (this.type == 'item') {
        this.gtin = <Gtin>{};
        this.restApiService.getGtinByItemCode(this.itemgtincode).subscribe((data: {}) => {

          this.serviceCallResults = JSON.stringify(data);

          if (this.serviceCallResults == JSON.stringify('No Results Found')) {
            this.showResultsNotFoundMessage = true;
            this.displayLoadingIcon = false;
          }
          this.serviceResultsforItem = JSON.parse(this.serviceCallResults);

          if (this.serviceResultsforItem.length == 1 && !this.showResultsNotFoundMessage) {
            this.restApiService.getItembyGtin(this.serviceResultsforItem[0].gtin).subscribe((data: {}) => {
              this.hasMultipleItemResult = false;
              this.displayLoadingIcon = false;
              if (data === 'No Results Found') {
                this.showResultsNotFoundMessage = true;
              }
              else {
                this.gtin = JSON.parse(JSON.stringify(data));
                // this.type = 'gtin';
                this.serviceResultsforItem = [];
                this.showsearchDiv = false;
              }
            });
          }
          else {
            this.hasMultipleItemResult = true;
            this.displayLoadingIcon = false;
          }
        },
          () => { this.showResultsNotFoundMessage = true; this.displayLoadingIcon = false; });

      }
      else if (this.type == 'gtin') {
        this.serviceResultsforItem = [];
        if (this.itemgtincode.toString() != "") {
          this.restApiService.getItembyGtin(this.itemgtincode).subscribe((data: {}) => {
            this.serviceCallResults = JSON.stringify(data);

            if (this.serviceCallResults == JSON.stringify('No Results Found') || this.serviceCallResults == JSON.stringify('One more component(s) does not have nutrition data.') || this.serviceCallResults == JSON.stringify('Something went wrong')) {
              this.showResultsNotFoundMessage = true;
            }
            else {
              this.showsearchDiv = false;
            }
            this.gtin = JSON.parse(this.serviceCallResults);
            this.displayLoadingIcon = false;
          },
            () => { this.showResultsNotFoundMessage = true; this.displayLoadingIcon = false; });
        }
        else {
          this.displayLoadingIcon = false;
        }
      }


    }
  }
  reScan() {
    this.showScanErrorMessage = false;
    this.disableCamera = false;
    this.eventEmitterService.onIntializeQuagga();
  }
}
