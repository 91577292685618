import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation.dialog.component.html',
})
export class ConfirmationDialogComponent {
  opened = false;

  @Input() message: any = '';

  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  open(): void {
    this.opened = true;
  }

  onClose(): void {
    this.cancel.emit();
    this.opened = false;
  }

  onConfirm(): void {
    this.confirm.emit();
    this.opened = false;
  }

  onCancel(): void {
    this.cancel.emit();
    this.opened = false;
  }
}
