import { Component, ElementRef, Input, OnChanges, Output, OnInit, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { AllergenIngredinatsUpdatedData, Gtin, Component as GtinComponent, NutrientValuesDetail, RecordsToUpdateDValue } from 'src/app/Models/Gtin';
@Component({
  selector: 'search-gtin-OCR-results',
  templateUrl: './search-ocr-results.html',
  styleUrls: ['./search-ocr-results.css']
})

export class SearchOCRGtinResults implements OnInit, OnChanges {
  @Input() searchGtinResults!: Gtin;
  @Input() searchPNMSGtinResultsToCompare!: Gtin;
  @Input() pageNumber!: number;
  @Output() updatedRecordDisplayValue = new EventEmitter<RecordsToUpdateDValue>();
  @Output() updatedRecordDvp = new EventEmitter<RecordsToUpdateDValue>();
  @Output() updatedRecordDualDisplayValue = new EventEmitter<RecordsToUpdateDValue>();
  @Output() updatedRecorDualdDvp = new EventEmitter<RecordsToUpdateDValue>();
  @Output() allergenIngredinatsUpdatedData = new EventEmitter<AllergenIngredinatsUpdatedData>();
  @Output() ResetIngredintns = new EventEmitter<string>();
  @Output() ResetAllergen = new EventEmitter<string>();



  includeNutrientIds = [200, 201];
  numofTables!: number;
  nutrtionDetails!: string;
  allergianDetails!: string;
  ingrediantDetails!: string;
  nutrionDetailsArray: string[] = [];
  allergionDetailsArray: string[] = [];
  ingrediantDetailsArray: string[] = [];
  id: number = 1;
  @ViewChild('tableid') inputitemgtincode!: ElementRef;
  constructor() {
  }
  restIngredientsToOCR(ComponentID: string) {
    this.ResetIngredintns.emit(ComponentID);
  }

  resetAllergenToOCR(ComponentID: string) {
    this.ResetAllergen.emit(ComponentID);
  }
  getDecimalValueFromString(input: string): number | null {
    const regex = /(\d+(\.\d+)?)/; // Regular expression to match decimal values

    const matches = input.match(regex); // Get matches from the input string

    if (matches && matches.length > 0) {
      const decimalValue = parseFloat(matches[0]); // Parse the matched value as a float
      return decimalValue;
    }

    return null; // Return null if no decimal value is found
  }
  addPNMSAttributesIfNotAvailable() {

    for (let i = 0; i < this.searchGtinResults.components.length; i++) {
      const component = this.searchGtinResults.components[i];
      let hasNutrient200 = false;
      let hasNutrient201 = false;
      for (let j = 0; j < component.nutrientValuesDetails.length; j++) {
        if (component.nutrientValuesDetails[j].nutrientID === 200) {
          hasNutrient200 = true;
          break;
        }
      }
      for (let k = 0; k < component.nutrientValuesDetails.length; k++) {
        if (component.nutrientValuesDetails[k].displayValue != undefined) {
          if (component.nutrientValuesDetails[k].displayValue.toLowerCase().startsWith("less") || component.nutrientValuesDetails[k].displayValue.startsWith("Less")) {
            if (!component.nutrientValuesDetails[k].nutrValue.startsWith("<")) {
              component.nutrientValuesDetails[k].nutrValue = "<" + this.getDecimalValueFromString(component.nutrientValuesDetails[k].displayValue);
            }
          }
        }
        if (component.nutrientValuesDetails[k].duaL_DisplayValue != undefined) {
          if (component.nutrientValuesDetails[k].duaL_DisplayValue.toLowerCase().startsWith("less") || component.nutrientValuesDetails[k].duaL_DisplayValue.startsWith("Less")) {
            if (!component.nutrientValuesDetails[k].dualNutrValue.startsWith("<")) {
              component.nutrientValuesDetails[k].dualNutrValue = "<" + this.getDecimalValueFromString(component.nutrientValuesDetails[k].duaL_DisplayValue);
            }
          }
        }
        if (component.nutrientValuesDetails[k].nutrientID === 201) {
          hasNutrient201 = true;
          break;
        }
      }

      if (!hasNutrient200) {
        const newNutrientValueDetail: NutrientValuesDetail = {
          nutrientID: 200,
          nutrientName: "Whole Grain",
          effectiveDt: "",
          terminationDt: "",
          nutrValue: "",
          dvp: "",
          displayValue: "",
          dualNutrValue: "",
          dualDVP: "",
          duaL_DisplayValue: "",
          uom: "",
          dualuom: "",
        };
        component.nutrientValuesDetails.push(newNutrientValueDetail);
      }
      if (!hasNutrient201) {
        const newNutrientValueDetail: NutrientValuesDetail = {
          nutrientID: 201,
          nutrientName: "Juice",
          effectiveDt: "",
          terminationDt: "",
          nutrValue: "",
          dvp: "",
          displayValue: "",
          dualNutrValue: "",
          dualDVP: "",
          duaL_DisplayValue: "",
          uom: "",
          dualuom: "",
        };
        component.nutrientValuesDetails.push(newNutrientValueDetail);
      }
    }
  }


  onWholeGrainDispalyvalueChange(gtin: string, componentID: string, nutrientID: number, dispalyvalue: string) {
    // this.updatedRecordDisplayValue.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dispalyvalue));
  }
  oningredientsDataChange(type: string, componentID: string, ingredient: string) {
    this.allergenIngredinatsUpdatedData.emit(new AllergenIngredinatsUpdatedData(type, componentID, ingredient))
  }
  onallergenDataChange(type: string, componentID: string, allergen: string) {
    this.allergenIngredinatsUpdatedData.emit(new AllergenIngredinatsUpdatedData(type, componentID, allergen))
  }
  GetWholeGrainNutrient(i: GtinComponent) {
    var component = this.searchGtinResults.components.find(x => x.componentID == i.componentID)?.nutrientValuesDetails.find(k => k.nutrientID == 200);
  }

  GetJuiceNutrient(i: GtinComponent) {
    var component = this.searchGtinResults.components.find(x => x.componentID == i.componentID);
  }

  sameSet(id: string, type: string): boolean {
    if (type === "ALLERGEN") {
      const ocrComponent = this.searchGtinResults.components.find(x => x.componentID === id);
      const pnmsComponent = this.searchPNMSGtinResultsToCompare.components.find(x => x.componentID === id);
      if (ocrComponent && pnmsComponent) {
        const replacements: Map<string, string> = new Map([
          ['treenut', 'tree nuts'],
          ['tree nuts', 'tree nuts'],
          ['treenuts', 'tree nuts'],
          ['egg', 'eggs'],
          ['eggs', 'eggs'],
          ['soybean', 'soybean'],
          ['soy', 'soybean']
        ]);
        ocrComponent.allergenList = ocrComponent.allergenList.replace('.', '');
        const ocrset: string[] = ocrComponent.allergenList.split(',').map(v => v.trim().toLowerCase());
        // Replace the values using the map
        for (let i = 0; i < ocrset.length; i++) {
          if (replacements.has(ocrset[i])) {
            ocrset[i] = replacements.get(ocrset[i])!;
          }
        }
        const newocrset: Set<string> = new Set(ocrset);

        const pnmsset = new Set(pnmsComponent.allergenList.toLowerCase().replace(/\./g, "").split(',').map(s => s.trim().toLowerCase()));
        if (newocrset.size !== pnmsset.size) {
          return false;
        }
        for (const val of pnmsset) {
          if (!newocrset.has(val)) {
            return false;
          }
        }
      }
    }
    if (type === "INGREDIENTS") {
      const ocrComponent = this.searchGtinResults.components.find(x => x.componentID === id);
      const pnmsComponent = this.searchPNMSGtinResultsToCompare.components.find(x => x.componentID === id);
      if (ocrComponent && pnmsComponent) {
        const ocrset = new Set(ocrComponent.ingredientList.split(',').map(s => s.trim().toLowerCase()));
        const pnmsset = new Set(pnmsComponent.ingredientList.split(',').map(s => s.trim().toLowerCase()));
        if (ocrset.size !== pnmsset.size) {
          return false;
        }
        for (const val of pnmsset) {
          if (!ocrset.has(val)) {
            return false;
          }
        }
      }
    }
    return true;
  }
  getUpdatedRecordDisplayValue(event: RecordsToUpdateDValue) {
    var selectedcomponent = this.searchGtinResults.components.find(x => x.componentID == event.componentID);
    var selectednutrition = selectedcomponent?.nutrientValuesDetails.find(y => y.nutrientID == event.nutrientID);

    if (selectedcomponent !== undefined && selectednutrition !== undefined) {
      if ((event.value.includes('<'))) {
        selectednutrition.displayValue = 'Less than ' + event.value;
      }
      else {
        selectednutrition.displayValue = event.value;
      }
    }

    this.updatedRecordDisplayValue.emit(event);
  }

  getUpdatedRecordDvp(event: RecordsToUpdateDValue) {
    this.updatedRecordDvp.emit(event);
  }
  getUpdatedRecorDualDisplayValue(event: RecordsToUpdateDValue) {
    var selectedcomponent = this.searchGtinResults.components.find(x => x.componentID == event.componentID);
    var selectednutrition = selectedcomponent?.nutrientValuesDetails.find(y => y.nutrientID == event.nutrientID);

    if (selectedcomponent !== undefined && selectednutrition !== undefined) {
      if ((event.value.includes('<'))) {
        selectednutrition.duaL_DisplayValue = 'Less than ' + event.value;
      }
      else {
        selectednutrition.duaL_DisplayValue = event.value;
      }
    }
    this.updatedRecordDualDisplayValue.emit(event);
  }
  getUpdatedRecordDualDvp(event: RecordsToUpdateDValue) {
    this.updatedRecorDualdDvp.emit(event);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['pageNumber']) {
      this.id = changes['pageNumber'].currentValue;
    }
  }
  ngOnInit(): void {

  }
  getGtinNutritionCount(): number {
    return this.searchGtinResults.components.length;
  }
  onNutrientDispalyvalueChange(gtin: string, componentID: string, nutrientID: number, dispalyvalue: string) {
    this.updatedRecordDisplayValue.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dispalyvalue));
  }
  onNutrientDvpvalueChange(gtin: string, componentID: string, nutrientID: number, dvp: string) {
    this.updatedRecordDvp.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dvp));
  }


  validateInput(event: KeyboardEvent) {
    const currentValue = (event.target as HTMLInputElement).value;
    const inputValue = parseFloat(currentValue + event.key);

    if (
      isNaN(inputValue) || // Check if it's not a number
      (inputValue !== 0 && (inputValue < 0.1 || inputValue > 100)) || // Check if it's less than 0.1 or greater than 100 (excluding 0)
      (event.key === '.' && (currentValue.includes('.') || currentValue === '')) || // Allow only one decimal point, even if the field is empty
      (currentValue === '0' && event.key !== '.') // Disallow initial zero, except for decimal values
    ) {
      event.preventDefault();
    }
  }




  GetNutrientsToShow(id: number) {
    this.addPNMSAttributesIfNotAvailable();
    return this.searchGtinResults.components[id - 1].nutrientValuesDetails.filter(n => this.includeNutrientIds.includes(n.nutrientID));
  }
  getdifference(ocrcomponentid: string, ocrnutrientId: number, value: string, type: string): boolean {
    if (type == "displayValue") {
      for (const component of this.searchPNMSGtinResultsToCompare.components) {
        if (component.componentID === ocrcomponentid) {
          for (const nutrientValue of component.nutrientValuesDetails) {
            if (nutrientValue.nutrientID === ocrnutrientId) {
              if (nutrientValue.nutrValue == null && value == null) {
                return true;
              }
              if (nutrientValue.nutrValue.trim() == "" && value.trim() == "") {
                return true;
              }
              // if (parseFloat(nutrientValue.nutrValue) == parseFloat(value)) {
              //   return true;
              // }
              if (parseFloat(nutrientValue.nutrValue) == parseFloat(value)) {
                return true;
              }
              else {
                return false;
              }
            }
          }
        }
      }
    }
    if (type == "dvp") {
      for (const component of this.searchPNMSGtinResultsToCompare.components) {
        if (component.componentID === ocrcomponentid) {
          for (const nutrientValue of component.nutrientValuesDetails) {
            if (nutrientValue.nutrientID === ocrnutrientId) {
              if (value != undefined && nutrientValue.dvp != undefined) {
                if (nutrientValue.dvp.trim() == "" && value.trim() == "") {
                  return true;
                }
              }
              if (nutrientValue.dvp == null && (value == undefined || value == null || value.trim() == '')) {
                return true;
              }
              if (parseFloat(nutrientValue.dvp) == parseFloat(value)) {
                return true;
              }
              else {
                return false;
              }
            }
          }
        }
      }
    }
    return false;
  }
}
