import { Component, EventEmitter, Input, OnInit, Output,ElementRef,AfterViewInit } from '@angular/core';
import { Component as GtinComponent, Gtin, NutrientsDto, RecordsToUpdateDValue,NutritionValue } from 'src/app/Models/Gtin';

const highlightedNutrientIds = [102, 104, 105, 106, 109]
const ignoreNutrientIds = [100,200,201];

const includeNutrientIds = [100];
const LESSTHANTEXT = 'less than';
var nutritionValues:NutritionValue[]=[];

@Component({
  selector: 'nutrition-fact-ocr',
  templateUrl: './nutrition-fact-ocr.html',
  styleUrls: ['./nutrition-fact-ocr.scss']
})
export class NutritionOCRFactComponent implements OnInit, AfterViewInit {
  @Input() component!: GtinComponent;
  @Input() pnmsComponents!: Gtin;
  @Output() updatedRecordDisplayValue = new EventEmitter<RecordsToUpdateDValue>();
  @Output() updatedRecordDvp = new EventEmitter<RecordsToUpdateDValue>();
  @Output() updatedRecordDualDisplayValue = new EventEmitter<RecordsToUpdateDValue>();
  @Output() updatedRecorDualdDvp = new EventEmitter<RecordsToUpdateDValue>();
  constructor(private elementRef: ElementRef) {
    
  }

  removeSpaces(nutrientName: string): string {
    return 'ocr'+nutrientName.replace(/\s+/g, '');
  }
  ngOnInit(): void {
    this.component.nutrientValuesDetails.forEach(function (value) {
      nutritionValues.push({nutrientID:value.nutrientID,showFact:false,showDualFact:false,showDv:false,showDualDv:false});
    }); 
  }
  ngAfterViewInit(){
    
}

  getVisibilityValue1(value:number){
    return nutritionValues.find(n=>n.nutrientID==value)?.showFact;
  }
  getVisibilityValue2(value:number){
    return nutritionValues.find(n=>n.nutrientID==value)?.showDualFact;
  }
  getVisibilityDv1(value:number){
    return nutritionValues.find(n=>n.nutrientID==value)?.showDv;
  }
  getVisibilityDv2(value:number){
    return nutritionValues.find(n=>n.nutrientID==value)?.showDualDv;
  }
  showTextBoxValue1(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showFact = true;        
      }
      });
      // var id= 'fv'+value.toString();
      // var ele = <HTMLElement>document.getElementById(id);
      // ele.focus();
  }
  showTextBoxValue2(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showDualFact = true;
      }
      });
  }
  showSpanValue1(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showFact = false;
      }
      });
  }
  showSpanValue2(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showDualFact = false;
      }
      });
  }
  showTextBoxDv1(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showDv = true;
        
      }
      });
      
  }
  showTextBoxDv2(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showDualDv = true;
      }
      });
  }
  showSpanDv1(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showDv = false;
      }
      });
  }
  showSpanDv2(value:number){
    nutritionValues.forEach(function (nutrVal) {
      if(nutrVal.nutrientID == value){
        nutrVal.showDualDv = false;
      }
      });
  }

  getMiscellaneousData() {
    var calorieValue = this.component.nutrientValuesDetails.find(n => n.nutrientID == 100)?.displayValue;
    var dualcalorieValue = this.component.nutrientValuesDetails.find(n => n.nutrientID == 100)?.dualNutrValue;
    var itemHeader = this.component.itemHeader;
    var dualitemHeader = this.component.dualItemHeader;

    return ({
      calorieValue, itemHeader, dualitemHeader, dualcalorieValue
    })
  }
  onNutrientDispalyvalueChange(gtin: string, componentID: string, nutrientID: number, dispalyvalue: string) {
    this.updatedRecordDisplayValue.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dispalyvalue));
  }
  onNutrientDvpChange(gtin: string, componentID: string, nutrientID: number, dvp: string) {
    this.updatedRecordDvp.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dvp));
  }
  onNutrientDualDispalyvalueChange(gtin: string, componentID: string, nutrientID: number, dualdispalyvalue: string) {
    this.updatedRecordDualDisplayValue.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dualdispalyvalue));
  }
  onNutrientDualDvpChange(gtin: string, componentID: string, nutrientID: number, dualdvp: string) {
    this.updatedRecorDualdDvp.emit(new RecordsToUpdateDValue(gtin, componentID, nutrientID, dualdvp));
  }

  getdifference(ocrcomponentid: string, ocrnutrientId: number, value: string, type: string): boolean {
    if (type == "displayValue") {
      for (const component of this.pnmsComponents.components) {
        if (component.componentID === ocrcomponentid) {
          for (const nutrientValue of component.nutrientValuesDetails) {
            if (nutrientValue.nutrientID === ocrnutrientId) {
              if (nutrientValue.nutrValue == value) {
                return true;
              }
              if (nutrientValue.nutrValue == null && value == null) {
                return true;
              }
              // if (parseFloat(nutrientValue.nutrValue) == parseFloat(value)) {
              //   return true;
              // }
              if (parseFloat(nutrientValue.nutrValue) == parseFloat(value)) {
                return true;
              }
              else {
                return false;
              }
            }
          }
        }
      }
    }
    if (type == "dvp") {
      for (const component of this.pnmsComponents.components) {
        if (component.componentID === ocrcomponentid) {
          for (const nutrientValue of component.nutrientValuesDetails) {
            if (nutrientValue.nutrientID === ocrnutrientId) {

              if (nutrientValue.dvp == null && (value == undefined ||  value == null ||  value.trim() == '')) {
                return true;
              }
              if (parseFloat(nutrientValue.dvp) == parseFloat(value)) {
                return true;
              }
              else {
                return false;
              }
            }
          }
        }
      }
    }
    if (type == "duaL_DisplayValue") {
      for (const component of this.pnmsComponents.components) {
        if (component.componentID === ocrcomponentid) {
          for (const nutrientValue of component.nutrientValuesDetails) {
            if (nutrientValue.nutrientID === ocrnutrientId) {
              if (nutrientValue.dualNutrValue == null && (value == undefined || value == null || value.trim() == '')) {
                return true;
              }
              if (nutrientValue.dualNutrValue == null && value == null) {
                return true;
              }
              if (nutrientValue.dualNutrValue == value) {
                return true;
              }
              if (parseFloat(nutrientValue.dualNutrValue) == parseFloat(value)) {
                return true;
              }
              else {
                return false;
              }
            }
          }
        }
      }
    }
    if (type == "dualDVP") {
      for (const component of this.pnmsComponents.components) {
        if (component.componentID === ocrcomponentid) {
          for (const nutrientValue of component.nutrientValuesDetails) {
            if (nutrientValue.nutrientID === ocrnutrientId) {
              if (nutrientValue.dualDVP == null && (value == undefined || value == null || value.trim() == '')) {
                return true;
              }
              if (parseFloat(nutrientValue.dualDVP) == parseFloat(value)) {
                return true;
              }
              else {
                return false;
              }
            }
          }
        }
      }
    }
    return false;
  }

  isHighlightedNutrient(nutrient: NutrientsDto) {
    return highlightedNutrientIds.includes(nutrient.nutrientID);
  }
  GetNutrientsToShow() {
    return this.component.nutrientValuesDetails.filter(n => !ignoreNutrientIds.includes(n.nutrientID));
  }
  GetNutrientCaloriyToShow() {
    return this.component.nutrientValuesDetails.filter(n => includeNutrientIds.includes(n.nutrientID));
  }
  getDualDisplayValue(value: string) {
    if (value != null) {
      var returnValue: string = value;
      if (value.toLowerCase().startsWith(LESSTHANTEXT)) {
        return '<' + value.replace(LESSTHANTEXT, '').replace('gram', 'g').replace(/ /g, "").trim();
      }
      return Number(returnValue);
    }
    return "";
  }

  getUom(value: string) {
    var uomVlaue: string = "";
    if (value != null) {
      uomVlaue = value;
      if (value === "IU") uomVlaue = "mcg";
    }
    return uomVlaue;
  }
}
