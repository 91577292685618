<div class="search">
  <!-- <div class="container-fluid">
          <div class="row">
              <div class="col my-sidebar">
                  <div class="backButton">
                      <a (click)="makeshowsearchDivtrue()" *ngIf="showsearchDiv!=true" style="cursor:pointer;">
                          <i class="fa fa-arrow-circle-left"></i>
                      </a>
                  </div>
              </div>
              <div class="col text-center">

              </div>

          </div>
      </div> -->
  <!-- <a (click)="makeshowsearchDivtrue()" *ngIf="showsearchDiv!=true"
      style="cursor:pointer; align-self: flex-start; margin-left: 40px;">
      <i class="fa fa-arrow-circle-left"></i>
  </a> -->

  <div>
    <div class="row">
      <!-- <div class="col my-sidebar">

      </div> -->
      <div class="col text-center">
        <!-- <div *ngIf="showsearchDiv==true"> -->
        <div *ngIf="showsearchDiv==true">
          <form (ngSubmit)="formSubmit()" #myForm="ngForm">
            <div class="px-4 py-4 pb-0 my-5 text-center">
              <div class="col-lg-4 mx-auto">
                <!-- Options for search -->
                <div class="search_page" [ngStyle]="{'padding-left':isLandscape?'0%':'20%'}">
                  <div class="form-check  donate-now">
                    <input type="radio" class="form-check-input" id="radio1" name="itemdetails"
                           value="item" [checked]="itemSelected" #item>
                    <label class="form-check-label" for="item" (click)="radioChangedHandler('item')">Item</label>
                  </div>
                  <div class="form-check  donate-now">
                    <input type="radio" class="form-check-input" [checked]="gtinSelected" id="radio2" name="itemdetails"
                           value="gtin" #gtin>
                    <label class="form-check-label" for="gtin" (click)="radioChangedHandler('gtin')">GTIN</label>
                  </div>
                  <div class="form-check  donate-now">
                    <input type="radio" class="form-check-input" id="radio3" name="itemdetails" [checked]="scanCodeSelected"
                           value="scanBarCode" #scanBarCode>
                    <label class="form-check-label" for="scanBarCode" (click)="radioChangedHandler('scanBarCode')" style="width: 140px;">
                      Scan
                      BarCode
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <br>


            <div class="p-2 col-lg-4 mx-auto " *ngIf="this.type != 'scanBarCode'" [ngStyle]="{'width':isLandscape?'35%':'50%'}">
              <div class="input-group">
                <input type="text"
                       class="form-control"
                       [placeholder]="placeholderText"
                       PositiveNumber
                       #gtinItemcode
                       name="gtinItemcode"
                       #inputitemgtincode
                       ngModel
                       style="text-align:center"
                       required />
              </div>
              <br /> <!-- Add a <br> element to create a line break -->
              <div class="text-center">
                <button class="btn btn-secondary buttonsuccess" id="myBtn"
                        [disabled]="displayLoadingIcon">
                  Search
                </button>
              </div>
            </div>


            <!--<div class="p-2 col-lg-4 mx-auto " *ngIf="this.type != 'scanBarCode'">

    <div class="input-group">-->
            <!--<input type="text" class="form-control" placeholder="Enter Item or GTIN without check digit.." PositiveNumber #gtinItemcode
    name="gtinItemcode" #inputitemgtincode ngModel required />-->
            <!--<input type="text"
               class="form-control"
               [placeholder]="placeholderText"
               PositiveNumber
               #gtinItemcode
               name="gtinItemcode"
               #inputitemgtincode
               ngModel
               required />


        <button class="btn btn-secondary buttonsuccess" id="myBtn"
                [disabled]="displayLoadingIcon">
          Search
        </button>
      </div>

    </div>-->
            <hr>
          </form>
        </div>
      </div>

    </div>

    <!-- gtin table -->
    <div>
      <div class="row">
        <div class="col text-center" *ngIf="showResultsNotFoundMessage==false && backButtonClicked==false">
          <search-gtin-results [searchGtinResults]="gtin"  [isFromAudit]="isFromAudit"  *ngIf="getGtinCount()>0" (calbackgtinresults)="makeshowsearchDivtrue()">
          </search-gtin-results>
        </div>
      </div>

    </div>
  </div>

  <!-- <app-scan-barcode *ngIf="type=='scanBarCode'"></app-scan-barcode>  -->
  <!-- scan bar code -->

  <app-scan-barcode *ngIf="!disableCamera && !showScanErrorMessage"
                    (scanBarcodeChanged)="onScanBarcodeChanged($event)">
  </app-scan-barcode>

  <!-- item table -->
  <div>
    <div class="row">
      <div class="col text-center" *ngIf="showResultsNotFoundMessage==false">
        <search-item-results [searchItemResults]="serviceResultsforItem" *ngIf="getItemsCount()>1"
                             (gtinCode)="getGtin($event)">
        </search-item-results>
      </div>

    </div>
  </div>

  <div *ngIf="showResultsNotFoundMessage" style="align-self: center; display:flex; align-items: center;">
    GTIN/Item code not exist.
    <a *ngIf="type=='scanBarCode'" (click)="reScan()"
       style="margin-left: 10px; color:blue;text-decoration: underline; cursor: pointer;">Rescan</a>
  </div>

  <!-- loading..-->
  <div *ngIf="displayLoadingIcon" class="spinner-border" role="status"
       style="position:absolute;top:50%;left:50% ;height: 50px; width: 50px;">
    <span class="sr-only">Loading...</span>
  </div>
</div>
