<!-- <div class="clearfix"></div>

<div class="container" style="display:table-cell;">
<div class="search-result">
    <div class="border">
    <span>Item details</span>
</div>
    <kendo-grid [data]="searchItemResults">
        <kendo-grid-column field="itemCode" title="Item Code" [headerStyle]="{ 'font-weight': 'bold','border-color': '#000' }"> </kendo-grid-column>
        <kendo-grid-column field="itemDesc" title="Item Description" [headerStyle]="{ 'font-weight': 'bold'}"> </kendo-grid-column>
        <kendo-grid-column field="gtin" title="Gtin" [headerStyle]="{ 'font-weight': 'bold' }">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                
                <a (click)="getGtinDetails(dataItem)" style="text-decoration: underline;"><span
                        style="color:blue">{{dataItem.gtin}}</span></a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="gtinLevel" title="Gtin Level" [headerStyle]="{ 'font-weight': 'bold' }"> </kendo-grid-column>
    </kendo-grid>
</div>
</div> -->

<main>
    <section class="mb-5 main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12" align="center">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h5 class="card-title">Item Details</h5> -->
                                <div style="text-align: left; width:60%;"> <b>Item Code:</b> {{searchItemResults[0].itemCode}}</div>
                                <div style="text-align: left;width:60%;"> <b>Item Description:</b> {{searchItemResults[0].itemDesc}}</div>
                                <div class="table-responsive">

                                    
                                    <table class="table table-striped table-bordered mb-0" style="width:60%">
                                        <thead class="green-bg">
                                            <tr>
                                                <!-- <th scope="col">Item Code</th>
                                                <th scope="col">Item Description</th> -->
                                                <th scope="col" style="color: white;">GTIN</th>
                                                <th scope="col" style="color: white;">GTIN Level</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of searchItemResults">
                                                <!-- <th scope="row">{{item.itemCode}}</th>
                                                <td>{{item.itemDesc}}</td> -->
                                                <td> 
                                                    <a (click)="getGtinDetails(item)" style="text-decoration: underline; cursor: pointer;"><span
                                                    style="color:blue">{{item.gtin}}</span></a>
                                                </td>
                                                <td>{{item.gtinLevel}}</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </section>


</main>