<div class="py-2 pb-0 text-center1">
  <div>
    <div class="col-md-12" style="display: inline-block;">

      <!---->
      <div *ngIf="uploadImag">
        <input type="file" class="btn btn-secondary buttonsuccess" style="width:300px" *ngIf="!isCaptured && !isCropped" accept="image/*" (change)="handleImageUpload($event)" [class.hide]="isCaptured">
        <!--<img *ngIf="isCaptured" [src]="currentImageData" alt="Captured Image"/>-->
      </div>
      <!---->
      <div style="padding-top:10px">
        <button class="btn btn-secondary buttonsuccess" *ngIf="!isCaptured && !isCropped" (click)="capture()"
                style="float:left;">
          Capture
          Image
        </button>

        <button class="btn btn-secondary buttonsuccess" *ngIf="isCaptured && !isCropped" (click)="cropCurrent()"
                style="float:left;">
          Crop/Save
        </button>
        <button class="btn btn-secondary buttonsuccess" *ngIf="isCaptured && !isCropped" (click)="removeCurrent()"
                style="margin-left:8px;float:left;width:auto;">
          Retake
        </button>
        <button class="btn btn-secondary buttonsuccess" *ngIf="isCaptured && isCropped" (click)="removeCurrent()"
                style="float:left;">
          Take
          Another
        </button>
      </div>

      <!--<button (click)="startRecording()">Record Audio</button>
      <button (click)="stopRecordingAndSendToAPI()" style="padding-left:20px">Stop Audio</button>-->

      <button class="btn btn-secondary buttonsuccess" *ngIf="isCaptured && !isCropped" (click)="rotateLeft()" style="float: right;margin-left: 4px;"><i class="bi bi-arrow-clockwise"></i></button>
      <button class="btn btn-secondary buttonsuccess" *ngIf="isCaptured && !isCropped" (click)="zoomOut()" style="float: right;margin-left: 4px;"><i class="bi bi-zoom-out"></i></button>
      <button class="btn btn-secondary buttonsuccess" *ngIf="isCaptured && !isCropped" (click)="zoomIn()" style="float: right;"><i class="bi bi-zoom-in"></i></button>
    </div>
  </div>
  <div>
    <div style="text-align: center">
        <!--For Ipad Horizontal and Desktop view-->
        <webcam *ngIf="isLandscape" [imageQuality]="1" [imageType]="'image/jpg'" [width]="WebCamWidth" [height]="WebCamHeight" [trigger]="invokeImageCapture"
                (imageCapture)="handleImageCaptured($event)" [videoOptions]="videoOptions" [class.hide]="isCaptured" [allowCameraSwitch]="allowCameraSwitch">
        </webcam>

        <!--For Ipad Vertical view-->
        <webcam *ngIf="!isLandscape" [imageQuality]="1" [imageType]="'image/jpg'" [width]="WebCamWidth" [height]="WebCamHeight" [trigger]="invokeImageCapture"
                (imageCapture)="handleImageCaptured($event)" [videoOptions]="videoOptions" [class.hide]="isCaptured" [allowCameraSwitch]="allowCameraSwitch">
        </webcam>
      <div class="resizable" *ngIf="isCaptured && isCropped" style="width: 380px; height: 500px; float:left;">
        <img [src]="selectedThumbnail?.data" *ngIf="isCaptured && isCropped"
             style="padding: 0px; float:left;" />
      </div>

      <!--<img [src]="selectedThumbnail?.data" *ngIf="isCaptured && isCropped"
       style="padding: 0px;" />-->

      <div style="height:531px; width:1000px;" *ngIf="isCaptured && !isCropped">
        <image-cropper *ngIf="isCaptured && !isCropped" [imageURL]="currentImageData"
                       [maintainAspectRatio]="false"
                       [containWithinAspectRatio]="false"
                       [aspectRatio]="1 / 1" format="png" [canvasRotation]="canvasRotation"
                       [transform]="transform"
                       [onlyScaleDown]="true" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                       (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>


    </div>
  </div>
</div>
