
<div style="text-align:center;" class="px-4 py-4 pb-0 mt-5 text-center row" >
<div class="error-message" *ngIf="errorMessage">
  {{ errorMessage }}
</div>

<div id="scanner-container">
  <!-- QuaggaJS will populate this element -->
</div>
</div>

<!--<div class="instructions">
    This is a companion app for the blog post located <a target="_blank" href="https://blog.classycode.com/">here</a>.
    The source code is available on <a target="_blank" href="https://github.com/classycodeoss/mobile-scanning-demo">Github</a>.
  </div>-->

<!-- <div class="controls">
  <form>
    <input id="acceptAnyCode" type="checkbox" [(ngModel)]="acceptAnyCode" name="acceptAnyCode" required />
    <label for="acceptAnyCode">Accept any code</label>
    <button (click)="clearCart()">Clear Barcodes</button>
  </form>

</div> -->


<!-- <div class="shopping-cart">
  
  <span *ngIf="items.length === 0 else shoppingCartItems">Barcode empty</span>
  <ng-template #shoppingCartItems>

    <app-barcode-item *ngFor="let articleAndCount of items" 
                            [article]="articleAndCount[0]"
                            [count]="articleAndCount[1]">
    </app-barcode-item>

    <div class="shopping-cart-total">
      Total: {{ totalPrice }} USD
    </div>
  </ng-template>
</div> -->

<!-- Check if this is required -->
<search-gtin-results [searchGtinResults]="gtin" *ngIf="getGtinCount()>0"></search-gtin-results>
