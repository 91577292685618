import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Gtin, NutrientValuesDetail } from 'src/app/Models/Gtin';
@Component({
  selector: 'search-gtin-PNMS-results',
  templateUrl: './search-pnms-results.html',
  styleUrls: ['./search-pnms-results.css']
})
export class SearchPNMSGtinResults implements OnInit, OnChanges {
  @Input() searchGtinResults!: Gtin;
  @Input() pageNumber!: number;
  @Input() shwoImage!: boolean;
  @Output() updateOCRingrediants = new EventEmitter<string>();
  @Output() updateOCRAllergen = new EventEmitter<string>();



  numofTables!: number;
  nutrtionDetails!: string;
  allergianDetails!: string;
  ingrediantDetails!: string;
  nutrionDetailsArray: string[] = [];
  allergionDetailsArray: string[] = [];
  ingrediantDetailsArray: string[] = [];
  includeNutrientIds = [200, 201];
  id: number = 1;
  @ViewChild('tableid') inputitemgtincode!: ElementRef;
  constructor() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['pageNumber']) {
      this.id = changes['pageNumber'].currentValue;
    }
  }
  copyIngredientsToOCR(componentId: string, type: string) {
    if (this.searchGtinResults.status.toLowerCase() == "draft") {
      if (type === 'ingrediants')
        this.updateOCRingrediants.emit(componentId);
      if (type === 'allergen')
        this.updateOCRAllergen.emit(componentId);
    }
  }
  ngOnInit(): void {
  }
  getDualDisplayValue(value: string) {
    if (value == undefined) {
      return "";
    }
    if (value.trim() == "") {
      return "";
    }
    if (value != null) {
      var returnValue: string = value;

      return Number(returnValue);
    }
    return "";
  }
  getDecimalValueFromString(input: string): number | null {
    const regex = /(\d+(\.\d+)?)/; // Regular expression to match decimal values

    const matches = input.match(regex); // Get matches from the input string

    if (matches && matches.length > 0) {
      const decimalValue = parseFloat(matches[0]); // Parse the matched value as a float
      return decimalValue;
    }

    return null; // Return null if no decimal value is found
  }
  addPNMSAttributesIfNotAvailable() {

    for (let i = 0; i < this.searchGtinResults.components.length; i++) {
      const component = this.searchGtinResults.components[i];
      let hasNutrient200 = false;
      let hasNutrient201 = false;
      for (let j = 0; j < component.nutrientValuesDetails.length; j++) {
        if (component.nutrientValuesDetails[j].nutrientID === 200) {
          hasNutrient200 = true;
          break;
        }
      }
      for (let k = 0; k < component.nutrientValuesDetails.length; k++) {
        if (component.nutrientValuesDetails[k].displayValue != undefined) {
          if (component.nutrientValuesDetails[k].displayValue.startsWith("less") || component.nutrientValuesDetails[k].displayValue.startsWith("Less")) {
            if (!component.nutrientValuesDetails[k].nutrValue.startsWith("<")) {
              component.nutrientValuesDetails[k].nutrValue = "<" + this.getDecimalValueFromString(component.nutrientValuesDetails[k].displayValue);
            }
          }
        }
        if (component.nutrientValuesDetails[k].duaL_DisplayValue != undefined) {
          if (component.nutrientValuesDetails[k].duaL_DisplayValue.startsWith("less") || component.nutrientValuesDetails[k].duaL_DisplayValue.startsWith("Less")) {
            if (!component.nutrientValuesDetails[k].dualNutrValue.startsWith("<")) {
              component.nutrientValuesDetails[k].dualNutrValue = "<" + this.getDecimalValueFromString(component.nutrientValuesDetails[k].duaL_DisplayValue);
            }
          }
        }

        if (component.nutrientValuesDetails[k].nutrientID === 201) {
          hasNutrient201 = true;
          break;
        }
      }
      for (let k = 0; k < component.nutrientValuesDetails.length; k++) {
        if (component.nutrientValuesDetails[k].nutrientID === 201) {
          hasNutrient201 = true;
          break;
        }
      }

      if (!hasNutrient200) {
        const newNutrientValueDetail: NutrientValuesDetail = {
          nutrientID: 200,
          nutrientName: "Whole Grain",
          effectiveDt: "",
          terminationDt: "",
          nutrValue: "",
          dvp: "",
          displayValue: "",
          dualNutrValue: "",
          dualDVP: "",
          duaL_DisplayValue: "",
          uom: "",
          dualuom: "",
        };
        component.nutrientValuesDetails.push(newNutrientValueDetail);
      }
      if (!hasNutrient201) {
        const newNutrientValueDetail: NutrientValuesDetail = {
          nutrientID: 201,
          nutrientName: "Juice",
          effectiveDt: "",
          terminationDt: "",
          nutrValue: "",
          dvp: "",
          displayValue: "",
          dualNutrValue: "",
          dualDVP: "",
          duaL_DisplayValue: "",
          uom: "",
          dualuom: "",
        };
        component.nutrientValuesDetails.push(newNutrientValueDetail);
      }
    }
  }

  GetNutrientsToShow(id: number) {
    this.addPNMSAttributesIfNotAvailable();
    return this.searchGtinResults.components[id - 1].nutrientValuesDetails.filter(n => this.includeNutrientIds.includes(n.nutrientID));
  }
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  ShowAndHide(id: number) {
    this.id = id;
  }
}
