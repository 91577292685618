import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gtin } from 'src/app/Models/Gtin';
import { Item } from 'src/app/Models/item';

@Component({
    selector: 'search-item-results',
    templateUrl: './search-item-results-component.html',
    styleUrls: ['./search-item-results-component.css']
})
export class SearchItemResults implements OnInit {
    @Input() searchItemResults!: Item[];
    @Output() gtinCode = new EventEmitter<number>();

    constructor() {
    }
    ngOnInit(): void {
    }
    getGtinDetails(item: Item) {
        this.gtinCode.emit(item.gtin);
    }
}
