//import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
//import { RestApiService } from '../services/RestApiService.service';
//import { Gtin, GTINConverter, GtinResult, GTINHelper } from '../Models/Gtin';
//import { Item } from '../Models/item';
//import { NgForm } from '@angular/forms';
//import { ScanBarcodeResult } from '../scan-barcode/models/scanBarCodeResult';
//import Quagga from '@ericblade/quagga2';
//import { EventEmitterService } from '../event-emitter.service';
//import { DataService } from '../services/DataService';
//import { ActivatedRoute, Router } from '@angular/router';
//import { bookIcon } from '@progress/kendo-svg-icons';
//import { Subscription } from 'rxjs';

//@Component({
//  selector: 'app-test-item',
//  templateUrl: './test.image.component.html'
//})
//export class TestImage implements OnInit {
//    ngOnInit(): void {
//        throw new Error('Method not implemented.');
//    }
//}



import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Subscription, async, of } from 'rxjs';
import { RestApiService } from '../services/RestApiService.service';
import { ReadImageFromBlobByGtinResponse } from '../Models/ProcessOcrData';

@Component({
  selector: 'app-test-item',
  templateUrl: './test.image.component.html'
})

export class TestImage implements OnInit, OnDestroy {
  public gtin!: string;
  public items: String[] = [];
  constructor(private restApiService: RestApiService) { }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  getimage() {
    this.items.push('noimage');
    this.restApiService.readImageFromBlobByGtin(this.gtin, 'draft').subscribe((data: ReadImageFromBlobByGtinResponse) => {
      var responseData = data;
      responseData.imageData.forEach(value => {
        this.items.push(value);
      });
    });
  }

  ngOnInit(): void {
    //this.items.push('noimage');
    //this.restApiService.readImageFromBlobByGtin('7114600437', 'draft').subscribe((data: ReadImageFromBlobByGtinResponse) => {
    //  var responseData = data;
    //  responseData.imageData.forEach(value => {
    //    this.items.push(value);
    //  });
    //});
  }
}

