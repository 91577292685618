import { Component, EventEmitter, Input, Output, OnInit, HostListener } from '@angular/core';
import { bookIcon } from '@progress/kendo-svg-icons';
import { Component as GtinComponent, NutrientsDto, NutrientValuesDetail } from 'src/app/Models/Gtin';
import { Nutrient, OcrNutrient, OcrNutritionFacts } from 'src/app/Models/OcrNutritionFacts';
import { TitleCasePipe } from '@angular/common';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestApiService } from 'src/app/services/RestApiService.service';
import { forEach } from 'lodash';
///

const highlightedNutrients: string[] = ['Total Fat', 'Cholesterol', 'Sodium', 'Total Carbohydrate', 'Protein'];
const ignoreNutrients: string[] = ['Calories'];
const LESSTHANTEXT = 'Less than';


@Component({
  selector: 'app-image-ocr-nutrition-fact',
  templateUrl: './nutrition-fact.component.html',
  styleUrls: ['./nutrition-fact.component.scss'],
  providers: [TitleCasePipe]
})
export class ImageOcrNutritionFactComponent implements OnInit {
  // @Input() ocrNutritionFacts!: OcrNutritionFacts;
  treeNuts = ['tree nuts', 'tree nut', 'almond', 'artificial nuts', 'beechnut', 'brazil nut', 'butternut', 'cashew', 'chestnut', 'chinquapin nut', 'coconut', 'filbert', 'hazelnut', 'gianduja', 'ginkgo nut', 'hickory nut', 'litchi', 'lichee', 'lychee nut', 'macadamia nut', 'marzipan', 'almond paste', 'Nangai nut', 'natural nut extract', 'nut butters', 'nut meal', 'nut meat', 'nut paste', 'nut pieces', 'pecan', 'pesto', 'pili nut', 'pine nut', 'pistachio', 'praline', 'shea nut', 'walnut'];
  @Input() ocrNutritionFacts!: OcrNutritionFacts;
  @Input() nutrientTypes!: any;
  @Input() ocrRawResponse!: string;
  showServingEditor = false;
  isPad = window.innerWidth < window.innerHeight;
  @Output() nutritionFactChanged = new EventEmitter();

  @Output() isIpadVertical = new EventEmitter();

  showNewRow = false;
  //nutrientTypes: any = [{ nutrientId: 1, nutrientName : 'Calories' }, { nutrientId: 2, nutrientName : 'Sodium' }];

  nutritionForm: any;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isPad = window.innerWidth < window.innerHeight;
    if (this.isPad) {
      this.isIpadVertical.emit(true);
    }
    else {
      this.isIpadVertical.emit(false);
    }
  }

  constructor(private titlecasePipe: TitleCasePipe, private restApiService: RestApiService) {
    this.createForm();
  }
  createForm() {
    this.nutritionForm = new FormGroup({
      newNutritionItem: new FormArray([

      ])
    });
  }

  ngOnInit(): void {

    setInterval(() => {
      this.isPad = window.innerWidth < window.innerHeight;

      if (this.isPad) {
        this.isIpadVertical.emit(true);
      }
      else {
        this.isIpadVertical.emit(false);
      }

    }, 1000);

    //this.getNutrients();
    if (this.ocrNutritionFacts.allergyInformation) {
      var finalAllergens = '';

      if (this.ocrNutritionFacts.allergyInformation != null && (
        this.ocrNutritionFacts.allergyInformation.indexOf(',') > 0 || this.ocrNutritionFacts.allergyInformation != "")) {
        var allergens = this.ocrNutritionFacts.allergyInformation.split(',');
        if (allergens) {
          var istreenuts = false;
          for (let i = 0; i < allergens.length; i++) {
            // Loop for array2
            var alergen = allergens[i].replace('.', '').trim().toLowerCase();
            var exists = this.treeNuts.some(element => {
              return element.toLowerCase() === alergen;
            });

            if (exists) {
              istreenuts = true;
            }
            else {
              finalAllergens += this.titlecasePipe.transform(allergens[i].trim()) + (i == allergens.length - 1 ? "" : ",");
            }
          }
          if (finalAllergens != '') {
            finalAllergens = (finalAllergens.substring(0, finalAllergens.length - 1)) + (istreenuts ? ",Tree Nuts" : "");
          }
          else if (istreenuts) {
            finalAllergens = "Tree Nuts";
          }

          this.ocrNutritionFacts.allergyInformation = finalAllergens;
        }
      }
    }
  }



  addNewRow(panelsize: number) {

    const newRow = <FormArray>this.nutritionForm.controls['newNutritionItem'];

    const hasNullOrSelect = newRow.controls.some((control) => {
      const factValue = control.get('fact')?.value;
      return factValue === null || factValue.toLowerCase() === '0' || factValue.toLowerCase() === '';
    });

    if (!hasNullOrSelect) {
      if (panelsize == 2) {
        newRow.push(new FormGroup({
          fact: new FormControl("0", Validators.required),
          value: new FormControl("0", Validators.required),
          dv: new FormControl("0", Validators.required),
          value2: new FormControl("0", Validators.required),
          dv2: new FormControl("0", Validators.required),
        }));
      }
      else {
        newRow.push(new FormGroup({
          fact: new FormControl("0", Validators.required),
          value: new FormControl("0", Validators.required),
          dv: new FormControl("0", Validators.required)
        }));
      }
    }
  }

  isHighlightedNutrient(nutrient: OcrNutrient) {
    return highlightedNutrients.includes(nutrient.fact);
  }

  removeLastComma(value: string) {
    const lastIndex = value.lastIndexOf(',');

    if (lastIndex !== -1) {
      return value.slice(0, lastIndex) + value.slice(lastIndex + 1);
    }

    return value;
  }

  getMiscellaneousData() {
    return this.ocrNutritionFacts.arrayoffacts.filter(n => n.fact === 'Calories');
  }

  GetNutrientsToShow() {

    if (Array.isArray(this.nutrientTypes)) {
      this.ocrNutritionFacts.arrayoffacts.forEach(x => {
        this.nutrientTypes = this.nutrientTypes.filter((item: { nutrientName: string; }) => {
          return item.nutrientName !== x.fact;
        });
      });
      //} else {
      //  // Handle the case where this.nutrientTypes is undefined or not an array
      //  console.error('this.nutrientTypes is not defined or not an array.');
      //}


      //this.ocrNutritionFacts.arrayoffacts.forEach(x => {
      //  this.nutrientTypes = this.nutrientTypes.filter((item: { nutrientName: string; }) => {
      //    return item.nutrientName !== x.fact;
      //  });
      //});
    }
    return this.ocrNutritionFacts.arrayoffacts.filter(n => !ignoreNutrients.includes(n.fact));
  }





  getDualDisplayValue(value: string) {
    var returnValue: string = value;
    if (value.startsWith(LESSTHANTEXT)) {
      returnValue = '<' + value.replace(LESSTHANTEXT, '').trim();
    }
    return returnValue;

  }

  onFieldChanged() {
    //  console.log("Field changed")
    this.nutritionFactChanged.emit();
  }

  addnewItem() {
    console.log(this.nutritionForm.controls['newNutritionItem'].value);
    var newNutritionItemsAdded = this.nutritionForm.controls['newNutritionItem'].value;
    if (newNutritionItemsAdded && newNutritionItemsAdded != null) {
      this.ocrNutritionFacts.arrayoffacts = this.ocrNutritionFacts.arrayoffacts.filter(f => !f.isnew);
      for (var i = 0; i < newNutritionItemsAdded.length; i++) {
        var item = newNutritionItemsAdded[i];
        if (item.fact != '' && item.value != '' && item.dv != '') {
          if (this.ocrNutritionFacts.panelSize == 1 || (this.ocrNutritionFacts.panelSize == 2 && item.value2 != '' && item.dv2 != '')) {
            item.newItem = true;
            this.ocrNutritionFacts.arrayoffacts.push(item);

            this.nutritionForm.controls['newNutritionItem'].controls = [];
            this.nutritionForm.controls['newNutritionItem'].value = [];

          }
        }
      }
    }
  }
}
