import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  isOpen: boolean = false;
  @Output() newItemEvent = new EventEmitter<boolean>();
  constructor() {
  }

  show() {
    this.isOpen = true;
  }

  hide(valid =false) {
    this.isOpen = false;
    if(valid)
    { 
      this.newItemEvent.emit(true);
    }
   
  }
}
