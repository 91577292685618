import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Roles } from '../shared/enum/roles';

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[];
  };
}
@Injectable({
  providedIn: 'root',
})
export class PrivilegeService {
  public roleExists = false;
  public hasRwAccess = false;
  readWriteAccess = new BehaviorSubject<boolean>(false);
  canEdit = this.readWriteAccess.asObservable();

  constructor(private authService: MsalService) {}

  HasEditAccess(): Observable<boolean> {
    const account: Account = this.authService.instance.getAllAccounts()[0];
    if (account && account.idTokenClaims && account.idTokenClaims.roles) {
      const roleExists = this.rwRoleExists(account?.idTokenClaims?.roles);
      return of(roleExists);
    }
    return of(this.roleExists);
  }
  private rwRoleExists(roles: string[] | undefined): boolean {
    _.forEach(roles, (value: any) => {
      if (value === Roles.CATEGORY_MANAGER || value === Roles.CORP_SUPPORT) {
        this.hasRwAccess = true;
      }
    });
    this.readWriteAccess.next(this.hasRwAccess);
    return this.hasRwAccess;
  }
}
