import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Subscription, async, of } from 'rxjs';
import { RestApiService } from '../../services/RestApiService.service';
import { ReadImageFromBlobByGtinResponse } from '../../Models/ProcessOcrData';

@Component({
  selector: 'app-home-test',
  templateUrl: './test.component.html',
})

export class TestComponent implements OnInit, OnDestroy {

  public items: String[] = [];
  constructor(private restApiService: RestApiService) { }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    this.items.push('noimage');
    this.restApiService.readImageFromBlobByGtin('7114600437', 'draft').subscribe((data: ReadImageFromBlobByGtinResponse) => {
        var responseData = data;
        responseData.imageData.forEach(value => {
          this.items.push(value);
        });
      });
    }
  }

