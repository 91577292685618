import { AfterViewInit, OnInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { BeepService } from '../../scan-barcode/beep.service';
import Quagga from '@ericblade/quagga2';
import { Article } from '../../scan-barcode/article';
import { ShoppingCart } from '../../scan-barcode/shopping-cart';
import { environment } from '../../../environments/environment';
import { getMainBarcodeScanningCamera } from '../../services/scanner.service';
import { RestApiService } from '../../services/RestApiService.service';
import { GTINConverter, Gtin } from '../../Models/Gtin';
import { ScanBarcodeResult } from '../../scan-barcode/models/scanBarCodeResult';
import { ModalComponent } from 'src/app/shared/modal.component';
import { anyChanged } from '@progress/kendo-angular-common';


@Component({
  selector: 'app-capture-scan-barcode',
  templateUrl: './capture-scan-barcode.component.html',
  styleUrls: ['./capture-scan-barcode.component.css']
})
export class CaptureScanBarcodeComponent implements AfterViewInit {

  @Input() skipscanningGtinStep = true;
  started: boolean | undefined;
  errorMessage: string | undefined;
  scannedErrorMessage: string | undefined;
  acceptAnyCode = true;
  items: [Article, number][] = [];
  loadingstatus:boolean =  false;
  totalPrice: number = 0;
  arr: number[] = [];
  openPopUp: boolean = false;
  gtin!: Gtin;
  @Input() inputGtinText: number = -1;
  enteredGtinText: string = '';
  gtinFlow: "Manual" | "Scan" | undefined;

  @Output() scanBarcodeChanged = new EventEmitter<ScanBarcodeResult>();

  @Output()
  compareItems = new EventEmitter<number>();
  disableCamera: boolean = false;

  @ViewChild("confirmationModalComponent") confirmationModal !: ModalComponent;
  @ViewChild("scanCodeModalComponent") scanCodeModalComponent !: ModalComponent;

  // private catalogue: Article[] = [
  //   { name: 'Classy Crab (red)', ean: '7601234567890', image: 'assets/classy_crab_red.png', price: 10 },
  //   { name: 'Classy Crab (blue)', ean: '7601234561232', image: 'assets/classy_crab_blue.png', price: 10 },
  //   { name: 'Classy Crab (gold, ltd. ed.)', ean: '7601234564561', image: 'assets/classy_crab_gold.png', price: 50 }
  // ];

  private shoppingCart: ShoppingCart;
  private lastScannedCode: string | undefined;
  private lastScannedCodeDate: number | undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private beepService: BeepService,
    private restApiService: RestApiService,
    private GtinConverter: GTINConverter) {
    this.shoppingCart = new ShoppingCart();

  }

  ngOnInit() {

    this.restApiService.quaggaStop();
    if (this.skipscanningGtinStep && this.inputGtinText > 0) {
      this.enteredGtinText = this.inputGtinText.toString();
      this.onGtinEntered();
    }
  }

  ngAfterViewInit(): void {

    //this.onBarcodeScanned("test")


    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
      this.errorMessage = 'getUserMedia is not supported';
      return;
    }

   this.initializeScanner();
    // testing
    //7114600437
    //this.onBarcodeScanned("0044000027299");
    //this.onBarcodeScanned("0028000846794");
    //this.onBarcodeScanned("0041271025910");
    //0074786000011
    //this.onBarcodeScanned("008259263247");
    //this.onBarcodeScanned("007214600477");
    //this.onBarcodeScanned("0722776003695"); //variety
    //this.onBarcodeScanned("004141517962"); //Single
    //this.onBarcodeScanned("0071146004375"); 
    //this.onBarcodeScanned("0038900740702"); 
     
    //if (environment.production) {
    //  setTimeout(() => {
    //    this.updateService.checkForUpdates();
    //  }, 10000);
    //}
  }

  useScanBarcode() {
    this.gtinFlow = 'Scan';
    this.enteredGtinText = '';
    this.reScan();
  }

  private initializeScanner(): Promise<void> {
    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
      this.errorMessage = 'getUserMedia is not supported. Please use Chrome on Android or Safari on iOS';
      this.started = false;
      return Promise.reject(this.errorMessage);
    }

    // enumerate devices and do some heuristics to find a suitable first camera
    return Quagga.CameraAccess.enumerateVideoDevices()
      .then((mediaDeviceInfos) => {
        const mainCamera = getMainBarcodeScanningCamera(mediaDeviceInfos);
        if (mainCamera) {
          console.log(`Using ${mainCamera.label} (${mainCamera.deviceId}) as initial camera`);
          return this.initializeScannerWithDevice(mainCamera.deviceId);
        } else {

          console.error(`Unable to determine suitable camera, will fall back to default handling`);
          return this.initializeScannerWithDevice(undefined);
        }
      })
      .catch(error => {
        this.errorMessage = `Failed to enumerate devices: ${error}`;
        this.started = false;
      });
  }

  private initializeScannerWithDevice(preferredDeviceId: string | undefined): Promise<void> {
    console.log(`Initializing Quagga scanner...`);

    const constraints: MediaTrackConstraints = {};
    if (preferredDeviceId) {
      // if we have a specific device, we select that
      constraints.deviceId = preferredDeviceId;
    } else {
      // otherwise we tell the browser we want a camera facing backwards (note that browser does not always care about this)
      constraints.facingMode = 'environment';
    }

    return Quagga.init({
      inputStream: {
        type: 'LiveStream',
        constraints,
        area: { // defines rectangle of the detection/localization area
          top: '25%',    // top offset
          right: '10%',  // right offset
          left: '10%',   // left offset
          bottom: '25%'  // bottom offset
        },
        target: document.querySelector('#scanner-container') ?? undefined
      },
      decoder: {
        readers: ['ean_reader'],
        multiple: false
      },
      // See: https://github.com/ericblade/quagga2/blob/master/README.md#locate
      locate: false
    },
      (err) => {
        if (err) {
          console.error(`Quagga initialization failed: ${err}`);
          this.errorMessage = `Initialization error: ${err}`;
          this.started = false;
        } else {
          console.log(`Quagga initialization succeeded`);
          Quagga.start();
          this.started = true;
          this.changeDetectorRef.detectChanges();
          Quagga.onDetected((res) => {
            if (res.codeResult.code) {
              this.gtinFlow = 'Scan';
              this.onBarcodeScanned(res.codeResult.code);
            }
          });
        }
      });
  }
  onGtinEntered() {
    this.scannedErrorMessage = undefined;
    this.gtinFlow = "Manual";
    this.loadingstatus = true;
    this.validateAndGetGtin(this.enteredGtinText);
  }

  onBarcodeScanned(code: string) {
    this.scannedErrorMessage = undefined;
    this.gtinFlow = 'Scan';
    // console.log("onBarcodeScanned called");

    // remove this - Comment test code
    // this.scanBarcodeChanged.emit({ code: 7114600437, isSuccess: true });
    code = code.slice(1, code.length).toString();

    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();
    if (code === this.lastScannedCode
      && ((this.lastScannedCodeDate !== undefined) && (now < this.lastScannedCodeDate + 1500))) {
      return;
    }

    this.arr.push(parseInt(code));

    var biggest = -1, number;
    for (var key in this.arr) {
      if (this.arr[key] > biggest) {
        biggest = this.arr[key];
        number = key;
      }
    }

    if (code === number) {
      return;
    }
    if (this.validateCheckDigit(code.toString())) {
      const numericalCode = Number(code.slice(0, -1));
      // remove
      // const numericalCode = '711460043798989';
      // add this - Uncomment
      // this.scanBarcodeChanged.emit({ code: numericalCode, isSuccess: true });
      this.lastScannedCode = numericalCode.toString();
      this.lastScannedCodeDate = now;

      const gtinCode = Number(numericalCode);

      this.validateAndGetGtin(this.lastScannedCode);

      this.restApiService.checkGtinExists(this.lastScannedCode).subscribe(result => {
        if (result) {
          this.confirmationModal.show();
        }
        else {
          this.getGTINDetails(gtinCode);
        }
      });

      Quagga.stop();
    }
    //testing remove this line
    //this.scannedErrorMessage = "Some error happened while scanning"
    //this.scanBarcodeChanged.emit({ code: -1, isSuccess: false, errorMessage: 'Some error happened while scanning' });

    //this.getGTINDetails(7114980);
  }

  private validateAndGetGtin(gtinCode: string) {
    this.restApiService.checkGtinExists(gtinCode).subscribe(result => {
      if (result) {
        this.loadingstatus = false;
        this.confirmationModal.show();
      }
      else {
        this.getGTINDetails(Number(gtinCode));
      }
    });
  }

  private getGTINDetails(gtinCode: number) {
    const gtinCodeStr = gtinCode.toString(); // Convert to string
    if (gtinCodeStr.length < 6) {
      //const paddedGtinCode = gtinCodeStr.padStart(6, '0');
      //gtinCode = +paddedGtinCode;

      const result = this.GtinConverter.convertUpcToGtin(gtinCodeStr.padStart(6, '0'));
      gtinCode = +result.slice(0, -1);
      // Now you can use paddedGtinCode as a string with leading zeros
    }

    if (gtinCodeStr.length == 6) {
      const result = this.GtinConverter.convertUpcToGtin(gtinCodeStr.padStart(6, '0'));
      gtinCode = +result.slice(0, -1);
      //alert(this.itemgtincode)
    }

    this.restApiService.getGtinBasicInformtaion(gtinCode).subscribe((data: any) => {
      var responseData = data.length == undefined ? JSON.stringify(data.value.replace("\"", "")) : JSON.stringify(data);
      // alert(JSON.stringify(data));
      this.openPopUp = true;
      this.loadingstatus = false;
      if (!responseData || responseData == JSON.stringify('No Results Found')) {
        // check gtin Exists in SCAN_CODE table or not
        this.restApiService.checkGtinExistsInScanCode(gtinCode).subscribe((data: any) => {
          var responseData = JSON.parse(JSON.stringify(data));
            if (responseData.isGtinExists) {
              //exists 
              this.scanCodeModalComponent.show();
            }
            else {              
              this.scannedErrorMessage = "GTIN is not found in PNMS"; //"GTIN is not exists in PNMS"
              this.disableCamera = true;
              this.scanBarcodeChanged.emit({ code: 0, isSuccess: false, errorMessage: this.scannedErrorMessage, showAddPnsButton: false });
            }
          });
      }
      else {
        const ocrDataResult = { data: JSON.parse(responseData) };
        // console.log("ocrData", ocrDataResult);
        this.scanBarcodeChanged.emit({ code: gtinCode, isSuccess: true, errorMessage: '', ocrDataResult, showAddPnsButton: false });
      }
    });
  }

  private checkGtinExistsInScanCode(gtinCode: number): boolean {
    var isExists = false;
    
      return isExists;
}

  private createUnknownArticle(code: string): Article {
    return {
      ean: code,
      name: `Code ${code}`,
      image: 'assets/classy_crab_unknown.png',
      price: 42
    }
  }
  private validateCheckDigit(barcode: string): boolean {

    //remove this 
    //  return true;
    // Here you can implement the algorithm of your choice to validate the check digit.
    // for example: 
    const checkDigit = Number(barcode[barcode.length - 1]);
    const barcodeWithoutCheckDigit = barcode.slice(0, -1);
    const sum = barcodeWithoutCheckDigit
      .split('')
      .map((char, index) => Number(char) * (index % 2 === 0 ? 3 : 1))
      .reduce((a, b) => a + b);
    const remainder = sum % 10;
    const calculatedCheckDigit = remainder === 0 ? 0 : 10 - remainder;
    return checkDigit === calculatedCheckDigit;
  }

  getGtinCount(): number {
    if (this.gtin != null) {
      return this.gtin.components == undefined ? 0 : this.gtin.components.length;
    }
    else {
      return 0;
    }
  }

  reScan() {
    //console.log("rescan called");
    this.scannedErrorMessage = undefined;
    this.disableCamera = false;
    this.initializeScanner();
  }

  onUserDialogClose(newItem:boolean){
    if(newItem) this.reScan();
  }

  onUserSelectionNo() { 
    this.confirmationModal.hide();
    const gtinCode = this.getGtinCodeToConsider();
    this.compareItems.emit(gtinCode);
  }

  onUserSelectionYes() {
    this.confirmationModal.hide();
    const gtinCode = this.getGtinCodeToConsider();
    this.deleteOcrData(gtinCode.toString());

    this.getGTINDetails(gtinCode);
  }

  onScanCodeGtinYes() {
    this.scanCodeModalComponent.hide();
    const gtinCode = this.getGtinCodeToConsider();
    //take to capture screen sending a flag to show add
    this.scanBarcodeChanged.emit({ code: gtinCode, isSuccess: true, errorMessage: '', showAddPnsButton: true });
  }

  onScanCodeGtinNo() {
    this.scanCodeModalComponent.hide();
    //take to scanbar code
   // this.scanBarcodeChanged.emit({ code: 0, isSuccess: true, errorMessage: '', showAddPnsButton: false });
  }

  private getGtinCodeToConsider() {
    return this.gtinFlow == "Manual" ? Number(this.enteredGtinText) : Number(this.lastScannedCode);
  }

  //delete ocr data
  deleteOcrData(gtin: string) {
    this.restApiService.deleteOcrData(gtin).subscribe((data: any) => {
    });
  }
}
