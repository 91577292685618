<div *ngIf="displayLoadingIcon" class="spinner-border" role="status"
     style="position:absolute;top:50%;left:50%; height: 50px; width: 50px;">
  <span class="sr-only">Loading...</span>
</div>
<br />
<div *ngIf="!displayLoadingIcon" class="container homestyle" style="width: 100%;max-width: 1200px;">
  <div class="box1">
    <kendo-expansionpanel #panel title="" style="background-color: #E6F3E1;" id="searchFilter" subtitle="Search/Filters">

      <div class="box" *ngIf="!isIpadVertical">

        <!--<button class="btn btn-secondary buttonsuccess" style="margin-left:18px" (click)="getDataOnClickingSearchButton()">Search</button>
  <label style="margin-left: 20px;"><b>Report Name</b></label>
  <input type="text" placeholder="Report Name" [(ngModel)]="reportName" style="margin-left:12px" />
  <button class="btn btn-secondary buttonsuccess" style="margin-left: 18px;" (click)="generateAuditReport()" [disabled]="!reportName || filterAuditReort.length === 0">Generate Report</button>-->
        <div style="background-color: #BEDDAB">
          <div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border: 1px" class="row" *ngIf="showDatePanel==true">
            <div class="col-sm-3">
              <b>Start Date</b>
              <!-- <div class="col-sm-2">-->

              <input type="date" *ngIf="!isDateGreaterThanToday(fromDate)"
                     [(ngModel)]="fromDate"
                     style="margin-left:9px"
                     name="fromDatePicker"
                     [max]="toDate || getMaxDate()"
                     (ngModelChange)="updateToDateMinDate()"
                     [readonly]="isDateGreaterThanToday(fromDate)"
                     value="{{ fromDate | date:'yyyy-MM-dd' }}" />

            </div>
            <div class="col-sm-3">
              <b> End Date</b>
              <!-- <div class="col-sm-2">-->
              <!--<input type="date"
        name="toDatePicker"
        [max]="getMaxDate()"
        style="margin-left:9px"
        [(ngModel)]="toDate"
        [min]="fromDate || getMinDate()"
        (ngModelChange)="updateFromDateMaxDate()"
        [disabled]="isDateGreaterThanToday(toDate)"
        value="{{ toDate | date:'yyyy-MM-dd' }}" />-->

              <input type="date"
                     name="toDatePicker"
                     [max]="getMaxDate()"
                     style="margin-left:9px"
                     [(ngModel)]="toDate"
                     [min]="fromDate || getMinDate()"
                     (ngModelChange)="onToDateChange($event)"
                     [disabled]="isDateGreaterThanToday(toDate)"
                     value="{{ toDate | date:'yyyy-MM-dd' }}" />

            </div>
            <div class="col-sm-3">
              <label for="storeNumber" style="margin-right:0px"><b>Store No:</b></label>
              <input type="text" id="storeNumber" [(ngModel)]="storeNumber" />
            </div>
            <div class="col-sm-3">
              <button class="btn btn-secondary buttonsuccess" style="margin-right:5px;" (click)="getDataOnClickingSearchButton()">Search</button>
            </div>
          </div>

          <!--new controls-->
          <div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border: 1px" class="row" *ngIf="showDatePanel==true">
            <div class="col-sm-3 homestyleelement">
              <label for="FamilyGroupID" style="margin-right:19px"><b>FG Code:</b></label>

              <input type="text" id="FamilygrID" [(ngModel)]="familyGroupID" (input)="selectFgNameOrFGId()" />
            </div>
            <div class="col-sm-3 homestyleelement">
              <label for="FamilyGroupName" style="margin-right:13px"><b>FG Name:</b></label>

              <input type="text" id="FamilygrName" [(ngModel)]="familyGroupName" (input)="selectFgNameOrFGId()" />
            </div>
            <div class="col-sm-3">
            </div>
            <div class="col-sm-3">
            </div>
          </div>
        </div>

        <div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border=1" class="row homestylerow">
          <div class="col-sm-3 homestyleelement">
            <b style="padding-right: 3px;">FG Include</b>
            <button class="button1" [ngClass]="{ 'selected': familyGroupIncludeOption === 'Y' }"
                    (click)="selectFamilyGroupIncludeOption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': familyGroupIncludeOption === 'N' }"
                    (click)="selectFamilyGroupIncludeOption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': familyGroupIncludeOption === 'YN' }"
                    (click)="selectFamilyGroupIncludeOption('YN')">
              Both
            </button>
          </div>
          <!--<div class="col-sm-1"></div>-->
          <div class="col-sm-3" style="padding-right: 5px">
            <b style="padding-right: 3px">Organic 1</b>
            <button class="button1" [ngClass]="{ 'selected': organic1option === 'Y' }"
                    (click)="selectorganic1option('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': organic1option === 'N' }"
                    (click)="selectorganic1option('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': organic1option === 'YN' }"
                    (click)="selectorganic1option('YN')">
              Both
            </button>
          </div>
          <!--<div class="col-sm-1"></div>-->
          <div class="col-sm-3 homestyleelement">
            <b style="padding-right: 3px">Gluten Free</b>
            <button class="button1" [ngClass]="{ 'selected': glutenFreeoption === 'Y' }"
                    (click)="selectGlutenFreeoption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': glutenFreeoption === 'N' }"
                    (click)="selectGlutenFreeoption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': glutenFreeoption === 'YN' }"
                    (click)="selectGlutenFreeoption('YN')">
              Both
            </button>

          </div>
          <div class="col-sm-3"></div>
          <!--<div class="col-sm-1"></div>-->
        </div>

        <div style="align-items:center ; display: flex; flex-direction: row; margin: 10px;" class="row homestylerow">

          <div class="col-sm-3 homestyleelement">
            <b style="padding-right: 22px">Natural</b>
            <button class="button1" [ngClass]="{ 'selected': naturalOption === 'Y' }"
                    (click)="selectNaturalOption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': naturalOption === 'N' }"
                    (click)="selectNaturalOption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': naturalOption === 'YN' }"
                    (click)="selectNaturalOption('YN')">
              Both
            </button>
          </div>
          <!--<div class="col-sm-1"></div>-->

          <div class="col-sm-3">
            <b style="padding-right: 3px">Organic 2</b>


            <button class="button1" [ngClass]="{ 'selected': organic2option === 'Y' }"
                    (click)="selectorganic2option('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': organic2option === 'N' }"
                    (click)="selectorganic2option('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': organic2option === 'YN' }"
                    (click)="selectorganic2option('YN')">
              Both
            </button>
          </div>
          <!--<div class="col-sm-1"></div>-->
          <div class="col-sm-6 homestyleelement">
            <!--<label for="FamilyGroupID" style="margin-right:19px"><b>FG Code:</b></label>

      <input type="text" id="FamilygrID" [(ngModel)]="familyGroupID" (input)="selectFgNameOrFGId()" />-->
            <!--<b style="padding-right: 3px">Organic 3</b>


      <button class="button1" [ngClass]="{ 'selected': organic3option === 'Y' }"
              (click)="selectorganic3option('Y')">
        Yes
      </button>
      <button class="button2" [ngClass]="{ 'selected': organic3option === 'N' }"
              (click)="selectorganic3option('N')">
        No
      </button>
      <button class="button3" [ngClass]="{ 'selected': organic3option === 'YN' }"
              (click)="selectorganic3option('YN')">
        Both
      </button>-->
          </div>
          <!--<div class="col-sm-1"></div>-->


        </div>


        <div style="align-items: center; display: flex; flex-direction: row; margin: 10px;" class="row homestylerow">
          <div class="col-sm-3 homestyleelement">
            <b style="padding-right: 22px">Exclude</b>

            <button class="button1" [ngClass]="{ 'selected': exludeOption === 'Y' }"
                    (click)="selectExludeOption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': exludeOption === 'N' }"
                    (click)="selectExludeOption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': exludeOption === 'YN' }"
                    (click)="selectExludeOption('YN')">
              Both
            </button>
          </div>
          <!--<div class="col-sm-1"></div>-->
          <div class="col-sm-3">
            <b style="padding-right: 3px">Organic 3</b>


            <button class="button1" [ngClass]="{ 'selected': organic3option === 'Y' }"
                    (click)="selectorganic3option('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': organic3option === 'N' }"
                    (click)="selectorganic3option('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': organic3option === 'YN' }"
                    (click)="selectorganic3option('YN')">
              Both
            </button>
          </div>
          <!--<div class="col-sm-1"></div>-->
          <div class="col-sm-3 homestyleelement" *ngIf="showDatePanel==true">

            <b>Report Name</b>
            <div>
              <input type="text" placeholder="Report Name" [(ngModel)]="reportName" />
            </div>



          </div>
          <div class="col-sm-3 homestyleelement" *ngIf="showDatePanel==true">
            <button class="btn btn-secondary buttonsuccess" style="margin-left: 10px; width: 175px;" (click)="generateAuditReport()" [disabled]="!reportName || filterAuditReort.length === 0">Generate Report</button>
          </div>

        </div>

        <!--<div style="align-items: center; display: flex; flex-direction: row; margin: 10px;" class="row" *ngIf="showDatePanel==true">
    <div class="col-sm-2"></div>
    <div class="col-sm-2" style="text-align:right;"><b>Report Name</b></div>
    <div class="col-sm-2">
      <input type="text" placeholder="Report Name" [(ngModel)]="reportName" />
    </div>
    <div class="col-sm-2" style="margin-left: 2%;">
      <button class="btn btn-secondary buttonsuccess" style="margin-left: 10px; width: 175px;" (click)="generateAuditReport()" [disabled]="!reportName || filterAuditReort.length === 0">Generate Report</button>
    </div>
    <div class="col-sm-2"></div>



  </div>-->



      </div>

      <div class="box" *ngIf="isIpadVertical">
        <div style="background-color: #BEDDAB">
          <div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border: 1px" class="row" *ngIf="showDatePanel==true">
            <div class="col-sm-6">
              <b>Start Date</b>
              <input type="date" *ngIf="!isDateGreaterThanToday(fromDate)"
                     [(ngModel)]="fromDate"
                     style="margin-left:9px"
                     name="fromDatePicker"
                     [max]="toDate || getMaxDate()"
                     (ngModelChange)="updateToDateMinDate()"
                     [readonly]="isDateGreaterThanToday(fromDate)"
                     value="{{ fromDate | date:'yyyy-MM-dd' }}" />
            </div>
            <div class="col-sm-6">
              <b> End Date</b>
              <input type="date"
                     name="toDatePicker"
                     [max]="getMaxDate()"
                     style="margin-left:9px"
                     [(ngModel)]="toDate"
                     [min]="fromDate || getMinDate()"
                     (ngModelChange)="onToDateChange($event)"
                     [disabled]="isDateGreaterThanToday(toDate)"
                     value="{{ toDate | date:'yyyy-MM-dd' }}" />

            </div>
          </div>

          <div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border: 1px" class="row" *ngIf="showDatePanel==true">
            <div class="col-sm-6">
              <label for="storeNumber" style="margin-right:0px"><b>Store No:</b></label>
              <input type="text" id="storeNumber" [(ngModel)]="storeNumber" />
            </div>
            <div class="col-sm-6">
              <button class="btn btn-secondary buttonsuccess" style="margin-right:5px;" (click)="getDataOnClickingSearchButton()">Search</button>
            </div>
          </div>
          <!--new controls-->
          <!--<div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border: 1px" class="row" *ngIf="showDatePanel==true">
        <div class="col-sm-3 homestyleelement" *ngIf="isIpadVertical">
          <label for="FamilyGroupID" style="margin-right:19px"><b>FG Code:</b></label>
          <input type="text" id="FamilygrID" [(ngModel)]="familyGroupID" (input)="selectFgNameOrFGId()" />
        </div>
        <div class="col-sm-3 homestyleelement">
          <label for="FamilyGroupName" style="margin-right:13px"><b>FG Name:</b></label>

          <input type="text" id="FamilygrName" [(ngModel)]="familyGroupName" (input)="selectFgNameOrFGId()" />
        </div>
        <div class="col-sm-3">
        </div>
        <div class="col-sm-3">
        </div>
      </div>-->
        </div>
        <div style="align-items: center; display: flex; flex-direction: row; margin: 10px; border=1" class="row homestylerow">
          <div class="col-sm-6 homestyleelement">
            <b style="padding-right: 15px;">FG Include</b>
            <button class="button1" [ngClass]="{ 'selected': familyGroupIncludeOption === 'Y' }"
                    (click)="selectFamilyGroupIncludeOption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': familyGroupIncludeOption === 'N' }"
                    (click)="selectFamilyGroupIncludeOption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': familyGroupIncludeOption === 'YN' }"
                    (click)="selectFamilyGroupIncludeOption('YN')">
              Both
            </button>
          </div>
          <div class="col-sm-6" style="padding-right: 5px">
            <b style="padding-right: 3px">Organic 1</b>
            <button class="button1" [ngClass]="{ 'selected': organic1option === 'Y' }"
                    (click)="selectorganic1option('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': organic1option === 'N' }"
                    (click)="selectorganic1option('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': organic1option === 'YN' }"
                    (click)="selectorganic1option('YN')">
              Both
            </button>
          </div>
        </div>
        <div style="align-items:center ; display: flex; flex-direction: row; margin: 10px;" class="row homestylerow">
          <div class="col-sm-6 homestyleelement">
            <b style="padding-right: 35px">Natural</b>
            <button class="button1" [ngClass]="{ 'selected': naturalOption === 'Y' }"
                    (click)="selectNaturalOption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': naturalOption === 'N' }"
                    (click)="selectNaturalOption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': naturalOption === 'YN' }"
                    (click)="selectNaturalOption('YN')">
              Both
            </button>
          </div>
          <div class="col-sm-6">
            <b style="padding-right: 3px">Organic 2</b>
            <button class="button1" [ngClass]="{ 'selected': organic2option === 'Y' }"
                    (click)="selectorganic2option('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': organic2option === 'N' }"
                    (click)="selectorganic2option('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': organic2option === 'YN' }"
                    (click)="selectorganic2option('YN')">
              Both
            </button>
          </div>
        </div>
        <div style="align-items: center; display: flex; flex-direction: row; margin: 10px;" class="row homestylerow">
          <div class="col-sm-6 homestyleelement">
            <b style="padding-right: 35px">Exclude</b>

            <button class="button1" [ngClass]="{ 'selected': exludeOption === 'Y' }"
                    (click)="selectExludeOption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': exludeOption === 'N' }"
                    (click)="selectExludeOption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': exludeOption === 'YN' }"
                    (click)="selectExludeOption('YN')">
              Both
            </button>
          </div>
          <div class="col-sm-6">
            <b style="padding-right: 3px">Organic 3</b>

            <button class="button1" [ngClass]="{ 'selected': organic3option === 'Y' }"
                    (click)="selectorganic3option('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': organic3option === 'N' }"
                    (click)="selectorganic3option('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': organic3option === 'YN' }"
                    (click)="selectorganic3option('YN')">
              Both
            </button>
          </div>
        </div>
        <div style="align-items: center; display: flex; flex-direction: row; margin: 10px;" class="row homestylerow">
          <div class="col-sm-6 homestyleelement">
            <b style="padding-right: 10px">Gluten Free</b>
            <button class="button1" [ngClass]="{ 'selected': glutenFreeoption === 'Y' }"
                    (click)="selectGlutenFreeoption('Y')">
              Yes
            </button>
            <button class="button2" [ngClass]="{ 'selected': glutenFreeoption === 'N' }"
                    (click)="selectGlutenFreeoption('N')">
              No
            </button>
            <button class="button3" [ngClass]="{ 'selected': glutenFreeoption === 'YN' }"
                    (click)="selectGlutenFreeoption('YN')">
              Both
            </button>
          </div>
        </div>
        <div style="align-items: center; display: flex; flex-direction: row; margin: 10px;" class="row" *ngIf="showDatePanel==true">
          <div style="text-align:left;width:50%">
            <b>Report Name</b>
            <input type="text" style="text-align:center;margin-left:5px" placeholder="Report Name" [(ngModel)]="reportName" />
          </div>
          <div class="col-sm-6" style="width:50%;margin-left: 0%;">
            <button class="btn btn-secondary buttonsuccess" style="margin-left: 5px; width: 150px;" (click)="generateAuditReport()" [disabled]="!reportName || filterAuditReort.length === 0">Generate Report</button>
          </div>
        </div>
      </div>
    </kendo-expansionpanel>
  </div>
  <div class="box">
    <div class="col-md-12" align="center">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <div style="text-align:right">
                <input type="checkbox" [(ngModel)]="auditNotes" />
                <b style="padding-left:7px">Audit Notes</b>
              </div>
              <kendo-grid [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)" [kendoGridBinding]="filteredAuditReport" [pageSize]="pageSize" [filterable]="true"
                          [skip]="skip" [pageable]="true" (pageChange)="pageChange($event)" style="width: 100%;">
                <kendo-grid-column field="gtin" title="GTIN" [headerStyle]="{ 'font-weight': 'bold' }" [width]="50" [style]="{'padding':'0px'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false" operator="eq" [column]="column" [filter]="filter"></kendo-grid-string-filter-cell>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [ngClass]="{ 'visited-link': isLinkVisited(dataItem.gtin) }">
                      <a (click)="navigateSearchPage(dataItem.gtin, dataItem.status)" class="bi-card-checklist" style="background-color: #2f880f; color: white; margin-left: 10px;"></a>
                      <a (click)="navigateSearchPage(dataItem.gtin, dataItem.status)"
                         [ngClass]="{ 'visited-link': isLinkVisited(dataItem.gtin) }"
                         [style.margin-left.px]="5"
                         [style.color]="isLinkVisited(dataItem.gtin) ? 'purple' : 'blue'"
                         [title]="dataItem.auditNotes"
                         style="text-decoration: underline; text-decoration-color: black;">
                        {{ dataItem.gtin }}
                      </a>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="itemCode" title="Item Code" [width]="46" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false"
                                                   [column]="column" operator="eq"
                                                   [filter]="filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="itemDescription" title="Item Description" [width]="65" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false"
                                                   [column]="column"
                                                   [filter]="filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="familyGroupCode" [hidden]="isIpadVertical" title="FG Code" [width]="50" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="familyGroupName" [hidden]="isIpadVertical" title="FG Name" [width]="58" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="buyerName" [hidden]="isIpadVertical" title="Buyer Name" [width]="50" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="lastModifiedDate" [hidden]="isIpadVertical" title="Last Mod.Date" [width]="56" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="status" title="Status" [width]="56" [headerStyle]="{ 'font-weight': 'bold'}"
                                   *ngIf="sourcePage!='auditList'">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [showOperators]="false"
                                                   [column]="column"
                                                   [filter]="filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.status }}
                    <div *ngIf="dataItem.auditNotes && dataItem.auditNotes.length > 0" kendoTooltip position="right" [closable]="true" showOn="click">
                      <i kendoButton title="{{ dataItem.auditNotes }}" class="bi bi-info-circle"></i>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="auditNotes" [hidden]="!auditNotes" title="Audit Notes" [width]="50" [headerStyle]="{ 'font-weight': 'bold'}">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal #modalComponent>
  <h1 class="modal-title fs-5 app-modal-header">{{modalData.header}}</h1>
  <div class="app-modal-body">{{modalData.message}}</div>
  <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
          (click)="closeModal()">
    Close
  </button>
</app-modal>
