<kendo-dialog title="Add Component" *ngIf="false && selectedPanelType==='Variety Pack'" [minWidth]="400" [width]="400" class="rounded-dialog">
  <div class="container1">
    <div [ngStyle]="{'padding-top': multiPanelopened && compnonentDescriptions.length>0 ? '25px' : '35px', 'padding-left': '20px'}">
      <div *ngIf="tmpCompnonentDescriptions.length > 0 && selectedPanelType === 'Variety Pack'" style="float:right;">
        <select #tmpcompDescriptions style="width:100px;" (change)="onTmpComonentSelected(tmpcompDescriptions.value)" *ngIf="tmpCompnonentDescriptions.length > 0">
          <option value="">Select</option>
          <option *ngFor="let comp of tmpCompnonentDescriptions" [value]="comp.componentId" [style.color]="isNotInCompDescriptions(comp.componentId) ? 'black' : 'blue'">{{comp.description}}</option>
        </select>
      </div>
    </div>
    <div [ngStyle]="{'padding-top': multiPanelopened && compnonentDescriptions.length>0 ? '10px' : '20px', 'padding-left': '20px'}">
      <b>  Description </b><input type="text" [(ngModel)]="description"><br />
      <label *ngIf="multiPanelopened"><b>Column</b></label> <br /><input *ngIf="multiPanelopened" type="text" [(ngModel)]="column"><br />
      <input type="checkbox" [(ngModel)]="isDualColumn" *ngIf="multiPanelopened" />
      <label *ngIf="multiPanelopened"><b>Is Dual Column</b></label>
    </div>
  </div>
  <div style="padding-left: 15px">
    <table *ngIf="compnonentDescriptions.length" border="1">
      <tr>
        <ng-container *ngFor="let comp of mutliColumnSet; let i=index">
          <td [attr.colspan]="comp.isDualColumn ? 2 : 1" style="height: 15px;border: 1px solid black;">
            {{ getdescrption(comp) }}
          </td>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let cell of generateCells(column-1)">
          <td style="border: 1px solid black;height:15px;width:15px"></td>
        </ng-container>
      </tr>
    </table>
  </div>

  <!--<div style="text-align: right; padding-top: 40px;">
    <button (click)="addNextMultiPanel()" [disabled]="description.trim()=='' || column.toString()==''" style="width: 50px; margin-right: 20px;" class="btn btn-secondary buttonsuccess">Add</button>
    <button (click)="closeDialogNonMultiPanel()" [disabled]="this.compnonentDescriptions.length==0" style="width: 50px; padding-left: 10px; text-align:center" class="btn btn-secondary buttonsuccess">OK</button>
  </div>-->
</kendo-dialog>

<kendo-dialog title="Please confirm" *ngIf="false" [minWidth]="600" [width]="600" class="rounded-dialog">
  <div style="padding-left: 40%"><b>Update Panel</b></div>
  <br>
  <table border="1" style="width: 100%;">
    <tr style="border:inherit">
      <td style="padding-left:5%"><b>Current Panel</b></td>
      <td style="text-align: right; font-size: 48px;"></td>
      <td><b>New Panel</b></td>
    </tr>
    <tr>
      <td style="padding-left:5%"><b>{{ panelType }}</b></td>
      <td style="text-align: right; font-size: 48px;"><i class="bi bi-arrow-right-circle-fill green-icon"></i></td>
      <td>
        <table style="margin: 0 auto;">
          <tr>
            <td style="padding-top: 10px;padding-left:20px">
              <label>
                <input type="radio" [(ngModel)]="selectedPanelType" value="Dual Column">&nbsp;
                <b>Dual Column</b>
              </label>
            </td>
          </tr>
          <tr>
            <td style="padding-top: 10px;padding-left:15px">
              <label>
                <input type="radio" [(ngModel)]="selectedPanelType" value="Single Panel">&nbsp;
                <b>Single Panel</b>
              </label>
            </td>
          </tr>
          <tr>
            <td style="padding-top: 10px;padding-left:15px">
              <label>
                <input type="radio" [(ngModel)]="selectedPanelType" value="Variety Pack">&nbsp;
                <b>Variety Pack</b>
                <div *ngIf="selectedPanelType=='Variety Pack'" style="padding-left:33px;">
                  <input type="checkbox" style="padding-left:5px" [(ngModel)]="isMultiPanel" /> <b>&nbsp;&nbsp;Is Mutlti Column</b>

                </div>
              </label>
            </td>
          </tr>
        </table>

      </td>
    </tr>
  </table>

  <!-- Separate container for the button -->
  <div style="text-align: right; padding-top: 30px;">
    <button (click)="close()" style="width:50px" class="btn btn-secondary buttonsuccess">OK</button>
  </div>
</kendo-dialog>

<confirm-panel *ngIf="opened" [selectedPanelType]="selectedPanelType" [panelType]="panelType"
               (confirmPanelType)="closeselectPanelType($event)" (isMultiPanelImage)="isMultiPanelImage($event)">
</confirm-panel>

<create-components *ngIf="componentPopUp && selectedPanelType==='Variety Pack'" [tmpCompnonentDescriptions]="tmpCompnonentDescriptions"
                   [multiPanelopened]="multiPanelopened" [compnonentDescriptions]="compnonentDescriptions" [compnonentPanelStatus]="compnonentPanelStatus"
                   (componentSetEvent)="closeCreateComponent($event)">
</create-components>

<div *ngIf="!opened && !componentPopUp" [ngStyle]="{'text-align': isIpadVertical ? 'center' : 'initial'}">

  <div style="text-align:center;">
    <div *ngIf="step=='capture-scanbarcode'">

      <app-capture-scan-barcode (scanBarcodeChanged)="onScanBarcodeChanged($event)"
                                (compareItems)="onCompareItemUnconditionally($event)" [inputGtinText]="gtinCode" [skipscanningGtinStep]="skipScanStep"></app-capture-scan-barcode>
    </div>
  </div>

  <div class="row h-100 pt-2 pb-2 mt-5" *ngIf="step=='capture-picture'"
       [ngStyle]="{'flex-direction': isIpadVertical ? 'column' : 'row', 'align-items': isIpadVertical ? 'center' : 'initial', 'justify-content': isIpadVertical ? 'center' : 'initial'}"
       style="padding-left: 2%; padding-right: 2%; overflow-y: scroll;">

    <div [class.col-md-4]="!isIpadVertical" [class.col-md-12]="isIpadVertical" class="d-flex flex-column" id="idv1">
      <app-capture-picture [gtinCode]="gtinCode" [itemDescription]="itemDescription" (onImageCaptured)="onImageCaptured($event)"
                           [selectedThumbnail]="selectedThumbnail"></app-capture-picture>
      <hr>
      <div class="d-flex1 align-items-center pb-2" style="width: 100%;">
        <div style="overflow-x: auto; height: 150px; white-space: nowrap;">
          <app-image-ocr-thumbnails [capturedImages]="capturedImages" (thumbnailChange)="onThumbnailSelected($event)"
                                    [selectedCapturedImage]="selectedThumbnail"></app-image-ocr-thumbnails>
        </div>
      </div>
      <hr>
      <div class="mt-auto align-self-start action-buttons" style="position: fixed; bottom:3%">
        <button [disabled]="capturedImages.length==0 || !selectedThumbnail" class="btn btn-secondary buttonsuccess"
                style="height:38px;" (click)="processOcr()">
          Process
          Image
        </button>
        &nbsp;
        <button class="btn btn-secondary buttonsuccess" [disabled]="enhancedOcrNutritionFacts == null"
                style="height:38px;" (click)="resetOcr()">
          Reset Image
        </button>
      </div>


    </div>

    <div [class.col-md-8]="!isIpadVertical" [class.col-md-12]="isIpadVertical" class="d-flex flex-column"
         [ngClass]="{'center-content': centerContent && isIpadVertical }" id="idv2">
      <div *ngIf="isIpadVertical" style="font-weight: 700; margin-bottom: 10px;">
        <span>GTIN : {{ gtinCode }} &nbsp;&nbsp;Description-{{ itemDescription }}</span>
      </div>
      <div style="font-weight: 700; margin-bottom: 10px;">
        <span *ngIf="!isIpadVertical">GTIN : {{ gtinCode }} &nbsp;&nbsp;Description-{{ itemDescription }}</span>
        <div *ngIf="selectedPanelType === 'Variety Pack'" style="float:right;">
          <div *ngIf="compnonentDescriptions.length > 0 || selectedPanelType === 'Variety Pack'" style="float:right;">
            <select #compDescriptions style="width:auto;" (change)="onComonentSelected(compDescriptions.value)">
              <option value="">Select</option>
              <option *ngFor="let comp of compnonentDescriptions" value="{{comp.componentId}}">{{comp.description}}</option>
            </select>

            <!--<button  (click)="addNewComponent()">+</button>-->
          </div>
        </div>
      </div>
      <app-image-ocrdata *ngIf="displayOcrView && ocrNutritionFacts && !isLoadingNutritionFacts"
                         [gtinCode]="gtinCode"
                         [ocrNutritionFacts]="ocrNutritionFacts"
                         (changedDevicePostion)="onChangedDevicePostion($event)"
                         [nutrientTypes]="nutrientTypes"
                         (onSaveDraftOcrNutritionFacts)="saveDraftOcrNutritionFacts(null)"
                         (nutritionFactChanged)="onNutritionFactChanged()" (onScanNewItem)="scanNewItem()"
                         (compareItems)="onCompareItems()" (onAddToPnms)="addToPnms()" style="flex: 1;"
                         [ocrRawResponse]="rawResponse"></app-image-ocrdata>
      <div *ngIf="isLoadingNutritionFacts" class="spinner-border" role="status"
           style="position:absolute;top:50%;left:50% ;height: 50px; width: 50px;">
        <span class="sr-only">Loading...</span>
      </div>

      <!--<div>
        <input type="text" *ngIf="rawResponse.length > 0 && !isProd" [(ngModel)]="rawResponse" readonly>
      </div>-->

    </div>
  </div>

  <app-modal #modalComponent>
    <h1 class="modal-title fs-5 app-modal-header">{{modalData.header}}</h1>
    <div class="app-modal-body">{{modalData.message}}</div>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="closeSaveDraftModal()">
      Close
    </button>
  </app-modal>

  <app-modal #scanNewItemModalComponent>
    <h1 class="modal-title fs-5 app-modal-header">Save Image Data</h1>
    <div class="app-modal-body">Do you want to save Image data?</div>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="onScanNewItemNo()">
      No
    </button>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer custom-bottom-right-button" data-bs-dismiss="modal"
            (click)="onScanNewItemYes()">
      Yes
    </button>
  </app-modal>
  <app-modal #modalVarietyComponent>
    <h1 class="modal-title fs-5 app-modal-header">{{modalVarietyData.header}}</h1>
    <div class="app-modal-body">{{modalVarietyData.message}}</div>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="closeVarietyComponenttModal()">
      Ok
    </button>
  </app-modal>
</div>
