import { Component } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { of } from "rxjs";
import { environment } from 'src/environments/environment';
import { RestApiService } from "../services/RestApiService.service";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent {
  public hasChildren = (item: any) => item.items && item.items.length > 0;
  public fetchChildren = (item: any) => of(item.items);
  public expandedKeys: any[] = [];// ["0", "0"];
  public selectedKeys: any[] = [];// ["0_0"];
  public userId: any = '';
  public isuserLoggedin: boolean = false;
  constructor(private authService: MsalService, private service: RestApiService) {
    var accountInfo = this.authService.instance.getAllAccounts();
    if (accountInfo != null && accountInfo.length > 0 && accountInfo[0].idTokenClaims) {
      this.userId = accountInfo[0].idTokenClaims['sAMAccountName'];
      this.isuserLoggedin = true;
    }
  }
  src = '';
  src1 = '';
  //Search Page
  SearchNutririonsFacts: boolean = false;
  SearchLandingPage: boolean = false;
  SearchItemResults: boolean = false;

  //Audit Report
  AuditReportLandingPage: boolean = false;

  //Capture Image
  CaptureImageSearchpage: boolean = false;
  CaptureUpdatePanelpage: boolean = false;
  CaptureAddComponentspage: boolean = false;
  CaptureImage: boolean = false;

  //AuditDashBoard
  AuditDashBoardLandingPage: boolean = false;
  AuditDashBoardReportPage: boolean = false;

  //Activity
  ActivityPage: boolean = false;
  imageUrl = '';
  key = '';
  public makedivvisible(section: string) {
    this.src = '';
    this.src1 = '';

    if (section == 'Audit Report Landing Page') {
      this.service.getImage('AuditDashBoard_Landing_Page').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'Update Panel') {
      this.service.getImage('Capture-Update-panel').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'Activity Page') {
      this.service.getImage('Activity_Page').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'Search for GTIN') {
      this.service.getImage('capture-Image-Search-Page').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'Add Components') {
      this.service.getImage('Capture-Image-Add-Components').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'Capture Image') {
      this.service.getImage('Capture_capture_image').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'Search Landing Page') {
      this.service.getImage('Search_Landing_Page').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }



    if (section == 'Search Results') {
      this.service.getImage('Search_Nutritions_Facts1').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });

      this.service.getImage('Search_Nutritions_Facts2').pipe(
      ).subscribe((data: any) => {
        this.src1 = data.base64String;
      });

    }

    if (section == 'Item Results') {
      this.service.getImage('Search_Item_Results').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'DashBoard Landing Page') {
      this.service.getImage('Dash_Board_Landing_Page').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    if (section == 'DashBoard Report Page') {
      this.service.getImage('Audit_DashBoard_Report_Page').pipe(
      ).subscribe((data: any) => {
        this.src = data.base64String;
      });
    }

    //Activity
    this.ActivityPage = section === 'Activity Page' ? true : false;

    //Search Page
    this.SearchLandingPage = section === 'Search Landing Page' ? true : false;
    this.SearchNutririonsFacts = section === 'Search Results' ? true : false;
    this.SearchItemResults = section === 'Item Results' ? true : false;

    //AudotReport
    this.AuditReportLandingPage = section === 'Audit Report Landing Page' ? true : false;

    //CaptureImage
    this.CaptureImageSearchpage = section === 'Search for GTIN' ? true : false;
    this.CaptureUpdatePanelpage = section === 'Update Panel' ? true : false;
    this.CaptureAddComponentspage = section === 'Add Components' ? true : false;
    this.CaptureImage = section === 'Capture Image' ? true : false;

    //AuditDashBoard
    this.AuditDashBoardLandingPage = section === 'DashBoard Landing Page' ? true : false;
    this.AuditDashBoardReportPage = section === 'DashBoard Report Page' ? true : false;
  }

  public data: any[] = [

    {
      text: "Help",
      items: [
      ],
    },

    {
      text: "Audit DashBoard",
      items: [
        { text: "DashBoard Landing Page" },
        { text: "DashBoard Report Page" },
      ],
    },
    {
      text: "Search",
      items: [
        { text: "Search Landing Page" },
        { text: "Item Results" },
        { text: "Search Results" },
      ],
    },

    {
      text: "Capture Image",
      items: [
        { text: "Search for GTIN" },
        { text: "Update Panel" },
        { text: "Add Components" },
        { text: "Capture Image" },
      ],
    },
    {
      text: "Activity",
      items: [
        { text: "Activity Page" }
      ],
    },
    {
      text: "Audit",
      items: [
        { text: "Audit Report Landing Page" },
      ],
    }

  ];
  onSelectionChange(event: any) {
    this.makedivvisible(event.dataItem.text);
  }
}
