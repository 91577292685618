export interface EisnmGtinData {
    gsno?: number;
    gtin: number;
    nutrInfoID: number 
    nutrSourceCd: string ;
    nutrStatusCd: string;
    ocrStatusCd: string ;
}

export class EisnmComponentData {
    gsno: number = 0;
    gtin: number = 0;
    componentID: string = "";
    compDescription: string | undefined;
    servingSizeVal: string = "";
    servingSizeUom: string = "";
    householdServingSize: string = "";
    householdServingUom: string = "";
    visualServingUom: string = "";
    servingPerPackage: string = "";
    panelType: string = "";
    itemHeader: string = "";
    dualItemHeader: string | undefined;
}

export class EismnAllergenData {
    gsno: number = 0;
    gtin: number = 0;
    componentId: string = "";
    allergen: string = "";
};

export class EismnIngredientsData {
    gtin: number = 0;
    componentId: string = "";
    ingredients: string = "";
}

export class EismnNutrientValueData {
    gsno: number = 0;
    csno: number = 0;
    gtin: number = 0;
    componentId: string = "";
    nutrientId: number = 0;
    nutrientName: string = "";
    nutrValue: number | undefined;
    dvp: number | undefined;
    displayValue: string = "";
    nutrValueDual: number | undefined;
    dvpDual: number | undefined;
    displayValueDual: string = "";
    nutrValUom: string = "";
    nutrValDualUom: string = "";

}

export class EismnOcrImagesData {
    gsno: number = 0;
    gtin: string = "";
    componentId: string = "";
    imageType: string = "";
    imagePath: string = "";
}


