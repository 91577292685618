import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private gtinNumber = new BehaviorSubject<number>(0);
  currentMessage = this.gtinNumber.asObservable();
  changeMessage(message: number) {
    this.gtinNumber.next(message)
  }

  private storeNumber = new BehaviorSubject<string>("");
  CurrentstoreNumber = this.storeNumber.asObservable();
  changeStoreNumber(message: string) {
    this.storeNumber.next(message)
  }

  private selectedValues = new BehaviorSubject<any[]>([]);
  currentSelectedValues = this.selectedValues.asObservable();
  changeselectedValues(records : any[]) {
    this.selectedValues.next(records)
  }


  private selectedKeys = new BehaviorSubject<any[]>([]);
  currentSelectedKeys = this.selectedKeys.asObservable();
  changeselectedKeys(records: any[]) {
    this.selectedKeys.next(records)
  }

  private expandedValues = new BehaviorSubject<any[]>([]);
  currentExpandedValues = this.expandedValues.asObservable();
  changeExpandedValues(records: any[]) {
    this.expandedValues.next(records)
  }

  private currentPage = new BehaviorSubject<number>(0);
  currentPageNumber = this.currentPage.asObservable();
  changePage(pageNumber: number) {
    this.currentPage.next(pageNumber)
  }

  private Record = new BehaviorSubject<number>(0);
  currentRecord = this.Record.asObservable();
  changeCurrentRecord(record: number) {
    this.Record.next(record)
  }

  private status = new BehaviorSubject<string>("");
  currentStatus = this.status.asObservable();
  changeStatus(status: string) {
    this.status.next(status)
  }

  private source = new BehaviorSubject<string>("");
  currentSource = this.source.asObservable();
  changeSource(message: string) {
    this.source.next(message)
  }

  private startDate = new BehaviorSubject<string>("");
  currentStartDate = this.startDate.asObservable();
  changeCurrentStartDate(startDate: string) {
    this.startDate.next(startDate)
  }

  private endDate = new BehaviorSubject<string>("");
  currentendDate = this.endDate.asObservable();
  changeCurrentEndDate(endDate: string) {
    this.endDate.next(endDate)
  }

  private auditReport = new BehaviorSubject<string>("");
  currentAuditReport = this.auditReport.asObservable();
  changeCurrentAuditReport(auditReport: string) {
    this.auditReport.next(auditReport)
  }

  private auditNotes = new BehaviorSubject<string>("");
  currentauditNotes = this.auditNotes.asObservable();
  changeCurrentAuditNotes(auditNotes: string) {
    this.auditNotes.next(auditNotes)
  }

  private allUserReport = new BehaviorSubject<string>('');
  currentAllUserData = this.allUserReport.asObservable();
  changecurrentAllUserData(checked: string) {
    this.allUserReport.next(checked)
  }

  private visistedLinks = new BehaviorSubject<string[]>([]);
  visitedLinks = this.visistedLinks.asObservable();
  addVisitedLinks(newString: string) {
    const currentArray = this.visistedLinks.getValue();
    const updatedArray = [...currentArray, newString];
    this.visistedLinks.next(updatedArray);
  }



  private organic1 = new BehaviorSubject<string>('');
  currentOrganic1 = this.organic1.asObservable();
  changecurrentOrganic1(checked: string) {
    this.organic1.next(checked)
  }

  private organic2 = new BehaviorSubject<string>('');
  currentOrganic2 = this.organic2.asObservable();
  changecurrentOrganic2(checked: string) {
    this.organic2.next(checked)
  }

  private organic3 = new BehaviorSubject<string>('');
  currentOrganic3 = this.organic3.asObservable();
  changecurrentOrganic3(checked: string) {
    this.organic3.next(checked)
  }

  private familyGroupNmae = new BehaviorSubject<string>('');
  currentFamilyGroupNmae = this.familyGroupNmae.asObservable();
  changecurrentFamilyGroupNmae(checked: string) {
    this.familyGroupNmae.next(checked)
  }

  private familyGroupId = new BehaviorSubject<string>('');
  currentFamilyGroupId = this.familyGroupId.asObservable();
  changecurrentFamilyGroupId(checked: string) {
    this.familyGroupId.next(checked)
  }

  private glutenFree = new BehaviorSubject<string>('');
  currentGlutenFree = this.glutenFree.asObservable();
  changecurrentGlutenFree(checked: string) {
    this.glutenFree.next(checked)
  }

  private natural = new BehaviorSubject<string>('');
  currentNatural = this.natural.asObservable();
  changecurrentNatural(checked: string) {
    this.natural.next(checked)
  }

  private exclude = new BehaviorSubject<string>('');
  currentExclude = this.exclude.asObservable();
  changecurrentExclude(checked: string) {
    this.exclude.next(checked)
  }

  private familyGroupInclude = new BehaviorSubject<string>('');
  currentFamilyGroupInclude = this.familyGroupInclude.asObservable();
  changecurrentFamilyGroupInclude(checked: string) {
    this.familyGroupInclude.next(checked)
  }

  private loadDashBoard = new BehaviorSubject<string>('');
  currentloadDashBoard = this.loadDashBoard.asObservable();
  changecurrentloadDashBoard(checked: string) {
    this.loadDashBoard.next(checked)
  }

  private _visitedAlreadySearchPage = new BehaviorSubject<string>('');
  visitedSearchPage = this._visitedAlreadySearchPage.asObservable();
  ChangevisitedSearchPage(checked: string) {
    this._visitedAlreadySearchPage.next(checked)
  }

  constructor() { }
  
}
