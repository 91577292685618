
export const environment = {
  production: false,
  scopeUri: 'api://cdb53823-9b6d-4131-9c88-5bc532c87fde/Read',  
  gatewayHost : 'https://nutritional-api-dev.publix.io/api/v1',
  tenantId: '623cac68-b5d0-45f1-9109-3122c3974cc9',
  ClienId: '268d90c2-7332-4b56-ad40-ee1aa42b58a8', 
  apiVersion:'v1'  
};

//export const environment = {
//  production: false,
//  dev: true,
//  scopeUri: 'api://cdb53823-9b6d-4131-9c88-5bc532c87fde/Read',
//  gatewayHost: 'http://localhost:5043/api/v1',
//  tenantId: '623cac68-b5d0-45f1-9109-3122c3974cc9',
//  ClienId: '268d90c2-7332-4b56-ad40-ee1aa42b58a8',
//  redirectUrl: 'http://localhost:4200/',
//  apiVersion: 'v1'

//};
