<div class="card-body">
    <div class="form-group">
        <label class="control-label col-sm-7 p-2">Servings container</label>
        <div class="col-sm-5" style="display:inline-table ">
            <input class="form-control" [(ngModel)]="servingData.servingPerPackage">
        </div>
    </div>

    <div class="form-group">
        <label class="control-label col-sm-7 p-2">Household serving size</label>
        <div class="col-sm-5" style="display:inline-table ">
            <input class=" form-control wd50" 
                [(ngModel)]="servingData.householdServingSize" NumberOnly>
            <input class=" form-control wd50" [(ngModel)]="servingData.householdServingUom">
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-7 p-2">Serving size</label>
        <div class="col-sm-5" style="display:inline-table ">
            <div class="d-flex">
                <input class="form-control me-3"         
                    [(ngModel)]="servingData.servingSizeVal" NumberOnly>
                <select [(ngModel)]="servingData.servingSizeUom">
                    <option value="g">g</option>
                    <option value="ml">ml</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-7 p-2">Visual unit of measure</label>
        <div class="col-sm-5" style="display:inline-table ">
            <input class=" form-control" [(ngModel)]="servingData.visualServingUom">
        </div>
    </div>
</div>
