<div class="modal fade" [class.show]="isOpen" [class.modal-visible]="isOpen" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <ng-content select=".app-modal-header"></ng-content>
        <button  class="btn-secondary btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="hide(true)"></button>
      </div>
      <div class="modal-body">
        <ng-content select=".app-modal-body"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select=".app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>