// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css']
// })
// export class AppComponent {
//   title = 'Nutritional.UI';
//   constructor(){
//   }
// }

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';

import { InteractionStatus } from '@azure/msal-browser';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginProfile } from './services/login-profile-types';
import { PrivilegeService } from './services/privilege.service';
import { componentDestroyed } from './shared/generics/componentdestroyed';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Nutritional.UI';

  loginProfileEvent: ReplaySubject<LoginProfile> =
    new ReplaySubject<LoginProfile>();
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  public loginProfile: LoginProfile = {
    profileName: '',
    icon: 'logout',
    email: '',
    profileLogoText: '',
    loginDisplay: false,
    userId: null
  };

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService,
  private privilegeService: PrivilegeService) 
  { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
   console.log("In ngOnInit in App");
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
    if (this.authService) {
      this.setLoginDisplay();
    }

    this.privilegeService
      .HasEditAccess()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setLoginDisplay(): void {
    this.loginProfile.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    //console.log(this.loginProfile.loginDisplay)
    const accountInfo = this.authService.instance.getAllAccounts();
    if (accountInfo && this.loginProfile.loginDisplay) {
      this.loginProfile.profileName = accountInfo[0].name ?? '';      
      this.loginProfile.email = accountInfo[0].username ?? '';
      if(accountInfo[0].idTokenClaims != null)
       { 
        this.loginProfile.userId  =  accountInfo[0].idTokenClaims['sAMAccountName'];
        
       }
      if (this.loginProfile.profileName != '') {
        this.loginProfile.profileLogoText =
          this.loginProfile.profileName.substring(0, 1);
      }
    }
    this.loginProfileEvent.next(this.loginProfile);
  }

}
