import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CapturedImage } from "../models/capturedImage";
import { ThumbnailData } from "../models/thumbnail";


@Component({
  selector: 'app-image-ocr-thumbnails',
  templateUrl: './thumbnails.component.html',
  styleUrls: ['./thumbnails.component.css']
})
export class ImageOcrThumbnailsComponent {

  @Input()
  capturedImages: CapturedImage[] = [];
  @Input()
  selectedCapturedImage : CapturedImage | undefined;

  @Output()
  thumbnailChange = new EventEmitter<CapturedImage>();


  onThumbnailClicked(capturedImage: CapturedImage) {
    this.selectedCapturedImage = capturedImage
    this.thumbnailChange.emit(capturedImage)
  }
}
