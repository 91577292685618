import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
@Component({
  selector: 'confirm-panel',
  templateUrl: './confirm-panel-component.html',
  styleUrls: ['./confirm-panel-component.css']
})
export class confirmpanelcomponent {


  public isMultiPanel: boolean = false;
  @Input() public selectedPanelType: string = '';
  @Output() confirmPanelType = new EventEmitter<string>();
  @Output() isMultiPanelImage = new EventEmitter<boolean>();
  @Input() public panelType: string = '';
  close() {
    this.confirmPanelType.emit(this.selectedPanelType);
  }
  handleChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.isMultiPanelImage.emit(isChecked);
  }
  constructor() {

  }
}
