<div style="padding-top: 200px; display: flex; justify-content: center; flex-direction: column;">


  <div style="padding-top: 20px;width:100%">
    <table border="1" style="padding-top: 20px; margin: 0 auto;width:400px">
      <tr>
        <td style="height: 10px; background-color: green; text-align: center;">
          <span style="color:white"><b>Add Components</b></span>
        </td>
      </tr>
      <tr>
        <td style="height: 30px;"></td>
      </tr>
      <tr>
        <td style="padding-left:10%">
          <div *ngIf="tmpCompnonentDescriptions.length > 0">
            <select #tmpcompDescriptions style="width:300px;" (change)="onTmpComonentSelected(tmpcompDescriptions.value)" *ngIf="tmpCompnonentDescriptions.length > 0">
              <option value="">Select</option>
              <option *ngFor="let comp of tmpCompnonentDescriptions" [value]="comp.componentId">{{comp.description}}</option>
            </select>
          </div><br />
          <div style="display: flex; justify-content: center; flex-direction: column">
            <b>Description</b>
            <input type="text" style="width:300px" [(ngModel)]="description">
            <label *ngIf="multiPanelopened">
              <input type="checkbox" [(ngModel)]="isDualColumn" />
              <b style="padding-left:4px">Is Dual Column</b>
            </label><br />
            <label *ngIf="multiPanelopened"><b>Seed Column</b></label>
            <input style="width:300px" *ngIf="multiPanelopened" type="text" [(ngModel)]="column">
          </div>



          <div style="padding-top:5px;width:300px;text-align:end">
            <button (click)="addNextMultiPanel()" [disabled]="description.trim()==''" style="width: 50px; margin-right: 20px;" class="btn btn-secondary buttonsuccess">Add</button>
            <button (click)="close()" [disabled]="compnonentDescriptions.length==0" style="width: 50px; padding-left: 10px; text-align:center" class="btn btn-secondary buttonsuccess">OK</button>
          </div>
          <div style="padding-top:5px">
            <table *ngIf="compnonentDescriptions.length" border="1">
              <tr>
                <!--<ng-container *ngFor="let comp of mutliColumnSet; let i=index">
    <td [attr.colspan]="comp.isDualColumn ? 2 : 1" style="height: 15px;border: 1px solid black;" [ngStye]="color:comp.isNewComponent?''Orange','green'">
      {{ getdescrption(comp) }}
    </td>
  </ng-container>-->

                <ng-container *ngFor="let comp of mutliColumnSet; let i=index">
                  <td [attr.colspan]="comp.isDualColumn ? 2 : 1" style="height: 15px; border: 1px solid black;" [ngStyle]="{ 'color': comp.isNewComponent ? 'orange' : 'black' }">
                    {{ getdescrption(comp) }}
                  </td>
                </ng-container>
              </tr>
              <tr>
                <ng-container *ngFor="let cell of generateCells(column-1)">
                  <td style="border: 1px solid black;height:15px;width:15px"></td>
                </ng-container>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </table>
  </div>
  </div>
