<!-- <div style="background-color: red;margin-top: 100px;">
Test class="p-2 col-lg-4 mx-auto " <div class="px-4 py-4 pb-0 mt-5 text-center">
</div> -->

<div class="class= px-4 py-4 pb-0 mt-5 text-center">
  <input placeholder="Enter GTIN without check digit" [(ngModel)]="enteredGtinText" PositiveNumber style="width: 250px;height: 35px;">
  <i class="bi bi-search" style="margin-left: -20px; cursor: pointer;" (click)="onGtinEntered()"></i>
</div>
<div>Or</div>
<div style="text-align:center; font-weight: bold;">
  <button (click)="useScanBarcode()" class="btn btn-secondary buttonsuccess">Scan Barcode</button>
</div>
<div>
  <div *ngIf="gtinFlow != 'Scan'">
    <div *ngIf="scannedErrorMessage" style="text-align: center; color: red;">
      <span>{{ scannedErrorMessage }}</span>
    </div>
  </div>
  <div style="display: flex; flex-direction: column;" *ngIf="gtinFlow=='Scan'">
    <div class="error-message">
      <span>{{ errorMessage }}</span>
    </div>
    <div *ngIf="scannedErrorMessage" style="align-self: center; display:flex; align-items: center;">
      <span>{{ scannedErrorMessage }}</span>&nbsp;
      <a (click)="reScan()" style="margin-left: 10px; color:blue;text-decoration: underline; cursor: pointer;">
        Rescan
      </a>
    </div>
    <!-- <div *ngIf="showScanErrorMessage" style="align-self: center; display:flex; align-items: center;">
          Some error happened while scanning
          <a (click)="reScan()"
              style="margin-left: 10px; color:blue;text-decoration: underline; cursor: pointer;">Rescan</a>
      </div> -->

    <div id="scanner-container" [ngClass]="{hide: disableCamera}">
      <!-- QuaggaJS will populate this element -->
    </div>

  </div>
  <app-modal #confirmationModalComponent (newItemEvent)="onUserDialogClose($event)">
    <h1 class="modal-title fs-5 app-modal-header">Image already exist</h1>
    <div class="app-modal-body">
      This item is already captured and processed.
      if you still want to recapture select YES or select NO to compare previously processed data
    </div>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="onUserSelectionNo()">
      No
    </button>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="onUserSelectionYes()">
      Yes
    </button>
  </app-modal>
 
  <app-modal #scanCodeModalComponent>
    <h1 class="modal-title fs-5 app-modal-header">Add New item alert message</h1>
    <div class="app-modal-body">This item is not found in PNMS, Would you like to add?</div>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="onScanCodeGtinNo()">
      No
    </button>
    <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
            (click)="onScanCodeGtinYes()">
      Yes
    </button>
  </app-modal>
</div>

  <div *ngIf="loadingstatus" class="spinner-border" role="status"
        style="position:absolute;top:50%;left:50% ;height: 50px; width: 50px;">
        <span class="sr-only">Loading...</span>
      </div>
