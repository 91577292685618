<!--<div class="scroll-container" style="overflow-x: auto; overflow-y: hidden; white-space: nowrap;width:250px">
  <ul style="list-style-type: none; padding-left: 0; display: inline-block;">
    <li *ngFor="let capturedImage of capturedImages; let idx = index" (click)="onThumbnailClicked(capturedImage)" style="margin-right: 10px; display: inline-block;">
      <div class="thumbnail-box" [ngClass]="{'highlighted': capturedImage == selectedCapturedImage}">
        <img [src]="capturedImage.data" height="100" width="100" style="padding-left: 0; padding-right: 0;" />
      </div>
    </li>
  </ul>
</div>-->


<!--<div class="scroll-container" style="overflow-x: auto; overflow-y: hidden; white-space: nowrap; max-width: 100%;">
  <ul style="list-style-type: none; padding-left: 0; display: inline-block;">
    <li *ngFor="let capturedImage of capturedImages; let idx = index" (click)="onThumbnailClicked(capturedImage)" style="margin-right: 10px; display: inline-block;">
      <div class="thumbnail-box" [ngClass]="{'highlighted': capturedImage == selectedCapturedImage}">
        <img [src]="capturedImage.data" height="100" width="100" style="padding-left: 0; padding-right: 0;" />
      </div>
    </li>
  </ul>
</div>-->

<!--<div class="scroll-container" style="overflow-x: auto; overflow: hidden; white-space: nowrap; max-width: 100%;">
  <ul style="list-style-type: none; padding-left: 0; display: inline-block; white-space: nowrap; max-width: 100%;">
    <li *ngFor="let capturedImage of capturedImages; let idx = index" (click)="onThumbnailClicked(capturedImage)" style="margin-right: 10px; display: inline-block;">
      <div class="thumbnail-box" [ngClass]="{'highlighted': capturedImage == selectedCapturedImage}">
        <img [src]="capturedImage.data" height="100" width="100" style="padding-left: 0; padding-right: 0;" />
      </div>
    </li>
  </ul>
</div>-->


<!--<div class="scroll-container" style="overflow-x: auto; white-space: nowrap; max-width: 100%;">
  <ul style="list-style-type: none; padding-left: 0; display: inline-block; white-space: nowrap; max-width: 100%;">
    <li *ngFor="let capturedImage of capturedImages; let idx = index" (click)="onThumbnailClicked(capturedImage)" style="margin-right: 10px; display: inline-block;">
      <div class="thumbnail-box" [ngClass]="{'highlighted': capturedImage == selectedCapturedImage}">
        <img [src]="capturedImage.data" height="100" width="100" style="padding-left: 0; padding-right: 0;" />
      </div>
    </li>
  </ul>

</div>-->
<div class="scroll-container" style="overflow-x: auto; white-space: nowrap; max-width: 100vw;">
  <ul style="list-style-type: none; padding-left: 0; display: inline-block; white-space: nowrap; max-width: 100vw;">
    <li *ngFor="let capturedImage of capturedImages; let idx = index" (click)="onThumbnailClicked(capturedImage)" style="margin-right: 10px; display: inline-block;">
      <div class="thumbnail-box" [ngClass]="{'highlighted': capturedImage == selectedCapturedImage}">
        <img [src]="capturedImage.data" height="100" width="100" style="padding-left: 0; padding-right: 0;" />
      </div>
    </li>
  </ul>
</div>
