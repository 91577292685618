import { Component } from '@angular/core';
declare var Tesseract: { recognize: (arg0: string) => Promise<any>; }
@Component({
    selector: 'read-text',
    templateUrl: './read.text.from.image.html',
})
export class ReadTextFromImage {
    title = 'Nutritional.UI';
    constructor() {
        this.test();
    }
    Result = 'Recognizing...';
    test() {
        Tesseract.recognize('assets/images/test.png').then(function (result) { alert(result.txt); });
    }
}
