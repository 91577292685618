<!-- <div style="display:flex;flex-direction: column;"> -->
<!-- <div style="padding-top: 30px;padding-left:10px; width: 80vw"> -->
<!-- <div style="font-weight: bold;">Nutrition Fact Details</div> -->
<ng-container *ngFor="let i of searchGtinResults.components;index as k">
    <div class="row">
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10" style="padding-right: 0px; margin-left: 50px;">
          <div [id]="k+1" *ngIf="k+1==id">
            <nutrition-pnms-fact [shwoImage]="shwoImage" [component]="i" [status]="searchGtinResults.status"></nutrition-pnms-fact>
            <br>
            <!-- <div class="details-header">Ingredient List:</div> -->
            <div *ngIf="i.isComponentDataExist && shwoImage===false">
              <div>
                <div><b>INGREDIENTS:</b></div>
                <div style="position: relative;">
                  <div class="details-box" style="  width: 100%;  padding: 10px;">
                    {{i.ingredientList}}
                  </div>
                  <button class="move-button" (click)="copyIngredientsToOCR(i.componentID,'ingrediants')"
                          style="position: absolute; top: -17%; left: 24%; height: 17%;background-color: #2f880f;color: white;">
                    <i class="bi bi-arrow-left-circle"></i>
                  </button>
                </div>
              </div>
              <br>
              <div><b>ALLERGEN: </b></div>
              <div style="position: relative;">
                <div class="details-box" style=" padding: 10px;height: 50px;">
                  {{i.allergenList}}
                </div>
                <button class="move-button" (click)="copyIngredientsToOCR(i.componentID,'allergen')"
                        style="position: absolute;  top: -52%; left: 19%; height: 51%;background-color: #2f880f;color: white;">
                  <i class="bi bi-arrow-left-circle"></i>
                </button>
              </div>
              <br>
              <!-- <div class="details-header"> PNMS Attribues List:</div> -->
              <div class="card">

                <div style=" background-color: #2f880f;color: white;border: 1px solid black;"><b>PNMS ATTRIBUTES</b></div>
                <table style="width: 100%;">
                  <tr style=" background-color:rgb(243 243 243);">
                    <td>Attribute Name(UOM)</td>
                    <td style="width: 50px; height: 20px">Amount Per Serving</td>
                    <td style="width: 30px; height: 20px">DV%</td>
                  </tr>
                  <tr *ngFor="let j of GetNutrientsToShow(id)">
                    <td style="width: 100px;height: 35px;">{{j.nutrientName}}%</td>
                    <td style="width: 70px; height: 20px;">
                      {{ getDualDisplayValue(j.nutrValue)}}
                    </td>
                    <td style="width: 40px; height: 32px;">
                      {{ getDualDisplayValue(j.dvp)}}
                    </td>
                  </tr>
                </table>



              </div>
              <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
        <div [id]="k+1" *ngIf="k+1==id" style="flex:1">

        </div>
    </div> -->
            </div>

            <div *ngIf="!i.isComponentDataExist"><h4>No PNMS component exist to compare.</h4></div>
            </div>
          </div>
      </div>
</ng-container>
<!-- </div> -->
<!-- </div> -->
