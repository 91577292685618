import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl:'pagination.component.html',
  //styleUrls: ['./pagination.component.css']
})
export class CustomPaginationComponent implements OnInit {
  @Input() currentPage!: number;
  @Input() totalPages!: number;
  pagesArray!: number[];

  constructor() {
   
  }

  ngOnInit(): void {
    this.pagesArray = [];
    if (this.totalPages > 5) {
      for (let i = 1; i <6; i++) {
        this.pagesArray.push(i);
      }
    }
  }

  getPagesArray(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  getLastFiveAscending() {
    this.pagesArray = [];
    if (this.totalPages < 5) {
      throw new Error('Input number must be greater than or equal to 5.');
    }

    for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
      this.pagesArray.push(i);
    }
  }

  isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  isLastPage(): boolean {
    return this.currentPage === this.totalPages;
  }

  getDisplayedPages() {
    const pagesArray = this.getPagesArray();
    const start = Math.max(0, this.currentPage - 3); // Show 3 pages before the current page
    const end = Math.min(start + 4, pagesArray.length - 1); // Show 4 pages after the current page
    this.pagesArray= pagesArray.slice(start, end + 1);
  }

  goToPage(page: number): void {
    // Implement your logic to handle navigation to the selected page here
  }
  goToLastPage(): void {
    // Implement your logic to handle navigation to the selected page here
  }
}
