<div style="overflow-x: hidden; overflow-y: hidden">
  <header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top green-bg bg-padding">
      <div class="container-fluid" style="padding-left: 0px;">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="col-lg-12">
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav me-auto mb-2 mb-md-0" *ngIf="isLandscape">
              <li class="nav-item">
                <a class="logo navbar-brand" routerLink="home" routerLinkActive="active">
                  <div>
                    <img src="../../../../assets/images/publix_logo.png" width="69px" /> <span> Enterprise Item Nutritional</span>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="home" (click)="loaddashboard()" routerLinkActive="active" id="headerReports">
                  <i class="bi-card-checklist"></i> Audit Dashboard
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="search" routerLinkActive="active" id="headerSearch">
                  <i class="bi bi-search"></i> Search
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="captureImage" routerLinkActive="active" id="headerCaptureImage">
                  <i class="bi bi-camera"></i> Capture Image
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="list" routerLinkActive="active" id="headerActivity">
                  <i class="bi-card-checklist"></i> Activity
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="auditList" routerLinkActive="active" id="headerAudit">
                  <i class="bi-card-checklist"></i> Audit
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" align="right" (click)="showProfile()"
                   style="position: absolute; right: 20px;"><i class="bi-person"></i> </a>
                <div *ngIf="isVisible" class="profile" style="position: absolute;
                                padding: 10px;
                                right: 29px;
                                top: 40px;
                                border: 2px solid gray;
                                width: auto;
                                height: auto;
                                background-color: white;
                                z-index: 6;
                                border-radius: 5px;
                                box-shadow: 6px 5px 15px gray;">
                  <div style="border-bottom: 1px solid; margin-bottom: 10px;">
                    <p style="margin:10px; font-weight: bold;">{{this.loginProfileInfo.profileName}}</p>
                    <p style="margin:10px;font-weight:bold;">{{this.loginProfileInfo.email}}</p>
                    <p style="margin:10px;font-weight:bold;">{{this.loginProfileInfo.userId}}</p>

                  </div>
                  <a align="right" (click)="logout()" style="cursor: pointer;
                                     color: #2f880f;
                                     text-align: right;
                                     display: block;">
                    <i class="bi bi-door-closed"></i>
                    Log Out
                  </a>
                </div>

              </li>
              <li class="nav-item">
                <a class="nav-link" align="right" (click)="toggleFullScreen()"
                   style="position: absolute; right: 60px;"><i class="bi bi-gear-fill"></i> </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" align="right" routerLink="helpContent" routerLinkActive="active"
                   style="position: absolute; right: 100px; "><i style="color:white" class="bi bi-info-circle"></i> </a>
              </li>
            </ul>
            <ul class="navbar-nav me-auto mb-2 mb-md-0" *ngIf="!isLandscape">
              <li class="nav-item">
                <a class="logo navbar-brand" routerLink="home" routerLinkActive="active">
                  <div>
                    <img src="../../../../assets/images/publix_logo.png" width="69px" /> <span> Enterprise Item Nutritional</span>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="home" routerLinkActive="active" id="headerReports">
                  <i class="bi-card-checklist"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="search" routerLinkActive="active" id="headerSearch">
                  <i class="bi bi-search"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="captureImage" routerLinkActive="active" id="headerCaptureImage">
                  <i class="bi bi-camera"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="list" routerLinkActive="active" id="headerActivity">
                  <i class="bi-card-checklist"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="auditList" routerLinkActive="active" id="headerAudit">
                  <i class="bi-card-checklist"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" align="right" (click)="showProfile()"
                   style="position: absolute; right: 20px;"><i class="bi-person"></i> </a>
                <div *ngIf="isVisible" class="profile" style="position: absolute;
                              padding: 10px;
                              right: 29px;
                              top: 40px;
                              border: 2px solid gray;
                              width: auto;
                              height: auto;
                              background-color: white;
                              z-index: 6;
                              border-radius: 5px;
                              box-shadow: 6px 5px 15px gray;">
                  <div style="border-bottom: 1px solid; margin-bottom: 10px;">
                    <p style="margin:10px; font-weight: bold;">{{this.loginProfileInfo.profileName}}</p>
                    <p style="margin:10px;font-weight:bold;">{{this.loginProfileInfo.email}}</p>
                    <p style="margin:10px;font-weight:bold;">{{this.loginProfileInfo.userId}}</p>

                  </div>
                  <a align="right" (click)="logout()" style="cursor: pointer;
                                   color: #2f880f;
                                   text-align: right;
                                   display: block;">
                    <i class="bi bi-door-closed"></i>
                    Log Out
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" align="right" (click)="toggleFullScreen()"
                   style="position: absolute; right: 60px;"><i class="bi bi-gear-fill"></i> </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" align="right" routerLink="helpContent" routerLinkActive="active"
                   style="position: absolute; right: 100px; "><i style="color:white" class="bi bi-info-circle"></i> </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</div>
