<div *ngIf="isLoadingAuditReport" class="spinner-border" role="status"
     style="position:absolute;top:50%;left:50%; height: 50px; width: 50px;">
  <span class="sr-only">Loading...</span>
</div>
<div class="container" style="margin-top: 50px !important;margin-left: auto !important;margin-right: auto!important;padding: 0px !important;align-items: center;">
  <div class="row " style="border:1px solid black;width:120%; background : #E6F3E1;">
    <div style="display:flex">
      <label class="switch">
        <input type="checkbox" (change)="reset($event)" [(ngModel)]="showUserReports">
        <span class="slider round"></span>
      </label>
      <span style="margin-left:1%"><b>User Reports</b></span>
      <span style="margin-left:28%"><b>Audit Dashboard</b></span>
    </div>
  </div>
  <div class="row" style="border:1px solid black; width:120%">
    <div *ngIf="showUserReports1">
      <kendo-splitter>
        <kendo-splitter-pane [collapsible]="true" [hidden]="!showUserReports" size="15%">
          <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" > -->
          <div *ngIf="!isLoadingAuditReport">
            <kendo-treeview [hidden]="!showUserReports"
                            [nodes]="reportData"
                            textField="name"
                            (selectionChange)="onSelectionChange($event)"
                            [hasChildren]="hasChildren"
                            [children]="fetchChildren"
                            kendoTreeViewExpandable
                            [(expandedKeys)]="expandedKeys"
                            kendoTreeViewSelectable
                            [(selectedKeys)]="selectedKeys"
                            (expand)="onExpand()"
                            (collapse)="onCollapse()" style="overflow:hidden">
              <ng-template kendoTreeViewNodeTemplate let-dataItem>
                <ng-container *ngIf="!dataItem.checkable">
                  <span> {{ dataItem.name }}</span>
                </ng-container>
                <ng-container *ngIf="dataItem.checkable">
                  <input type="checkbox"
                         [(ngModel)]="dataItem.checked"
                         [id]="dataItem.name"
                         (change)="onCheckboxChange(dataItem)" />  {{ dataItem.name }}
                </ng-container>
              </ng-template>
            </kendo-treeview>
          </div>
        </kendo-splitter-pane>
        <kendo-splitter-pane [collapsible]="true">
          <div *ngIf="!isLoadingAuditReport && !loadDashBoard" style="margin-top: -28px;">
            <app-audit-list [showDatePanel]="false" [sourcePage]="sourcePage" [displayLoadingIcon]="displayLoadingIcon"
                            [auditReportFromHome]="auditReportForSelectedID"></app-audit-list>
          </div>
          <div *ngIf="loadDashBoard && !isIpadVertical" style="height: 100%;">
            <div style="margin-top: 0px;display:flex;margin-left:20%">

              <div class="rounded-box" style="background-color: darkolivegreen; border-color: darkolivegreen; display:flex">
                <i style="font-size:3em" class="bi bi-list-check"></i>
                <span style="font-size:1.1em">{{dashboardReport && dashboardReport.totalProcessedItems}} <br />Total Audit Completed</span>
              </div>

              <!--<div class="rounded-box" style="background-color: rgb(172, 88, 255); border-color: rgb(172, 88, 255); display:flex">
                <i style="font-size:3em" class="bi bi-card-list"></i>
                <span style="font-size:1.1em">{{dashboardReport && dashboardReport.totalDraftItems}} <br />Total Draft Items</span>
              </div>-->

              <div class="rounded-box" style="background-color: rgb(75, 95, 250); border-color: rgb(75, 95, 250); display:flex">
                <i style="font-size:3em" class="bi bi-person-lines-fill"></i>
                <span style="font-size:1.1em"> {{dashboardReport && dashboardReport.totalAuditPendingItems }}<br />Total Audit Pending Items</span>
              </div>

              <div class="rounded-box" style="background-color: orchid; border-color: orchid; display:flex">
                <i style="font-size:3em" class="bi bi-tag"></i>
                <span style="font-size:1.1em"> {{dashboardReport && dashboardReport.totalAuditNotReviewItems}} <br />Total Un-Audited Items</span>
              </div>

            </div>
            <br />

            <div style="display: flex;flex-direction:column">
              <div style="height: 50%; text-align: left;">

                <div style="display: flex;">
                  <div style="width: 50%; text-align: left;">
                    <span style="padding-left:35%"><b> Total Un-Audited Items by each year</b></span>
                    <kendo-chart title="">
                      <kendo-chart-legend position="bottom"></kendo-chart-legend>
                      <kendo-chart-series>
                        <kendo-chart-series-item type="pie"
                                                 [data]="pieData"
                                                 field="value"
                                                 categoryField="category"
                                                 [labels]="{ visible: true, content: labelContent }">
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                    </kendo-chart>
                  </div>
                  <div style="width: 50%; text-align: center;padding-left:5px">
                    <span style="padding-left:5%"><b> Categories Count</b></span>
                    <kendo-chart>
                      <kendo-chart-title text=""></kendo-chart-title>
                      <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="categoryNames">
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-series>
                        <kendo-chart-series-item type="column"
                                                 [gap]="1"
                                                 [spacing]="0.0"
                                                 [data]="categoryValues" color="#E6F3E1"
                                                 [labels]="{ visible: true, format: '{0}' }">
                        </kendo-chart-series-item>
                      </kendo-chart-series>
                    </kendo-chart>
                  </div>
                </div>
              </div>
              <div style="height: 50%; text-align: center;padding-top:5%">
                <span style="padding-left:5%"><b>Top Ten Family Groups With Highest Audit Pending Items</b></span>
                <kendo-chart>
                  <kendo-chart-title text=""></kendo-chart-title>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="keynames">
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                  <kendo-chart-series>
                    <kendo-chart-series-item type="column"
                                             [gap]="1"
                                             [spacing]="0.0"
                                             [data]="values" color="lightblue"
                                             [labels]="{ visible: true, format: '{0}' }">
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                </kendo-chart>
              </div>
            </div>
          </div>
          <div *ngIf="loadDashBoard && isIpadVertical" style="height: 100%;">
            <div style="margin-top: 0px;display:flex; margin-left:10%">
              <div class="rounded-box" style="background-color: darkolivegreen; border-color: darkolivegreen; display:flex">
                <i style="font-size:3em" class="bi bi-list-check"></i>
                <span style="font-size:1.1em">{{dashboardReport && dashboardReport.totalProcessedItems}} <br />Total Processed Items</span>
              </div>
              <!--<div class="rounded-box" style="background-color: rgb(172, 88, 255); border-color: rgb(172, 88, 255); display:flex">
                <i style="font-size:3em" class="bi bi-card-list"></i>
                <span style="font-size:1.1em">{{dashboardReport && dashboardReport.totalDraftItems}} <br />Total Draft Items</span>
              </div>-->
            <!--</div>
            <div style="margin-top: 0px;display:flex; margin-left:20%">-->
              <div class="rounded-box" style="background-color: rgb(75, 95, 250); border-color: rgb(75, 95, 250); display:flex">
                <i style="font-size:3em" class="bi bi-person-lines-fill"></i>
                <span style="font-size:1.1em"> {{dashboardReport && dashboardReport.totalAuditPendingItems }}<br />Total Audit Pending Items</span>
              </div>
              <div class="rounded-box" style="background-color: orchid; border-color: orchid; display:flex">
                <i style="font-size:3em" class="bi bi-tag"></i>
                <span style="font-size:1.1em"> {{dashboardReport && dashboardReport.totalAuditNotReviewItems}} <br />Total Audit Not Reviewed Items</span>
              </div>
            </div>
            <br />
            <div style="height: 50%; text-align: left;">
              <div style="width: 80%; margin-left:10%">
                <span style="padding-left:35%"><b> Total Items By Each Year</b></span>
                <kendo-chart title="">
                  <kendo-chart-legend position="bottom"></kendo-chart-legend>
                  <kendo-chart-series>
                    <kendo-chart-series-item type="pie"
                                             [data]="pieData"
                                             field="value"
                                             categoryField="category"
                                             [labels]="{ visible: true, content: labelContent }">
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                </kendo-chart>
              </div>
              <div style="width: 80%; margin-left:10%">
                <span style="padding-left:25%"><b> Categories Count</b></span>
                <kendo-chart>
                  <kendo-chart-title text=""></kendo-chart-title>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="categoryNames">
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                  <kendo-chart-series>
                    <kendo-chart-series-item type="column"
                                             [gap]="1"
                                             [spacing]="0.0"
                                             [data]="categoryValues" color="#E6F3E1"
                                             [labels]="{ visible: true, format: '{0}' }">
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                </kendo-chart>
              </div>
              <div style="height: 60%; text-align: center;padding-top:5%">
                <span style="padding-left:5%"><b> Top Ten Family Groups With Highest Audit Items</b></span>
                <kendo-chart>
                  <kendo-chart-title text=""></kendo-chart-title>

                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="keynames">
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                  <kendo-chart-series>
                    <kendo-chart-series-item type="column"
                                             [gap]="1"
                                             [spacing]="0.0"
                                             [data]="values" color="lightblue"
                                             [labels]="{ visible: true, format: '{0}' }">
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                </kendo-chart>
              </div>
            </div>
          </div>

        </kendo-splitter-pane>
      </kendo-splitter>
    </div>
  </div>
</div>
<app-confirmation-dialog #confirmationDialogForRoot
                         (confirm)="onRootConfirm()"
                         (cancel)="onRootCancel()" [message]="confirmationMessge"></app-confirmation-dialog>
<app-confirmation-dialog #confirmationDialogForChild
                         (confirm)="onChildConfirm()"
                         (cancel)="onChildCancel()" [message]="confirmationMessge"></app-confirmation-dialog>

