import { Component, ComponentFactoryResolver, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { Data, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { DataService } from 'src/app/services/DataService';
import { RestApiService } from 'src/app/services/RestApiService.service';
import { AuditReport } from '../../Models/AuditReport';
import { GenerateReportSearch, LastRunDate } from '../../Models/Gtin';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.css']
})

export class AuditListComponent implements OnInit {

  lastRunDate: string = '';
  @Input() sourcePage = 'auditList';
  isIpadVertical = false;
  //lastModifiedBy = false;
  auditNotes = false;

  visitedLinks: string[] = [];
  @Input() showDatePanel = true;
  @Input() auditReportFromHome!: any[];
  userId!: any
  public today: Date = new Date();
  public threeMonthsAgo!: Date
  auditReport!: AuditReport[];
  filteredAuditReport!: AuditReport[];
  public gridView !: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  familyGroupIncludeIsCheckboxChecked = false;
  naturalIsCheckboxChecked = false;
  exludedIsCheckboxChecked = false;
  glutenFreeIsCheckboxChecked = false;
  organic1IsCheckboxChecked = false;
  organic2IsCheckboxChecked = false;
  organic3IsCheckboxChecked = false;
  public fromDate!: string;
  public storeNumber: string = "";
  public toDate!: string;
  selectedOption: string = 'Yes';
  userReportNames: any = [];

  //getFromDate!: string;
  //getToDate!: string;
  reportName!: string;
  @ViewChild('modalComponent') modalComponent: any;
  @Input() displayLoadingIcon: boolean = false;
  oldToDate!: string;

  changeReport() {
    this.skip = 0;
  }

  public state: State = {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{ field: 'gtin', operator: 'contains' }]
    }
  };

  loadUserReportNames() {
    this.restApiService.getUserReports(this.userId).subscribe(
      (data) => {
        this.userReportNames = data;
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isIpadVertical = window.innerWidth < window.innerHeight;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['auditReportFromHome']) {
      this.auditReport = changes['auditReportFromHome'].currentValue;
      this.filteredAuditReport = this.auditReport;
      this.filterAuditReort(false);
    }
  }
  toDayTemp!: Date;
  constructor(private restApiService: RestApiService, private router: Router, private dataService: DataService, private authService: MsalService) {
    this.restApiService.getLastAuditRunDate().subscribe(
      (data: LastRunDate) => {
        this.lastRunDate = data.lstAuditRunDate;
        const today = this.get18MonthsBeforeDate(this.lastRunDate);
        this.toDayTemp = today;
        const threeMonthsBack = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());

        this.dataService.currentStartDate.subscribe(startDate => {
          if (startDate !== '') {
            this.fromDate = startDate;
          }
          else {
            this.fromDate = threeMonthsBack.toISOString().split('T')[0];
          }
        });

        //this.fromDate = threeMonthsBack.toISOString().split('T')[0];
        this.dataService.currentendDate.subscribe(endate => {
          if (endate !== '') {
            this.toDate = endate;
            this.oldToDate = endate;
          }
          else {
            this.toDate = this.get18MonthsBeforeDate(this.lastRunDate).toISOString().split('T')[0];
            this.oldToDate = this.toDate;
          }
        });


        if (this.showDatePanel) {
          this.getData();
          this.restApiService.quaggaStop();
        }
      },
      (error: any) => {
        console.error('lastRunDate error occurred:', error);
      }
    );
    var accountInfo = this.authService.instance.getAllAccounts();
    if (accountInfo != null && accountInfo.length > 0 && accountInfo[0].idTokenClaims) {
      this.userId = accountInfo[0].idTokenClaims['sAMAccountName'];
    }
    this.loadUserReportNames();
  }


  isLinkVisited(gtin: string): boolean {
    return this.visitedLinks.includes(gtin);
  }

  //familyGroupInclude(event: any) {
  //  alert(this.familyGroupIncludeisChecked);
  //  if (event.target.checked) {

  //    }
  //}



  getDataOnClickingSearchButton(): void {
    if (this.isDateGreaterThanToday(this.toDate)) {
      alert('Please select valid dates. Make sure you are selected date not later than ' + this.formatDate(this.get18MonthsBeforeDate(this.lastRunDate)));
      return;
    }
    this.dataService.changeSource('');
    this.dataService.changePage(0);
    this.getData();
  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  // Helper method to get the maximum allowed date (today)
  getMaxDate(): string {
    return this.getFormattedDate(this.get18MonthsBeforeDate(this.lastRunDate));
  }

  // Helper method to get the minimum allowed date (defaulting to 1 year ago)
  getMinDate(): string {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 2);
    return this.getFormattedDate(oneYearAgo);
  }

  // Helper method to check if a given date is greater than today's date
  isDateGreaterThanToday(date: string): boolean {
    return new Date(date) > this.get18MonthsBeforeDate(this.lastRunDate); //new Date();
  }


  // Event handler for updating the 'toDate' control's min attribute when 'fromDate' changes
  updateToDateMinDate(): void {
    if (this.toDate && new Date(this.fromDate) > new Date(this.toDate)) {
      this.toDate = this.fromDate;
    }
  }

  onToDateChange(newDate: string) {
    if (this.isDateGreaterThanToday(this.toDate)) {
      alert('Please select valid dates. Make sure you are selected date not later than ' + this.formatDate(this.get18MonthsBeforeDate(this.lastRunDate)));
      setTimeout(() => {
        this.toDate = this.oldToDate;
      }, 0);

    } else {
      this.toDate = newDate;
      this.oldToDate = newDate;
      this.updateFromDateMaxDate();
    }
  }

  // Event handler for updating the 'fromDate' control's max attribute when 'toDate' changes
  updateFromDateMaxDate(): void {
    if (this.fromDate && new Date(this.toDate) < new Date(this.fromDate)) {
      this.fromDate = this.toDate;
    }
  }

  getData(): void {

    this.dataService.currentSource.subscribe(x => {
      if (x == '') {

        this.dataService.changePage(0);
        this.skip = 0;
      }
    })
    this.getAuditList();
  }


  parseDate(dateString: string): Date {
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Month is 0-based in Date constructor
  }

  formatDate(date: Date): string {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${month}/${day}/${year}`;
  }

  get18MonthsBeforeDate(baseDateStr: string): Date {
    const baseDate = this.parseDate(baseDateStr);
    const resultDate = new Date(baseDate);

    resultDate.setMonth(resultDate.getMonth() - 18);

    return resultDate;
  }

  async ngOnInit(): Promise<void> {

    setInterval(() => {
      this.isIpadVertical = window.innerWidth < window.innerHeight;
    }, 1000);
  

    this.dataService.CurrentstoreNumber.subscribe(storeNumber => {
      if (storeNumber !== '') {
        this.storeNumber = storeNumber;
      }
    });

    
    if (!this.fromDate) {
      this.fromDate = this.today.toISOString().split('T')[0];
    }

    this.dataService.currentPageNumber.subscribe(page => {
      if (page !== 0) {
        this.skip = (page - 1) * this.pageSize;
      }
    });


    this.dataService.currentFamilyGroupInclude.subscribe(status => {
      if (status !== '') {
        this.familyGroupIncludeOption = status;
      }
    });
    this.dataService.changecurrentFamilyGroupInclude('');


    this.dataService.currentFamilyGroupNmae.subscribe(status => {
      if (status !== '') {
        this.familyGroupName = status;
      }
    });
    this.dataService.changecurrentFamilyGroupNmae('');


    this.dataService.currentFamilyGroupId.subscribe(status => {
      if (status !== '') {
        this.familyGroupID = status;
      }
    });
    this.dataService.changecurrentFamilyGroupId('');


    this.dataService.currentNatural.subscribe(status => {
      if (status !== '') {
        this.naturalOption = status;
      }
    });
    this.dataService.changecurrentNatural('');


    this.dataService.currentExclude.subscribe(status => {
      if (status !== '') {
        this.exludeOption = status;
      }
    });
    this.dataService.changecurrentExclude('');

    //this.dataService.currentSelectedValues.subscribe(x => {
    //  if (x.length > 0) {
    //    this.filteredAuditReport = x;
    //  }
    //});

    this.dataService.currentGlutenFree.subscribe(status => {
      if (status !== '') {
        this.glutenFreeoption = status;
      }
    });
    this.dataService.changecurrentGlutenFree('');


    this.dataService.currentOrganic1.subscribe(status => {
      if (status !== '') {
        this.organic1option = status;
      }
    });
    this.dataService.changecurrentOrganic1('');

    this.dataService.currentOrganic2.subscribe(status => {
      if (status !== '') {
        this.organic2option = status;
      }
    });
    this.dataService.changecurrentOrganic2('');

    this.dataService.currentOrganic3.subscribe(status => {
      if (status !== '') {
        this.organic3option = status;
      }
    });
    this.dataService.changecurrentOrganic3('');


    //this.dataService.changeCurrentEndDate('');
    //this.dataService.changeCurrentStartDate('');
    this.dataService.changeCurrentAuditReport('');
    this.dataService.changePage(0);
    this.dataService.visitedLinks.subscribe(links => {
      this.visitedLinks = links;
    });
    this.filterAuditReort(false);
    this.dataService.changeselectedValues([]);

    //this.dataService.changeSource('');
  }
  filterOnloadData() {
    // this.familyGroupName = 'CRACKERS';
    if (this.familyGroupName != '') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.familyGroupName.toLowerCase().indexOf(this.familyGroupName.toLowerCase()) >= 0);
    }
    if (this.familyGroupID != '') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.familyGroupCode.toLowerCase().indexOf(this.familyGroupID.toLowerCase()) >= 0);
    }
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
  }
  public sort: SortDescriptor[] = [
    {
      field: "status",
      dir: "asc",
    },
  ];

  navigateSearchPage(gtinCode: any, status: any) {
    //this.dataService.changeFromAuditPage(true);
    this.dataService.changeStoreNumber(this.storeNumber);
    this.dataService.changePage(this.skip / 10 + 1);
    this.dataService.changeCurrentEndDate(this.toDate);
    this.dataService.changeCurrentStartDate(this.fromDate);
    this.dataService.changeMessage(gtinCode);
    this.dataService.changeStatus(status);
    this.dataService.changeSource(this.sourcePage);
    this.dataService.addVisitedLinks(gtinCode);
    const url = `search?fromAudit=true`;
    this.router.navigateByUrl(url);
    this.dataService.changecurrentloadDashBoard('N');

    const auditNotes = this.filteredAuditReport.filter(x => x.gtin == gtinCode)[0].auditNotes;
    this.dataService.changeCurrentAuditNotes(auditNotes);

    if (this.familyGroupIncludeOption != 'YN') {
      this.dataService.changecurrentFamilyGroupInclude(this.familyGroupIncludeOption);
    }
    if (this.naturalOption != 'YN') {
      this.dataService.changecurrentNatural(this.naturalOption);
    }
    if (this.exludeOption != 'YN') {
      this.dataService.changecurrentExclude(this.exludeOption);
    }
    if (this.glutenFreeoption != 'YN') {
      this.dataService.changecurrentGlutenFree(this.glutenFreeoption);
    }
    if (this.organic1option != 'YN') {
      this.dataService.changecurrentOrganic1(this.organic1option);
    }
    if (this.organic2option != 'YN') {
      this.dataService.changecurrentOrganic2(this.organic2option);
    }
    if (this.organic3option != 'YN') {
      this.dataService.changecurrentOrganic3(this.organic3option);
    }
    if (this.familyGroupName != '') {
      this.dataService.changecurrentFamilyGroupNmae(this.familyGroupName);
    }
    if (this.familyGroupID != '') {
      this.dataService.changecurrentFamilyGroupId(this.familyGroupID);
    }
    if (this.filteredAuditReport.length > 0) {
      this.dataService.changeselectedValues(this.filteredAuditReport);
    }
  }
  getAuditList() {
    const fromdateParts = this.fromDate.split('-');
    const fromyear = parseInt(fromdateParts[0], 10);
    const frommonth = parseInt(fromdateParts[1], 10) - 1; // Adjusting for zero-based month index
    const fromday = parseInt(fromdateParts[2], 10);

    const todateParts = this.toDate.split('-');
    const toyear = parseInt(todateParts[0], 10);
    const tomonth = parseInt(todateParts[1], 10) - 1; // Adjusting for zero-based month index
    const today = parseInt(todateParts[2], 10);

    this.displayLoadingIcon = true;
    this.restApiService.getAuditReport(new Date(fromyear, frommonth, fromday), new Date(toyear, tomonth, today), this.storeNumber).subscribe((data: any) => {
      this.dataService.changeCurrentAuditReport(JSON.stringify(data.auditReportList));
      this.auditReport = JSON.parse(JSON.stringify(data.auditReportList));

      this.filteredAuditReport = this.auditReport;
      this.filterOnloadData();
      this.loadItems();
      this.displayLoadingIcon = false;
      this.dataService.currentSelectedValues.subscribe(x => {
        if (x.length > 0) {
          this.filteredAuditReport = x;
        }
      });
    });
  }

  @ViewChild("panel", { static: false }) public panel!: ExpansionPanelComponent;

  public toggleContent(): void {
    this.panel.toggle();
  }


  generateAuditReport() {

    const index = this.userReportNames.indexOf(this.reportName.toLowerCase().trim());
    //alert(index);
    if (index !== -1) {
      this.modalData.message = `The report name is already exist. Enter another name.`;
      this.modalData.header = 'Audit Report Generation';
      this.modalComponent.show();
      return;
    }

    if (this.filteredAuditReport.length == 0) {
      this.modalData.message = 'Please select atleast one record.';
      this.modalData.header = 'Audit Report Generation';
      this.modalComponent.show();
      return;
    }

    const fromdateParts = this.fromDate.split('-');
    const fromyear = parseInt(fromdateParts[0], 10);
    const frommonth = parseInt(fromdateParts[1], 10) - 1; // Adjusting for zero-based month index
    const fromday = parseInt(fromdateParts[2], 10);

    const todateParts = this.toDate.split('-');
    const toyear = parseInt(todateParts[0], 10);
    const tomonth = parseInt(todateParts[1], 10) - 1; // Adjusting for zero-based month index
    const today = parseInt(todateParts[2], 10);

    const fromMonth = (new Date(fromyear, frommonth, fromday).getMonth() + 1).toString().padStart(2, '0');
    const fromDay = new Date(fromyear, frommonth, fromday).getDate().toString().padStart(2, '0');
    const fromYear = new Date(fromyear, frommonth, fromday).getFullYear().toString();
    const fromDateInDesiredFormat = `${fromMonth}/${fromDay}/${fromYear}`;

    const toMonth = (new Date(toyear, tomonth, today).getMonth() + 1).toString().padStart(2, '0');
    const toDay = new Date(toyear, tomonth, today).getDate().toString().padStart(2, '0');
    const toYear = new Date(toyear, tomonth, today).getFullYear().toString();
    const toDateInDesiredFormat = `${toMonth}/${toDay}/${toYear}`;

    var generateReportSearch = new GenerateReportSearch(fromDateInDesiredFormat, toDateInDesiredFormat, this.reportName, this.exludeOption,
      this.naturalOption, this.organic1option, this.organic2option, this.organic3option, this.glutenFreeoption, this.familyGroupName, this.familyGroupID, this.userId, this.storeNumber);

    this.displayLoadingIcon = true;

    var status = this.restApiService.generateAuditReport(generateReportSearch).subscribe((data: any) => {
      this.displayLoadingIcon = false;
      if (!data) {
        this.modalData.message = 'Something went wrong.Report is not generated';
        this.modalData.header = 'Audit Report Generation';
        this.modalComponent.show();
      }
      else {
        this.modalData.message = 'Report has been generated for the selected search criteria.';
        this.modalData.header = 'Audit Report Generation';
        this.modalComponent.show();
        this.auditReport = this.auditReport.filter(item => !this.filteredAuditReport.includes(item));
        this.filteredAuditReport = [];
      }
    });

    //var status = this.restApiService.generateAuditReport(new Date(fromyear, frommonth, fromday), new Date(toyear, tomonth, today), this.reportName).subscribe((data: any) => {
    //  this.displayLoadingIcon = false;
    //  if (!data) {
    //    this.modalData.message = 'Already report exixts for the slected date range.';
    //    this.modalData.header = 'Audit Report Generation';
    //    this.modalComponent.show();
    //  }
    //  else {
    //    this.modalData.message = 'Report has been generated for the selected date range.';
    //    this.modalData.header = 'Audit Report Generation';
    //    this.modalComponent.show();
    //  }
    //});

  }
  cureentpage: number = 1;

  filterAuditReort(setskip: boolean) {
    if (setskip) {
      this.skip = 0;
    }
    this.filteredAuditReport = this.auditReport;
    if (this.familyGroupIncludeOption != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.familyGroupInclude == this.familyGroupIncludeOption);
    }
    if (this.naturalOption != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.natural == this.naturalOption);
    }
    if (this.exludeOption != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.excluded == this.exludeOption);
    }
    if (this.glutenFreeoption != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.glutenFree == this.glutenFreeoption);
    }
    if (this.organic1option != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.organic1 == this.organic1option);
    }
    if (this.organic2option != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.organic2 == this.organic2option);
    }
    if (this.organic3option != 'YN') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.organic3 == this.organic3option);
    }
    if (this.familyGroupName != '') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.familyGroupName.toLowerCase().indexOf(this.familyGroupName.toLowerCase()) >= 0);
    }
    if (this.familyGroupID != '') {
      this.filteredAuditReport = this.filteredAuditReport.filter(x => x.familyGroupCode.toLowerCase().indexOf(this.familyGroupID.toLowerCase()) >= 0);
    }
  }
  naturalOption: string = 'YN';
  familyGroupIncludeOption: string = 'YN';
  exludeOption: string = 'YN';
  glutenFreeoption: string = 'YN';
  organic1option: string = 'YN';
  organic2option: string = 'YN';
  organic3option: string = 'YN';
  familyGroupName: string = '';
  familyGroupID: string = '';


  selectFgNameOrFGId() {
    this.filterAuditReort(true);
  }

  selectGlutenFreeoption(option: string) {
    this.glutenFreeoption = option;
    this.filterAuditReort(true);
  }
  selectFamilyGroupIncludeOption(option: string) {
    this.familyGroupIncludeOption = option;
    this.filterAuditReort(true);
  }
  selectNaturalOption(option: string) {
    this.naturalOption = option;
    this.filterAuditReort(true);
  }
  selectExludeOption(option: string) {
    this.exludeOption = option;
    this.filterAuditReort(true);
  }

  selectorganic1option(option: string) {
    this.organic1option = option;
    this.filterAuditReort(true);
  }
  selectorganic2option(option: string) {
    this.organic2option = option;
    this.filterAuditReort(true);
  }
  selectorganic3option(option: string) {
    this.organic3option = option;
    this.filterAuditReort(true);
  }

  public pageChange(event: PageChangeEvent): void {
    // this.dataService.changePage(event.skip / event.take + 1);
    this.skip = event.skip;
    this.loadItems();
  }
  modalData = {
    isSuccess: false,
    message: '',
    header: ''
  }
  closeModal() {
    this.modalComponent.hide();
  }
  private loadItems(): void {
    this.gridView = {
      data: this.auditReport.slice(this.skip, this.skip + this.pageSize),
      total: this.auditReport.length,
    };
  }
}
