import { IcomponentDescription } from "../capture-image/models/componentinfo";

export interface ApiResponse {
  dashBoardReport: DashboardReport;
  status: string;
}

export interface DashboardReport {
   totalProcessedItems: number;
   totalDraftItems: number;
   totalAuditPendingItems: number;
   totalAuditNotReviewItems: number;
  topTenFamilyGroupsWithHighestAuditItems: { [key: string]: number };
  categoriesCount: { [key: string]: number };
  totalItemsByEachYear: { [key: number]: number };
}

//export class DashboardReport {
//  public totalProcessedItems: number=0;
//  public totalDraftItems!: number;
//  public totalAuditPendingItems!: number;
//  public totalAuditNotReviewItems!: number;
//  public topTenFamilyGroupsWithHighestAuditItems!: { [key: string]: number };
//  public totalItemsByEachQuarter!: { [key: string]: number };
//}




export class MutliColumnSet {
  public index: number = -1;
 public  componentID!: number;
  public column!: number;
  public isDualColumn!: boolean;
  public description!: string;
  public isNewComponent!: boolean;
  constructor(_componentID: number, _column: number, _isDualColumn: boolean, _description: string, _isNewComponent: boolean, _index: number) {
    this.componentID = _componentID;
    this.column = _column;
    this.isDualColumn = _isDualColumn;
    this.description = _description;
    this.isNewComponent = _isNewComponent;
    this.index = _index;
  }
}

export class ComponentSet {

  public multiColSet!: MutliColumnSet[];
  public componentSet!: Array<IcomponentDescription>;

}

export interface GtinResult {
  Status: number;
  Message: string;
  Value: string;
}
export class GTINConverter {

  convertUpcToGtin(upce: string): string {
    if (upce.length !== 6) {
      throw new Error('Invalid 6-digit UPC-E.');
    }

    const systemNumber = '0';

    let upcaMainBody: string;
    switch (upce.charAt(5)) {
      case '0':
      case '1':
      case '2':
        upcaMainBody = upce.substring(0, 2) + upce.charAt(5) + '0000' + upce.substring(2, 5);
        break;
      case '3':
        upcaMainBody = upce.substring(0, 3) + '00000' + upce.substring(3, 5);
        break;
      case '4':
        upcaMainBody = upce.substring(0, 4) + '00000' + upce.charAt(4);
        break;
      default:
        upcaMainBody = upce.substring(0, 5) + '0000' + upce.charAt(5);
        break;
    }

    const upcaWithoutCheckDigit = systemNumber + upcaMainBody;
    const checkDigit = this.calculateUpcCheckDigit(upcaWithoutCheckDigit);

    return upcaWithoutCheckDigit + checkDigit;
  }
  calculateUpcCheckDigit(upc: string): number {
    let sum = 0;

    for (let i = 0; i < upc.length; i++) {
      const digit = parseInt(upc.charAt(i), 10);
      if (i % 2 === 0) { // even-indexed (0-based) digit
        sum += digit;
      } else { // odd-indexed digit
        sum += digit * 3;
      }
    }

    const mod = sum % 10;
    return mod === 0 ? 0 : 10 - mod;
  }



  convertUpcToGtin1(upcNumber: string): GtinResult {
    let res: GtinResult = { Status: 0, Message: "", Value: "" };
    let numberOfDigits: number = 0;
    let GTIN: string = '';

    if (/^\d+$/.test(upcNumber)) {
      let temp: number = parseInt(upcNumber, 10);
      if (temp <= 0) {
        return { Status: -1, Message: "Invalid UPC code. It must be a positive number.", Value: '' };
      }
      numberOfDigits = Math.floor(Math.log10(temp) + 1);
      if (numberOfDigits > 6) {
        return { Status: -1, Message: "Invalid UPC code. Length should not be more than 6 digits.", Value: '' };
      }

      let upcAfterPadding: string = GTINHelper.padZeroesToUpc(numberOfDigits, temp);
      let upcAfterExtractingAndConcat: string = GTINHelper.ExtractDigitsAndConcat(upcAfterPadding);

      if (upcAfterExtractingAndConcat.length === 10) {
        GTIN = upcAfterExtractingAndConcat.padStart(13, '0');
        let isGtinValid: boolean = GTINHelper.ValidateGtin(GTIN);
        if (isGtinValid) {
          let result: string = GTINHelper.CalculateCheckDigit(GTIN).toString();
          return { Status: 0, Message: "Given UPC is successfully converted to Valid Gtin", Value: GTIN + result };
        } else {
          return { Status: -1, Message: "Invalid UPC code", Value: GTIN };
        }
      } else {
        return { Status: -1, Message: "Invalid UPC code", Value: GTIN };
      }
    } else {
      return { Status: -1, Message: "Invalid UPC code. It must contain only numerical values.", Value: GTIN };
    }
  }
}
export class GTINHelper {
  static padZeroesToUpc(numberOfDigits: number, UpcNumber: number): string {
    let padCount: number = 6;
    if (numberOfDigits === 6) {
      padCount = 0;
    }
    let valueAfterPadding: string = UpcNumber.toString().padStart(padCount, '0');
    return valueAfterPadding;
  }

  static ExtractDigitsAndConcat(upcAfterPadding: string): string {
    if (upcAfterPadding) {
      const lastDigit: number = parseInt(upcAfterPadding[upcAfterPadding.length - 1].toString(), 10);
      let finalGTIN: string = '';

      switch (lastDigit) {
        case 0:
        case 1:
        case 2:
          finalGTIN = `${upcAfterPadding.substring(0, 2)}${upcAfterPadding.substring(5, 6)}0000${upcAfterPadding.substring(2, 5)}`;
          break;
        case 3:
          finalGTIN = `${upcAfterPadding.substring(0, 3)}00000${upcAfterPadding.substring(3, 5)}`;
          break;
        case 4:
          finalGTIN = `${upcAfterPadding.substring(0, 4)}00000${upcAfterPadding.substring(4, 5)}`;
          break;
        default:
          finalGTIN = `${upcAfterPadding.substring(0, 5)}0000${upcAfterPadding.substring(5, 6)}`;
          break;
      }

      return finalGTIN;
    }
    return '';
  }

  static ValidateGtin(Gtin: string): boolean {
    if (Gtin.length === 13) {
      const temp: string = Gtin.replace(/^0+/, '');  // This will trim starting zeros
      if (temp.length >= 8) {
        return true;
      }
    }
    return false;
  }

  static CalculateCheckDigit(Gtin: string): number {
    let sum: number = 0;
    for (let i = 0; i < Gtin.length; i++) {
      const num: number = +Gtin[i];  // the unary plus is a quick way to convert a string to a number
      sum += (i + 1) % 2 === 0 ? num : num * 3;
    }
    const rem: number = sum % 10;
    return rem === 0 ? 0 : 10 - rem;
  }
}
export class ReportData {
  id!: any;
  name!: string;
  userReports!: Report[];
  checkable!: boolean;
}
export class Report {
  name!: string;
  id!: number;
  checkable!: boolean;
  reportCreateDate!: string;
}
export interface LastRunDate {
  lstAuditRunDate: string;
}
export class GenerateReportSearch {
  public fromDate!: string;
  public toDate!: string;
  public reportName!: string;
  public excluded!: string;
  public natural!: string;
  public organic1!: string;
  public organic2!: string;
  public organic3!: string;
  public glutenFree!: string;
  public familyGroupName!: string;
  public familyGroupID!: string;
  public loggedInUserPNumber!: string;
  public storeNumber!: string;
  
  constructor(_fromDate: string, _toDate: string, _reportName: string, _excluded: string, _natural: string, _organic1: string, _organic2: string,
    _organic3: string, _glutenFree: string, _familyGroupName: string, _familyGroupID: string, _loggedInUser: string, _storeNumber: string) {
    this.fromDate = _fromDate;
    this.toDate = _toDate;
    this.reportName = _reportName;
    this.excluded = _excluded;
    this.natural = _natural;
    this.organic1 = _organic1;
    this.organic2 = _organic2;
    this.organic3 = _organic3;
    this.glutenFree = _glutenFree;
    this.familyGroupName = _familyGroupName;
    this.familyGroupID = _familyGroupID;
    this.loggedInUserPNumber = _loggedInUser;
    this.storeNumber = _storeNumber;
  }
}
export class AuditNotes {
  notes!: string;
  gtin!: string;
  constructor(_notes: string, _gtin: string) {
    this.notes = _notes;
    this.gtin = _gtin;
  }
}
export class UpdatedRecordCollection {
  type!: string
  user!: string
  UpdateOCRRecords: RecordsToUpdateDValue[] = [];
  constructor(_type: string, _user: string, _UpdateOCRRecords: RecordsToUpdateDValue[]) {
    this.UpdateOCRRecords = _UpdateOCRRecords;
    this.type = _type;
    this.user = _user;
  }
}
export class AllergenIngredinatsUpdatedData {
  type!: string;
  componentID!: string;
  updateData!: string;
  constructor(_type: string, _componentID: string, _updateData: string) {
    this.type = _type;
    this.componentID = _componentID;
    this.updateData = _updateData;
  }
}
export class RecordsToUpdateDValue {
  gtin!: string;
  componentID!: string;
  nutrientID!: number;
  value!: string;
  constructor(_gtin: string, _componentID: string, _nutrientID: number, _value: string) {
    this.gtin = _gtin;
    this.componentID = _componentID;
    this.nutrientID = _nutrientID;
    this.value = _value;
  }
}
export interface NutrientValuesDetail {
  nutrientID: number;
  nutrientName: string;
  effectiveDt: string;
  terminationDt: string;
  nutrValue: string;
  dvp: string;
  displayValue: string;
  dualNutrValue: string;
  dualDVP: string;
  duaL_DisplayValue: string;
  uom: string;
  dualuom: string;
}
export interface Component {
  gtin: string;
  nutrInfoID: string;
  nutrSourceCd: string;
  segment: string;
  componentID: string;
  description: string | undefined;
  servingSizeVal: string;
  servingSizeUom: string;
  householdServingSize: string;
  householdServingUom: string;
  visualServingUom: string;
  servingPerPackage: string;
  panelType: string;
  itemHeader: string;
  dualItemHeader?: any;
  allergenList: string;
  ingredientList: string;
  nutrientValuesDetails: NutrientValuesDetail[];
  unwantedIngridients: string;
  isComponentDataExist: boolean;
}
export interface Gtin {
  components: Component[];
  itemCode?: any;
  itemDescription?: any;
  pimsAttribues: PIMSAttributes;
  status: string;
  auditNotes: string;
  auditStatusName: string;
  isPartOfAudit: boolean;
}
export interface PIMSAttributes {
  natural: string;
  excluded: string;
  glutenfree: string;
  organic1: string;
  organic2: string;
  organic3: string;
}
export interface NutrientsDto {
  nutrientID: number
  nutrientName: string
  effectiveDt: string
  terminationDt: string
  nutrValue: string
  dvp: any
  displayValue: string
  dualNutrValue: any
  dualDVP: any
  duaL_DisplayValue: any
  uom: string
}
export interface NutritionValue {
  nutrientID: number
  showFact: boolean
  showDualFact: boolean
  showDv: boolean
  showDualDv: boolean
}



