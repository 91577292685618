<div style="padding-top:100px">
  <input type="text" [(ngModel)]="gtin" placeholder="Enter GTIN without check digit.." 
         name="gtinItemcode" />
  <button style="height:25px;width:25px" (click)="getimage()"></button>
  <kendo-scrollview [data]="items" [arrows]="true">
    <ng-template let-item="item">
      <img *ngIf="item!=='noimage'" src="{{ item }}" draggable="false"
           style=" height: 500px;
        width: 400px;" />
    </ng-template>
  </kendo-scrollview>
  <h1>Testing</h1>
</div>

