import { Component, Input, OnInit } from '@angular/core'; 
import { Article } from '../article'

@Component({
  selector: 'app-barcode-item',
  template:`
  <div *ngIf="article !== undefined" class="shopping-cart-item">

    <img class="article-image" [src]="article.image" [alt]="article.name">
  
    <div class="article-name">{{ article.name }}</div>
  
    <div class="article-count">{{ count }}</div>
  </div>
  `,
})
export class Barcodeitem implements OnInit {

  constructor() {
  }

  @Input()
  article: Article | undefined;

  @Input()
  count: number | undefined;

  ngOnInit(): void {
  }

}

