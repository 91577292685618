
<div *ngIf="isLoadingCompareItems" class="spinner-border" role="status"
     style="position:absolute;top:50%;left:50%; height: 50px; width: 50px;">
  <span class="sr-only">Loading...</span>
</div>
<div style="display: flex; justify-content: center; align-items: center; height: 100vh;" *ngIf="loadingError">
  <p>Error occurred while loading either PNMS/OCR data!</p>
</div>

<div *ngIf="!isLoadingCompareItems && !loadingError">
  <div class="px-4 py-2 pb-0 mt-5 text-center" align="center" style="border: 1px solid rgba(0, 0, 0, 0.125);border-radius: 0.25rem;margin-left: 1%;margin-right: 1%;">
    <div class="col-lg-4 mx-auto" style="width:100%;">
      <div *ngIf="getGtinPNMSCount()==1">
        <div class="form-check-label font-head" for="Item">
          <span style="float:left">Item Code: {{pnmsGtinResults.itemCode}} </span>
          <span>GTIN: {{pnmsGtinResults.components[id-1].gtin}}</span>
          <span style="float:right"> Description: {{pnmsGtinResults.itemDescription}}</span>
        </div>
        <!-- <div class="form-check-label font-head" for="Item">Gtin: {{pnmsGtinResults.components[id-1].gtin}}</div>
        <div class="form-check-label font-head" for="Item">Description: {{pnmsGtinResults.itemDescription}}</div> -->
      </div>
    </div>
    <div *ngIf="getGtinPNMSCount()>1" class="left col-lg-4 mx-auto" style="width: 100%; margin-top: 5px;">
      <ng-container *ngFor="let i of pnmsGtinResults.components;index as t">
        <div [id]="t+1" *ngIf="t+1==id">
          <div style="display: table;">
            <div class="form-check-label font-head" for="Item" style="display: table-row;">
              <div style="float:left;margin-left: 100px;width:170px; display: table-column;text-align: left;">Item Code: {{pnmsGtinResults.itemCode}} </div>
              <div style="float:left;margin-left: 100px;width:370px;display: table-column;">
                GTIN: {{i.gtin}}
              </div>
              <!--<div style="display: table-column; float: right; width:370px;text-align: left;">
                Variety Pack
                <span *ngFor="let j of pnmsGtinResults.components;index as k"><a [id]="k+1+'x'"
                      [ngClass]="k+1+'x'===id+'x'?'inactiveLink':'activeLink'"
                      style="text-decoration: underline;padding-right: 3px; ">{{k+1}}</a></span>
              </div>-->
              <div style="display: table-column; float: right; width:370;text-align: left;">
                Variety Pack
                <span *ngFor="let j of pnmsGtinResults.components;index as k">
                  <a (click)="ShowAndHide(k+1)" [id]="k+1+'x'" [ngClass]="k+1+'x'===id+'x'?'inactiveLink':'activeLink'" style="text-decoration: underline;padding-right: 3px; ">{{k+1}}</a>
                </span>
              </div>

            </div>
          </div>
          <div class="form-check-label font-head" style="text-align:left; padding-left: 100px;">
            Description: {{getocrDescription(i)}}
          </div>
        </div>

      </ng-container>
    </div>
    <!--<div *ngIf="getGtinPNMSCount()>1"
         style="display: flex; align-items: center; justify-content: flex-end; white-space: nowrap;">

      <span style="float:left">Item Code: {{pnmsGtinResults.itemCode}} </span>
      <span>GTIN: {{pnmsGtinResults.components[id-1].gtin}}</span>

      <ng-container *ngFor="let j of pnmsGtinResults.components;index as t">
        <div [id]="t+1" *ngIf="t+1==id">

          <div style="display: inline-block; vertical-align: middle;">
            Variety Pack
            <ng-container *ngFor="let i of pnmsGtinResults.components;index as k">
              <a (click)="ShowAndHide(k+1)" [id]="k+1+'x'" [ngClass]="k+1+'x'===id+'x'?'inactiveLink':'activeLink'"
                 style="text-decoration: underline;">{{k+1}}&nbsp;</a>
            </ng-container>
            <div class="form-check-label font-head" style="text-align:left; padding-left: 100px;">
              Description: {{j.description}}
            </div>
          </div>
        </div>
      </ng-container>
    </div>-->

  </div>
  <!--max-height: 682px;-->
  <div class="container-fluid comparegrid" [ngStyle]="getStyle()">
    <div class="row">
      <div class="container1">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 captured-image compareborder div1" style="padding-right: 1px;
        padding-left: 1px;">
          <!--<div style="background-color:#2f880f;
    color:white;">
    <h5>Image Data</h5>  <i class="bi bi-images" (click)="onExpand()"></i>

  </div>-->

          <div style="background-color:#2f880f; color:white; display: flex; justify-content: space-between; align-items: center;">
            <h5 style="margin: 0;padding-left:40%">Image Data</h5>
            <i class="bi bi-images" style="cursor: pointer;" (click)="onExpand()"></i>
          </div>


          <search-gtin-OCR-results (updatedRecordDisplayValue)="getUpdatedRecordDispalyValue($event)"
                                   (updatedRecordDvp)="getUpdatedRecordDvp($event)"
                                   (updatedRecorDualdDvp)="getUpdatedRecordDualDvp($event)"
                                   (updatedRecordDualDisplayValue)="getUpdatedRecordDualDispalyValue($event)"
                                   (allergenIngredinatsUpdatedData)="onUpdateallergenIngredinatsUpdatedData($event)"
                                   [searchPNMSGtinResultsToCompare]="pnmsGtinResults" [searchGtinResults]="ocrGtinResults"
                                   (ResetIngredintns)="resetIngredintns($event)"
                                   (ResetAllergen)="resetAllergen($event)"
                                   [pageNumber]="id"></search-gtin-OCR-results>


        </div>



        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 captured-image compareborder div2" style="padding-right: 1px;
        padding-left: 1px;">
          <!--<kendo-expansionpanel #panel title="" style="background-color: #E6F3E1;" id="searchFilter"  (expand)="onExpand()"
       (collapse)="onCollapse()" subtitle="Image">
           <div *ngIf="shwoImage==true" style="text-align: center; background-color:#2f880f;
  color:white;">
             <h5>PNMS</h5>
           </div>-->
          <div style="text-align: center; background-color:#2f880f;
  color:white;"  *ngIf="shwoImage==false">
            <h5>
              PNMS 
            </h5>
          </div>
          <div *ngIf="shwoImage==true">
            <search-gtin-PNMS-results (updateOCRingrediants)="updateOCRingrediants($event)"
                                      (updateOCRAllergen)="updateOCRAllergen($event)"
                                      [searchGtinResults]="pnmsGtinResults"
                                      [pageNumber]="id" [shwoImage]="shwoImage"></search-gtin-PNMS-results>
          </div>
          <!--</kendo-expansionpanel>-->

          <div *ngIf="shwoImage==false">
            <search-gtin-PNMS-results (updateOCRingrediants)="updateOCRingrediants($event)"
                                      (updateOCRAllergen)="updateOCRAllergen($event)"
                                      [searchGtinResults]="pnmsGtinResults"
                                      [pageNumber]="id" [shwoImage]="shwoImage"></search-gtin-PNMS-results>
          </div>
          <!-- <hr> -->
          <div class="row" style="position: fixed; bottom: 2%; right: 4%">
            <div style="display: flex; flex-direction: row;">
              <button id="btnSaveDraft" [disabled]="ocrGtinResults.status=='complete'" (click)="updateOCRDBwithUpdatedRecotds()" style="margin-left: 10px;"
                      class="btn btn-secondary  buttonsuccess">
                Save Draft
              </button>
              <button id="btnUpdatePNMS" [disabled]="ocrGtinResults.status=='complete'" (click)="openConfirmationDialog()" style="margin-left: 10px;"
                      class="btn btn-secondary  buttonsuccess">
                Update PNMS
              </button>
              <!-- <div class="flash-message" [ngStyle]="{'display': showFlashMessage ? 'block' : 'none'}">
          {{flashMessage}}
      </div> -->
              <!--<button id="btnUpdatePNMS" [disabled]="ocrGtinResults.status=='complete'" (click)="gotosearchpage()" style="margin-left: 10px;"
              class="btn btn-secondary  buttonsuccess">
       Go To Search
      </button>-->

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- alert dialog-->
<app-modal #modalComponent>
  <h1 class="modal-title fs-5 app-modal-header">{{modalData.header}}</h1>
  <div class="app-modal-body">{{modalData.message}}</div>
  <button type="button" class="btn btn-secondary buttonsuccess app-modal-footer" data-bs-dismiss="modal"
          (click)="closeModal()">
    Close
  </button>
</app-modal>
<app-confirmation-dialog #confirmationDialog
                         (confirm)="onConfirm()"
                         (cancel)="onCancel()" [message]="'Are you sure you want to proceed?'"></app-confirmation-dialog>
