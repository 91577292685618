<!-- <div class="row">
    <div class="nutrition_facts"  [ngClass]="ocrNutritionFacts.panelSize===1?'col-xl-7 col-lg-7 col-md-7 col-sm-7':'col-xl-7 col-lg-7 col-md-7 col-sm-7'">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title" align="center">Nutrition Facts</h3>
                <hr>
                <div class="row">
                    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11">
                        {{ ocrNutritionFacts.servingBlockText }}
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1" (click)="showServingEditor=!showServingEditor"
                        style="font-size: 30px;">
                        {{showServingEditor?'-':'+'}}
                    </div>
                </div>
                <app-image-ocr-serving-editor *ngIf="showServingEditor"
                    [ocrNutritionFacts]="ocrNutritionFacts"></app-image-ocr-serving-editor>
                <hr>
                <div class="table-responsive">
                    <table class="table table-striped table-bordered mb-0" *ngIf="ocrNutritionFacts.panelSize==1">
                        <thead>
                            <tr>
                                <th scope="col" class="calories-header">
                                    <h5 class="mb-0"> <b> Calories</b>
                                    </h5>
                                </th>
                                <th scope="col" colspan="2">
                                    <span
                                        *ngIf="ocrNutritionFacts.factHeaders != null && ocrNutritionFacts.factHeaders.length > 0">{{ocrNutritionFacts.factHeaders[0]}}</span>
                                    <div *ngFor="let cal of getMiscellaneousData() index as k">
                                        <input [(ngModel)]="cal.value" style="width:50px" class="dualeditable-textinput" (ngModelChange)="onFieldChanged()"/>

                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td align="right"></td>
                                <td align="right"></td>
                                <td align="right" class="dv-bold-right">%DV*</td>
                            </tr>
                            <tr *ngFor="let nutrient of GetNutrientsToShow() index as i">

                                <td [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
                                    {{nutrient.fact}}

                                </td>
                                <td>

                                    <input [(ngModel)]="nutrient.value" style="width:49px"  class="dualeditable-textinput" (ngModelChange)="onFieldChanged()"/>


                                </td>
                                <td>

                                    <input [(ngModel)]="nutrient.dv" style="width:49px"  class="dualeditable-textinput" (ngModelChange)="onFieldChanged()"/>


                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table class="table table-striped table-bordered mb-0" *ngIf="ocrNutritionFacts.panelSize==2">
                        <thead>
                            <tr>
                                <th scope="col" class="calories-header">
                                    <h5 class="mb-0"> <b> Calories</b>
                                    </h5>
                                </th>
                                <th scope="col" colspan="2" >
                                    <span
                                        *ngIf="ocrNutritionFacts.factHeaders != null && ocrNutritionFacts.factHeaders.length > 0">{{ocrNutritionFacts.factHeaders[0]}}</span>
                                    <div *ngFor="let cal of getMiscellaneousData() index as k">
                                        <input [(ngModel)]="cal.value" class="dualeditable-textinput" (ngModelChange)="onFieldChanged()"  />
                                        <div class="separator-calories"></div>
                                    </div>
                                </th>
                                <th scope="col" colspan="2">
                                    <span
                                        *ngIf="ocrNutritionFacts.factHeaders != null && ocrNutritionFacts.factHeaders.length > 1">{{ocrNutritionFacts.factHeaders[1]}}</span>
                                    <div *ngFor="let cal of getMiscellaneousData()">
                                        <input [(ngModel)]="cal.value2" class="dualeditable-textinput" (ngModelChange)="onFieldChanged()" />
                                        <div class="separator-calories"></div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td align="right"></td>
                                <td align="right"></td>
                                <td align="right" class="dv-bold-right">%DV*</td>
                                <td align="right"></td>
                                <td align="right" class="dv-bold-right">%DV*</td>
                            </tr>
                            <tr *ngFor="let nutrient of GetNutrientsToShow() index as i">

                                <td [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
                                    {{nutrient.fact}}

                                </td>
                                <td>

                                    <input [(ngModel)]="nutrient.value" class="dualeditable-textinput" (ngModelChange)="onFieldChanged()"/>


                                </td>
                                <td>

                                    <input [(ngModel)]="nutrient.dv" class="dualeditable-textinput"  (ngModelChange)="onFieldChanged()"/>


                                </td>
                                <td>

                                    <input [(ngModel)]="nutrient.value2" class="dualeditable-textinput" (ngModelChange)="onFieldChanged()" />


                                </td>
                                <td>

                                    <input [(ngModel)]="nutrient.dv2"class="dualeditable-textinput" (ngModelChange)="onFieldChanged()"/>


                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="nutrition_facts"  [ngClass]="ocrNutritionFacts.panelSize===1?'col-xl-5 col-lg-5 col-md-5 col-sm-5':'col-xl-5 col-lg-5 col-md-5 col-sm-5'">
        <div class="nutrition_fact_details-container">

            <div class="details-box">
                <b>INGREDIENTS:</b>
                <textarea [(ngModel)]="ocrNutritionFacts.ingredients" class="editable-textinput" ></textarea>
            </div>

            <div class="details-box">
                <b>ALLERGEN CONTAINS:</b>
                <textarea [(ngModel)]="ocrNutritionFacts.allergyInformation" class="editable-textinput"></textarea>
            </div>

            <div style="height: 118px;" class="details-box">
                <b> PIMS ATTRIBUTES:</b>
                <textarea class="editable-textinput"></textarea>
            </div>
        </div>

    </div>
</div>

 -->
<div class="nutrition_fact-container" id="nutrition_fact" [ngStyle]="{'flex-direction': isPad ? 'column' : 'row'}">
  <div class="nutrition_fact_table-container">
    <div style="border:solid 1px black ; width:460px" class="nutrition_fact_table-container">
      <div class="header">Nutrition Facts</div>
      <div class="separator-thin"></div>
      <!-- <div class="text-align-left">{{component.servingPerPackage}} servings per container</div> -->
      <div class="d-flex1" *ngIf="ocrNutritionFacts.arrayoffacts.length > 0">
        <div>
          <div class="serving-size1" style="padding-top: 10px;">
            <textarea [(ngModel)]="ocrNutritionFacts.servingBlockText" class="editable-input txtarea" style="width: 100%;"></textarea>
          </div>
        </div>
        <div (click)="showServingEditor=!showServingEditor"
             style="cursor:pointer; margin-left: auto; width:20px; display:flex; align-items: flex-end; font-size: 30px;">
          {{showServingEditor?'-':'+'}}
        </div>
      </div>
      <app-image-ocr-serving-editor *ngIf="showServingEditor"
                                    [ocrNutritionFacts]="ocrNutritionFacts"></app-image-ocr-serving-editor>
      <div class="separator-thin"></div>

      <!--single panel results-->

      <table *ngIf="ocrNutritionFacts.panelSize==1 && ocrNutritionFacts.arrayoffacts.length > 0">
        <thead>
          <tr class="separator-calories">
            <th class="calories-header">
              Calories
              <!-- <div class="separator-calories"></div> -->
            </th>
            <th>
              <textarea *ngIf="ocrNutritionFacts.factHeaders != null && ocrNutritionFacts.factHeaders.length > 0" [(ngModel)]="ocrNutritionFacts.factHeaders[0]" class="editable-input txtarea"></textarea>

              <div *ngFor="let cal of getMiscellaneousData() index as k">
                <input [(ngModel)]="cal.value" class="editable-input" NutrientNumberOnly />
                <!-- <div class="separator-calories"></div> -->
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width:50%; border-right: 1px solid black;"></td>
            <td style=" font-weight: bold; width: 50%;float: right;">%DV*</td>
          </tr>
          <tr *ngFor="let nutrient of GetNutrientsToShow(); index as i"
              [ngStyle]="{'border-bottom':GetNutrientsToShow().length==i+1?'0pt solid black':'0.6pt solid black'}">
            <td class="separator-thin-right-line"
                [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
              {{nutrient.fact}}
              <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}"></div> -->
            </td>
            <td>
              <div class="serving-container">
                <span style="margin-left:5px;">
                  <!-- {{nutrient.value}} -->
                  <input [(ngModel)]="nutrient.value" class="editable-input" NutrientNumberOnly />
                </span>
                <span class="text-bold">
                  <input [(ngModel)]="nutrient.dv" class="editable-input" ValidateWithPercentage />
                </span>
              </div>
              <!-- <div [ngClass]="{'separator-fact': i < GetNutrientsToShow().length  - 1}"></div> -->
            </td>
          </tr>

          <!--New row-->
          <tr style="border-top: 0.6pt solid;">
            <td colspan="3">
              <form [formGroup]="nutritionForm">
                <div formArrayName="newNutritionItem">
          <tr style="border-bottom: 0.6pt solid black;"
              *ngFor="let child of nutritionForm.get('newNutritionItem')['controls']; let k = index;"
              formGroupName="{{k}}">

            <td class="separator-thin-right-line">
              <select formControlName="fact" (change)="addnewItem()" style="width: 144px;">
                <option value="0">Select</option>
                <option *ngFor="let nutType of nutrientTypes" value="{{nutType.nutrientName}}">{{nutType.nutrientName}}</option>
              </select>
            </td>
            <td class="separator-thin-right-line">
              <div class="serving-container">
                <span style="margin-left:5px;">
                  <!-- {{nutrient.value}} -->
                  <input formControlName="value" class="editable-input" NutrientNumberOnly />
                </span>
                <span class="text-bold">
                  <input formControlName="dv" class="editable-input" (blur)="addnewItem()" ValidateWithPercentage />
                </span>
              </div>
              <!-- <div [ngClass]="{'separator-fact': i < GetNutrientsToShow().length  - 1}"></div> -->
            </td>


          </tr>
    </div>

    </form>
    </td>

    </tr>

    </tbody>
    </table>
    <!--dual panel results-->

    <table *ngIf="ocrNutritionFacts.panelSize==2 && ocrNutritionFacts.arrayoffacts.length > 0">
      <thead>
        <tr>
          <th class="separator-thin-right-line">

          </th>
          <th class="separator-thin-right-line">
            <textarea *ngIf="ocrNutritionFacts.factHeaders != null && ocrNutritionFacts.factHeaders.length > 0" [(ngModel)]="ocrNutritionFacts.factHeaders[0]" class="editable-input txtarea"></textarea>

          </th>
          <th>
            <textarea *ngIf="ocrNutritionFacts.factHeaders != null && ocrNutritionFacts.factHeaders.length > 1" [(ngModel)]="ocrNutritionFacts.factHeaders[1]" class="editable-input txtarea"></textarea>

          </th>
        </tr>
        <tr class="separator-calories">
          <th class="calories-header separator-thin-right-line">
            Calories
          </th>
          <th class="separator-thin-right-line">
            <div *ngFor="let cal of getMiscellaneousData()">
              <input [(ngModel)]="cal.value" class="editable-input" NutrientNumberOnly />
            </div>
          </th>
          <th>
            <div *ngFor="let cal of getMiscellaneousData()">
              <input [(ngModel)]="cal.value2" class="editable-input" NutrientNumberOnly />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td class="dv-bold-right" style="padding-right: 46px;">%DV*</td>
          <td class="dv-bold-right" style="padding-right: 46px;">%DV*</td>
        </tr>
        <tr *ngFor="let nutrient of GetNutrientsToShow(); index as i"
            style="border: 0.6pt solid black">
          <td class="separator-thin-right-line"
              [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
            {{nutrient.fact}}
            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}"></div> -->
          </td>
          <td>
            <div class="serving-container">
              <span style="margin-left:5px;">
                <!-- {{nutrient.value}} -->
                <input [(ngModel)]="nutrient.value" class="editable-input" NutrientNumberOnly />
              </span>
              <span class="text-bold">
                <input [(ngModel)]="nutrient.dv" class="editable-input" ValidateWithPercentage />
              </span>
            </div>
            <!-- <div [ngClass]="{'separator-facts': i < GetNutrientsToShow().length  - 1}"></div> -->
          </td>
          <td class="borderleft">
            <div class="serving-container">
              <span style="margin-left:5px;">
                <!-- {{nutrient.value}} -->
                <input [(ngModel)]="nutrient.value2" class="editable-input" NutrientNumberOnly />
              </span>
              <span class="text-bold">
                <input [(ngModel)]="nutrient.dv2" class="editable-input" ValidateWithPercentage />
              </span>
            </div>
            <!-- <div [ngClass]="{'separator-facts': i < GetNutrientsToShow().length  - 1}"></div> -->
          </td>
        </tr>
        <!--New row-->

        <tr style="border-top: 0.6pt solid;">
          <td colspan="3">
            <form [formGroup]="nutritionForm">
              <div formArrayName="newNutritionItem">
        <tr style="border-bottom: 0.6pt solid black;"
            *ngFor="let child of nutritionForm.get('newNutritionItem')['controls']; let k = index;"
            formGroupName="{{k}}">

          <td class="separator-thin-right-line">
            <select formControlName="fact" (change)="addnewItem()" style="width: 144px;">
              <option value="0">Select</option>
              <option *ngFor="let nutType of nutrientTypes" value="{{nutType.nutrientName}}">{{nutType.nutrientName}}</option>
            </select>
          </td>
          <td class="separator-thin-right-line">
            <div class="serving-container">
              <span style="margin-left:5px;">
                <!-- {{nutrient.value}} -->
                <input formControlName="value" class="editable-input" NutrientNumberOnly />
              </span>
              <span class="text-bold">
                <input formControlName="dv" class="editable-input" ValidateWithPercentage (blur)="addnewItem()" />
              </span>
            </div>
            <!-- <div [ngClass]="{'separator-fact': i < GetNutrientsToShow().length  - 1}"></div> -->
          </td>
          <td class="separator-thin-right-line">
            <div class="serving-container">
              <span style="margin-left:5px;">
                <!-- {{nutrient.value}} -->
                <input formControlName="value2" class="editable-input" NutrientNumberOnly />
              </span>
              <span class="text-bold">
                <input formControlName="dv2" class="editable-input" (blur)="addnewItem()"
                       ValidateWithPercentage />
              </span>
            </div>
            <!-- <div [ngClass]="{'separator-fact': i < GetNutrientsToShow().length  - 1}"></div> -->
          </td>


        </tr>
  </div>

  </form>
  </td>

  </tr>



  </tbody>
  </table>

  <div (click)="addNewRow(ocrNutritionFacts.panelSize)"
       style="cursor:pointer; margin-left: auto; width:20px; display:flex; align-items: flex-end; font-size: 30px;">
    +
  </div>
  <div *ngIf="ocrNutritionFacts.arrayoffacts.length == 0">
    No nutrition facts are available
  </div>
</div>
    </div>

<div [class.nutrition_fact_details-container]="!isPad" [class.nutrition_fact_table-container]="isPad">
  <!-- <div class="details-header">Ingredient List:</div> -->
  <div class="details-box">
    <b>INGREDIENTS:</b>
    <!--<textarea [(ngModel)]="ocrNutritionFacts.ingredients" class="editable-textinput"></textarea>-->
    <textarea [(ngModel)]="ocrNutritionFacts.ingredients" class="editable-textinput" [style.width.px]="isPad ? 460 : null"></textarea>

  </div>
  <!-- <div class="details-header">Allergen List:</div> -->
  <div class="details-box">
    <b>ALLERGEN:</b>
    <!--<textarea [(ngModel)]="ocrNutritionFacts.allergyInformation" class="editable-textinput"></textarea>-->
    <textarea [(ngModel)]="ocrNutritionFacts.allergyInformation" class="editable-textinput" [style.width.px]="isPad ? 460 : null"></textarea>
  </div>

  <div class="details-box">
    <textarea [(ngModel)]="ocrRawResponse" *ngIf="ocrRawResponse.trim().length>0"  class="editable-textinput" [style.width.px]="isPad ? 460 : null"></textarea>
  </div>
  <!-- <div class="details-header"> PNMS Attribues List:</div> -->
  <div>

    <div class="details-box" style="width: 100%; padding:5px;">
      <table [style.width.px]="isPad ? 460 : null">
        <tr class="borders">
          <th colspan="3" style="background-color:#2f880f; color:white;">PNMS ATTRIBUTES</th>

        </tr>
        <tr style="border-bottom: 0.5pt solid black; background-color:rgb(243 243 243); ">
          <th class="borderleft">Attribute Name (UOM)</th>
          <th class="borderleft">Amount Per Serving</th>
          <th class="borderleft" style="border-right: 0.5pt solid black;">DV%</th>
        </tr>

        <tr style="border-bottom: 0.5pt solid black;"
            *ngFor="let attribute of ocrNutritionFacts.pnmsAttributes">
          <td class="borderleft">{{attribute.display}}</td>
          <td class="borderleft">
            <input [(ngModel)]="attribute.amountPerServing" class="editable-input"
                   ValidatePercentage />
          </td>
          <td class="borderleft" style="border-right: 0.5pt solid black;">
            <input [(ngModel)]="attribute.dv" class="editable-input" ValidatePercentage />
          </td>
        </tr>

      </table>
      <!-- <table style="width:100%">
          <thead>
              <tr>
                  <th>Attribute Name (UOM)</th>
                  <th>Amount Per Serving</th>
                  <th>DV%</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let attribute of ocrNutritionFacts.pnmsAttributes">
                  <td>{{attribute.display}}</td>
                  <td style="padding-top: 5px;padding-bottom: 5px;"><input [(ngModel)]="attribute.amountPerServing" class="editable-input" NumberOnly /></td>
                  <td><input [(ngModel)]="attribute.dv" class="editable-input" NumberOnly /></td>
              </tr>
          </tbody>
      </table> -->
    </div>
  </div>
</div>
</div>
