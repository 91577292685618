import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, ElementRef, ViewChild, OnInit, Input, Output, EventEmitter, Renderer2, HostListener } from "@angular/core";
import { WebcamImage } from "ngx-webcam";
import { Observable } from "rxjs/internal/Observable";
import { Subject } from "rxjs/internal/Subject";
import ShortUniqueId from 'short-unique-id';
import { CapturedImage } from "../models/capturedImage";
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MsalService } from "@azure/msal-angular";
import { RestApiService } from "src/app/services/RestApiService.service";
import { bufferToWave } from "../../services/bufferToWave";

const uid = new ShortUniqueId({ length: 10 });

@Component({
  selector: 'app-capture-picture',
  templateUrl: './capture-picture.component.html',
  styleUrls: ['./capture-picture.component.css']
})

export class CapturePictureComponent implements OnInit {
  @Output() audioIngrediants = new EventEmitter<string>();
  uploadImag: boolean = true;
  imageURL: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  WIDTH = 400;
  HEIGHT = 700;
  WebCamWidth = 1200;
  WebCamHeight = 1550;
  trigger: Subject<any> = new Subject();
  private nextWebcam: Subject<any> = new Subject();
  isLandscape = window.innerWidth > window.innerHeight;
  mediaRecorder: any;
  audioChunks: any[] = [];

  //stopRecordingAndSendToAPI_Original() {

  //  console.log("chunks length:" + this.audioChunks.length);
  //  this.mediaRecorder.stop();
  //  this.sendToServer();
  //}

  private audioContext: AudioContext = new AudioContext();

  //blobToBase64(blob: Blob): Promise<string> {
  //  return new Promise((resolve, reject) => {
  //    const reader = new FileReader();
  //    reader.onerror = (event) => {
  //      console.error('Error reading Blob:', event);
  //      reject(event);
  //    };
  //    reader.onload = () => {
  //      resolve(reader.result as string);
  //    };
  //    reader.readAsDataURL(blob);
  //  });
  //}

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }
  receivedSpeechToText = "";
  //async sendToServer() {
  //  try {
  //    if (this.audioChunks.length > 0) {
  //      const audioData1 = await new Blob(this.audioChunks).arrayBuffer();
  //      const audioBuffer = await this.audioContext.decodeAudioData(audioData1);
  //      const wavBlob = bufferToWave(audioBuffer, ((audioBuffer)).length);

  //      const Base64String = await this.blobToBase64(wavBlob);

  //      const audioData = {
  //        Base64String: Base64String // Match property name with controller
  //      };
  //      const now: Date = new Date();
  //      console.log("started calling API");
  //      this.restApiService.getTextFromAudio(audioData).subscribe(x => {
  //        setTimeout
  //          (() => {
  //            console.log(JSON.stringify(now.toISOString()));
  //            console.log(JSON.stringify(x.ocrResponse));
  //            console.log("Done calling API");
  //          }, 1000);
  //        //console.log(JSON.stringify(x));
  //        this.audioChunks = [];
  //      });
  //    }
  //  } catch (error) {
  //    console.error('Error converting audio to base64:', error);
  //  }
  //}


  isRecording: boolean = false;

  startRecording() {
    if (!this.isRecording) {
      console.log("started Recording");
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.isRecording = true;
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event: { data: any; }) => {
            this.audioChunks.push(event.data);
          };
          this.mediaRecorder.start();
        })
        .catch(error => {
          console.error('Error accessing microphone:', error);
        });
    }
  }

  //stopRecordingAndSendToAPI() {
  //  setTimeout(() => {
  //    if (this.isRecording) {
  //      console.log("chunks length:" + this.audioChunks.length);
  //      this.isRecording = false;
  //      this.mediaRecorder.stop();
  //      this.sendToServer();
  //      this.mediaRecorder = null;
  //    }
  //  }, 2000)
    //if (this.isRecording) {
    //  console.log("chunks length:" + this.audioChunks.length);
    //  this.isRecording = false;
    //  this.mediaRecorder.stop();
    //  this.sendToServer();
    //  this.mediaRecorder = null;
    //}
 // }

  imageSrc: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.imageSrc = e.target?.result;
      };

      reader.readAsDataURL(file);
    }
  }


  startRecording_original() {
    console.log("started Recording");
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event: { data: any; }) => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.start();
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLandscape = window.innerWidth > window.innerHeight;
    if (!this.isLandscape) {//vertical
      this.WebCamWidth = 1200;
      this.WebCamHeight = 1000;
    }
    else {//Horizontal
      this.WebCamWidth = 1200;
      this.WebCamHeight = 1550;
    }
  }

  error: any;
  isCaptured!: boolean;
  isCropped!: boolean;
  currentImageData: any;


  @Input()
  selectedThumbnail: CapturedImage | undefined;

  @Input()
  gtinCode!: number;

  @Input()
  itemDescription!: string;

  @ViewChild("canvas")
  public canvas!: ElementRef;

  @Output()
  onImageCaptured = new EventEmitter<CapturedImage>();

  //@ViewChild('webcamElement', { static: true })
  //  webcamElement!: ElementRef;

  intiliazeonce = true;
  ngOnInit(): void {
    this.GetImageUploadImageStatus();
    //if (this.intiliazeonce) {
    //  this.startRecording();
    //  setTimeout(() => {
    //    this.stopRecordingAndSendToAPI();
    //    this.intiliazeonce = false;
    //  }, 1000)
    //}
    setInterval(() => {
      this.isLandscape = window.innerWidth > window.innerHeight;
      if (!this.isLandscape) {//vertical
        this.WebCamWidth = 1200;
        this.WebCamHeight = 1000;
      }
      else {//Horizontal
        this.WebCamWidth = 1200;
        this.WebCamHeight = 1550;
      }
    }, 1000);
  }

  public capture(): void {
    this.trigger.next(void 0);

  }

  constructor(private authService: MsalService, private datepipe: DatePipe, private restApiService: RestApiService) {
    this.restApiService.quaggaStop();
  }

  GetImageUploadImageStatus(): void{
    this.restApiService.GetImageUploadImageStatus().subscribe(x => {
      this.uploadImag = x;
    });
  }

  public handleImageUpload(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result as string;
        const fileName = this.gtinCode + '-' + this.datepipe.transform((new Date), 'MMddyyyy-hmmss') + ".png";
        const capturedImage = { fileName, data: imageData, isProcessed: false };

        this.currentImageData = imageData;  // Base64 encoded image data
        this.isCaptured = true;
        this.isCropped = false;

        console.log("Image uploaded", capturedImage);
      };
      reader.readAsDataURL(file);  // Convert the file to a data URL (base64)
    }
  }
  public handleImageCaptured(webcamImage: WebcamImage): void {
    console.log("capture image", webcamImage);


    var imageData = webcamImage!.imageAsDataUrl;
    const fileName = this.gtinCode + '-' + this.datepipe.transform((new Date), 'MMddyyyy-hmmss') + ".png";
    const capturedImage = { fileName, data: imageData, isProcessed: false };

    this.currentImageData = imageData;
    this.isCaptured = true;
    this.isCropped = false;
  }

  public get invokeImageCapture(): Observable<any> {
    return this.trigger.asObservable();
  }

  downloadImageNew(data: any, filename = 'untitled.jpg') {
    var a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  //here is the method is used to get content type of an bas64 data
  public getContentType(base64Data: any) {
    let block = base64Data.split(";");
    let contentType = block[0].split(":")[1];
    return contentType;
  }
  refreshCamera() {
    this.isLandscape = !this.isLandscape
    setTimeout(() => {
      this.isLandscape = !this.isLandscape
    }, 500);
  }
  removeCurrent() {

    this.isCaptured = false;
    this.isCropped = false;

    // this.setupDevices();
  }
  cropCurrent() {
    const fileName = this.gtinCode + '-' + this.datepipe.transform((new Date), 'MMddyyyy-hmmss') + ".png";
    const capturedImage = { fileName, data: this.croppedImage, isProcessed: false };
    this.isCropped = true;

    // this.downloadImageNew(this.croppedImage, fileName);
    this.onImageCaptured.emit(capturedImage);
  }

  fileChangeEvent(event: any): void {
    this.imageURL = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH - 20, this.HEIGHT);
  }
  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }
  facingMode: string = 'environment'; //Set rear camera 
  //facingMode: string = 'user';  //Set front camera
  allowCameraSwitch = true;
  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = { width: { min: 1280, exact: 1280 }, height: { min: 680, exact: 680 } };
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}

