<div class="nutrition_fact-container">
    <div class="header">Nutrition Facts</div>
    <div class="separator-thin"></div>
    <div *ngIf="!showNutritionFacts">
        No nutrition facts are available
    </div>
    <div *ngIf="showNutritionFacts">
        <div class="text-align-left">{{component.servingPerPackage}} servings per container</div>
        <div class="serving-size">Serving size {{ component.householdServingSize |
            number}}&nbsp;{{component.householdServingUom}}&nbsp;({{component.servingSizeVal}}{{component.servingSizeUom}}/{{component.visualServingUom}})
        </div>
        <div class="separator-dark"></div>
        <!--single panel results-->
        <table style="height: 100%; width:100%;" *ngIf="component.panelType==='Single Panel'">
            <thead>
                <tr>
                    <th class="calories-header">
                        Calories
                        <div class="separator-calories"></div>
                    </th>
                    <th>
                        <div>
                            {{getMiscellaneousData().itemHeader}}
                            <div class="calories-number">{{getMiscellaneousData().calorieValue}}</div>
                            <div class="separator-calories"></div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style="height: 50px;">
                    <td></td>
                    <td class="dv-bold-right">%DV*</td>
                </tr>
                <ng-container *ngFor="let nutrient of GetNutrientsToShow() index as i">
                    <tr *ngIf="filternutrition1(nutrient)" [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}">
                        <td class="separator-thin-right-line"
                            [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
                            {{nutrient.nutrientName}}
                            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 3}"></div> -->
                        </td>
                        <td>
                            <div class="serving-container">
                              <span *ngIf="nutrient.displayValue != null && nutrient.displayValue.toLowerCase().startsWith('less than');
                         else uomSVDisplay;"
                                    style="margin-left:5px;">{{getDualDisplayValue(nutrient.displayValue)}}{{getUom(nutrient.uom)}}</span>
                                <ng-template #uomSVDisplay>
                                    <span
                                        style="margin-left:5px;">{{getDualDisplayValue(nutrient.nutrValue)}}{{getUom(nutrient.uom)}}</span>
                                </ng-template>
                                <span class="text-bold"> {{nutrient.dvp | number: '1.0-0'}}% </span>
                            </div>
                            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length-3}"></div> -->
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <!--dual panel results-->
        <table style="height: 100%;  width:100%;" *ngIf="component.panelType==='Dual Column'">
            <thead>
                <tr style="height:80px">
                    <th class="calories-header" style="border-right: 0.5pt solid black;">
                        Calories
                        <div class="separator-calories"></div>
                    </th>
                    <th style="border-right: 0.5pt solid black;">
                        <div class="d-flex h-100 flex-column">
                            <!-- <span *ngIf="getMiscellaneousData().dualitemHeader === null">&nbsp;</span> -->
                            {{getMiscellaneousData().itemHeader}}
                            <div class="calories-number mt-auto">{{getMiscellaneousData().calorieValue}}</div>
                            <div class="separator-calories"></div>
                        </div>
                    </th>
                    <th colspan="2">
                        <div class="d-flex h-100 flex-column">
                            <!-- <span *ngIf="getMiscellaneousData().dualitemHeader === null">&nbsp;</span> -->
                            {{getMiscellaneousData().dualitemHeader}}
                            <div class="calories-number mt-auto">{{getMiscellaneousData().dualcalorieValue | number:
                                '1.0-0'}}</div>
                            <div class="separator-calories"></div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td class="dv-bold-right">%DV*</td>
                    <td class="dv-bold-right">%DV*</td>
                </tr>
                <ng-container *ngFor="let nutrient of GetNutrientsToShow(); index as i">
                    <tr *ngIf="filternutrition1(nutrient)" [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}">
                        <td class="separator-thin-right-line"
                            [ngClass]="{'nutrient-highlighted':isHighlightedNutrient(nutrient)}">
                            {{nutrient.nutrientName}}
                            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 1}" class=""></div> -->
                        </td>
                        <td class="separator-thin-right-line">
                            <div class="serving-container">
                              <span *ngIf="nutrient.displayValue != null && nutrient.displayValue.toLowerCase().startsWith('less than');
                             else uomDVDisplay;"
                                    style="margin-left:5px;">{{getDualDisplayValue(nutrient.displayValue)}}{{getUom(nutrient.uom)}}</span>
                                <ng-template #uomDVDisplay>
                                    <span
                                        style="margin-left:5px;">{{getDualDisplayValue(nutrient.nutrValue)}}{{getUom(nutrient.uom)}}</span>
                                </ng-template>
                                <span class="text-bold"> {{nutrient.dvp | number: '1.0-0'}}% </span>
                            </div>
                            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 3}" class=""></div> -->
                        </td>
                        <td>
                            <div class="serving-container">
                              <span *ngIf="nutrient.duaL_DisplayValue != null && nutrient.duaL_DisplayValue.toLowerCase().startsWith('less than');
                             else uomDisplay;"
                                    style="margin-left:5px;">{{getDualDisplayValue(nutrient.duaL_DisplayValue)}}{{getUom(nutrient.uom)}}</span>
                                <ng-template #uomDisplay>
                                    <span
                                        style="margin-left:5px;">{{getDualDisplayValue(nutrient.dualNutrValue)}}{{getUom(nutrient.uom)}}</span>
                                </ng-template>
                                <span style="margin-left:20px;" class="text-bold"> {{nutrient.dualDVP | number:
                                    '1.0-0'}}%
                                </span>
                            </div>
                            <!-- <div [ngClass]="{'separator-thin': i < GetNutrientsToShow().length  - 3}" class=""></div> -->
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
