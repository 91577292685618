import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component as GtinComponent, NutrientsDto, NutrientValuesDetail } from 'src/app/Models/Gtin';

const highlightedNutrientIds = [102, 104, 105, 106, 109];
const ignoreNutrientIds = [100];
const LESSTHANTEXT = 'less than';

@Component({
  selector: 'nutrition-fact',
  templateUrl: './nutrition-fact.component.html',
  styleUrls: ['./nutrition-fact.component.scss']
})
export class NutritionFactComponent implements OnInit {
  @Input() component!: GtinComponent;
  showNutritionFacts: boolean = true;
  nutrientValuesDetails: NutrientValuesDetail[] = [];

  constructor() {
  }
  ngOnInit(): void {
    this.showNutritionFacts = this.component.nutrientValuesDetails.length > 0;
    this.nutrientValuesDetails = this.component.nutrientValuesDetails.filter(n => !ignoreNutrientIds.includes(n.nutrientID));
    this.nutrientValuesDetails=this.rearrangeItems(this.nutrientValuesDetails);
  }

  rearrangeItems(items: NutrientValuesDetail[]): NutrientValuesDetail[] {
    const sugarItems = [];
    const otherItems = [];
    let sugarIndex = -1;

    for (let i = 0; i < items.length; i++) {
      if (items[i].nutrientName.toLowerCase().includes("sugar")) {
        sugarItems.push(items[i]);
        if (sugarIndex === -1) {
          sugarIndex = i;
        }
      } else {
        otherItems.push(items[i]);
      }
    }

    if (sugarIndex === -1) {
      return items; // No sugar items found, return the original list
    }

    // Split the list into two parts: before and after the sugarIndex
    const beforeSugar = otherItems.slice(0, sugarIndex);
    const afterSugar = otherItems.slice(sugarIndex);

    // Combine the parts with sugarItems in the middle
    return [...beforeSugar, ...sugarItems, ...afterSugar];
  }

  filternutrition1(nutrientValuesDetail: NutrientValuesDetail): boolean {
    if (nutrientValuesDetail.nutrientID == 200 || nutrientValuesDetail.nutrientID == 201) {
      return false;
    }
    return true;
  }

  filternutrition3(): boolean {
    return this.component.nutrientValuesDetails.some(element => element.nutrientID === 200 || element.nutrientID === 201);
  }

  isHighlightedNutrient(nutrient: NutrientsDto) {
    return highlightedNutrientIds.includes(nutrient.nutrientID);
  }

  getMiscellaneousData() {
    var calorieValue = this.component.nutrientValuesDetails.find(n => n.nutrientID == 100)?.displayValue;
    var dualcalorieValue = this.component.nutrientValuesDetails.find(n => n.nutrientID == 100)?.dualNutrValue;
    var itemHeader = this.component.itemHeader;
    var dualitemHeader = this.component.dualItemHeader;

    return ({
      calorieValue, itemHeader, dualitemHeader, dualcalorieValue
    })
  }

  GetNutrientsToShow() {
    return this.nutrientValuesDetails;
  }

  getDualDisplayValue(value: string) {
    if (value != null) {
      var returnValue: string = value;
      if (value.toLowerCase().startsWith(LESSTHANTEXT)) {
        return '<' + value.toLowerCase().replace(LESSTHANTEXT, '').replace('gram', 'g').replace(/ /g, "").trim();
      }
      return Number(returnValue);
    }
    return "";

  }

  getUom(value: string) {
    var uomVlaue: string = "";
    if (value != null) {
      uomVlaue = value;
      if (value === "IU") uomVlaue = "mcg";
    }
    return uomVlaue;
  }
}
