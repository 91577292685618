import { OcrNutritionFacts } from "./OcrNutritionFacts";

export const getOcrNutritionFactsObject = (rawResponseData: any) => {
    var responseData = JSON.stringify(rawResponseData);
    var ocrNutritionFacts: OcrNutritionFacts = JSON.parse(responseData);
    console.log(ocrNutritionFacts);
    //parseServingData(ocrNutritionFacts);
    fillPnmsAttributes(ocrNutritionFacts) // currently we are assuming that the data is not coming from api.So fill it on ui side
    reformatOcrData(ocrNutritionFacts);
    return ocrNutritionFacts;
}

// function parseServingData(ocrNutritionFacts: OcrNutritionFacts) {
//     const servingData = {
//         servingSizeVal: 0,
//         servingSizeUom: "g",
//         householdServingSize: 0,
//         householdServingUom: "g",
//         visualServingUom: "",
//         servingPerPackage: 0
//     };

//ocrNutritionFacts.servingData = servingData;
/*
    if (!ocrNutritionFacts.servingBlockText) {
        return;
    }

    let texts = ocrNutritionFacts.servingBlockText.split("servings per container");
    console.log(texts);

    servingData.servingPerPackage = Number(texts[0].trim());
    texts = texts[1].split("(");
    console.log(texts)
    const householdTexts = texts[0].replace("Serving size", "").trim().split(" ");
    console.log(householdTexts);

    servingData.householdServingSize = Number(householdTexts[0]);
    servingData.householdServingUom = householdTexts[1];
    texts = texts[1].replace(")", "").trim().split("/");
    console.log(texts);
    const servingSizeTexts = texts[0].trim().match(/([\d\.]+)(.*)/) || [];
    servingData.servingSizeVal = Number(servingSizeTexts[1]);
    servingData.servingSizeUom = servingSizeTexts[2];
    servingData.visualServingUom= texts[1].trim();
    console.log(servingData);
    */
//}

function reformatOcrData(ocrNutritionFacts: OcrNutritionFacts) {
    ocrNutritionFacts.arrayoffacts.forEach(af => af.value = reformatValue(af.value));
}

function reformatValue(value: string) {
    if (value == null || value == undefined) {
        return value;
    }
    value = value.toLowerCase();
    return value.replace("less than ", "<");
}

function fillPnmsAttributes(ocrNutritionFacts: OcrNutritionFacts) {
    ocrNutritionFacts.pnmsAttributes = [
        { name: "Whole Grain", amountPerServing: undefined, dv: undefined, display:"Whole Grain(%)" },
        { name: "Juice", amountPerServing: undefined, dv: undefined, display:"Juice(%)" }
    ];
}
