import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { DataService as MyService } from 'src/app/services/DataService';
import { Subscription, of, timeout } from 'rxjs';
import { ApiResponse, DashboardReport, Report, ReportData } from '../Models/Gtin';
import { RestApiService } from '../services/RestApiService.service';
import { AuditListComponent } from '../items-list/audit/audit-list.component';
import { SelectableSettings } from "@progress/kendo-angular-treeview";
import { ConfirmationDialogComponent } from '../shared/confirmation/confirmation.dialog.component';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(AuditListComponent) childComponent!: AuditListComponent;
  public selection: SelectableSettings = { mode: "multiple" };
  reportData: ReportData[] = [];
  paneSize = '15%';
  divsize = 100;
  showUserReports = false;
  isIpadVertical = false;
  confirmationMessge: any = "Are you sure to remove all GTIN's with status 'Pending/Not reviewed' from the report, while those with a 'Completed' status will remain unaffected ? ";
  displayLoadingIcon: boolean = false;
  temp: number = 0;
  sourcePage = 'home';
  loadDashBoard: boolean = true;
  isLoadingAuditReport: boolean = true;
  userId: any;
  foundRecordName: boolean = false;
  selectedValue!: { id: number, name: string };
  selectedValueForTreeDropDwon!: { id: any, name: string };
  filteredReportData: ReportData[] = [];
  //isChecked = false;
  nodeName: any = '';
  auditReportForSelectedID: any[] = [];
  userName!: any;
  subscription!: Subscription;
  @ViewChild('confirmationDialogForRoot', { static: false }) confirmationDialogForRoot!: ConfirmationDialogComponent;
  @ViewChild('confirmationDialogForChild', { static: false }) confirmationDialogForChild!: ConfirmationDialogComponent;
  constructor(private cdr: ChangeDetectorRef, private restApiService: RestApiService, private authService: MsalService, private dataService: MyService, private router: Router, private route: ActivatedRoute) {
    this.labelContent = this.labelContent.bind(this);
    var accountInfo = this.authService.instance.getAllAccounts();

    if (accountInfo != null && accountInfo.length > 0 && accountInfo[0].idTokenClaims) {
      this.userId = accountInfo[0].idTokenClaims['sAMAccountName'];
      this.userName = accountInfo[0].idTokenClaims['name'];
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isIpadVertical = window.innerWidth < window.innerHeight;
  }


  showUserReports1 = true;
  reset(event: Event) {
    this.showUserReports1 = false;
    setTimeout(async () => {
      this.showUserReports1 = true;
    }, 1)
    if (!this.showUserReports == true) {
      this.loadDashBoard = true;
    }
    else {
      if (this.selectedKeys.length > 0 && this.expandedKeys.length > 0) {
        this.loadDashBoard = false;
      }
    }
  }



  public labelContent(args: SeriesLabelsContentArgs): string {
    return `${args.category}: ${args.value}`;
  }

  public selectedKeys: any[] = [];// ["0_0"];
  public hasChildren = (item: any) => item.userReports && item.userReports.length > 0;
  public fetchChildren = (item: any) => of(item.userReports);
  public expandedKeys: string[] = [];//["-1", "-1"];


  onRootCancel() {
    this.selectedNode.checked = false;
    this.onCheckboxChange(this.selectedNode);
  }
  onRootConfirm() {
    this.restApiService.DeleteAllUserReports(this.userId).subscribe();
    setTimeout(async () => {
      window.location.reload()
    }, 1000)
  }
  onChildConfirm() {
    this.restApiService.DeleteUserSelectedReport(this.nodeName, this.userId).subscribe();
    setTimeout(async () => {
      window.location.reload()
    }, 1000)
  }
  onChildCancel() {
    this.selectedNode.checked = false;
    this.onCheckboxChange(this.selectedNode);
  }

  selectedNode: any;
  async onCheckboxChange(node: any) {
    this.selectedNode = node;
    if (node.userReports) {
      const isChecked = node.checked;
      this.toggleChildrenCheckbox(node, isChecked);

      setTimeout(() => {
        if (node.checked) {
          this.confirmationDialogForRoot.open();
        }
      }, 1000)


    }
    else {
      setTimeout(async () => {
        if (node.checked) {
          this.nodeName = node.name.substring(0, node.name.lastIndexOf('_'));
          this.confirmationDialogForChild.open();
        }
      }, 1000)
    }
  }

  private toggleChildrenCheckbox(node: any, isChecked: boolean) {
    node.userReports.forEach((child: { checked: boolean; }) => {
      child.checked = isChecked;
    });
  }

  onSelectionChange(event: any) {
    //alert(event.dataItem.name);
    if (event.dataItem.name === 'Home') {
      this.loadDashBoard = true;
      return;
    }
    // this.dataService.changePage(0);
    this.loadDashBoard = false;
    this.dataService.changecurrentloadDashBoard('N');
    this.loadReportForSelectedId(event.dataItem.id);
    this.dataService.changeselectedKeys(this.selectedKeys);
    this.dataService.changeExpandedValues(this.expandedKeys);
    this.childComponent.changeReport();


  }

  public onExpand(): void {
    this.dataService.changeExpandedValues(this.expandedKeys)
  }
  public onCollapse(): void {
    this.dataService.changeExpandedValues(this.expandedKeys)
  }

  loadReportForSelectedId(id: number) {
    if (id.toString().toLowerCase().indexOf('user') < 0) {
      this.dataService.changecurrentloadDashBoard('N');
      this.isLoadingAuditReport = true;

      this.auditReportSubscription = this.restApiService.getAuditReportForSelectedReportId(id).subscribe(
        (data: any) => {
          this.auditReportForSelectedID = JSON.parse(JSON.stringify(data.auditReportList))
          this.dataService.changeCurrentRecord(id);
          this.unsubscribeAuditReportSubscription();
          this.isLoadingAuditReport = false;
        },
        (error) => {
          console.error('Error:', error);
          this.isLoadingAuditReport = false;
        }
      );
    }

    else {
      this.loadDashBoard = true;
      this.auditReportForSelectedID = [];
      this.dataService.changecurrentloadDashBoard('Y');
    }
  }

  async loadData() {
    this.loadDashBoardData();
    await this.getReport();
    this.dataService.changeCurrentRecord(0);
    this.dataService.changecurrentAllUserData('');
    this.isLoadingAuditReport = false;
    if (this.selectedKeys.length > 0) {
      this.showUserReports = true;
      this.showUserReports1 = true;
      this.loadDashBoard = false;
    }
  }

  refresh(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (!isChecked) {
      this.router.navigate([this.route.snapshot.url.join('/')]);
    }
  }

  async ngOnInit() {

    await this.loadData();
    setInterval(() => {
      this.isIpadVertical = window.innerWidth < window.innerHeight;
    }, 1000);
  }
  dashboardReport!: DashboardReport;
  keynames: string[] = [];
  values: number[] = [];

  yearnames: string[] = [];
  yearvalues: number[] = [];

  categoryNames: string[] = [];
  categoryValues: number[] = [];

  cnames = '';

  cvalue = '';
  pieData: { category: string, value: number }[] = [];

  dashoBoarsResponse!: ApiResponse

  splitIntoLines(text: string, maxLineLength: number) {
    let lines = [];
    let currentLine = '';

    for (const char of text) {
      if (currentLine.length === maxLineLength) {
        lines.push(currentLine);
        currentLine = '';
      }
      currentLine += char;
    }

    if (currentLine) {
      lines.push(currentLine); // Push the last line if there's any residue
    }

    return lines;
  }

  async loadDashBoardData() {

    try {
      this.dashoBoarsResponse = JSON.parse(JSON.stringify(await this.restApiService.getDashBoardReport().toPromise()));
      this.dashboardReport = this.dashoBoarsResponse.dashBoardReport;
      const yearwiseCount = Object.entries(this.dashboardReport.totalItemsByEachYear);
      this.yearnames = yearwiseCount.map(([key, _]) => key);
      this.cnames = this.yearnames.join(", ");
      this.yearvalues = Object.values(this.dashboardReport.totalItemsByEachYear);
      this.cvalue = this.yearvalues.join(", ");
      for (var i = 0; i < this.yearnames.length; i++) {
        this.pieData.push({ category: this.yearnames[i], value: this.yearvalues[i] });
      }
      const topTenFamilyGroupsWithHighestAuditItems = Object.entries(this.dashboardReport.topTenFamilyGroupsWithHighestAuditItems);


      this.keynames = topTenFamilyGroupsWithHighestAuditItems.map(([key, _]) => key);


      this.keynames = this.keynames.map(entry => {
        // Split the entry into number and text parts
        let [numberPart, ...textParts] = entry.split(' ');
        // Join the text parts back into one string and split into lines
        let text = textParts.join(' ');
        let lines = this.splitIntoLines(text, 10);

        // Join all lines with a new line character and include the number part
        return `${numberPart}\n${lines.join('\n')}`;
      });


      //this.keynames = this.keynames.map(entry => {
      //  // Split the entry into number and text parts
      //  let [numberPart, ...textParts] = entry.split(' ');
      //  // Join the text parts with space and insert new line (\n) after the number
      //  return `${numberPart}\n${textParts.join(' ')}`;
      //});

      //this.keynames = this.insertNewlinesRespectingWords(this.keynames);

      this.values = Object.values(this.dashboardReport.topTenFamilyGroupsWithHighestAuditItems);
      const categories = Object.entries(this.dashboardReport.categoriesCount);

      this.categoryNames = categories.map(([key, _]) => key);

      this.categoryNames = this.categoryNames.map(x => {
        if (x === "FamilyGroupInclude") {
          return "FamilyGroup \n Include";
        }
        if (x === "GlutenFree") {
          return "Gluten \n Free";
        }
        return x;
      });

      this.categoryValues = Object.values(this.dashboardReport.categoriesCount);

      this.dataService.currentloadDashBoard.subscribe(status => {
        if (status == '') {
          setTimeout(async () => {
            this.loadDashBoard = false;
          }, 500)

          setTimeout(async () => {
            this.loadDashBoard = true;
          }, 600)
        }
        if (status != '' && status === 'Y') {

          this.loadDashBoard = true;
        }
        if (status != '' && status === 'N') {
          this.loadDashBoard = false;
        }
      });

    } catch (error) {
      console.error('Error loading dashboard data:', error); // Log any errors
    }

  }

  insertNewlinesRespectingWords(keynames: string[]): string[] {
    return keynames.map(keyname => {
      let result = "";
      let currentLine = "";
      for (let i = 0; i < keyname.length; i++) {
        const char = keyname[i];
        const nextWord = currentLine + char;

        // Check if next word fits within 10 characters and doesn't end the string
        if (nextWord.length <= 10 && i < keyname.length - 1) {
          currentLine += char;
        } else {
          // Add current line (or word if less than 10 chars) and newline
          result += currentLine.length < 10 ? currentLine + char : currentLine + "\n";
          currentLine = char; // Start new line with the current character
        }
      }
      // Add the last line (might be a single character)
      result += currentLine;
      return result;
    });
  }

  async getReport() {
    this.dataService.currentloadDashBoard.subscribe(x => {
      if (x == 'Y') {
        this.loadDashBoard = true;
        this.dataService.changeselectedKeys([]);
        this.dataService.changeExpandedValues([]);
        this.selectedKeys = [];
        this.expandedKeys = [];
      }
    })



    this.displayLoadingIcon = true;
    this.reportData = await this.restApiService.getReports(this.userId).toPromise();
    console.log('user report data:' + JSON.stringify(this.reportData));
    this.dataService.currentRecord.subscribe(targetId => {
      if (targetId != 0) {
        this.temp = targetId;
      }
    });

    //this.dataService.changecurrentloadDashBoard('');
    this.loadDashBoard = true;
    this.dataService.currentSelectedKeys.subscribe(targetId => {
      if (targetId.length != 0) {
        this.selectedKeys = targetId;
      }
    });

    this.dataService.currentExpandedValues.subscribe(expandedValues => {
      if (expandedValues.length != 0) {
        this.expandedKeys = expandedValues;
      }
    });

    if (this.temp != 0) {
      let foundReport: Report | undefined;

      for (const reportData of this.reportData) {
        if (foundReport) {
          break; // Exit the loop if a matching report is already found
        }

        foundReport = reportData.userReports.find((report) => report.id === this.temp);

        if (foundReport) {
          this.selectedValue = foundReport;
          this.handleValueChange({ id: foundReport.id });
          break; // Exit the loop after processing the matching report
        }
      }
    }
    else {
      if (this.filteredReportData.length > 0) {
        this.selectedValue = this.filteredReportData[0].userReports[0];
        this.handleValueChange({ id: this.filteredReportData[0].userReports[0].id });
      }
    }
    this.displayLoadingIcon = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  unsubscribeAuditReportSubscription() {
    if (this.auditReportSubscription) {
      this.auditReportSubscription.unsubscribe();
    }
  }
  private auditReportSubscription: Subscription | undefined;
  handleValueChange(value: any) {
    if (value.id && typeof value.id === 'string' && value.id.startsWith('user_')) {
      this.auditReportForSelectedID = [];
    }
    else if (value.id && typeof value.id === 'number') {
      this.auditReportSubscription = this.restApiService.getAuditReportForSelectedReportId(value.id).subscribe(
        (data: any) => {
          this.auditReportForSelectedID = JSON.parse(JSON.stringify(data.auditReportList))
          this.dataService.changeCurrentRecord(value.id);
          this.unsubscribeAuditReportSubscription();
        },
        (error) => {
          console.error('Error:', error);
        }
      );
    }
    else {
      alert("Unexpected value: " + JSON.stringify(value));
    }
  }
}
