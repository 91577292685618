<ng-container *ngFor="let i of searchGtinResults.components;index as k">
    <div class="row">
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10" style="margin-left: 50px;">
            <div [id]="k+1" *ngIf="k+1==id">
                <nutrition-fact-ocr (updatedRecordDvp)="getUpdatedRecordDvp($event)"
                    (updatedRecordDisplayValue)="getUpdatedRecordDisplayValue($event)"
                    (updatedRecordDualDisplayValue)="getUpdatedRecorDualDisplayValue($event)"
                    (updatedRecorDualdDvp)="getUpdatedRecordDualDvp($event)"
                    [pnmsComponents]="searchPNMSGtinResultsToCompare" [component]="i"></nutrition-fact-ocr>
                <br>
                <div *ngIf="i.isComponentDataExist">
                  <div><b>INGREDIENTS:</b></div>
                  <!-- <div>
      <textarea type="text" [(ngModel)]="i.ingredientList" class="editable-textinput"
          (ngModelChange)="oningredientsDataChange('INGREDIENTS',i.componentID,i.ingredientList)"
          [ngStyle]="{'border-color': sameSet(i.componentID,'INGREDIENTS') ? 'rgba(0, 0, 0, 0.125)' : '#ff6600'}"></textarea>
  </div> -->

                  <div style="position: relative;">
                    <textarea type="text" [(ngModel)]="i.ingredientList" class="editable-textinput"
                              (ngModelChange)="oningredientsDataChange('INGREDIENTS',i.componentID,i.ingredientList)"
                              [ngStyle]="{'border-color': sameSet(i.componentID,'INGREDIENTS') ? 'rgba(0, 0, 0, 0.125)' : '#ff6600'}"
                              [readonly]="searchGtinResults.status !== 'draft'"></textarea>
                    <button class="move-button" (click)="restIngredientsToOCR(i.componentID)"
                            style="position: absolute; top: -17%; left: 25%; height: 17%;background-color: #2f880f;color: white;">
                      <i class="bi bi-arrow-clockwise"></i>
                    </button>
                  </div>

                  <div style=" margin-top: 16px;">
                    <div><b>ALLERGEN:</b></div>
                    <!-- <div>
        <textarea type="text" [(ngModel)]="i.ingredientList" class="editable-textinput"
            (ngModelChange)="onallergenDataChange('INGREDIENTS',i.componentID,i.ingredientList)"
            [ngStyle]="{'border-color': sameSet(i.componentID,'INGREDIENTS') ? 'rgba(0, 0, 0, 0.125)' : '#ff6600'}"></textarea>
    </div> -->

                    <div style="position: relative;">
                      <textarea type="text" [(ngModel)]="i.allergenList" class="editable-textinput"
                                style="min-height: 10%;"
                                (ngModelChange)="onallergenDataChange('ALLERGEN',i.componentID,i.allergenList)"
                                [ngStyle]="{'border-color': sameSet(i.componentID,'ALLERGEN') ? 'rgba(0, 0, 0, 0.125)' : '#ff6600'}"></textarea>
                      <button class="move-button" (click)="resetAllergenToOCR(i.componentID)"
                              style="position: absolute; top: -45%; left: 19%; height: 45%; background-color: #2f880f;color: white;">
                        <i class="bi bi-arrow-clockwise"></i>
                      </button>
                    </div>


                  </div>

                  <div class="card">

                    <div style=" background-color: #2f880f;color: white;border: 1px solid black;"><b>PNMS ATTRIBUTES</b></div>
                    <table style="width: 100%;">
                      <tr style=" background-color:rgb(243 243 243);">
                        <td>Attribute Name(UOM)</td>
                        <td style="width: 50px; height: 20px">Amount Per Serving</td>
                        <td style="width: 30px; height: 20px">DV%</td>
                      </tr>
                      <tr *ngFor="let j of GetNutrientsToShow(id)">
                        <td style="width: 100px;height: 35px;">{{j.nutrientName}}%</td>
                        <td style="width: 70px; height: 20px;">
                          <input type="text"
                                 [(ngModel)]="j.nutrValue"
                                 (ngModelChange)="onNutrientDispalyvalueChange(i.gtin, i.componentID, j.nutrientID, j.nutrValue)"
                                 [ngStyle]="{'border-color': getdifference(i.componentID, j.nutrientID, j.nutrValue, 'displayValue') ? 'rgba(0, 0, 0, 0.125)' : '#ff6600','border-bottom-style':
                        getdifference(i.componentID, j.nutrientID, j.nutrValue, 'displayValue') ? 'hidden': 'groove'}"
                                 style="width: 40px; height: 32px; display: inline-block; margin-right: 2px;border: none;"
                                 size="6"
                                 oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                 [readonly]="searchGtinResults.status !== 'draft'"
                                 (keypress)="validateInput($event)" />
                        </td>
                        <td style="width: 40px; height: 32px;">
                          <input type="text" [(ngModel)]="j.dvp"
                                 (ngModelChange)="onNutrientDvpvalueChange(i.gtin, i.componentID, j.nutrientID, j.dvp)"
                                 [ngStyle]="{'border-color': getdifference(i.componentID, j.nutrientID, j.dvp, 'dvp') ? 'rgba(0, 0, 0, 0.125)' : '#ff6600'
       ,'border-bottom-style': getdifference(i.componentID, j.nutrientID, j.dvp, 'dvp') ? 'hidden': 'groove'}"
                                 style="width: 40px; height: 32px; display: inline-block; margin-right: 2px;border: none;"
                                 size="6"
                                 oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                 [readonly]="searchGtinResults.status !== 'draft'" (keypress)="validateInput($event)" />
                        </td>
                      </tr>
                    </table>



                  </div>

             
                </div>
            </div>
        </div>
    </div>
</ng-container>
